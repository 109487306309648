import {
  N_UserProfile,
  UpdatedBy,
  UpdatedByUser,
} from "@specsheet-common/shared-types";

export const toUpdatedByUser = (profile: N_UserProfile): UpdatedByUser => {
  return { kind: "user", profile };
};

export const isUserUpdate = (
  updatedBy?: UpdatedBy
): updatedBy is UpdatedByUser => updatedBy?.kind === "user";
