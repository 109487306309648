export const encodeQueryParams = (
  route: string,
  queryParams: object
): string => {
  return `${route}?${Object.entries(queryParams)
    .filter((entry) => entry[1] !== undefined)
    .map(([key, value]) =>
      Array.isArray(value)
        ? value
            .map((v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`)
            .join("&")
        : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&")}`;
};
