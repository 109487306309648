import { idFlavor, instantZod } from "../../common";
import z, { boolean } from "zod";
import { SpecSheetTab } from "../placement";
import { N_CampaignTemplateColumnColour } from "../campaignTemplate";
import { InfoSchemaTableViewColumnType } from "./tableView";

export const fieldType = {
  TEXT: "TEXT",
  RICHTEXT: "RICHTEXT",
  NUMBER: "NUMBER",
  DATE: "DATE",
  BOOLEAN: "BOOLEAN",
  DATERANGE: "DATERANGE",
  DICTIONARY: "DICTIONARY",
} as const;

export type N_FieldType = (typeof fieldType)[keyof typeof fieldType];

export const fieldSystemType = {
  DISPATCH_DATE: "DISPATCH_DATE",
  FILE_NAME: "FILE_NAME",
  LIVE_DATE: "LIVE_DATE",
  END_DATE: "END_DATE",
} as const;

export type FieldSystemType =
  (typeof fieldSystemType)[keyof typeof fieldSystemType];

export const dictionaryTagColor = {
  red: "red",
  orange: "orange",
  yellow: "yellow",
  green: "green",
  turquoise: "turquoise",
  indigo: "indigo",
  purple: "purple",
  black: "black",
  none: "none",
} as const;

export type N_DictionaryTagColor =
  (typeof dictionaryTagColor)[keyof typeof dictionaryTagColor];

const infoSchemaFieldBaseValueZod = z.object({
  fieldType: z.nativeEnum(fieldType),
  options: z
    .object({
      mergeCells: z.boolean().nullish(),
      isSticky: z.boolean().nullish(),
    })
    .or(z.undefined()),
});

const infoSchemaFieldDbValuesZod = z.object({
  id: z.string().min(1),
  isLimited: boolean(),
  updated: z.number(),
  systemType: z.nativeEnum(fieldSystemType).nullish(),
  created: z.number(),
  label: z.string(),
});

export const infoSchemaDictionaryFieldZod = infoSchemaFieldBaseValueZod.merge(
  z.object({
    fieldType: z.literal(fieldType.DICTIONARY),
    options: z
      .object({
        useAsRowColour: z.boolean().optional(),
        allowTagCreation: z.boolean().optional(),
        mergeCells: z.boolean().nullish(),
        isSticky: z.boolean().nullish(),
        values: z
          .record(
            z.string(),
            z.object({
              name: z.string(),
              color: z.nativeEnum(dictionaryTagColor),
              created: instantZod.optional(),
            })
          )
          .optional(),
      })
      .or(z.undefined()),
  })
);

const infoSchemaTextFieldZod = infoSchemaFieldBaseValueZod.merge(
  z.object({
    fieldType: z.literal(fieldType.TEXT),
    options: z
      .object({
        hasCharacterLimit: z.boolean().optional(),
        characterLimit: z.number().optional(),
        mergeCells: z.boolean().nullish(),
        isSticky: z.boolean().nullish(),
      })
      .or(z.undefined()),
  })
);

const infoSchemaRichTextFieldZod = infoSchemaFieldBaseValueZod.merge(
  z.object({
    fieldType: z.literal(fieldType.RICHTEXT),
    options: z
      .object({
        hasCharacterLimit: z.boolean().optional(),
        characterLimit: z.number().optional(),
        mergeCells: z.boolean().nullish(),
        isSticky: z.boolean().nullish(),
      })
      .or(z.undefined()),
  })
);

const infoSchemaDateFieldZod = infoSchemaFieldBaseValueZod.merge(
  z.object({
    fieldType: z.literal(fieldType.DATE),
    options: z
      .object({
        dateOffset: z.number().optional(),
        offsetColumnId: z.string().optional(),
        isOffsetAfter: z.boolean().optional(),
        mergeCells: z.boolean().nullish(),
        isSticky: z.boolean().nullish(),
      })
      .or(z.undefined()),
  })
);

export const infoSchemaDictionaryFieldWithDbZod =
  infoSchemaDictionaryFieldZod.merge(infoSchemaFieldDbValuesZod);
const infoSchemaTextFieldWithDbZod = infoSchemaTextFieldZod.merge(
  infoSchemaFieldDbValuesZod
);
const infoSchemaRichTextFieldWithDbZod = infoSchemaRichTextFieldZod.merge(
  infoSchemaFieldDbValuesZod
);
const infoSchemaDateFieldWithDbZod = infoSchemaDateFieldZod.merge(
  infoSchemaFieldDbValuesZod
);

const infoSchemaDefaultFieldZod = infoSchemaFieldBaseValueZod.merge(
  z.object({
    fieldType: z.enum([
      fieldType.NUMBER,
      fieldType.BOOLEAN,
      fieldType.DATERANGE,
    ]),
  })
);

export const infoSchemaFieldViewZod = z.union([
  infoSchemaDictionaryFieldZod,
  infoSchemaTextFieldZod,
  infoSchemaRichTextFieldZod,
  infoSchemaDateFieldZod,
  infoSchemaDefaultFieldZod,
]);

export const infoSchemaFieldWithDbValuesZod = z.union([
  infoSchemaDictionaryFieldWithDbZod,
  infoSchemaTextFieldWithDbZod,
  infoSchemaRichTextFieldWithDbZod,
  infoSchemaDateFieldWithDbZod,
  infoSchemaDefaultFieldZod.merge(infoSchemaFieldDbValuesZod),
]);

export type InfoSchemaFieldView = z.infer<
  typeof infoSchemaFieldWithDbValuesZod
>;
export type InfoSchemaFieldViewSimple = z.infer<typeof infoSchemaFieldViewZod>;

export type InfoSchemaDictionaryFieldViewSimple = z.infer<
  typeof infoSchemaDictionaryFieldZod
>;
export type InfoSchemaDictionaryFieldView = z.infer<
  typeof infoSchemaDictionaryFieldWithDbZod
>;
export type InfoSchemaTextFieldView = z.infer<
  typeof infoSchemaTextFieldWithDbZod
>;
export type InfoSchemaRichTextFieldView = z.infer<
  typeof infoSchemaRichTextFieldWithDbZod
>;
export type InfoSchemaDateFieldView = z.infer<
  typeof infoSchemaDateFieldWithDbZod
>;
export type InfoSchemaOptions = InfoSchemaDictionaryFieldView["options"] &
  InfoSchemaTextFieldView["options"] &
  InfoSchemaRichTextFieldView["options"] &
  InfoSchemaDateFieldView["options"];

export interface InfoSchemaFieldWithDefaultElements {
  id?: idFlavor<"InfoSchemaField">;
  fieldLabel: string;
  systemType?: FieldSystemType | null;
  fieldType: N_FieldType;
  position: number;
  tableViewColumnType: InfoSchemaTableViewColumnType;
  isLimited: boolean | undefined;
  isHidden: boolean | undefined;
  options?: InfoSchemaOptions;
  positionByTab?: Partial<Record<SpecSheetTab, number>>;
  isHiddenByTab?: Partial<Record<SpecSheetTab, boolean>>;
  color?: N_CampaignTemplateColumnColour;
}
