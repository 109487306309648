export function memoize<T extends (...args: Parameters<T>) => ReturnType<T>>(
  func: T
): {
  cacheFn: (...args: Parameters<T>) => ReturnType<T>;
  clear(): void;
} {
  const cache = new Map<string, ReturnType<T>>();

  const cacheFn = (...args: Parameters<T>): ReturnType<T> => {
    const key = JSON.stringify(args);

    if (cache.has(key)) {
      return cache.get(key)!;
    }

    const result = func(...args);
    cache.set(key, result);
    return result;
  };

  const clear = cache.clear;
  return {
    cacheFn,
    clear,
  };
}
