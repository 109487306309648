import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { TSSAuth0SquareLogoIcon } from "../shared/atoms/icons/TSSAuth0SquareLogoIcon";

interface Auth0DialogProps {
  hideLogo?: boolean;
  hideTitle?: boolean;
  title?: string;
}

export const Auth0Dialog: React.FC<Auth0DialogProps> = ({
  hideLogo,
  hideTitle,
  title,
  children,
}) => (
  <Box
    sx={{
      flex: "1 1 auto",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: (theme) => theme.colors.colorGray,
    }}
  >
    <Box
      sx={{
        maxWidth: "400px",
        width: "100%",
        py: 4,
        px: 4,
        textAlign: "center",
        backgroundColor: "#fbfbfd",
        borderRadius: "5px",
        boxShadow: "0 12px 40px rgb(0 0 0 / 12%)",
      }}
    >
      <Stack>
        {hideLogo ? null : (
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TSSAuth0SquareLogoIcon />
          </Box>
        )}
        {!title || hideTitle ? null : (
          <Typography align="center" component="h1" variant="h4" mt={2.4}>
            {title}
          </Typography>
        )}
        <>{children}</>
      </Stack>
    </Box>
  </Box>
);
