import { idFlavor } from "../../common";

export const clientSubRoute = {
  list: "list",
  view: "",
  create: "",
  update: "",
} as const;

export type ClientRoutes =
  | {
      sub: typeof clientSubRoute.list;
      tailParam: idFlavor<"Workspace">;
      method?: "Get";
    }
  | {
      sub: typeof clientSubRoute.view;
      tailParam: idFlavor<"Client">;
      method?: "Get";
    }
  | {
      sub: typeof clientSubRoute.create;
      method?: "Post";
    }
  | {
      sub: typeof clientSubRoute.update;
      tailParam: idFlavor<"Campaign">;
      method?: "Patch";
    };
