import { idFlavor } from "../../common";

export const actionLinkSubRoute = {
  useActionLink: "use",
  getActivationData: "getactivationdata",
} as const;

export type ActionLinkRoutes =
  | {
      sub: typeof actionLinkSubRoute.useActionLink;
      method?: "Post";
    }
  | {
      sub: typeof actionLinkSubRoute.getActivationData;
      method?: "Get";
      tailParam: idFlavor<"ActionLinkToken">;
    };
