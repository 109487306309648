import { Components, Theme } from "@mui/material";

export const MuiToggleButton: Components<Theme>["MuiToggleButton"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: "none",
      padding: theme.spacing(2, 3),
      border: "none",
      display: "flex",
      gap: theme.spacing(2),
    }),
    sizeMedium: ({ theme }) => ({
      ...theme.typography.subtitle1,
    }),
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(0, 1),
      ["& .MuiSvgIcon-root"]: {
        padding: 1,
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    }),
  },
};
