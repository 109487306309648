import { idFlavor } from "src/common";

export const campaignUserSubRoute = {
  invite: "invite",
  list: "list",
  updateRole: "updateRole",
  remove: "remove",
  resendInvite: "resendinvite",
  updateOwnership: "updateOwnership",
} as const;

export const campaignUserUrls = {
  "campaign.users": "campaignusers",
  "campaign.users.invite": "campaignusers/invite",
};

export type CampaignUserRoutes =
  | {
      sub: typeof campaignUserSubRoute.invite;
      method?: "Post";
    }
  | {
      sub: typeof campaignUserSubRoute.list;
      tailParam: idFlavor<"Campaign">;
      method?: "Get";
    }
  | {
      sub: typeof campaignUserSubRoute.updateRole;
      method?: "Post";
    }
  | {
      sub: typeof campaignUserSubRoute.remove;
      method?: "Post";
    }
  | {
      sub: typeof campaignUserSubRoute.resendInvite;
      method?: "Post";
    }
  | {
      sub: typeof campaignUserSubRoute.updateOwnership;
      method?: "Post";
    };
