import z from "zod";

export const mediaWorksBookingProperties = {
  panelNumber: "panelNumber",
  panelName: "panelName",
  bookingCode: "bookingCode",
  campaignCode: "campaignCode",
  campaignName: "campaignName",
  client: "client",
  agency: "agency",
  bookingType: "bookingType",
  auditor: "auditor",
  salesRep: "salesRep",
  slotsBooked: "slotsBooked",
  bookingStartDate: "bookingStartDate",
  bookingEndDate: "bookingEndDate",
  digital: "digital",
};

export const mediaWorksBookingSchema = z.object({
  panelNumber: z.string(),
  panelName: z.string(),
  bookingCode: z.string(),
  campaignCode: z.string(),
  campaignName: z.string(),
  client: z.string(),
  agency: z.string(),
  bookingType: z.string(),
  auditor: z.string(),
  salesRep: z.string(),
  slotsBooked: z.string(),
  bookingStartDate: z.string(),
  bookingEndDate: z.string(),
  digital: z.string(),
  static: z.string(),
  transit: z.string(),
});

export type MediaWorksBooking = z.infer<typeof mediaWorksBookingSchema>;
