import {
  N_ArtworkSpec,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { notNull } from "../../typescript";
import { stringifyPrintDimension } from "./stringify";

export const getPhysicalDimensions = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>((dimensions, spec) => {
    if (spec.value.kind !== "printOOH") {
      return dimensions;
    }

    if (spec.value.value.physicalDimension === null) {
      return dimensions;
    }

    return [
      ...dimensions,
      notNull(spec.value.value.physicalDimension.value.finishSize)
        ? {
            ...spec,
            name: "Finish Size",
            value: stringifyPrintDimension(
              spec.value.value.physicalDimension.value.finishSize.value
            ),
          }
        : null,
      notNull(spec.value.value.physicalDimension.value.visualSize)
        ? {
            ...spec,
            name: "Visual Size",
            value: stringifyPrintDimension(
              spec.value.value.physicalDimension.value.visualSize.value
            ),
          }
        : null,
    ].filter(notNull);
  }, []);
