export const getEnv = () => {
  const host = window.location.host;

  if (host.includes("ops.develop.thespecsheet.com")) {
    return "ops.develop";
  }

  if (host.includes("ops.testing.thespecsheet.com")) {
    return "ops.testing";
  }

  if (host.includes("ops.staging.thespecsheet.com")) {
    return "ops.staging";
  }

  if (host.includes("ops.prod.thespecsheet.com")) {
    return "ops.prod";
  }

  if (host.includes("ops.thespecsheet.com")) {
    return "prod";
  }

  return "local";
};
