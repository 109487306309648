import { N_DataOrigin_Url } from "@specsheet-common/shared-types";

export interface DB_PLAIN_URL {
  url: string;
}

export const convertPlainUrlToN_DataOrigin_Url = (
  data: DB_PLAIN_URL
): N_DataOrigin_Url => {
  return { kind: "url", value: data.url };
};
