import { getEnv } from "./util";

const data = {
  "ops.testing": {
    authURL: "https://auth.testing.thespecsheet.com",
    coreApiURL: "https://coreapi.testing.thespecsheet.com",
    legacyApiURL: "https://legacyapi.testing.thespecsheet.com",
    socketIoURL: "https://ws.testing.thespecsheet.com",
  },
  "ops.develop": {
    authURL: "https://auth.develop.thespecsheet.com",
    coreApiURL: "https://coreapi.develop.thespecsheet.com",
    legacyApiURL: "https://legacyapi.develop.thespecsheet.com",
    socketIoURL: "https://ws.develop.thespecsheet.com",
  },
  "ops.staging": {
    authURL: "https://auth.staging.thespecsheet.com",
    coreApiURL: "https://coreapi.staging.thespecsheet.com",
    legacyApiURL: "https://legacyapi.staging.thespecsheet.com",
    socketIoURL: "https://ws.staging.thespecsheet.com",
  },
  "ops.prod": {
    authURL: "https://auth.prod.thespecsheet.com",
    coreApiURL: "https://coreapi.prod.thespecsheet.com",
    legacyApiURL: "https://legacyapi.prod.thespecsheet.com",
    socketIoURL: "https://ws.prod.thespecsheet.com",
  },
  prod: {
    authURL: "https://auth.thespecsheet.com",
    coreApiURL: "https://coreapi.thespecsheet.com",
    legacyApiURL: "https://legacyapi.thespecsheet.com",
    socketIoURL: "https://ws.thespecsheet.com",
  },
  local: {
    authURL: "http://localhost:3030",
    coreApiURL: "http://localhost:3010",
    legacyApiURL: "http://localhost:8081",
    socketIoURL: "http://localhost:3050",
  },
};

const urls = data[getEnv()];

export const authURL = urls.authURL;
export const coreApiURL = urls.coreApiURL;
export const legacyApiURL = urls.legacyApiURL;
export const socketIoURL = urls.socketIoURL;
