import React from "react";
import { LoadingButton } from "../temporaryComponentsToShare/LoadingButton";
import { legacyApiURL } from "../../configuration/constants/url";
import { useAuth0 } from "@auth0/auth0-react";
import { run } from "@specsheet-common/shared-tools";

export interface DownloadLinkSpinnerProps {
  fetch: {
    input: RequestInfo;
    init?: RequestInit;
  };
  downloadAsFilename: string;
}

export function DownloadLinkSpinner(props: DownloadLinkSpinnerProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = React.useState<string | null>(null);

  React.useEffect(() => {
    void run(async () => {
      setAccessToken(await getAccessTokenSilently());
    });
  }, []);

  const init = props.fetch.init;

  const getRequestInit = (): RequestInit | undefined => {
    if (!accessToken) {
      return init;
    }

    if (init) {
      return {
        ...init,
        headers: { ...init.headers, "X-Auth-Token": accessToken },
      };
    }

    return { headers: { "X-Auth-Token": accessToken } };
  };

  async function download() {
    const resp = await fetch(
      `${legacyApiURL}${props.fetch.input}`,
      getRequestInit()
    );
    const blob = await resp.blob();
    const dataUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = dataUrl;
    anchor.download = props.downloadAsFilename;
    // eslint-disable-next-line unicorn/prefer-dom-node-append
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
  }

  return (
    <LoadingButton color="green" onClick={download}>
      DOWNLOAD
    </LoadingButton>
  );
}
