import { Components, Theme } from "@mui/material";

export const MuiContainer: Components<Theme>["MuiContainer"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ["&.MuiContainer-maxWidthLg"]: {
        maxWidth: "none",
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    }),
  },
};
