export const formatUrl = (url?: string) => {
  if (!url) {
    return "";
  }
  if (url.startsWith("https://")) {
    return url;
  }
  if (url.startsWith("http://")) {
    return url.replace("http", "https");
  }
  return "https://" + url;
};
