export const compare2Sets = <T extends string | number>(
  firstSet: Set<T>,
  secondSet: Set<T>
): { remains: T[]; removed: T[]; added: T[] } => {
  const firstSetArray = [...firstSet];
  const removed = firstSetArray.filter((el) => !secondSet.has(el));
  const added = [...secondSet].filter((el) => !firstSet.has(el));
  const remains = firstSetArray.filter((el) => secondSet.has(el));

  return { remains, removed, added };
};

export const sets = {
  compare2Sets: compare2Sets,
};
