import { N_Unit, N_Url, N_UrlResource } from "../common";

export const frameRateUnit = {
  perSecond: "perSecond",
} as const;

export type N_FrameRateUnit =
  (typeof frameRateUnit)[keyof typeof frameRateUnit];

export const sampleRateUnit = {
  kiloHertz: "kiloHertz",
} as const;

export type N_SampleRateUnit =
  (typeof sampleRateUnit)[keyof typeof sampleRateUnit];

export const fileSizeUnit = {
  kb: "kb",
  mb: "mb",
  gb: "gb",
} as const;

export type N_FileSizeUnit = (typeof fileSizeUnit)[keyof typeof fileSizeUnit];

export const bitRateUnit = {
  Mbps: "Mbps",
  Kbps: "Kbps",
} as const;

export type N_BitRateUnit = (typeof bitRateUnit)[keyof typeof bitRateUnit];

export interface N_Specifications_Components {
  kind: "components";
  value: N_NamedAnnotated<N_ArtworkSpec>[];
}

export type N_Specifications = N_Specifications_Components;

export type N_VectorNamedAnnotated<T> = N_NamedAnnotated<T>[];

export type N_VectorAnnotated<T> = N_Annotated<T>[];

export interface N_ArtworkSpec_DigitalStatic {
  kind: "digitalStatic";
  value: N_DigitalStaticMedia;
}
export interface N_ArtworkSpec_Animated {
  kind: "animated";
  value: N_AnimatedMedia;
}
export interface N_ArtworkSpec_Html5 {
  kind: "html5";
  value: N_Html5Media;
}
export interface N_ArtworkSpec_Video {
  kind: "video";
  value: N_Video;
}
export interface N_ArtworkSpec_Audio {
  kind: "audio";
  value: N_Audio;
}
export interface N_ArtworkSpec_Copy {
  kind: "copy";
  value: N_CopyMedia;
}
export interface N_ArtworkSpec_Newspaper {
  kind: "newspaper";
  value: N_PrintNewspaper;
}
export interface N_ArtworkSpec_Magazine {
  kind: "magazine";
  value: N_PrintMagazine;
}
export interface N_ArtworkSpec_PrintOOH {
  kind: "printOOH";
  value: N_PrintOOH;
}

/**
 * Artwork Specification - one of a family of appropriate properties per media type
 */
export type N_ArtworkSpec =
  | N_ArtworkSpec_DigitalStatic
  | N_ArtworkSpec_Animated
  | N_ArtworkSpec_Html5
  | N_ArtworkSpec_Video
  | N_ArtworkSpec_Audio
  | N_ArtworkSpec_Copy
  | N_ArtworkSpec_Newspaper
  | N_ArtworkSpec_Magazine
  | N_ArtworkSpec_PrintOOH;

export interface N_Annotated<T> {
  metadata: N_Metadata | null;
  value: T;
}

export interface N_NamedAnnotated<T> {
  metadata: N_Metadata | null;
  name: string | null;
  value: T;
}

export interface N_Metadata {
  notes: string | null;
  images: N_UrlResource[];
  authorNotes: N_AuthorNotes[];
}

export interface N_AuthorNotes {
  author: string;
  reason: N_AuthorNotesReason;
  notes: string | null;
}

export interface N_AuthorNotesReason_Clarification {
  kind: "clarification";
}
export interface N_AuthorNotesReason_Underspecified {
  kind: "underspecified";
}
export interface N_AuthorNotesReason_Other_ {
  kind: "other_";
  value: string;
}

export type N_AuthorNotesReason =
  | N_AuthorNotesReason_Clarification
  | N_AuthorNotesReason_Underspecified
  | N_AuthorNotesReason_Other_;

export interface N_PrintResolution {
  amount: number;
  unit: N_PrintResolutionUnit;
}

export interface N_PrintResolutionUnit_DPI {
  kind: "DPI";
}
export interface N_PrintResolutionUnit_LPI {
  kind: "LPI";
}
export interface N_PrintResolutionUnit_Other {
  kind: "other";
  value: string;
}

export type N_PrintResolutionUnit =
  | N_PrintResolutionUnit_DPI
  | N_PrintResolutionUnit_LPI
  | N_PrintResolutionUnit_Other;

export interface N_DigitalResolution {
  amount: number;
  unit: N_DigitalResolutionUnit;
}

export interface N_DigitalResolutionUnit_DPI {
  kind: "DPI";
}
export interface N_DigitalResolutionUnit_PPI {
  kind: "PPI";
}
export interface N_DigitalResolutionUnit_Other {
  kind: "other";
  value: string;
}

/**
 * Digital Resolution
 */
export type N_DigitalResolutionUnit =
  | N_DigitalResolutionUnit_DPI
  | N_DigitalResolutionUnit_PPI
  | N_DigitalResolutionUnit_Other;

export interface N_DigitalStaticMedia {
  dimensions: N_Annotated<N_DigitalMediaDimensions>;
  maxFileSize: N_Annotated<N_DataAmount> | null;
  safeZone: N_Annotated<N_Unit> | null;
  DPI: N_Annotated<number> | null;
  resolution: N_Annotated<N_DigitalResolution> | null;
  aspectRatios: N_Annotated<N_VideoAspectRatios> | null;
  color: N_Annotated<N_DigitalColorSpace> | null;
  border: N_Annotated<N_Border> | null;
  /**
   * Supported formats for static media
   */
  formats: N_Annotated<N_StaticMediaFormat> | null;
  /**
   * Untyped general notes / properties
   */
  attributes: N_Attributes;
}

export type N_AdditionalPrintDimensions =
  N_VectorNamedAnnotated<N_PrintDimensions>;

export const dimensionUnit = {
  m: "m",
  cm: "cm",
  mm: "mm",
} as const;

export type N_DimensionUnit =
  (typeof dimensionUnit)[keyof typeof dimensionUnit];

export interface N_PrintDimension {
  value: number;
  unit: N_DimensionUnit;
}

export interface N_PrintDimensions {
  height: N_PrintDimension;
  width: N_PrintDimension;
}

export interface N_PrintBorderDimensions {
  top: N_PrintDimension;
  left: N_PrintDimension;
  right: N_PrintDimension;
  bottom: N_PrintDimension;
}

export interface N_PrintMediaFormat {
  pdf: N_PDF | null;
  eps: N_EPS | null;
  ai: N_AI | null;
  indd: N_INDD | null;
  psd: N_PSD | null;
  quarkXPress: N_QuarkXPress | null;
  other: N_VectorAnnotated<string>;
}

export interface N_PrintNewspaper {
  dimension: N_Annotated<N_PrintDimensions> | null;
  trim: N_Annotated<N_PrintDimensions> | null;
  additionalPrintDimensions: N_Annotated<N_AdditionalPrintDimensions> | null;
  textSafeArea: N_Annotated<N_PrintBorderDimensions> | null;
  textFreeGutter: N_Annotated<string> | null;
  bleed: N_Annotated<N_PrintBorderDimensions> | null;
  finishedTrimBleed: N_Annotated<N_PrintDimensions> | null;
  resolution: N_Annotated<N_PrintResolution> | null;
  colourProfile: N_Annotated<string> | null;
  separationProfile: N_Annotated<string> | null;
  inkWeightCoverage: N_Annotated<string> | null;
  blackInkLimit: N_Annotated<string> | null;
  dotGain: N_Annotated<string> | null;
  highlightSet: N_Annotated<string> | null;
  formats: N_Annotated<N_PrintMediaFormat>;
  /**
   * Max file weight
   */
  maxFileSize: N_Annotated<N_DataAmount> | null;
  font: N_Annotated<string> | null;
  spread: N_Annotated<string> | null;
  placementInNewspaper: N_Annotated<string> | null;
  /**
   * Untyped general notes / properties
   */
  attributes: N_Attributes;
}

export interface N_PrintMagazine {
  trim: N_Annotated<N_PrintDimensions> | null;
  design: N_Annotated<N_PrintDimensions> | null;
  textSafeArea: N_Annotated<N_PrintBorderDimensions> | null;
  bleed: N_Annotated<N_PrintBorderDimensions> | null;
  finishedTrimBleed: N_Annotated<N_PrintDimensions> | null;
  textFreeGutter: N_Annotated<string> | null;
  resolution: N_Annotated<N_PrintResolution> | null;
  colourProfile: N_Annotated<string> | null;
  separationProfile: N_Annotated<string> | null;
  inkWeightCoverage: N_Annotated<string> | null;
  dotGain: N_Annotated<string> | null;
  formats: N_Annotated<N_PrintMediaFormat>;
  font: N_Annotated<string> | null;
  scaling: N_Annotated<number> | null;
  paperType: N_Annotated<string> | null;
  finish: N_Annotated<string> | null;
  binding: N_Annotated<string> | null;
  placementInMagazine: N_Annotated<string> | null;
  /**
   * Max file weight
   */
  maxFileSize: N_Annotated<N_DataAmount> | null;
  /**
   * Untyped general notes / properties
   */
  attributes: N_Attributes;
}

export interface N_PrintDimensionOptions {
  visualSize: N_Annotated<N_PrintDimensions> | null;
  finishSize: N_Annotated<N_PrintDimensions> | null;
}

export interface N_Frontlit {}
export interface N_Backlit {}
export interface N_FrontlitTop {}
export interface N_FrontlitBottom {}

export interface N_Illumination_Backlit {
  kind: "backlit";
  value: N_Backlit;
}
export interface N_Illumination_Frontlit {
  kind: "frontlit";
  value: N_Frontlit;
}

export interface N_Illumination_FrontlitTop {
  kind: "frontlitTop";
  value: N_FrontlitTop;
}
export interface N_Illumination_FrontlitBottom {
  kind: "frontlitBottom";
  value: N_FrontlitBottom;
}

export type N_Illumination =
  | N_Illumination_Backlit
  | N_Illumination_Frontlit
  | N_Illumination_FrontlitTop
  | N_Illumination_FrontlitBottom;

export interface N_PrintOOH {
  artworkDimension: N_Annotated<N_PrintDimensionOptions> | null;
  artworkBleed: N_Annotated<N_PrintBorderDimensions> | null;
  artworkScale: N_Annotated<string> | null;
  visualSafeArea: N_Annotated<N_Unit> | null;
  physicalDimension: N_Annotated<N_PrintDimensionOptions> | null;
  physicalBleed: N_Annotated<N_PrintBorderDimensions> | null;
  finishedEdgedDimensions: N_Annotated<string> | null;
  edging: N_Annotated<N_PrintBorderDimensions> | null;
  illumination: N_Annotated<N_Illumination> | null;
  install: N_Annotated<string> | null;
  formats: N_Annotated<N_PrintMediaFormat> | null;
  maxFileSize: N_Annotated<N_DataAmount> | null;
  resolution: N_Annotated<N_PrintResolution> | null;
  font: N_Annotated<string> | null;
  colourProfile: N_Annotated<string> | null;
  blackSpecification: N_Annotated<string> | null;
  material: N_Annotated<string> | null;
  materialAttributes: N_Attributes | null;
  attributes: N_Attributes;
}

export interface N_PixelDimension {
  width: number;
  height: number;
}

/**
 * Listing of dimension properties in a flat list.
 *   - covers variations by standard/retina expanded/collapsed
 *   - May be used to cover *minor* cases of alternate dimensions (major alternate cases should be different placements entirely)
 */
export type N_DigitalMediaDimensions =
  N_VectorNamedAnnotated<N_DigitalMediaDimension>;

export interface N_DigitalMediaDimension {
  pixelDimension: N_PixelDimension;
  /**
   * Resolution type
   */
  resolutionType: N_DimensionResolutionType | null;
  sizeCase: N_DimensionSizeCase | null;
}

export interface N_DimensionResolutionType_Standard {
  kind: "standard";
}
export interface N_DimensionResolutionType_Retina {
  kind: "retina";
}
export interface N_DimensionResolutionType_Other {
  kind: "other";
  value: string;
}

/**
 * Dimension Resolution Type (standard or retina)
 */
export type N_DimensionResolutionType =
  | N_DimensionResolutionType_Standard
  | N_DimensionResolutionType_Retina
  | N_DimensionResolutionType_Other;

export interface N_DimensionSizeCase_Regular {
  kind: "regular";
}
export interface N_DimensionSizeCase_Expanded {
  kind: "expanded";
}
export interface N_DimensionSizeCase_Collapsed {
  kind: "collapsed";
}
export interface N_DimensionSizeCase_Other {
  kind: "other";
  value: string;
}

/**
 * Dimension size use-case (regular vs expanded or collapsed)
 */
export type N_DimensionSizeCase =
  | N_DimensionSizeCase_Regular
  | N_DimensionSizeCase_Expanded
  | N_DimensionSizeCase_Collapsed
  | N_DimensionSizeCase_Other;

/**
 * Static media format
 */
export interface N_StaticMediaFormat {
  jpg: N_JPG | null;
  bmp: N_BMP | null;
  gif: N_GIF | null;
  png: N_PNG | null;
  eps: N_EPS | null;
  ai: N_AI | null;
  tiff: N_TIFF | null;
  psdLayered: N_PSD | null;
  psdFlattened: N_PSD | null;
  svg: N_SVG | null;
  other: N_VectorAnnotated<string>;
}

export interface N_JPG {}
export interface N_BMP {}
export interface N_SVG {}
export interface N_GIF {
  transparency: N_Unit | null;
}
export interface N_PDF {}
export interface N_INDD {}
export interface N_QuarkXPress {}
export interface N_PNG {
  transparency: N_Unit | null;
  png8: N_PNGFormat | null;
  png24: N_PNGFormat | null;
}
export interface N_PNGFormat {}
export interface N_EPS {
  convertToOutline: N_Unit | null;
}
export interface N_AI {
  convertToOutline: N_Unit | null;
}
export interface N_TIFF {}
export interface N_PSD {
  psdExampleUrl: N_Url | null;
}

export type N_DataAmounts = N_VectorNamedAnnotated<N_DataAmountProperty>;

export interface N_DataAmountProperty {
  amount: N_DataAmount;
  kind: N_DataAmountType;
}

export interface N_DataAmountType_Total {
  kind: "total";
}
export interface N_DataAmountType_Initial {
  kind: "initial";
}
export interface N_DataAmountType_Polite {
  kind: "polite";
}
export interface N_DataAmountType_UserInitiated {
  kind: "userInitiated";
}
export interface N_DataAmountType_AdVerificationScripts {
  kind: "adVerificationScripts";
}
export interface N_DataAmountType_HostedSharedLibrary {
  kind: "hostedSharedLibrary";
}
export interface N_DataAmountType_Other {
  kind: "other";
  value: string;
}

export type N_DataAmountType =
  | N_DataAmountType_Total
  | N_DataAmountType_Initial
  | N_DataAmountType_Polite
  | N_DataAmountType_UserInitiated
  | N_DataAmountType_AdVerificationScripts
  | N_DataAmountType_HostedSharedLibrary
  | N_DataAmountType_Other;

export interface N_AnimatedMedia {
  dimensions: N_Annotated<N_DigitalMediaDimensions>;
  durations: N_Annotated<N_Durations>;
  loop: N_Annotated<N_Unit> | null;
  loopCount: N_Annotated<number> | null;
  endWithStaticFrame: N_Annotated<boolean>;
  safeZone: N_Annotated<N_Unit> | null;
  DPI: N_Annotated<number> | null;
  resolution: N_Annotated<N_DigitalResolution> | null;
  color: N_Annotated<N_RGBOnlyColorSpace> | null;
  border: N_Annotated<N_Border> | null;
  formats: N_Annotated<N_AnimatedMediaFormat>;
  maxFileSize: N_Annotated<N_DataAmount> | null;
  attributes: N_Attributes;
}

export interface N_Border_SinglePixel {
  kind: "singlePixel";
}
export interface N_Border_Other {
  kind: "other";
  value: string;
}

export type N_Border = N_Border_SinglePixel | N_Border_Other;

export type N_Durations = N_VectorNamedAnnotated<N_Duration>;

export interface N_Duration {
  hours: number | null;
  minutes: number | null;
  seconds: number | null;
  milliSeconds: number | null;
}

export interface N_AnimatedMediaFormat {
  gif: N_GIF | null;
  other: N_VectorAnnotated<string>;
}

export interface N_Html5Formats {
  static: N_Annotated<N_StaticMediaFormat>;
  animated: N_Annotated<N_AnimatedMediaFormat>;
  web: N_Annotated<N_WebMediaFormat>;
  video: N_Annotated<N_Html5Video> | null;
}

export interface N_WebMediaFormat {
  HTML5: N_Unit | null;
  CSS: N_Unit | null;
  JS: N_Unit | null;
}

export interface N_Html5Video {
  video: N_Video;
}

export interface N_Html5Media {
  dimensions: N_Annotated<N_DigitalMediaDimensions>;
  safeZone: N_Annotated<N_Unit> | null;
  DPI: N_Annotated<number> | null;
  resolution: N_Annotated<N_DigitalResolution> | null;
  color: N_Annotated<N_DigitalColorSpace> | null;
  border: N_Annotated<N_Border> | null;
  dataAmounts: N_Annotated<N_DataAmounts> | null;
  formats: N_Annotated<N_Html5Formats>;
  durations: N_Annotated<N_Durations> | null;
  frameRate: N_Annotated<N_FrameRate> | null;
  loop: N_Annotated<N_Unit> | null;
  loopCount: N_Annotated<number> | null;
  accepted3rdPartyVendors: N_Attributes | null;
  attributes: N_Attributes;
}

export interface N_FrameRate {
  value: number;
  unit: N_FrameRateUnit;
}

export interface N_Video {
  dimensions: N_Annotated<N_DigitalMediaDimensions>;
  orientation: N_Annotated<N_MediaOrientation> | null;
  aspectRatios: N_Annotated<N_VideoAspectRatios> | null;
  safeZone: N_Annotated<N_Unit> | null;
  durations: N_Annotated<N_Durations> | null;
  formats: N_Annotated<N_VideoFormat>;
  codec: N_Annotated<N_VideoCodec> | null;
  frameRate: N_Annotated<N_FrameRate> | null;
  profile: N_Annotated<string> | null;
  profileLevel: N_Annotated<string> | null;
  bitrate: N_Annotated<N_BitRate> | null;
  fieldRender: N_Annotated<string> | null;
  loop: N_Annotated<N_Unit> | null;
  loopCount: N_Annotated<number> | null;
  audio: N_Annotated<N_VideoAudioSupport> | null;
  subtitles: N_Annotated<N_Unit> | null;
  script: N_Annotated<N_VideoScriptSupport> | null;
  dataAmounts: N_Annotated<N_DataAmounts> | null;
  maxFileSize: N_Annotated<N_DataAmount> | null;
  accepted3rdPartyVendors: N_Attributes | null;
  /**
   * Untyped general notes / properties
   */
  attributes: N_Attributes;
}

export interface N_VideoAspectRatios {
  square: N_Unit | null;
  portrait_9_16: N_Unit | null;
  landscape_16_9: N_Unit | null;
  portrait_4_5: N_Unit | null;
  custom: N_VectorAnnotated<N_CustomAspectRatio>;
  other: N_VectorAnnotated<string>;
}

export interface N_CustomAspectRatio {
  name: string;
  width: number;
  height: number;
}

export interface N_BitRate {
  value: number;
  unit: N_BitRateUnit;
}

export interface N_VideoAudioSupport {
  formats: N_Annotated<N_AudioFormat>;
  decibels: N_Annotated<string> | null;
  audioBitrate: N_Annotated<N_BitRate> | null;
  sampleRate: N_Annotated<N_SampleRate> | null;
}

export interface N_VideoScriptSupport {
  scriptFormat: N_VideoScriptFormat;
}

export interface N_Audio {
  durations: N_Annotated<N_Durations>;
  formats: N_Annotated<N_AudioFormat>;
  codecs: N_Annotated<N_AudioCodecs> | null;
  bitrate: N_Annotated<N_BitRate> | null;
  sampleRate: N_Annotated<N_SampleRate> | null;
  decibels: N_Annotated<string> | null;
  maxFileSize: N_Annotated<N_DataAmount> | null;
  adServing: N_Annotated<string> | null;
  tracking: N_Annotated<string> | null;
  attributes: N_Attributes;
}

export type N_AudioCodecs = N_VectorNamedAnnotated<string>;

export interface N_SampleRate {
  value: number;
  unit: N_SampleRateUnit;
}

export interface N_MediaOrientation {
  landscape: N_Unit | null;
  portrait: N_Unit | null;
  square: N_Unit | null;
  other: N_VectorAnnotated<string>;
}

export interface N_VideoScriptFormat {
  VAST: N_Unit | null;
  VPAID: N_Unit | null;
}

/**
 * Video format
 */
export interface N_VideoFormat {
  MP4: N_Unit | null;
  WebM: N_Unit | null;
  MOV: N_Unit | null;
  WMV: N_Unit | null;
  AVI: N_Unit | null;
  OGG: N_Unit | null;
  ThreeGP: N_Unit | null;
  FLV: N_Unit | null;
  other: N_VectorAnnotated<string>;
}

/**
 * Video codec
 */
export interface N_VideoCodec {
  avc_h264: N_Unit | null;
  hevc_h265: N_Unit | null;
  mpeg2: N_Unit | null;
  mpeg4: N_Unit | null;
  divx: N_Unit | null;
  Webm_vp9: N_Unit | null;
  Webm_vp8: N_Unit | null;
  ProRes422: N_Unit | null;
  QuickTime: N_Unit | null;
  other: N_VectorAnnotated<string>;
}

export interface N_AudioFormat {
  WAV: N_Unit | null;
  AIFF: N_Unit | null;
  MP3: N_Unit | null;
  AAC: N_Unit | null;
  other: N_VectorAnnotated<string>;
}

export interface N_DigitalColorSpace {
  RGB: N_Unit | null;
  HEX: N_Unit | null;
}

export interface N_RGBOnlyColorSpace {
  RGB: N_Unit | null;
}

export interface N_DataAmount {
  amount: number;
  unit: N_FileSizeUnit;
}

/**
 * Listing of string properties in a flat list.
 */
export type N_Attributes = N_KeyValueAttribute[];

export interface N_KeyValueAttribute {
  name: string;
  value: string;
  metadata: N_Metadata | null;
}

export interface N_CopyMedia {
  text: N_Annotated<N_CopyMediaTextSpecs>;
  /**
   * Untyped general notes / properties
   */
  attributes: N_Attributes;
}

export type N_CopyMediaTextSpecs = N_VectorNamedAnnotated<N_CopyMediaTextSpec>;

export interface N_CopyMediaTextSpec {
  minCharacters: number | null;
  maxCharacters: number | null;
  maxFileSize: N_DataAmount | null;
}
