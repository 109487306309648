import { N_NamedAnnotated } from "@specsheet-common/shared-types";

export * from "./duration";
export * from "./dimension";
export * from "./printDimension";
export * from "./physicalDimension";
export * from "./artWorkDimension";
export * from "./safeArea";
export * from "./trim";
export * from "./bleedArea";
export * from "./stringify";
export * from "./artWorkScale";
export * from "./aspectRatio";
export * from "./bitRate";
export * from "./copy";
export * from "./fileFormat";
export * from "./fileSize";
export * from "./frameRate";
export * from "./resolution";

export const searchValuesFromNamedAnnotated = (
  annotated: N_NamedAnnotated<string>[]
): string[] => [...new Set(annotated.map((s) => s.value))];
