import {
  InfoSchemaFieldValueValues,
  infoSchemaTableViewColumnType as columnType,
  InfoSchemaTableViewColumnType,
  InfoSchemaFieldView,
  InfoSchemaDictionaryFieldView,
} from "@specsheet-common/shared-types";
import {
  dateToInstantNumber,
  DEFAULT_DATE_FORMAT,
  formatDateAsMMDDYYYY,
  formatInstant,
  templatedStringForDurationCell,
} from "../dateHelpers";
import { uuidv4 } from "../libs";
import { notNullish } from "../typescript";

const formatLink = (link: string): string => {
  const [, label, url] = link.match(/<(.*?)> (.*)/) || ["", ""];
  const trimmedUrl = url?.trim();
  const formattedUrl =
    trimmedUrl && trimmedUrl !== "https://" ? `(${trimmedUrl ?? ""})` : "";
  return `${label}${
    label !== "" && formattedUrl !== "" ? " " : ""
  }${formattedUrl}`;
};

const textToOption = (text: string, now: number) => [
  uuidv4(),
  { name: text, color: "none", created: now } as const,
];

const mapping: Record<
  InfoSchemaTableViewColumnType,
  | ((
      value?: InfoSchemaFieldValueValues[""][]
    ) => NonNullable<InfoSchemaDictionaryFieldView["options"]>["values"])
  | null
> = {
  [columnType.text]: (values) => {
    const now = dateToInstantNumber(new Date());
    const safeValues = values?.flatMap((value) => {
      return value.kind === "TEXT" && value.value ? [value.value] : [];
    });
    return Object.fromEntries(
      [...new Set(safeValues)].map((value) => textToOption(value, now))
    );
  },
  [columnType.number]: (values) => {
    const now = dateToInstantNumber(new Date());
    const safeValues = values?.flatMap((value) => {
      return value.kind === "NUMBER" && value.value ? [value.value] : [];
    });
    return Object.fromEntries(
      [...new Set(safeValues)].map((value) =>
        textToOption(value.toString(), now)
      )
    );
  },
  [columnType.date]: (values) => {
    const now = dateToInstantNumber(new Date());
    const safeValues = values?.flatMap((value) => {
      return value.kind === "DATE" && value.value
        ? [formatInstant(value.value, DEFAULT_DATE_FORMAT)]
        : [];
    });
    return Object.fromEntries(
      [...new Set(safeValues)].map((value) => textToOption(value, now))
    );
  },
  [columnType.link]: (values) => {
    const now = dateToInstantNumber(new Date());
    const safeValues = values?.flatMap((value) => {
      return value.kind === "TEXT" && value.value
        ? [formatLink(value.value)]
        : [];
    });
    return Object.fromEntries(
      [...new Set(safeValues)].map((value) => textToOption(value, now))
    );
  },
  [columnType.boolean]: (values) => {
    const now = dateToInstantNumber(new Date());
    const safeValues = values?.flatMap((value) => {
      return value.kind === "BOOLEAN" ? [value.value ? "True" : "False"] : [];
    });
    return Object.fromEntries(
      [...new Set(safeValues)].map((value) => textToOption(value, now))
    );
  },
  [columnType.duration]: (values) => {
    const now = dateToInstantNumber(new Date());
    const safeValues = values?.flatMap((value) => {
      return value.kind === "DATE" && notNullish(value.value)
        ? [templatedStringForDurationCell(value.value)]
        : [];
    });
    return Object.fromEntries(
      [...new Set(safeValues)].map((value) => textToOption(value, now))
    );
  },
  // these cases result in a loss of data
  [columnType.richText]: null,
  [columnType.durationRange]: null,
  [columnType.dateRange]: null,
  // these are never hit but here for completeness
  [columnType.singleSelectTag]: null,
  [columnType.multiSelectTag]: null,
};

export const convertInfoSchemaColumnTypeDictionaries = (
  oldColumnType: InfoSchemaTableViewColumnType | undefined,
  value: InfoSchemaFieldValueValues[""][]
): NonNullable<InfoSchemaDictionaryFieldView["options"]>["values"] | null => {
  if (!oldColumnType) {
    return null;
  }
  return mapping[oldColumnType]?.(value) ?? null;
};
