import { useContext } from "react";
import { RootMobxStoreContext } from "../../contexts/RootMobxStoreContext";

export const useRootStore = () => {
  const context = useContext(RootMobxStoreContext);

  if (!context) {
    throw new Error("Root store was not created for some reason.");
  }

  return context;
};
