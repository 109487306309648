export const withinSysPeriod = ({
  booking,
  relatedEntity,
}: {
  booking: { sysPeriodStart: Date; sysPeriodEnd: Date | null };
  relatedEntity: { sysPeriodStart: Date; sysPeriodEnd: Date | null };
}) => {
  const bookingStart = booking.sysPeriodStart;
  const bookingEnd = booking.sysPeriodEnd;
  const placementStart = relatedEntity.sysPeriodStart;
  const placementEnd = relatedEntity.sysPeriodEnd;
  return (
    (bookingStart <= placementStart &&
      (bookingEnd === null || bookingEnd > placementStart)) ||
    (placementStart <= bookingStart &&
      (placementEnd === null || placementEnd > bookingStart))
  );
};
