import {
  InfoSchemaFieldValueValues,
  InfoSchemaFieldValueWithoutIdZod,
  InfoSchemaFieldViewSimple,
  N_Booking,
  isBookingStandardColumn,
} from "@specsheet-common/shared-types";
import {
  convertCampaignTemplateStandardColumnToBookingStandardColumn,
  getStandardColumnValueForBooking,
} from "../bookings";
import {
  addBusinessDays,
  dateToInstantNumber,
  toDateFromISO,
  toLocalUnixTime,
} from "../dateHelpers";
import { notNullish } from "../typescript";
import { memoize } from "../cache";

const getFieldValuesForInfoSchemaField =
  ({ addBusinessDays }: { addBusinessDays(date: Date, days: number): Date }) =>
  ({
    fieldValues,
    infoSchemaFields,
    booking,
  }: {
    fieldValues: InfoSchemaFieldValueValues | undefined;
    booking: N_Booking;
    infoSchemaFields:
      | Array<InfoSchemaFieldViewSimple & { id: string }>
      | undefined;
  }): InfoSchemaFieldValueValues => {
    if ((infoSchemaFields?.length ?? 0) === 0 && fieldValues) {
      return fieldValues;
    }
    const getValueForBooking = getStandardColumnValueForBooking(booking);

    return Object.fromEntries(
      infoSchemaFields?.flatMap((infoSchemaField) => {
        const fieldValue = fieldValues?.[infoSchemaField.id];
        if (
          (!fieldValue || fieldValue.kind === "DATE") &&
          infoSchemaField.fieldType === "DATE" &&
          infoSchemaField.options?.offsetColumnId !== undefined
        ) {
          const matchedFieldValue =
            fieldValues?.[infoSchemaField.options.offsetColumnId];

          if (matchedFieldValue?.kind === "DATE") {
            if (
              !notNullish(
                fieldValue?.offsetValue ?? infoSchemaField.options.dateOffset
              )
            ) {
              return [
                [
                  infoSchemaField.id,
                  {
                    kind: "DATE",
                    ...fieldValue,
                    value: null,
                    fallbackValue: matchedFieldValue?.value,
                  } as InfoSchemaFieldValueWithoutIdZod,
                ],
              ];
            }
            if (matchedFieldValue.value) {
              const columnDate = addBusinessDays(
                // Since dates are stored in sydney time we need to convert it to sydney and back
                // when doing the calculation so it doesn't mess up the business day math
                new Date(toLocalUnixTime(matchedFieldValue.value)),
                fieldValue?.offsetValue ??
                  infoSchemaField.options.dateOffset ??
                  0
              );

              return [
                [
                  infoSchemaField.id,
                  {
                    kind: "DATE",
                    ...fieldValue,
                    value: toLocalUnixTime(dateToInstantNumber(columnDate)),
                  } as InfoSchemaFieldValueWithoutIdZod,
                ],
              ];
            }
            return [
              [
                infoSchemaField.id,
                {
                  kind: "DATE",
                  ...fieldValue,
                  value: null,
                  fallbackValue: matchedFieldValue?.value,
                } as InfoSchemaFieldValueWithoutIdZod,
              ],
            ];
          }

          const convertedColumn =
            convertCampaignTemplateStandardColumnToBookingStandardColumn(
              infoSchemaField.options.offsetColumnId
            );

          if (convertedColumn && isBookingStandardColumn(convertedColumn)) {
            const bookingDate = getValueForBooking[convertedColumn]();
            const bookingDateAsDate = bookingDate
              ? toDateFromISO(bookingDate)
              : undefined;
            if (
              !notNullish(
                fieldValue?.offsetValue ?? infoSchemaField.options.dateOffset
              )
            ) {
              return [
                [
                  infoSchemaField.id,
                  {
                    ...fieldValue,
                    kind: "DATE",
                    value: null,
                    fallbackValue: bookingDateAsDate
                      ? dateToInstantNumber(bookingDateAsDate)
                      : undefined,
                  } as InfoSchemaFieldValueWithoutIdZod,
                ],
              ];
            }

            const columnDate = bookingDateAsDate
              ? addBusinessDays(
                  bookingDateAsDate,
                  fieldValue?.offsetValue ??
                    infoSchemaField.options.dateOffset ??
                    0
                )
              : null;

            return [
              [
                infoSchemaField.id,
                {
                  ...fieldValue,
                  kind: "DATE",
                  value: dateToInstantNumber(columnDate),
                  fallbackValue: bookingDateAsDate
                    ? dateToInstantNumber(bookingDateAsDate)
                    : undefined,
                } as InfoSchemaFieldValueWithoutIdZod,
              ],
            ];
          }

          return [
            [
              infoSchemaField.id,
              {
                kind: "DATE",
                ...fieldValue,
                value: null,
                fallbackValue: null,
              } as InfoSchemaFieldValueWithoutIdZod,
            ],
          ];
        }

        if (fieldValue) {
          return [[infoSchemaField.id, fieldValue]];
        }
        return [];
      }) ?? []
    );
  };

export const memoizedFieldValuesForInfoSchemaField = () => {
  const { cacheFn: memoizeAddBusinessDays } = memoize(addBusinessDays);

  return getFieldValuesForInfoSchemaField({
    addBusinessDays: memoizeAddBusinessDays,
  });
};
