import z from "zod";
import { sitecardsData } from "../sitecards";

export const oohDigitalBookingSchema = z
  .object({
    bookingType: z.string(),
    suburb: z.string(),
    state: z.string(),
    locationName: z.string(),
    frameName: z.string(),
    sizeType: z.string(),
    campaignNumber: z.number(),
    campaignName: z.string(),
    advertiser: z.string(),
    startDate: z.string(),
    endDate: z.string(),

    spotDuration: z.number(),
    pixelHeight: z.number().optional(),
    pixelWidth: z.number().optional(),
    mediaType0: z.string().optional(),
    mediaType1: z.string().optional(),
    staticMaxFileSize: z.number().optional(),
    staticAspectRatio: z.string().optional(),
    staticResolution: z.number().optional(),
    staticColour: z.string().optional(),
    videoMaxFileSize: z.number().optional(),
    videoAspectRatio: z.string().optional(),
    videoCodec: z.string().optional(),
    videoFrameRate: z.number().optional(),
    videoBitRate: z.number().optional(),
    videoSupportAudioCodec: z.string().optional(),
    videoSupportAudioBitrate: z.number().optional(),
    sampleRate: z.number().optional(),
    channels: z.string().optional(),
    audioLevels: z.string().optional(),
    maxTruePeak: z.string().optional(),
    additionalInformation: z.string().optional(),
    overridePlacementId: z.string().optional(),
    originalSpecUrl: z.string().optional(),

    siteCardImage: z.string().optional(),
  })
  .merge(sitecardsData);

export type OohDigitalBooking = z.infer<typeof oohDigitalBookingSchema>;
