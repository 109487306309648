import {
  calculatedCampaignUserRole,
  CalculatedCampaignUserRole,
  CAMPAIGN_PERMISSIONS,
  campaignAccessLevel,
  CampaignAccessLevel,
  campaignAccessLevelWeight,
  campaignCalculatedAccessLevelWeight,
  OrgMemberRole,
  workspaceMemberRole,
  WorkspaceMemberRole,
} from "@specsheet-common/shared-types";
import { assertNever } from "../typescript";

const getCampaignRoleForCampaignAccessLevel = ({
  accessLevel,
  isExternal,
  organisationRole,
  workspaceRole,
}: {
  accessLevel: CampaignAccessLevel;
  isExternal: boolean;
  organisationRole: OrgMemberRole;
  workspaceRole: WorkspaceMemberRole;
}): CalculatedCampaignUserRole => {
  switch (accessLevel) {
    case campaignAccessLevel.FULL_ACCESS: {
      return isExternal
        ? calculatedCampaignUserRole.FULL_ACCESS_EXTERNAL
        : calculatedCampaignUserRole.FULL_ACCESS_INTERNAL;
    }
    case campaignAccessLevel.EDITOR: {
      return isExternal
        ? calculatedCampaignUserRole.EDITOR_EXTERNAL
        : calculatedCampaignUserRole.EDITOR_INTERNAL;
    }
    case campaignAccessLevel.VIEWER: {
      return isExternal
        ? calculatedCampaignUserRole.VIEWER_EXTERNAL
        : calculatedCampaignUserRole.VIEWER_INTERNAL;
    }
    default: {
      return assertNever(accessLevel);
    }
  }
};

export const getResultingCampaignUserRole = ({
  isCampaignOwner,
  campaignAccessLevel,
  organisationRole,
  workspaceRole,
}: {
  isCampaignOwner: boolean;
  campaignAccessLevel: CampaignAccessLevel;
  organisationRole: OrgMemberRole;
  workspaceRole: WorkspaceMemberRole;
}): CalculatedCampaignUserRole => {
  if (isCampaignOwner) {
    return calculatedCampaignUserRole.OWNER;
  }

  return getCampaignRoleForCampaignAccessLevel({
    accessLevel: campaignAccessLevel,
    isExternal: workspaceRole === workspaceMemberRole.GUEST,
    organisationRole: organisationRole,
    workspaceRole: workspaceRole,
  });
};

export const campaignPermissionsMap = {
  [calculatedCampaignUserRole.OWNER]: [
    CAMPAIGN_PERMISSIONS.BOOKING_CREATE,
    CAMPAIGN_PERMISSIONS.BOOKING_DELETE,
    CAMPAIGN_PERMISSIONS.BOOKING_UPDATE,
    CAMPAIGN_PERMISSIONS.REVERT_CHANGES,
    CAMPAIGN_PERMISSIONS.SELECT_BOOKING,
    CAMPAIGN_PERMISSIONS.GROUP_CREATE,
    CAMPAIGN_PERMISSIONS.GROUP_UPDATE,
    CAMPAIGN_PERMISSIONS.GROUP_DELETE,
    CAMPAIGN_PERMISSIONS.INVITE_TO_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_MANAGEMENT,
    CAMPAIGN_PERMISSIONS.REMOVE_OTHERS_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_LIST_VIEW,
    CAMPAIGN_PERMISSIONS.TRANSFER_OWNERSHIP,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DETAILS_UPDATE,
    CAMPAIGN_PERMISSIONS.VERSION_CREATE,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DOWNLOAD,
    CAMPAIGN_PERMISSIONS.SEARCH_FILTER,
    CAMPAIGN_PERMISSIONS.CREATE_COLUMN,
    CAMPAIGN_PERMISSIONS.UPDATE_COLUMN,
    CAMPAIGN_PERMISSIONS.RESIZE_COLUMN,
  ],
  [calculatedCampaignUserRole.FULL_ACCESS_INTERNAL]: [
    CAMPAIGN_PERMISSIONS.BOOKING_CREATE,
    CAMPAIGN_PERMISSIONS.BOOKING_DELETE,
    CAMPAIGN_PERMISSIONS.BOOKING_UPDATE,
    CAMPAIGN_PERMISSIONS.SELECT_BOOKING,
    CAMPAIGN_PERMISSIONS.REVERT_CHANGES,
    CAMPAIGN_PERMISSIONS.GROUP_CREATE,
    CAMPAIGN_PERMISSIONS.GROUP_UPDATE,
    CAMPAIGN_PERMISSIONS.GROUP_DELETE,
    CAMPAIGN_PERMISSIONS.PUBLICATION_UPDATE,
    CAMPAIGN_PERMISSIONS.INVITE_TO_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_MANAGEMENT,
    CAMPAIGN_PERMISSIONS.REMOVE_OTHERS_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_LIST_VIEW,
    CAMPAIGN_PERMISSIONS.REMOVE_MYSELF_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DETAILS_UPDATE,
    CAMPAIGN_PERMISSIONS.VERSION_CREATE,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DOWNLOAD,
    CAMPAIGN_PERMISSIONS.SEARCH_FILTER,
    CAMPAIGN_PERMISSIONS.CREATE_COLUMN,
    CAMPAIGN_PERMISSIONS.UPDATE_COLUMN,
    CAMPAIGN_PERMISSIONS.RESIZE_COLUMN,
  ],
  [calculatedCampaignUserRole.FULL_ACCESS_EXTERNAL]: [
    CAMPAIGN_PERMISSIONS.BOOKING_CREATE,
    CAMPAIGN_PERMISSIONS.BOOKING_DELETE,
    CAMPAIGN_PERMISSIONS.BOOKING_UPDATE,
    CAMPAIGN_PERMISSIONS.SELECT_BOOKING,
    CAMPAIGN_PERMISSIONS.REVERT_CHANGES,
    CAMPAIGN_PERMISSIONS.GROUP_CREATE,
    CAMPAIGN_PERMISSIONS.GROUP_UPDATE,
    CAMPAIGN_PERMISSIONS.GROUP_DELETE,
    CAMPAIGN_PERMISSIONS.PUBLICATION_UPDATE,
    CAMPAIGN_PERMISSIONS.INVITE_TO_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_MANAGEMENT,
    CAMPAIGN_PERMISSIONS.REMOVE_OTHERS_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_LIST_VIEW,
    CAMPAIGN_PERMISSIONS.REMOVE_MYSELF_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.VERSION_CREATE,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DOWNLOAD,
    CAMPAIGN_PERMISSIONS.SEARCH_FILTER,
    CAMPAIGN_PERMISSIONS.CREATE_COLUMN,
    CAMPAIGN_PERMISSIONS.UPDATE_COLUMN,
    CAMPAIGN_PERMISSIONS.RESIZE_COLUMN,
  ],
  [calculatedCampaignUserRole.EDITOR_INTERNAL]: [
    CAMPAIGN_PERMISSIONS.BOOKING_UPDATE,
    CAMPAIGN_PERMISSIONS.SELECT_BOOKING,
    CAMPAIGN_PERMISSIONS.REVERT_CHANGES,
    CAMPAIGN_PERMISSIONS.INVITE_TO_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_MANAGEMENT,
    CAMPAIGN_PERMISSIONS.REMOVE_OTHERS_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_LIST_VIEW,
    CAMPAIGN_PERMISSIONS.REQUEST_HIGHER_ACCESS,
    CAMPAIGN_PERMISSIONS.REMOVE_MYSELF_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.VERSION_CREATE,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DOWNLOAD,
    CAMPAIGN_PERMISSIONS.SEARCH_FILTER,
    CAMPAIGN_PERMISSIONS.RESIZE_COLUMN,
  ],
  [calculatedCampaignUserRole.EDITOR_EXTERNAL]: [
    CAMPAIGN_PERMISSIONS.BOOKING_UPDATE,
    CAMPAIGN_PERMISSIONS.SELECT_BOOKING,
    CAMPAIGN_PERMISSIONS.REVERT_CHANGES,
    CAMPAIGN_PERMISSIONS.INVITE_TO_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_MANAGEMENT,
    CAMPAIGN_PERMISSIONS.REMOVE_OTHERS_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_LIST_VIEW,
    CAMPAIGN_PERMISSIONS.REQUEST_HIGHER_ACCESS,
    CAMPAIGN_PERMISSIONS.REMOVE_MYSELF_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.VERSION_CREATE,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DOWNLOAD,
    CAMPAIGN_PERMISSIONS.SEARCH_FILTER,
    CAMPAIGN_PERMISSIONS.RESIZE_COLUMN,
  ],
  [calculatedCampaignUserRole.VIEWER_INTERNAL]: [
    CAMPAIGN_PERMISSIONS.INVITE_TO_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.REMOVE_OTHERS_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_LIST_VIEW,
    CAMPAIGN_PERMISSIONS.REQUEST_HIGHER_ACCESS,
    CAMPAIGN_PERMISSIONS.REMOVE_MYSELF_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DOWNLOAD,
    CAMPAIGN_PERMISSIONS.SEARCH_FILTER,
    CAMPAIGN_PERMISSIONS.RESIZE_COLUMN,
  ],
  [calculatedCampaignUserRole.VIEWER_EXTERNAL]: [
    CAMPAIGN_PERMISSIONS.INVITE_TO_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.REMOVE_OTHERS_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_LIST_VIEW,
    CAMPAIGN_PERMISSIONS.REQUEST_HIGHER_ACCESS,
    CAMPAIGN_PERMISSIONS.REMOVE_MYSELF_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DOWNLOAD,
    CAMPAIGN_PERMISSIONS.SEARCH_FILTER,
    CAMPAIGN_PERMISSIONS.RESIZE_COLUMN,
  ],
  // these are basic permissions for workspace admin. We can't grant all permissions because they can rely on a user being an actual owner/workspace member
  workspaceAdminBasePermissions: [
    CAMPAIGN_PERMISSIONS.BOOKING_CREATE,
    CAMPAIGN_PERMISSIONS.BOOKING_DELETE,
    CAMPAIGN_PERMISSIONS.BOOKING_UPDATE,
    CAMPAIGN_PERMISSIONS.SELECT_BOOKING,
    CAMPAIGN_PERMISSIONS.REVERT_CHANGES,
    CAMPAIGN_PERMISSIONS.GROUP_CREATE,
    CAMPAIGN_PERMISSIONS.GROUP_UPDATE,
    CAMPAIGN_PERMISSIONS.GROUP_DELETE,
    CAMPAIGN_PERMISSIONS.PUBLICATION_UPDATE,
    CAMPAIGN_PERMISSIONS.INVITE_TO_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_MANAGEMENT,
    CAMPAIGN_PERMISSIONS.REMOVE_OTHERS_FROM_CAMPAIGN,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_LIST_VIEW,
    CAMPAIGN_PERMISSIONS.TRANSFER_OWNERSHIP,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DETAILS_UPDATE,
    CAMPAIGN_PERMISSIONS.VERSION_CREATE,
    CAMPAIGN_PERMISSIONS.CAMPAIGN_DOWNLOAD,
    CAMPAIGN_PERMISSIONS.SEARCH_FILTER,
    CAMPAIGN_PERMISSIONS.RESIZE_COLUMN,
    CAMPAIGN_PERMISSIONS.CREATE_COLUMN,
    CAMPAIGN_PERMISSIONS.UPDATE_COLUMN,
  ],
} satisfies Record<
  CalculatedCampaignUserRole | "workspaceAdminBasePermissions",
  CAMPAIGN_PERMISSIONS[]
>;

export const getCampaignPermissions = ({
  isCampaignOwner,
  organisationRole,
  workspaceRole,
  ...rest
}: {
  isCampaignOwner: boolean;
  organisationRole: OrgMemberRole;
  workspaceRole: WorkspaceMemberRole;
} & (
  | {
      calculatedCampaignUserRole: CalculatedCampaignUserRole | null;
    }
  | {
      campaignAccessLevel: CampaignAccessLevel;
    }
)): CAMPAIGN_PERMISSIONS[] => {
  const calculatedRole =
    "calculatedCampaignUserRole" in rest
      ? rest.calculatedCampaignUserRole
      : getResultingCampaignUserRole({
          isCampaignOwner,
          campaignAccessLevel: rest.campaignAccessLevel,
          organisationRole,
          workspaceRole,
        });

  const roleCampaignPermissions = calculatedRole
    ? campaignPermissionsMap[calculatedRole]
    : [];

  const permissionsToElevate =
    workspaceRole === "ADMIN"
      ? campaignPermissionsMap.workspaceAdminBasePermissions
      : [];

  const combinedPermissions = new Set([
    ...roleCampaignPermissions,
    ...permissionsToElevate,
  ]);

  return [...combinedPermissions];
};

export const checkAccessLvlChangeIsPermitted = ({
  ownedCalculatedAccessLevel,
  targetAccessLevel,
}: {
  ownedCalculatedAccessLevel: CalculatedCampaignUserRole;
  targetAccessLevel: CampaignAccessLevel;
}): boolean => {
  return (
    campaignCalculatedAccessLevelWeight[ownedCalculatedAccessLevel] -
      campaignAccessLevelWeight[targetAccessLevel] >=
    0
  );
};
