export const externalSubRoute = {
  searchCampaign: "searchcampaign",
  claimCampaign: "claimcampaign",
  registerClaim: "registerclaim",
  originalCampaignDataByCampaignData: "originalCampaignDataByCampaignData",
  searchClients: "searchclients",
  contractVariationReport: "contractvariationreport",
} as const;

export type ExternalRoutes =
  | {
      sub: typeof externalSubRoute.searchCampaign;
      method?: "Post";
    }
  | {
      sub: typeof externalSubRoute.searchClients;
      method?: "Get";
    }
  | {
      sub: typeof externalSubRoute.claimCampaign;
      method?: "Post";
    }
  | {
      sub: typeof externalSubRoute.originalCampaignDataByCampaignData;
      method?: "Post";
    }
  | {
      sub: typeof externalSubRoute.contractVariationReport;
      method?: "Post";
    };
