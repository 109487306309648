import {
  DB_CampaignTemplateSpecColumn,
  campaignTemplateSpecColumn,
} from "@specsheet-common/shared-types";

export const specColumns = [
  campaignTemplateSpecColumn.STATE,
  campaignTemplateSpecColumn.SUBURB,
  campaignTemplateSpecColumn.ADDRESS,
  campaignTemplateSpecColumn.SITE_ID,
  campaignTemplateSpecColumn.DOCUMENTS,
  campaignTemplateSpecColumn.TRIM,
  campaignTemplateSpecColumn.DIMENSIONS,
  campaignTemplateSpecColumn.AVAILABLE_DURATION,
  campaignTemplateSpecColumn.PHYSICAL_DIMENSION,
  campaignTemplateSpecColumn.ARTWORK_DIMENSION,
  campaignTemplateSpecColumn.SAFE_AREA,
  campaignTemplateSpecColumn.BLEED_AREA,
  campaignTemplateSpecColumn.ARTWORK_SCALE,
  campaignTemplateSpecColumn.RESOLUTION,
  campaignTemplateSpecColumn.BIT_RATE,
  campaignTemplateSpecColumn.SAMPLE_RATE,
  campaignTemplateSpecColumn.FRAME_RATE,
  campaignTemplateSpecColumn.ASPECT_RATIOS,
  campaignTemplateSpecColumn.FILE_SIZES,
  campaignTemplateSpecColumn.FILE_FORMATS,
  campaignTemplateSpecColumn.COPY,
] as const;

export const getSpecColumns = (
  fixedColumnsLength: number
): DB_CampaignTemplateSpecColumn[] =>
  specColumns.map((column, i) => ({
    column,
    position: i + fixedColumnsLength,
    isHidden: false,
    isLimited: false,
    channels: undefined,
  }));
