import { Components, Theme } from "@mui/material";

export const MuiButton: Components<Theme>["MuiButton"] = {
  styleOverrides: {
    text: ({ theme }) => ({
      backgroundColor: theme.newColors.neutral.gray100,
    }),
    sizeSmall: ({ theme }) => ({
      ...theme.typography.subtitle3,
      padding: theme.spacing(1),
    }),

    sizeMedium: ({ theme }) => ({
      ...theme.typography.subtitle1,
      padding: theme.spacing(2),
    }),

    sizeLarge: { fontSize: "1.1rem" },
    root: ({ theme }) => ({
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: 600,
      boxShadow: "none",
      "& .MuiButton-endIcon": {
        margin: 0,
      },
      "& .MuiButton-endIcon > svg": {
        fontSize: theme.typography.subtitle1.fontSize,
      },
      "& .MuiBox-root": {
        margin: theme.spacing(0, 2),
      },
    }),
  },
};
