export function once<T extends (...args: Parameters<T>) => ReturnType<T>>(
  func: T
): (...args: Parameters<T>) => ReturnType<T> {
  let result: ReturnType<T> | "ONCE_NO_RESULT" = "ONCE_NO_RESULT";

  return (...args: Parameters<T>): ReturnType<T> => {
    if (result === "ONCE_NO_RESULT") {
      result = func(...args);
    }
    return result;
  };
}
