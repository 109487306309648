import { idFlavor } from "../../common";
import { N_PlacementCategory } from "./general";

export const placementSubRoute = {
  list: "list",
  searchOptions: "searchOptions",
  searchTermOptions: "searchTermOptions",
  searchPublicationOptions: "searchPublicationOptions",
  searchPublisherOptions: "searchPublisherOptions",
  searchCategoryOptions: "searchCategoryOptions",
  searchCountryOptions: "searchCountryOptions",
  placementIds: "placementIds",
  view: "",
  create: "",
  update: "update",
} as const;

export type PlacementIdsQueryParams = {
  searchTerms?: string[];
  publisherIds?: idFlavor<"Publisher">[];
  publicationIds?: idFlavor<"Publication">[];
  categories?: N_PlacementCategory[];
  organisationId: idFlavor<"Organisation">;
  countryIds?: idFlavor<"Country">[];
};

export type SearchTermOptionsQueryParams = {
  search: string;
  publicationIds?: idFlavor<"Publication">[];
  publisherIds?: idFlavor<"Publisher">[];
  categories?: N_PlacementCategory[];
  organisationId: idFlavor<"Organisation">;
  size: string;
  countryIds?: idFlavor<"Country">[];
};

export type SearchPublicationOptionsQueryParams = {
  publicationName?: string;
  searchTerms?: string[];
  publisherIds?: idFlavor<"Publisher">[];
  categories?: N_PlacementCategory[];
  organisationId: idFlavor<"Organisation">;
  size: string;
  skip: string;
  countryIds?: idFlavor<"Country">[];
};

export type SearchPublisherOptionsQueryParams = {
  searchTerms?: string[];
  publicationIds?: idFlavor<"Publication">[];
  categories?: N_PlacementCategory[];
  organisationId: idFlavor<"Organisation">;
  countryIds?: idFlavor<"Country">[];
};

export type SearchCategoryOptionsQueryParams = {
  searchTerms?: string[];
  publisherIds?: idFlavor<"Publisher">[];
  publicationIds?: idFlavor<"Publication">[];
  organisationId: idFlavor<"Organisation">;
  countryIds?: idFlavor<"Country">[];
};

export type SearchCountryOptionsQueryParams = {
  categories?: N_PlacementCategory[];
  searchTerms?: string[];
  publisherIds?: idFlavor<"Publisher">[];
  publicationIds?: idFlavor<"Publication">[];
  organisationId: idFlavor<"Organisation">;
};

export type SearchOptionsQueryParams = {
  organisationId: idFlavor<"Organisation">;
};

export const placementUrls = {
  placement: "placements",
  "placement.check": "placements/:placementId/check",
} as const;

export type PlacementRoutes =
  | {
      sub: typeof placementSubRoute.list;
      method?: "Post";
    }
  | {
      sub: typeof placementSubRoute.searchOptions;
      queryParams?: SearchOptionsQueryParams;
      method?: "Get";
    }
  | {
      sub: typeof placementSubRoute.searchCategoryOptions;
      queryParams?: SearchCategoryOptionsQueryParams;
      method?: "Get";
    }
  | {
      sub: typeof placementSubRoute.searchCountryOptions;
      queryParams?: SearchCountryOptionsQueryParams;
      method?: "Get";
    }
  | {
      sub: typeof placementSubRoute.searchPublisherOptions;
      queryParams?: SearchPublisherOptionsQueryParams;
      method?: "Get";
    }
  | {
      sub: typeof placementSubRoute.searchTermOptions;
      queryParams?: SearchTermOptionsQueryParams;
      method?: "Get";
    }
  | {
      sub: typeof placementSubRoute.searchPublicationOptions;
      queryParams?: SearchPublicationOptionsQueryParams;
      method?: "Get";
    }
  | {
      sub: typeof placementSubRoute.placementIds;
      queryParams?: PlacementIdsQueryParams;
      method?: "Get";
    }
  | {
      sub: typeof placementSubRoute.view;
      tailParam: idFlavor<"Placement">;
      method?: "Get";
    }
  | {
      sub: typeof placementSubRoute.create;
      method?: "Post";
    }
  | {
      sub: typeof placementSubRoute.update;
      method?: "Patch";
    };
