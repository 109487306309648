/* @generated from adl module kachemedia.specsheet.common */

import * as ADL from './../../runtime/adl';
import * as common_strings from './../../common/strings';

/**
 * Generated hash for an user uploaded image
 */
export type ImageHash = string;

const ImageHash_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Generated hash for an user uploaded image\n"}],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"ImageHash","version":{"kind":"nothing"}}};

export const snImageHash: ADL.ScopedName = {moduleName:"kachemedia.specsheet.common", name:"ImageHash"};

export function texprImageHash(): ADL.ATypeExpr<ImageHash> {
  return {value : {typeRef : {kind: "reference", value : snImageHash}, parameters : []}};
}

/**
 * A URL string
 */
export type Url = string;

const Url_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A URL string\n"}],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"Url","version":{"kind":"nothing"}}};

export const snUrl: ADL.ScopedName = {moduleName:"kachemedia.specsheet.common", name:"Url"};

export function texprUrl(): ADL.ATypeExpr<Url> {
  return {value : {typeRef : {kind: "reference", value : snUrl}, parameters : []}};
}

/**
 * Markdown notes
 */
export type Notes = common_strings.StringMD;

const Notes_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Markdown notes\n"}],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringMD"}},"parameters":[]}}},"name":"Notes","version":{"kind":"nothing"}}};

export const snNotes: ADL.ScopedName = {moduleName:"kachemedia.specsheet.common", name:"Notes"};

export function texprNotes(): ADL.ATypeExpr<Notes> {
  return {value : {typeRef : {kind: "reference", value : snNotes}, parameters : []}};
}

export interface DataOrigin_Url {
  kind: 'url';
  value: Url;
}

/**
 * Choice of Data Source (URL only)
 */
export type DataOrigin = DataOrigin_Url;

export interface DataOriginOpts {
  url: Url;
}

export function makeDataOrigin<K extends keyof DataOriginOpts>(kind: K, value: DataOriginOpts[K]) { return {kind, value}; }

const DataOrigin_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Choice of Data Source (URL only)\n"}],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"url","default":{"kind":"nothing"},"name":"url","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"Url"}},"parameters":[]}}]}},"name":"DataOrigin","version":{"kind":"nothing"}}};

export const snDataOrigin: ADL.ScopedName = {moduleName:"kachemedia.specsheet.common", name:"DataOrigin"};

export function texprDataOrigin(): ADL.ATypeExpr<DataOrigin> {
  return {value : {typeRef : {kind: "reference", value : snDataOrigin}, parameters : []}};
}

/**
 * A Url/Datasource with name and notes
 */
export interface UrlResource {
  name: (common_strings.StringNE|null);
  data: DataOrigin;
  notes: (Notes|null);
  fileSize: (number|null);
}

export function makeUrlResource(
  input: {
    name?: (common_strings.StringNE|null),
    data: DataOrigin,
    notes?: (Notes|null),
    fileSize?: (number|null),
  }
): UrlResource {
  return {
    name: input.name === undefined ? null : input.name,
    data: input.data,
    notes: input.notes === undefined ? null : input.notes,
    fileSize: input.fileSize === undefined ? null : input.fileSize,
  };
}

const UrlResource_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A Url/Datasource with name and notes\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"name","default":{"kind":"just","value":null},"name":"name","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}]}},{"annotations":[{"v1":{"moduleName":"common.ui","name":"FormLabel"},"v2":"URL"}],"serializedName":"data","default":{"kind":"nothing"},"name":"data","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"DataOrigin"}},"parameters":[]}},{"annotations":[],"serializedName":"notes","default":{"kind":"just","value":null},"name":"notes","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"Notes"}},"parameters":[]}]}},{"annotations":[],"serializedName":"fileSize","default":{"kind":"just","value":null},"name":"fileSize","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Int32"},"parameters":[]}]}}]}},"name":"UrlResource","version":{"kind":"nothing"}}};

export const snUrlResource: ADL.ScopedName = {moduleName:"kachemedia.specsheet.common", name:"UrlResource"};

export function texprUrlResource(): ADL.ATypeExpr<UrlResource> {
  return {value : {typeRef : {kind: "reference", value : snUrlResource}, parameters : []}};
}

export interface ListWysiwygItem_ListItem {
  kind: 'listItem';
  value: WysiwygCustomText[];
}

export type ListWysiwygItem = ListWysiwygItem_ListItem;

export interface ListWysiwygItemOpts {
  listItem: WysiwygCustomText[];
}

export function makeListWysiwygItem<K extends keyof ListWysiwygItemOpts>(kind: K, value: ListWysiwygItemOpts[K]) { return {kind, value}; }

const ListWysiwygItem_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.common","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"listItem","default":{"kind":"nothing"},"name":"listItem","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"WysiwygCustomText"}},"parameters":[]}]}}]}},"name":"ListWysiwygItem","version":{"kind":"nothing"}}};

export const snListWysiwygItem: ADL.ScopedName = {moduleName:"kachemedia.specsheet.common", name:"ListWysiwygItem"};

export function texprListWysiwygItem(): ADL.ATypeExpr<ListWysiwygItem> {
  return {value : {typeRef : {kind: "reference", value : snListWysiwygItem}, parameters : []}};
}

export interface WysiwygDescendant_Paragraph {
  kind: 'paragraph';
  value: WysiwygCustomText[];
}
export interface WysiwygDescendant_NumberedList {
  kind: 'numberedList';
  value: ListWysiwygItem[];
}
export interface WysiwygDescendant_BulletedList {
  kind: 'bulletedList';
  value: ListWysiwygItem[];
}

export type WysiwygDescendant = WysiwygDescendant_Paragraph | WysiwygDescendant_NumberedList | WysiwygDescendant_BulletedList;

export interface WysiwygDescendantOpts {
  paragraph: WysiwygCustomText[];
  numberedList: ListWysiwygItem[];
  bulletedList: ListWysiwygItem[];
}

export function makeWysiwygDescendant<K extends keyof WysiwygDescendantOpts>(kind: K, value: WysiwygDescendantOpts[K]) { return {kind, value}; }

const WysiwygDescendant_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.common","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"paragraph","default":{"kind":"nothing"},"name":"paragraph","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"WysiwygCustomText"}},"parameters":[]}]}},{"annotations":[],"serializedName":"numberedList","default":{"kind":"nothing"},"name":"numberedList","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"ListWysiwygItem"}},"parameters":[]}]}},{"annotations":[],"serializedName":"bulletedList","default":{"kind":"nothing"},"name":"bulletedList","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"ListWysiwygItem"}},"parameters":[]}]}}]}},"name":"WysiwygDescendant","version":{"kind":"nothing"}}};

export const snWysiwygDescendant: ADL.ScopedName = {moduleName:"kachemedia.specsheet.common", name:"WysiwygDescendant"};

export function texprWysiwygDescendant(): ADL.ATypeExpr<WysiwygDescendant> {
  return {value : {typeRef : {kind: "reference", value : snWysiwygDescendant}, parameters : []}};
}

export interface WysiwygCustomText {
  text: string;
  bold: (boolean|null);
  italic: (boolean|null);
  underline: (boolean|null);
}

export function makeWysiwygCustomText(
  input: {
    text: string,
    bold?: (boolean|null),
    italic?: (boolean|null),
    underline?: (boolean|null),
  }
): WysiwygCustomText {
  return {
    text: input.text,
    bold: input.bold === undefined ? null : input.bold,
    italic: input.italic === undefined ? null : input.italic,
    underline: input.underline === undefined ? null : input.underline,
  };
}

const WysiwygCustomText_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.common","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"text","default":{"kind":"nothing"},"name":"text","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"bold","default":{"kind":"just","value":null},"name":"bold","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}]}},{"annotations":[],"serializedName":"italic","default":{"kind":"just","value":null},"name":"italic","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}]}},{"annotations":[],"serializedName":"underline","default":{"kind":"just","value":null},"name":"underline","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}]}}]}},"name":"WysiwygCustomText","version":{"kind":"nothing"}}};

export const snWysiwygCustomText: ADL.ScopedName = {moduleName:"kachemedia.specsheet.common", name:"WysiwygCustomText"};

export function texprWysiwygCustomText(): ADL.ATypeExpr<WysiwygCustomText> {
  return {value : {typeRef : {kind: "reference", value : snWysiwygCustomText}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "kachemedia.specsheet.common.ImageHash" : ImageHash_AST,
  "kachemedia.specsheet.common.Url" : Url_AST,
  "kachemedia.specsheet.common.Notes" : Notes_AST,
  "kachemedia.specsheet.common.DataOrigin" : DataOrigin_AST,
  "kachemedia.specsheet.common.UrlResource" : UrlResource_AST,
  "kachemedia.specsheet.common.ListWysiwygItem" : ListWysiwygItem_AST,
  "kachemedia.specsheet.common.WysiwygDescendant" : WysiwygDescendant_AST,
  "kachemedia.specsheet.common.WysiwygCustomText" : WysiwygCustomText_AST
};
