import {
  N_ArtworkSpec,
  N_Duration,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { notNull } from "../../typescript";
import { joinNames } from "./joinNames";

const getDuration = (duration: N_Duration): string =>
  (duration.hours !== null ? `${duration.hours}h` : "") +
  (duration.minutes !== null ? ` ${duration.minutes}m` : "") +
  (duration.seconds !== null ? ` ${duration.seconds}s` : "") +
  (duration.milliSeconds !== null ? ` ${duration.milliSeconds}ms` : "");

export const getDurations = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>(
    (durations, spec) =>
      (spec.value.kind === "animated" ||
        spec.value.kind === "html5" ||
        spec.value.kind === "video" ||
        spec.value.kind === "audio") &&
      spec.value.value.durations !== null
        ? [
            ...durations,
            ...[
              ...spec.value.value.durations.value,
              ...(spec.value.kind === "html5" &&
              notNull(spec.value.value.formats.value.video) &&
              notNull(
                spec.value.value.formats.value.video.value.video.durations
              )
                ? spec.value.value.formats.value.video.value.video.durations
                    .value
                : []),
            ].map((d) => ({
              ...spec,
              name: joinNames(spec.name, d.name),
              value: getDuration(d.value),
            })),
          ]
        : durations,
    []
  );
