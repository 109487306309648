import { idFlavor } from "src/common";

export const userGroupSubRoute = {
  list: "list",
  create: "",
  update: "",
  updatemembers: "updatemembers",
  listUserGroupWithAppUsers: "listUserGroupWithAppUsers",
  addUsersToGroups: "adduserstogroups",
  removeUsersFromGroups: "removeusersfromgroups",
} as const;

export type UserGroupRoutes =
  | {
      sub: typeof userGroupSubRoute.list;
      tailParam: idFlavor<"Workspace">;
      method?: "Get";
    }
  | {
      sub: typeof userGroupSubRoute.create;
      method?: "Post";
    }
  | {
      sub: typeof userGroupSubRoute.update;
      method?: "Patch";
    }
  | {
      sub: typeof userGroupSubRoute.updatemembers;
      method?: "Post";
    }
  | {
      sub: typeof userGroupSubRoute.addUsersToGroups;
      method?: "Post";
    }
  | {
      sub: typeof userGroupSubRoute.removeUsersFromGroups;
      method?: "Delete";
    }
  | {
      sub: typeof userGroupSubRoute.listUserGroupWithAppUsers;
      tailParam: idFlavor<"Workspace">;
      method?: "Get";
    };
