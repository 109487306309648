const managementRoute = "/management";
const workspaceManagementRoute = `${managementRoute}/workspace`;
const orgManagementRoute = `${managementRoute}/organisation`;
const sharedRoute = "/shared";

export const routePaths = {
  default: "/",
  inactiveAccount: "/inactiveaccount",
  login: "/login",
  status: "/status",
  logout: "/logout",
  auth: "/auth",
  setup: "/setup",
  dashboard: "/dashboard",
  shared: "/shared",
  notificationCenter: "/notifications",
  admin: "/admin",
  adminTable: "/admin/table",
  adminStats: "/adminstats",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  welcome: "/welcome",
  userProfile: "/profile",
  campaignEdit: "/campaign/name",
  campaignBuild: "/campaign/build",
  campaignView: "/campaign/view",
  campaignCreate: "/campaign/create",
  newDuplicate: "/campaign/newduplicate",
  oldCampaignView: "/campaign/old-view",
  campaignDuplicate: "/campaign/duplicate",
  placementView: "/placement/view",
  mediaOwner: "/media",
  orgManagement: orgManagementRoute,
  orgWorkspaces: `${orgManagementRoute}/workspaces`,
  orgMembers: `${orgManagementRoute}/members`,
  workspaceManagement: workspaceManagementRoute,
  workspaceUsers: `${workspaceManagementRoute}/users`,
  workspaceTemplates: `${workspaceManagementRoute}/templates`,
  workspaceClients: `${workspaceManagementRoute}/clients`,
  workspaceAdServers: `${workspaceManagementRoute}/adservers`,
  workspaceCampaigns: `${workspaceManagementRoute}/campaigns`,
  workspaceMembers: `${workspaceManagementRoute}/members`,
  workspaceGroups: `${workspaceManagementRoute}/groups`,
  newWorkspaceManagement: `${workspaceManagementRoute}`,
  newWorkspaceCampaigns: `${workspaceManagementRoute}/campaigns`,
  newWorkspaceMembers: `${workspaceManagementRoute}/members`,
  newWorkspaceGroups: `${workspaceManagementRoute}/groups`,
  newWorkspaceClients: `${workspaceManagementRoute}/clients`,
  sharedCampaigns: `${sharedRoute}/dashboard`,
  sharedCampaignBuild: `${sharedRoute}/campaign/build`,
} as const;

export const adminRoutePaths = {
  default: "/",
  login: "/login",
  logout: "/logout",
  auth: "/auth",
  dashboard: "/dashboard",
  admin: "/admin",
  adminTable: "/admin/table",
  adminStats: "/adminstats",
  emails: "/emails",
} as const;

export type RoutePaths = (typeof routePaths)[keyof typeof routePaths];
export type AdminRoutePaths =
  (typeof adminRoutePaths)[keyof typeof adminRoutePaths];
