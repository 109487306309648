import z from "zod";
export const qmsDigitalPanelProperties = {
  optionDetails: "optionDetails",
  clientName: "clientName",
  size: "size",
  state: "state",
  subareaName: "subareaName",
  panel: "panel",
  panelAddress: "panelAddress",
  bookingStartDate: "bookingStartDate",
  bookingEndDate: "bookingEndDate",
  shareOfTimeLite: "shareOfTimeLite",
  materialType: "materialType",
  visualWidth: "visualWidth",
  visualHeight: "visualHeight",
  pixelWidth: "pixelWidth",
  pixelHeight: "pixelHeight",
  installCompanyName: "installCompanyName",
  productionComments: "productionComments",
  panelCensorships: "panelCensorships",
  panelProhibitions: "panelProhibitions",
  panelDirection: "panelDirection",
  dimensions: "dimensions",
  digitalAspectRatio: "digitalAspectRatio",
  artworkDeadline: "artworkDeadline",
  productionSpecs1: "productionSpecs1",
} as const;

export const qmsDigitalPanelSchema = z.object({
  optionDetails: z.string(),
  clientName: z.string(),
  size: z.string(),
  state: z.string(),
  subareaName: z.string(),
  panel: z.string(),
  panelAddress: z.string(),
  bookingStartDate: z.string(),
  bookingEndDate: z.string(),
  shareOfTimeLite: z.string(),
  materialType: z.string(),
  visualWidth: z.string(),
  visualHeight: z.string(),
  pixelWidth: z.string(),
  pixelHeight: z.string(),
  installCompanyName: z.string(),
  productionComments: z.string(),
  panelCensorships: z.string(),
  panelProhibitions: z.string(),
  panelDirection: z.string(),
  dimensions: z.string(),
  digitalAspectRatio: z.string(),
  artworkDeadline: z.string(),
  productionSpecs1: z.string(),
});

export type QmsDigitalPanel = z.infer<typeof qmsDigitalPanelSchema>;
