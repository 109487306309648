import {
  DB_AdditionalPrintDimensions,
  DB_Annotated,
  DB_ArtworkSpec,
  DB_AuthorNotesReason,
  DB_DigitalMediaDimensions,
  DB_Metadata,
  DB_NamedAnnotated,
  DB_Placement_Specs,
  DB_PrintMediaFormat,
  DB_StaticMediaFormat,
  N_AdditionalPrintDimensions,
  N_Annotated,
  N_ArtworkSpec,
  N_ArtworkSpec_DigitalStatic,
  N_ArtworkSpec_Newspaper,
  N_AuthorNotesReason,
  N_DigitalMediaDimensions,
  N_Metadata,
  N_NamedAnnotated,
  N_PrintMediaFormat,
  N_Specifications,
  N_StaticMediaFormat,
} from "@specsheet-common/shared-types";
import { assertNever, notNull } from "../typescript";
import { convertNUrlResourceToDbUrlResource } from "./placementConverter";

export const convertNAuthorNotesReason = (
  data: N_AuthorNotesReason
): DB_AuthorNotesReason => {
  if (data.kind === "clarification") {
    return "clarification";
  }

  if (data.kind === "underspecified") {
    return "underspecified";
  }

  if (data.kind === "other_") {
    return { other_: data.value };
  }

  assertNever(data);
  throw new Error();
};

export const convertNMetadata = (data: N_Metadata): DB_Metadata => {
  return {
    ...data,
    images: data.images.map(convertNUrlResourceToDbUrlResource),
    authorNotes: data.authorNotes.map((an) => ({
      ...an,
      reason: convertNAuthorNotesReason(an.reason),
    })),
  };
};

export const convertNNamedAnnotated = <T>(
  data: N_NamedAnnotated<T>
): DB_NamedAnnotated<T> => {
  return {
    ...data,
    metadata: data.metadata ? convertNMetadata(data.metadata) : null,
  };
};

export const convertNAnnotated = <T>(data: N_Annotated<T>): DB_Annotated<T> => {
  return {
    ...data,
    metadata: data.metadata ? convertNMetadata(data.metadata) : null,
  };
};

const convertNPrintFormats = (
  formats: N_PrintMediaFormat
): DB_PrintMediaFormat => ({
  ...formats,
  other: formats.other.map((o) => ({
    ...convertNAnnotated(o),
    value: o.value,
  })),
});

const convertNStaticFormats = (
  formats: N_StaticMediaFormat
): DB_StaticMediaFormat => ({
  ...formats,
  other: formats.other.map((o) => ({
    ...convertNAnnotated(o),
    value: o.value,
  })),
});

const convertNPrintDimensions = (
  dimensions: N_AdditionalPrintDimensions
): DB_AdditionalPrintDimensions =>
  dimensions.map((d) => ({
    ...convertNNamedAnnotated(d),
    value: {
      height: d.value.height,
      width: d.value.width,
    },
  }));

const convertNDigitalMediaDimensions = (
  dimensions: N_DigitalMediaDimensions
): DB_DigitalMediaDimensions =>
  dimensions.map((d) => ({
    ...convertNNamedAnnotated(d),
    value: {
      pixelDimension: d.value.pixelDimension,
      resolutionType: null,
      sizeCase: null,
    },
  }));

const convertNDigitalStaticSpec = (
  nArtWorkSpec: N_ArtworkSpec_DigitalStatic
): DB_ArtworkSpec => ({
  digitalStatic: {
    dimensions: {
      ...convertNAnnotated(nArtWorkSpec.value.dimensions),
      value: convertNDigitalMediaDimensions(
        nArtWorkSpec.value.dimensions.value
      ),
    },
    maxFileSize: notNull(nArtWorkSpec.value.maxFileSize)
      ? {
          ...convertNAnnotated(nArtWorkSpec.value.maxFileSize),
          value: nArtWorkSpec.value.maxFileSize.value,
        }
      : null,
    formats: nArtWorkSpec.value.formats
      ? {
          ...convertNAnnotated(nArtWorkSpec.value.formats),
          value: convertNStaticFormats(nArtWorkSpec.value.formats.value),
        }
      : null,
    safeZone: null,
    DPI: null,
    resolution: null,
    aspectRatios: null,
    color: null,
    border: null,
    attributes: [],
  },
});

const convertNNewspaperSpec = (
  nArtWorkSpec: N_ArtworkSpec_Newspaper
): DB_ArtworkSpec => ({
  newspaper: {
    dimension: null,
    additionalPrintDimensions: notNull(
      nArtWorkSpec.value.additionalPrintDimensions
    )
      ? {
          ...convertNAnnotated(nArtWorkSpec.value.additionalPrintDimensions),
          value: convertNPrintDimensions(
            nArtWorkSpec.value.additionalPrintDimensions.value
          ),
        }
      : null,
    maxFileSize: notNull(nArtWorkSpec.value.maxFileSize)
      ? {
          ...convertNAnnotated(nArtWorkSpec.value.maxFileSize),
          value: nArtWorkSpec.value.maxFileSize.value,
        }
      : null,
    formats: {
      ...convertNAnnotated(nArtWorkSpec.value.formats),
      value: convertNPrintFormats(nArtWorkSpec.value.formats.value),
    },
    textSafeArea: null,
    trim: null,
    spread: null,
    textFreeGutter: null,
    bleed: null,
    finishedTrimBleed: null,
    resolution: null,
    colourProfile: null,
    separationProfile: null,
    inkWeightCoverage: null,
    blackInkLimit: null,
    dotGain: null,
    highlightSet: null,
    font: null,
    placementInNewspaper: null,
    attributes: [],
  },
});

export const convertNArtworkSpec = (
  nArtWorkSpec: N_ArtworkSpec
): DB_ArtworkSpec => {
  if (nArtWorkSpec.kind === "digitalStatic") {
    return convertNDigitalStaticSpec(nArtWorkSpec);
  }

  if (nArtWorkSpec.kind === "newspaper") {
    return convertNNewspaperSpec(nArtWorkSpec);
  }

  if (nArtWorkSpec.kind === "animated") {
    throw new Error();
  }

  if (nArtWorkSpec.kind === "audio") {
    throw new Error();
  }

  if (nArtWorkSpec.kind === "copy") {
    throw new Error();
  }

  if (nArtWorkSpec.kind === "html5") {
    throw new Error();
  }

  if (nArtWorkSpec.kind === "magazine") {
    throw new Error();
  }

  if (nArtWorkSpec.kind === "printOOH") {
    throw new Error();
  }

  if (nArtWorkSpec.kind === "video") {
    throw new Error();
  }

  assertNever(nArtWorkSpec);
  throw new Error();
};

export const nPlacementSpecsToDbSpecs = (
  specs: N_Specifications
): DB_Placement_Specs => {
  return {
    components: specs.value.map((s) => ({
      ...convertNNamedAnnotated(s),
      value: convertNArtworkSpec(s.value),
    })),
  };
};
