const unit = 4;
const borderWidth = 1;
const contentHeight = 8 * unit;
const contentPadding = 4 * unit;
const headerHeight = contentHeight + 4 * contentPadding + borderWidth;
const publicHeaderHeight = contentHeight + contentPadding + borderWidth;

export const metrics = {
  unit,
  borderWidth,
  borderRadius: 1,
  globals: {
    page: {
      minPageWidth: 1024,
      maxPageWidth: 1920,
    },
  },
  contentHeight,
  contentPadding,
  headerHeight,
  publicHeaderHeight,
} as const;
