import { N_ChangedValue, N_UrlResource, idFlavor } from "../../common";
import { N_WysiwygDescendant } from "../../elements";

export interface N_CampaignUpdatedValues {
  campaignTitleChanged?: N_ChangedValue<string> | null;
  liveDateChanged?: N_ChangedValue<string> | null;
  endDateChanged?: N_ChangedValue<string | null> | null;
  documentsChanged?: N_ChangedValue<N_UrlResource[]> | null;
  notesChanged?: N_ChangedValue<N_WysiwygDescendant[]> | null;
  jobNumberChanged?: N_ChangedValue<string | null> | null;
}

/* This is for temporal work such that we have a reliable set of values 
which represent campaign details which can be updated to use within switch statements */
export const campaignUpdatableValues = {
  campaignTitle: "title",
  liveDate: "liveDate",
  endDate: "endDate",
  documents: "documents",
  notes: "notes",
  jobNumber: "jobNumber",
};

export const campaignEditKind = {
  campaignDuplicated: "campaignDuplicated",
  campaignCreated: "campaignCreated",
  campaignAmended: "campaignAmended",
  campaignArchived: "campaignArchived",
  campaignActivated: "campaignActivated",
} as const;

export type CampaignEditKind =
  (typeof campaignEditKind)[keyof typeof campaignEditKind];

export interface N_CampaignDetailsEdit_CampaignDuplicated {
  kind: typeof campaignEditKind.campaignDuplicated;
  sourceCampaign: {
    id: idFlavor<"Campaign">;
    name: string;
  };
}

export interface N_CampaignDetailsEdit_CampaignCreated {
  kind: typeof campaignEditKind.campaignCreated;
  campaign: {
    id: idFlavor<"Campaign">;
    name: string;
  };
}

export interface N_CampaignDetailsEdit_CampaignArchived {
  kind: typeof campaignEditKind.campaignArchived;
  campaign: {
    id: idFlavor<"Campaign">;
    name: string;
  };
}

export interface N_CampaignDetailsEdit_CampaignActivated {
  kind: typeof campaignEditKind.campaignActivated;
  campaign: {
    id: idFlavor<"Campaign">;
    name: string;
  };
}

export interface N_CampaignDetailsEdit_CampaignAmended {
  kind: typeof campaignEditKind.campaignAmended;
  campaign: {
    id: idFlavor<"Campaign">;
    name: string;
  };
  updated: N_CampaignUpdatedValues;
}

export type N_CampaignDetailsEdit =
  | N_CampaignDetailsEdit_CampaignDuplicated
  | N_CampaignDetailsEdit_CampaignCreated
  | N_CampaignDetailsEdit_CampaignAmended
  | N_CampaignDetailsEdit_CampaignArchived
  | N_CampaignDetailsEdit_CampaignActivated;
