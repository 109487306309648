import { idFlavor, N_Instant } from "../../common";
import {
  InfoSchemaFieldView,
  N_InfoSchemaTableViewView,
  N_InfoSchemaView,
} from "../infoSchema";
import * as z from "zod";
import { SpecSheetTab, specSheetTab } from "../placement";

export const campaignTemplateSpecColumn = {
  ADDRESS: "ADDRESS",
  SITE_ID: "SITE_ID",
  STATE: "STATE",
  DOCUMENTS: "DOCUMENTS",
  AVAILABLE_DURATION: "AVAILABLE_DURATION",
  TRIM: "TRIM",
  DIMENSIONS: "DIMENSIONS",
  PHYSICAL_DIMENSION: "PHYSICAL_DIMENSION",
  ARTWORK_DIMENSION: "ARTWORK_DIMENSION",
  SAFE_AREA: "SAFE_AREA",
  BLEED_AREA: "BLEED_AREA",
  ARTWORK_SCALE: "ARTWORK_SCALE",
  RESOLUTION: "RESOLUTION",
  BIT_RATE: "BIT_RATE",
  SAMPLE_RATE: "SAMPLE_RATE",
  FRAME_RATE: "FRAME_RATE",
  ASPECT_RATIOS: "ASPECT_RATIOS",
  FILE_SIZES: "FILE_SIZES",
  FILE_FORMATS: "FILE_FORMATS",
  COPY: "COPY",
  SUBURB: "SUBURB",
} as const;

export const campaignTemplateStandardColumn = {
  MEDIA_OWNER: "MEDIA_OWNER",
  PLACEMENT_INFO: "PLACEMENT_INFO",
  STATUS: "STATUS",
  CHANNEL: "CHANNEL",
  DISPATCH_DATE: "DISPATCH_DATE",
  DISPATCH_DETAILS: "DISPATCH_DETAILS",
  DISPATCH_TO: "DISPATCH_TO",
  LIVE_DATE: "LIVE_DATE",
  END_DATE: "END_DATE",
  OBJECTIVES: "OBJECTIVES",
  CREATIVE_CREATIVE: "CREATIVE_CREATIVE",
  TARGETING: "TARGETING",
  MESSAGING: "MESSAGING",
  CREATIVE: "CREATIVE",
  NOTES: "NOTES",
  REQUIRED_DURATION: "REQUIRED_DURATION",
  PUBLICATIONS: "PUBLICATIONS",
  CAMPAIGN_CODE: "CAMPAIGN_CODE",
} as const;

export type CampaignTemplateStandardColumn =
  (typeof campaignTemplateStandardColumn)[keyof typeof campaignTemplateStandardColumn];

export type CampaignTemplateSpecColumn =
  (typeof campaignTemplateSpecColumn)[keyof typeof campaignTemplateSpecColumn];

export const campaignTemplateCustomColumn = {
  richText: "richText",
  text: "text",
  number: "number",
  date: "date",
  boolean: "boolean",
  link: "link",
  dateRange: "dateRange",
  durationRange: "durationRange",
  duration: "duration",
  singleSelectTag: "singleSelectTag",
  multiSelectTag: "multiSelectTag",
} as const;

export type CampaignTemplateCustomColumn =
  (typeof campaignTemplateCustomColumn)[keyof typeof campaignTemplateCustomColumn];

export const campaignTemplateColumnColour = {
  red: "red",
  orange: "orange",
  yellow: "yellow",
  green: "green",
  turquoise: "turquoise",
  indigo: "indigo",
  purple: "purple",
  black: "black",
  none: "none",
} as const;

export type N_CampaignTemplateColumnColour =
  (typeof campaignTemplateColumnColour)[keyof typeof campaignTemplateColumnColour];

export const campaignTemplateSpecColumnZod = z.object({
  isHidden: z.boolean(),
  position: z.number(),
  isSticky: z.boolean().optional(),
  column: z.nativeEnum(campaignTemplateSpecColumn),
  isLimited: z.boolean().optional(),
  channels: z.array(z.nativeEnum(specSheetTab)).optional(),
  width: z.number().optional(),
  color: z.nativeEnum(campaignTemplateColumnColour).optional(),
  positionByTab: z.record(z.nativeEnum(specSheetTab), z.number()).optional(),
  isHiddenByTab: z.record(z.nativeEnum(specSheetTab), z.boolean()).optional(),
});

export const campaignTemplateStandardColumnZod = z.object({
  isHidden: z.boolean(),
  isSticky: z.boolean().optional(),
  position: z.number(),
  column: z.nativeEnum(campaignTemplateStandardColumn),
  isLimited: z.boolean().optional(),
  color: z.nativeEnum(campaignTemplateColumnColour).optional(),
  channels: z.array(z.nativeEnum(specSheetTab)).optional(),
  width: z.number().optional(),
  positionByTab: z.record(z.nativeEnum(specSheetTab), z.number()).optional(),
  isHiddenByTab: z.record(z.nativeEnum(specSheetTab), z.boolean()).optional(),
});

export const campaignTemplateStandardColumnsZod = z.array(
  campaignTemplateStandardColumnZod
);

export const campaignTemplateSpecColumnsZod = z.array(
  campaignTemplateSpecColumnZod
);

export type DB_CampaignTemplateStandardColumn = z.infer<
  typeof campaignTemplateStandardColumnZod
>;

export type DB_CampaignTemplateSpecColumn = z.infer<
  typeof campaignTemplateSpecColumnZod
>;

export const campaignTemplateCustomColumnSettingsConfigZod = z.object({
  categories: z.array(z.nativeEnum(specSheetTab)),
});

export const campaignTemplateCustomColumnSettingsZod = z.record(
  z.string(),
  campaignTemplateCustomColumnSettingsConfigZod
);

export type CampaignTemplateColumnPosition = Record<SpecSheetTab, number>;

export type CampaignTemplateCustomColumnSettings = Record<
  idFlavor<"InfoSchemaField">,
  z.infer<typeof campaignTemplateCustomColumnSettingsConfigZod>
>;

export const templateStatus = {
  Active: "Active",
  Removed: "Removed",
} as const;

export type N_TemplateStatus =
  (typeof templateStatus)[keyof typeof templateStatus];

export interface N_CampaignTemplateListViewItem {
  id: idFlavor<"CampaignTemplate">;
  label: string;
  description: string;
  isDefault: boolean;
  created: Date;
  isSystem: boolean;
  updated: Date;
  workspaceId: idFlavor<"Workspace">;
}

export interface N_CampaignTemplateData {
  label: string;
  description: string;
  isDefault: boolean;
}

export interface N_CampaignTemplate {
  label: string;
  description: string;
  isDefault: boolean;
  organisationId: idFlavor<"Organisation">;
  workspaceId: idFlavor<"Workspace">;
  authorId: idFlavor<"AppUser">;
  campaignId: idFlavor<"Campaign"> | null;
  originalTemplateId: idFlavor<"CampaignTemplate"> | null;
  isOriginal: boolean;
  created: N_Instant;
  updated: N_Instant;
  isActive: boolean;
  isSystem: boolean;
  schemaId: idFlavor<"InfoSchema">;
  standardColumns: DB_CampaignTemplateStandardColumn[];
  specColumns: DB_CampaignTemplateSpecColumn[] | undefined;
  customColumnSettings: CampaignTemplateCustomColumnSettings | undefined;
}

export interface N_CampaignTemplateWithId extends N_CampaignTemplate {
  id: idFlavor<"CampaignTemplate">;
}

export interface N_CampaignTemplateView {
  id: idFlavor<"CampaignTemplate">;
  campaignTemplate: N_CampaignTemplate;
  schema: N_InfoSchemaView;
  fields: InfoSchemaFieldView[];
  defaultTableView: N_InfoSchemaTableViewView;
}

export const campaignTemplateColumnKind = {
  standard: "standard",
  spec: "spec",
  custom: "custom",
} as const;

export type CampaignTemplateColumnKind =
  (typeof campaignTemplateColumnKind)[keyof typeof campaignTemplateColumnKind];

export const campaignTemplateMoveDirection = {
  start: "start",
  left: "left",
  right: "right",
  end: "end",
} as const;

export type CampaignTemplateMoveDirection =
  (typeof campaignTemplateMoveDirection)[keyof typeof campaignTemplateMoveDirection];
