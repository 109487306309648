import {
  fieldType,
  infoSchemaTableViewColumnType,
  InfoSchemaTableViewColumnType,
  N_FieldType,
} from "@specsheet-common/shared-types";
import { assertNever } from "../typescript";

export const convertInfoSchemaTableViewToFieldTypes = (
  tableViewColumnType: InfoSchemaTableViewColumnType
): N_FieldType => {
  switch (tableViewColumnType) {
    case infoSchemaTableViewColumnType.richText: {
      return fieldType.RICHTEXT;
    }
    case infoSchemaTableViewColumnType.text: {
      return fieldType.TEXT;
    }
    case infoSchemaTableViewColumnType.number: {
      return fieldType.NUMBER;
    }
    case infoSchemaTableViewColumnType.link: {
      return fieldType.TEXT;
    }
    case infoSchemaTableViewColumnType.date: {
      return fieldType.DATE;
    }
    case infoSchemaTableViewColumnType.duration: {
      return fieldType.DATE;
    }
    case infoSchemaTableViewColumnType.durationRange: {
      return fieldType.DATERANGE;
    }
    case infoSchemaTableViewColumnType.dateRange: {
      return fieldType.DATERANGE;
    }
    case infoSchemaTableViewColumnType.boolean: {
      return fieldType.BOOLEAN;
    }
    case infoSchemaTableViewColumnType.singleSelectTag: {
      return fieldType.DICTIONARY;
    }
    case infoSchemaTableViewColumnType.multiSelectTag: {
      return fieldType.DICTIONARY;
    }
    default: {
      return assertNever(tableViewColumnType);
    }
  }
};
