import {
  CampaignUserDetails,
  CampaignUserDetailsByStatus,
} from "@specsheet-common/shared-types";

export const groupCampaignUsersByStatus = (
  users: CampaignUserDetails[]
): CampaignUserDetailsByStatus => {
  return users.reduce<CampaignUserDetailsByStatus>(
    (acc, user) => {
      switch (user.status) {
        case "Active": {
          return { ...acc, active: [...acc.active, user] };
        }
        case "Pending": {
          return { ...acc, pending: [...acc.pending, user] };
        }
        default: {
          return acc;
        }
      }
    },
    { active: [], pending: [] }
  );
};
