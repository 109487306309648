import { idFlavor } from "src/common";
import { ExtCampaignKind } from "src/entities/external/extCampaign/general";
import { PartialBooking } from "src/main";

export const contractEditKind = {
  contractAdded: "contractAdded",
  variation: "variation",
  variationLegacy: "variationLegacy",
} as const;

export type ContractEditKind =
  (typeof contractEditKind)[keyof typeof contractEditKind];

export interface CampaignVariationEdit {
  kind: typeof contractEditKind.variationLegacy;
  extCampaignId: idFlavor<"ExtCampaign">;
  extCampaignKind: ExtCampaignKind;
  campaignVersion: number;
  extImportEventId: idFlavor<"ExtImportEvent">;
}

export interface Variation {
  extCampaignKind: ExtCampaignKind;
  extCampaignId: idFlavor<"ExtCampaign">;
  extImportEventId: idFlavor<"ExtImportEvent">;
  campaignCode: string;
  mediaOwnerRaw: string;
  mediaOwnerPrettified: string;
}

export interface ContractVariation {
  kind: typeof contractEditKind.variation;
  variations: Variation[];
}

export interface ContractAdded {
  kind: typeof contractEditKind.contractAdded;
  extCampaignKind: ExtCampaignKind;
  extCampaignId: idFlavor<"ExtCampaign">;
  campaignCode: string;
  mediaOwnerRaw: string;
  mediaOwnerPrettified: string;
  bookings: PartialBooking[];
}

export type CampaignContractVariationEdit =
  | ContractAdded
  | CampaignVariationEdit
  | ContractVariation;
