import {
  Autocomplete,
  CircularProgress,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, { Fragment } from "react";

interface SsAutocompleteSingleProps<OptionType> {
  fetchOptions?(searchTerm?: string): void;
  options: OptionType[];
  isLoading: boolean | undefined;
  isOptionEqualToValue(option: OptionType, value: OptionType): boolean;
  getOptionLabel(option: OptionType): string;
  setSelectedValue(optionValue: OptionType): void;
  label?: string;
  placeholder?: string;
  error?: TextFieldProps["error"];
  helperText?: TextFieldProps["helperText"];
  disableClearable?: boolean;
  onClear?(): void;
  fullWidth?: boolean;
  inputValue?: string;
  disabled?: boolean;
  value?: OptionType;
  defaultValue?: OptionType;
}

export const SsAutocompleteSingle = <OptionType,>({
  fetchOptions,
  options,
  isLoading,
  isOptionEqualToValue,
  getOptionLabel,
  setSelectedValue,
  label,
  placeholder,
  error,
  helperText,
  disableClearable,
  onClear,
  fullWidth,
  inputValue,
  disabled,
  value,
  defaultValue,
}: SsAutocompleteSingleProps<OptionType>) => {
  return (
    <Autocomplete
      defaultValue={defaultValue}
      disabled={disabled}
      fullWidth={fullWidth}
      onOpen={() => {
        if (fetchOptions) {
          fetchOptions();
        }
      }}
      options={options}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={(event, autoCompleteVal) => {
        if (autoCompleteVal) {
          setSelectedValue(autoCompleteVal);
        } else {
          if (onClear) {
            onClear();
          }
        }
      }}
      inputValue={inputValue}
      disableClearable={disableClearable}
      onInputChange={(event, valueChanged) => {
        if (fetchOptions) {
          fetchOptions(valueChanged);
        }
      }}
      filterOptions={(x) => x}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};
