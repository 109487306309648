import { z } from "zod";
import { idFlavor } from "../../common";
import { N_UserProfile } from "../appUser";
import { DB_CampaignEdit, DB_CampaignEditWithInfoSchemaLog } from "./edits";

export interface CampaignChangeBasic {
  id: idFlavor<"CampaignChange">;
  user?: N_UserProfile;
  date: Date;
  changes: DB_CampaignEdit[];
  campaignVersion: number;
}

export interface CampaignChangeBasicWithInfoSchemaLog {
  id: idFlavor<"CampaignChange">;
  user?: N_UserProfile;
  date: Date;
  changes: DB_CampaignEditWithInfoSchemaLog[];
  campaignVersion: number;
  campaignMetaLogId: idFlavor<"CampaignMetaLog"> | null;
  isConsolidated: boolean;
}

export const campaignChangeCreatedBy = {
  USER: "USER",
  UNKNOWN: "UNKNOWN",
  EXT_IMPORT_JCD: "EXT_IMPORT_JCD",
  EXT_IMPORT_JCDNZ: "EXT_IMPORT_JCDNZ",
  EXT_IMPORT_QMS: "EXT_IMPORT_QMS",
  EXT_IMPORT_OOH: "EXT_IMPORT_OOH",
  EXT_IMPORT_API: "EXT_IMPORT_API",
  EXT_IMPORT_GO_MEDIA: "EXT_IMPORT_GO_MEDIA",
  EXT_IMPORT_MEDIA_WORKS: "EXT_IMPORT_MEDIA_WORKS",
} as const;

export type CampaignChangeCreatedBy =
  (typeof campaignChangeCreatedBy)[keyof typeof campaignChangeCreatedBy];

export const campaignChangeCreatedByEnum = z.enum([
  campaignChangeCreatedBy.EXT_IMPORT_JCD,
  campaignChangeCreatedBy.EXT_IMPORT_JCDNZ,
  campaignChangeCreatedBy.EXT_IMPORT_QMS,
  campaignChangeCreatedBy.EXT_IMPORT_OOH,
  campaignChangeCreatedBy.EXT_IMPORT_API,
  campaignChangeCreatedBy.EXT_IMPORT_GO_MEDIA,
  campaignChangeCreatedBy.EXT_IMPORT_MEDIA_WORKS,
  campaignChangeCreatedBy.USER,
  campaignChangeCreatedBy.UNKNOWN,
]);
