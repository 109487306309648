import * as z from "zod";
import { qmsExtBookingData, qmsPanelKinds } from "./general";

const stringChange = z.object({ before: z.string(), after: z.string() });

export const qmsExtBookingChangeData = z.union([
  z.object({
    panelKindChanged: z.literal(true),
    change: z.object({ before: qmsExtBookingData, after: qmsExtBookingData }),
  }),
  z.object({
    panelKindChanged: z.literal(false),
    data: z.union([
      z.object({
        kind: z.literal(qmsPanelKinds.DIGITAL),
        change: z.record(z.nativeEnum({}), stringChange),
      }),
      z.object({
        kind: z.literal(qmsPanelKinds.STATIC),
        change: z.record(z.nativeEnum({}), stringChange),
      }),
      z.object({}),
    ]),
  }),
]);

export type QmsExtBookingChangeData = z.infer<typeof qmsExtBookingChangeData>;
