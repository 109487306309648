import { z } from "zod";

const validEmail = z.string().email();

interface Auth0UserObject {
  user_id?: string;
  email?: string;
  upn?: string;
  given_name?: string;
  family_name?: string;
  name?: string;
}

interface UserName {
  firstName: string;
  lastName: string;
}

export const getUserEmail = (
  user: Auth0UserObject | undefined
): string | undefined => {
  if (user?.email) {
    /* Most user objects coming from Auth0 will have 'email' key */
    try {
      validEmail.parse(user.email);
      return user.email;
    } catch (_e) {}
  }
  if (user?.upn) {
    /* In some SSO flows, user object has UPN in email-like format instead */
    try {
      validEmail.parse(user.upn);
      return user.upn;
    } catch (_e) {}
  }
  /* If neither of these, no identifying email is available for the user */
  return undefined;
};

export const getUserName = (user: Auth0UserObject | undefined): UserName => {
  if (user?.given_name && user?.family_name) {
    /* Auth0 will usually return First & Last names seperately */
    return {
      firstName: user?.given_name,
      lastName: user?.family_name,
    };
  }
  if (user?.name) {
    /* In some flows Auth0 will return a single name object */
    const nameParts = user.name.split(" ");
    if (nameParts.length > 1) {
      return {
        firstName: nameParts[0],
        lastName: nameParts.slice(1).join(" "),
      };
    }
  }

  return {
    firstName: "",
    lastName: "",
  };
};
