import { N_UserProfile } from "src/main";
import { N_PlacementWithPublisherAndPublications } from "../../placement";

export const extCampaignResKind = {
  FOUND: "FOUND",
  NOT_FOUND: "NOT_FOUND",
  USED_ALREADY: "USED_ALREADY",
  LEGACY_CONTRACT: "LEGACY_CONTRACT",
} as const;

export type ExtCampaignResKind =
  (typeof extCampaignResKind)[keyof typeof extCampaignResKind];

export type ExtCampaignSearchRes =
  | {
      kind: typeof extCampaignResKind.FOUND;
      value: N_PlacementWithPublisherAndPublications[];
      bookingsAmount: number;
      groupNames: string[];
    }
  | {
      kind: typeof extCampaignResKind.USED_ALREADY;
      campaignName: string;
      ownerUser: N_UserProfile;
    }
  | {
      kind: typeof extCampaignResKind.NOT_FOUND;
    }
  | {
      kind: typeof extCampaignResKind.LEGACY_CONTRACT;
    };

export interface SearchExtClientRes {
  clients: Array<{ name: string }>;
}

export interface ContractVariationReportRes {
  status: "requested";
}
