export const externalImportEventType = {
  API: "API",
  JCD: "JCD",
  JCDNZ: "JCDNZ",
  QMS: "QMS",
  JCD_FILES: "JCD_FILES",
  GO_MEDIA: "GO_MEDIA",
  MEDIA_WORKS: "MEDIA_WORKS",
  OOH: "OOH",
} as const;

export type ExternalImportEventType =
  (typeof externalImportEventType)[keyof typeof externalImportEventType];

export const externalImportStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
} as const;

export type ExternalImportStatus =
  (typeof externalImportStatus)[keyof typeof externalImportStatus];
