import z from "zod";

export const qmsInstallCompanyProperties = {
  installCompany: "installCompany",
  address1: "address1",
  address2: "address2",
  address3: "address3",
  address4: "address4",
  address5: "address5",
  telephone: "telephone",
  emailAddress: "emailAddress",
};

export const qmsInstallCompanySchema = z.object({
  installCompany: z.string(),
  address1: z.string(),
  address2: z.string(),
  address3: z.string(),
  address4: z.string(),
  address5: z.string(),
  telephone: z.string(),
  emailAddress: z.string(),
});

export type QmsInstallCompany = z.infer<typeof qmsInstallCompanySchema>;
