import { idFlavor } from "../../common";

export const organisationSubRoute = {
  list: "list",
  view: "",
  create: "",
  update: "",
} as const;

export type OrganisationRoutes =
  | {
      sub: typeof organisationSubRoute.list;
      method?: "Get";
    }
  | {
      sub: typeof organisationSubRoute.view;
      tailParam: idFlavor<"Organisation">;
      method?: "Get";
    }
  | {
      sub: typeof organisationSubRoute.create;
      method?: "Post";
    }
  | {
      sub: typeof organisationSubRoute.update;
      tailParam: idFlavor<"Organisation">;
      method?: "Patch";
    };
