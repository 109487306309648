import {
  CampaignVersionFileReadyMessage,
  ContractVariationFileReadyMessage,
  idFlavor,
  socketMessageKinds,
} from "@specsheet-common/shared-types";
import { assertNever } from "../typescript";

export type WebsocketMessageIdTemplate =
  | `:kind:${typeof socketMessageKinds.contractVariationReportFileReady}:campaignId:${idFlavor<"Campaign">}:campaignVersion:${idFlavor<"CampaignVersion">}:extCampaignId:${idFlavor<"ExtCampaign">}:extImportEventId:${idFlavor<"ExtImportEvent">}`
  | `:kind:${typeof socketMessageKinds.campaignVersionReportFileReady}:campaignId:${idFlavor<"Campaign">}:campaignVersion:${idFlavor<"CampaignVersion">}`;

type BuildMessageIdInput =
  | {
      kind: typeof socketMessageKinds.campaignVersionReportFileReady;
      data: Omit<CampaignVersionFileReadyMessage["data"], "clientFileId">;
    }
  | {
      kind: typeof socketMessageKinds.contractVariationReportFileReady;
      data: Omit<ContractVariationFileReadyMessage["data"], "clientFileId">;
    };

export const buildMessageId = (
  input: BuildMessageIdInput
): WebsocketMessageIdTemplate => {
  switch (input.kind) {
    case socketMessageKinds.campaignVersionReportFileReady: {
      return `:kind:${input.kind}:campaignId:${input.data.campaignId}:campaignVersion:${input.data.campaignVersion}`;
    }
    case socketMessageKinds.contractVariationReportFileReady: {
      return `:kind:${input.kind}:campaignId:${input.data.campaignId}:campaignVersion:${input.data.campaignVersion}:extCampaignId:${input.data.extCampaignId}:extImportEventId:${input.data.extImportEventId}`;
    }
    default: {
      return assertNever(input);
    }
  }
};
