const tableViewColumns = {
  "Copy - Block 2": {
    isActive: true,
    isHidden: false,
    position: 25,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: false,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 43,
      BVOD: 37,
      DOOH: 30,
      AUDIO: 30,
      OTHER: 43,
      RADIO: 29,
      VIDEO: 20,
      CINEMA: 28,
      NATIVE: 8,
      ONLINE: 30,
      SOCIAL: 22,
      DISPLAY: 15,
      Overview: 22,
      BROADCAST: 37,
      PRINT_OOH: 17,
      RICHMEDIA: 33,
      OOH_TRANSIT: 18,
      LEAD_GEN_FORM: 43,
      PRINT_MAGAZINE: 17,
      PRINT_NEWSPAPER: 17,
    },
  },
  "Copy - Block 3": {
    isActive: true,
    isHidden: false,
    position: 26,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: false,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 44,
      BVOD: 38,
      DOOH: 31,
      AUDIO: 31,
      OTHER: 44,
      RADIO: 30,
      VIDEO: 21,
      CINEMA: 29,
      NATIVE: 9,
      ONLINE: 31,
      SOCIAL: 23,
      DISPLAY: 16,
      Overview: 23,
      BROADCAST: 38,
      PRINT_OOH: 18,
      RICHMEDIA: 34,
      OOH_TRANSIT: 19,
      LEAD_GEN_FORM: 43,
      PRINT_MAGAZINE: 18,
      PRINT_NEWSPAPER: 18,
    },
  },
  "Call to Action": {
    isActive: true,
    isHidden: false,
    position: 27,
    isLimited: false,
    columnType: "text",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: false,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 45,
      BVOD: 39,
      DOOH: 32,
      AUDIO: 32,
      OTHER: 45,
      RADIO: 31,
      VIDEO: 22,
      CINEMA: 30,
      NATIVE: 10,
      ONLINE: 32,
      SOCIAL: 24,
      DISPLAY: 17,
      Overview: 24,
      BROADCAST: 38,
      PRINT_OOH: 18,
      RICHMEDIA: 35,
      OOH_TRANSIT: 20,
      LEAD_GEN_FORM: 45,
      PRINT_MAGAZINE: 19,
      PRINT_NEWSPAPER: 19,
    },
  },
  "TVC / Creative Title": {
    isActive: true,
    isHidden: false,
    position: 32,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      BVOD: 4,
      DOOH: 4,
      AUDIO: 5,
      RADIO: 5,
      VIDEO: 7,
      CINEMA: 6,
      NATIVE: 7,
      ONLINE: 4,
      SOCIAL: 5,
      DISPLAY: 5,
      Overview: 6,
      BROADCAST: 5,
      PRINT_OOH: 4,
      RICHMEDIA: 4,
      OOH_TRANSIT: 4,
      PRINT_MAGAZINE: 3,
      PRINT_NEWSPAPER: 4,
    },
  },
  "Key Number": {
    isActive: true,
    isHidden: false,
    position: 33,
    isLimited: false,
    columnType: "text",
    isHiddenByTab: {
      SEM: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: false,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      BVOD: 6,
      DOOH: 5,
      AUDIO: 6,
      RADIO: 6,
      VIDEO: 8,
      CINEMA: 8,
      NATIVE: 8,
      ONLINE: 5,
      SOCIAL: 6,
      DISPLAY: 6,
      Overview: 7,
      BROADCAST: 7,
      PRINT_OOH: 5,
      RICHMEDIA: 5,
      OOH_TRANSIT: 5,
      PRINT_MAGAZINE: 4,
      PRINT_NEWSPAPER: 5,
    },
  },
  "Creative Rotation": {
    isActive: true,
    isHidden: false,
    position: 34,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      DOOH: true,
      AUDIO: false,
      OTHER: true,
      RADIO: false,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: false,
      Overview: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      BVOD: 7,
      DOOH: 6,
      AUDIO: 7,
      RADIO: 7,
      VIDEO: 9,
      CINEMA: 9,
      NATIVE: 9,
      ONLINE: 6,
      SOCIAL: 7,
      DISPLAY: 12,
      Overview: 8,
      BROADCAST: 8,
      PRINT_OOH: 6,
      RICHMEDIA: 6,
      OOH_TRANSIT: 6,
      PRINT_MAGAZINE: 5,
      PRINT_NEWSPAPER: 6,
    },
  },
  "CAD Approved?": {
    isActive: true,
    isHidden: false,
    position: 35,
    isLimited: false,
    columnType: "singleSelectTag",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: false,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 33,
      BVOD: 27,
      DOOH: 10,
      AUDIO: 10,
      OTHER: 33,
      RADIO: 9,
      VIDEO: 14,
      CINEMA: 14,
      NATIVE: 14,
      ONLINE: 11,
      SOCIAL: 15,
      DISPLAY: 10,
      Overview: 13,
      BROADCAST: 27,
      PRINT_OOH: 11,
      RICHMEDIA: 11,
      OOH_TRANSIT: 11,
      LEAD_GEN_FORM: 33,
      PRINT_MAGAZINE: 10,
      PRINT_NEWSPAPER: 10,
    },
  },
  "Creative Name": {
    isActive: true,
    isHidden: false,
    position: 36,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: false,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: false,
      CINEMA: false,
      NATIVE: false,
      ONLINE: false,
      SOCIAL: false,
      DISPLAY: false,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: false,
      RICHMEDIA: false,
      OOH_TRANSIT: false,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: false,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 37,
      BVOD: 31,
      DOOH: 14,
      AUDIO: 13,
      OTHER: 37,
      RADIO: 12,
      VIDEO: 12,
      CINEMA: 9,
      NATIVE: 14,
      ONLINE: 10,
      SOCIAL: 12,
      DISPLAY: 11,
      Overview: 17,
      BROADCAST: 31,
      PRINT_OOH: 16,
      RICHMEDIA: 27,
      OOH_TRANSIT: 6,
      LEAD_GEN_FORM: 37,
      PRINT_MAGAZINE: 11,
      PRINT_NEWSPAPER: 13,
    },
  },
  Targeting: {
    isActive: true,
    isHidden: false,
    position: 37,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: false,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 40,
      BVOD: 34,
      DOOH: 27,
      AUDIO: 27,
      OTHER: 40,
      RADIO: 15,
      VIDEO: 17,
      CINEMA: 17,
      NATIVE: 18,
      ONLINE: 27,
      SOCIAL: 19,
      DISPLAY: 7,
      Overview: 19,
      BROADCAST: 34,
      PRINT_OOH: 14,
      RICHMEDIA: 30,
      OOH_TRANSIT: 15,
      LEAD_GEN_FORM: 40,
      PRINT_MAGAZINE: 14,
      PRINT_NEWSPAPER: 14,
    },
  },
  "Required Duration": {
    isActive: true,
    isHidden: false,
    position: 38,
    isLimited: false,
    columnType: "text",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: false,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 41,
      BVOD: 35,
      DOOH: 28,
      AUDIO: 28,
      OTHER: 41,
      RADIO: 27,
      VIDEO: 18,
      CINEMA: 2,
      NATIVE: 19,
      ONLINE: 28,
      SOCIAL: 20,
      DISPLAY: 13,
      Overview: 20,
      BROADCAST: 35,
      PRINT_OOH: 15,
      RICHMEDIA: 31,
      OOH_TRANSIT: 16,
      LEAD_GEN_FORM: 41,
      PRINT_MAGAZINE: 15,
      PRINT_NEWSPAPER: 15,
    },
  },
  "File Name": {
    fieldId: "6f760ad3-7658-4137-aa4d-a05a1111d844",
    isActive: true,
    isHidden: false,
    position: 39,
    isLimited: false,
    columnType: "text",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      SEM: 46,
      BVOD: 40,
      DOOH: 33,
      AUDIO: 33,
      OTHER: 46,
      RADIO: 32,
      VIDEO: 27,
      CINEMA: 31,
      NATIVE: 23,
      ONLINE: 33,
      SOCIAL: 25,
      DISPLAY: 18,
      Overview: 25,
      BROADCAST: 40,
      PRINT_OOH: 20,
      RICHMEDIA: 36,
      OOH_TRANSIT: 21,
      LEAD_GEN_FORM: 46,
      PRINT_MAGAZINE: 20,
      PRINT_NEWSPAPER: 10,
    },
  },
  "Delivery Instructions": {
    isActive: true,
    isHidden: false,
    position: 40,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: false,
      RICHMEDIA: true,
      OOH_TRANSIT: false,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: false,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      SEM: 47,
      BVOD: 41,
      DOOH: 34,
      AUDIO: 34,
      OTHER: 47,
      RADIO: 33,
      VIDEO: 28,
      CINEMA: 32,
      NATIVE: 24,
      ONLINE: 34,
      SOCIAL: 26,
      DISPLAY: 19,
      Overview: 26,
      BROADCAST: 31,
      PRINT_OOH: 17,
      RICHMEDIA: 37,
      OOH_TRANSIT: 7,
      LEAD_GEN_FORM: 47,
      PRINT_MAGAZINE: 12,
      PRINT_NEWSPAPER: 27,
    },
  },
  "CAD/ CAB #": {
    isActive: true,
    isHidden: false,
    position: 42,
    isLimited: false,
    columnType: "text",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: false,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 34,
      BVOD: 28,
      DOOH: 11,
      AUDIO: 11,
      OTHER: 34,
      RADIO: 10,
      VIDEO: 15,
      CINEMA: 15,
      NATIVE: 15,
      ONLINE: 12,
      SOCIAL: 16,
      DISPLAY: 11,
      Overview: 14,
      BROADCAST: 28,
      PRINT_OOH: 12,
      RICHMEDIA: 12,
      OOH_TRANSIT: 12,
      LEAD_GEN_FORM: 34,
      PRINT_MAGAZINE: 11,
      PRINT_NEWSPAPER: 11,
    },
  },
  "Link to Asset": {
    isActive: true,
    isHidden: false,
    position: 43,
    isLimited: false,
    columnType: "link",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: false,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: false,
      CINEMA: false,
      NATIVE: false,
      ONLINE: false,
      SOCIAL: false,
      DISPLAY: false,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: false,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      SEM: 38,
      BVOD: 32,
      DOOH: 16,
      AUDIO: 14,
      OTHER: 38,
      RADIO: 13,
      VIDEO: 13,
      CINEMA: 10,
      NATIVE: 15,
      ONLINE: 11,
      SOCIAL: 13,
      DISPLAY: 13,
      Overview: 18,
      BROADCAST: 32,
      PRINT_OOH: 17,
      RICHMEDIA: 28,
      OOH_TRANSIT: 14,
      LEAD_GEN_FORM: 38,
      PRINT_MAGAZINE: 13,
      PRINT_NEWSPAPER: 11,
    },
  },
  "Run as Bonus?": {
    isActive: true,
    isHidden: false,
    position: 45,
    isLimited: false,
    columnType: "boolean",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: false,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 35,
      BVOD: 29,
      DOOH: 12,
      AUDIO: 12,
      OTHER: 35,
      RADIO: 11,
      VIDEO: 16,
      CINEMA: 16,
      NATIVE: 16,
      ONLINE: 13,
      SOCIAL: 17,
      DISPLAY: 12,
      Overview: 15,
      BROADCAST: 29,
      PRINT_OOH: 13,
      RICHMEDIA: 13,
      OOH_TRANSIT: 13,
      LEAD_GEN_FORM: 35,
      PRINT_MAGAZINE: 12,
      PRINT_NEWSPAPER: 12,
    },
  },
  "Dispatched Y/N": {
    isActive: true,
    isHidden: false,
    position: 46,
    isLimited: false,
    columnType: "singleSelectTag",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: false,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: false,
      CINEMA: false,
      NATIVE: false,
      ONLINE: false,
      SOCIAL: false,
      DISPLAY: false,
      Overview: false,
      BROADCAST: true,
      PRINT_OOH: false,
      RICHMEDIA: false,
      OOH_TRANSIT: false,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: false,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      SEM: 39,
      BVOD: 33,
      DOOH: 16,
      AUDIO: 15,
      OTHER: 39,
      RADIO: 14,
      VIDEO: 14,
      CINEMA: 11,
      NATIVE: 16,
      ONLINE: 12,
      SOCIAL: 14,
      DISPLAY: 14,
      Overview: 7,
      BROADCAST: 33,
      PRINT_OOH: 18,
      RICHMEDIA: 29,
      OOH_TRANSIT: 8,
      LEAD_GEN_FORM: 39,
      PRINT_MAGAZINE: 14,
      PRINT_NEWSPAPER: 13,
    },
  },
  "Rights Expiry Date?": {
    isActive: true,
    isHidden: false,
    position: 48,
    isLimited: false,
    columnType: "date",
    isHiddenByTab: {
      DOOH: true,
      AUDIO: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 36,
      BVOD: 30,
      DOOH: 13,
      AUDIO: 16,
      OTHER: 36,
      RADIO: 17,
      VIDEO: 4,
      CINEMA: 18,
      NATIVE: 17,
      ONLINE: 15,
      SOCIAL: 18,
      DISPLAY: 20,
      Overview: 16,
      BROADCAST: 30,
      PRINT_OOH: 22,
      RICHMEDIA: 14,
      OOH_TRANSIT: 32,
      LEAD_GEN_FORM: 36,
      PRINT_MAGAZINE: 13,
      PRINT_NEWSPAPER: 7,
    },
  },
  "Social Copy - 1": {
    isActive: true,
    isHidden: false,
    position: 50,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: false,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 48,
      BVOD: 42,
      DOOH: 35,
      AUDIO: 35,
      OTHER: 48,
      RADIO: 34,
      VIDEO: 29,
      CINEMA: 33,
      NATIVE: 25,
      ONLINE: 35,
      SOCIAL: 8,
      DISPLAY: 27,
      Overview: 27,
      BROADCAST: 42,
      PRINT_OOH: 27,
      RICHMEDIA: 38,
      OOH_TRANSIT: 34,
      LEAD_GEN_FORM: 48,
      PRINT_MAGAZINE: 27,
      PRINT_NEWSPAPER: 28,
    },
  },
  "Social Copy - 2": {
    isActive: true,
    isHidden: false,
    position: 51,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: false,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 51,
      BVOD: 43,
      DOOH: 36,
      AUDIO: 36,
      OTHER: 51,
      RADIO: 35,
      VIDEO: 30,
      CINEMA: 34,
      NATIVE: 26,
      ONLINE: 36,
      SOCIAL: 9,
      DISPLAY: 28,
      Overview: 28,
      BROADCAST: 43,
      PRINT_OOH: 28,
      RICHMEDIA: 39,
      OOH_TRANSIT: 35,
      LEAD_GEN_FORM: 51,
      PRINT_MAGAZINE: 28,
      PRINT_NEWSPAPER: 29,
    },
  },
  Market: {
    isActive: true,
    isHidden: false,
    position: 55,
    isLimited: false,
    columnType: "multiSelectTag",
    isHiddenByTab: {
      SEM: true,
      DOOH: true,
      AUDIO: false,
      OTHER: true,
      RADIO: false,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      BVOD: 3,
      DOOH: 9,
      AUDIO: 6,
      RADIO: 5,
      VIDEO: 13,
      CINEMA: 13,
      NATIVE: 13,
      ONLINE: 10,
      SOCIAL: 14,
      DISPLAY: 6,
      Overview: 12,
      BROADCAST: 4,
      PRINT_OOH: 10,
      RICHMEDIA: 10,
      OOH_TRANSIT: 10,
      PRINT_MAGAZINE: 9,
      PRINT_NEWSPAPER: 9,
    },
  },
  "Social Copy - 3": {
    isActive: true,
    isHidden: false,
    position: 56,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: false,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 51,
      BVOD: 44,
      DOOH: 37,
      AUDIO: 37,
      OTHER: 51,
      RADIO: 36,
      VIDEO: 31,
      CINEMA: 35,
      NATIVE: 27,
      ONLINE: 37,
      SOCIAL: 10,
      DISPLAY: 29,
      Overview: 29,
      BROADCAST: 44,
      PRINT_OOH: 29,
      RICHMEDIA: 40,
      OOH_TRANSIT: 36,
      LEAD_GEN_FORM: 51,
      PRINT_MAGAZINE: 29,
      PRINT_NEWSPAPER: 30,
    },
  },
  "Social CTA": {
    isActive: true,
    isHidden: false,
    position: 57,
    isLimited: false,
    columnType: "text",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: false,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 52,
      BVOD: 45,
      DOOH: 38,
      AUDIO: 38,
      OTHER: 52,
      RADIO: 37,
      VIDEO: 32,
      CINEMA: 36,
      NATIVE: 28,
      ONLINE: 38,
      SOCIAL: 11,
      DISPLAY: 30,
      Overview: 30,
      BROADCAST: 45,
      PRINT_OOH: 30,
      RICHMEDIA: 41,
      OOH_TRANSIT: 37,
      LEAD_GEN_FORM: 52,
      PRINT_MAGAZINE: 30,
      PRINT_NEWSPAPER: 31,
    },
  },
  "Copy - Block 1": {
    isActive: true,
    isHidden: false,
    position: 58,
    isLimited: false,
    columnType: "richText",
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: false,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      SEM: 42,
      BVOD: 36,
      DOOH: 29,
      AUDIO: 29,
      OTHER: 42,
      RADIO: 28,
      VIDEO: 19,
      CINEMA: 27,
      NATIVE: 7,
      ONLINE: 29,
      SOCIAL: 21,
      DISPLAY: 14,
      Overview: 21,
      BROADCAST: 36,
      PRINT_OOH: 16,
      RICHMEDIA: 32,
      OOH_TRANSIT: 17,
      LEAD_GEN_FORM: 42,
      PRINT_MAGAZINE: 16,
      PRINT_NEWSPAPER: 16,
    },
  },
  Delivery: {
    kind: "standard",
    color: "green",
    width: 189,
    columnType: "date",
    isHidden: false,
    isActive: true,
    position: 47,
    isLimited: false,
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: false,
      AUDIO: false,
      OTHER: true,
      RADIO: false,
      VIDEO: false,
      CINEMA: false,
      NATIVE: false,
      ONLINE: false,
      SOCIAL: true,
      DISPLAY: false,
      Overview: false,
      BROADCAST: true,
      PRINT_OOH: false,
      RICHMEDIA: false,
      OOH_TRANSIT: false,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: false,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      BVOD: 3,
      DOOH: 11,
      AUDIO: 2,
      RADIO: 2,
      VIDEO: 9,
      CINEMA: 6,
      NATIVE: 11,
      ONLINE: 7,
      SOCIAL: 10,
      DISPLAY: 8,
      Overview: 6,
      BROADCAST: 9,
      PRINT_OOH: 13,
      RICHMEDIA: 4,
      OOH_TRANSIT: 3,
      PRINT_MAGAZINE: 8,
      PRINT_NEWSPAPER: 7,
    },
  },
  Live: {
    kind: "standard",
    color: "yellow",
    width: 189,
    columnType: "date",
    isHidden: false,
    isActive: true,
    position: 48,
    isLimited: false,
    isHiddenByTab: {
      SEM: true,
      BVOD: false,
      DOOH: false,
      AUDIO: false,
      OTHER: true,
      RADIO: false,
      VIDEO: false,
      CINEMA: false,
      NATIVE: false,
      ONLINE: false,
      SOCIAL: true,
      DISPLAY: false,
      Overview: false,
      BROADCAST: true,
      PRINT_OOH: false,
      RICHMEDIA: false,
      OOH_TRANSIT: false,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: false,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      BVOD: 8,
      DOOH: 11,
      AUDIO: 3,
      RADIO: 3,
      VIDEO: 10,
      CINEMA: 7,
      NATIVE: 12,
      ONLINE: 8,
      SOCIAL: 12,
      DISPLAY: 9,
      Overview: 5,
      BROADCAST: 10,
      PRINT_OOH: 14,
      RICHMEDIA: 5,
      OOH_TRANSIT: 4,
      PRINT_MAGAZINE: 9,
      PRINT_NEWSPAPER: 8,
    },
  },
  End: {
    kind: "standard",
    color: "red",
    width: 189,
    columnType: "date",
    isHidden: false,
    isActive: true,
    position: 49,
    isLimited: false,
    isHiddenByTab: {
      SEM: true,
      BVOD: false,
      DOOH: false,
      AUDIO: false,
      OTHER: true,
      RADIO: false,
      VIDEO: false,
      CINEMA: false,
      NATIVE: false,
      ONLINE: false,
      SOCIAL: true,
      DISPLAY: false,
      Overview: false,
      BROADCAST: true,
      PRINT_OOH: false,
      RICHMEDIA: false,
      OOH_TRANSIT: false,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: false,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      BVOD: 9,
      DOOH: 12,
      AUDIO: 4,
      RADIO: 4,
      VIDEO: 11,
      CINEMA: 8,
      NATIVE: 13,
      ONLINE: 9,
      SOCIAL: 13,
      DISPLAY: 10,
      Overview: 6,
      BROADCAST: 11,
      PRINT_OOH: 15,
      RICHMEDIA: 6,
      OOH_TRANSIT: 5,
      PRINT_MAGAZINE: 10,
      PRINT_NEWSPAPER: 9,
    },
  },
};
const specColumns = [
  {
    kind: "spec",
    column: "STATE",
    isHidden: false,
    position: 8,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true },
    positionByTab: { DOOH: 3, PRINT_OOH: 3, OOH_TRANSIT: 23 },
  },
  {
    kind: "spec",
    column: "SUBURB",
    isHidden: false,
    position: 9,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true },
    positionByTab: { DOOH: 4, PRINT_OOH: 4, OOH_TRANSIT: 23 },
  },
  {
    kind: "spec",
    column: "ADDRESS",
    isHidden: false,
    position: 10,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true },
    positionByTab: { DOOH: 5, PRINT_OOH: 5, OOH_TRANSIT: 25 },
  },
  {
    kind: "spec",
    column: "SITE_ID",
    isHidden: false,
    position: 11,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true },
    positionByTab: { DOOH: 6, PRINT_OOH: 6, OOH_TRANSIT: 26 },
  },
  {
    kind: "spec",
    width: 163,
    column: "DOCUMENTS",
    isHidden: false,
    position: 12,
    isLimited: false,
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: false,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: false,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      BVOD: 4,
      DOOH: 3,
      AUDIO: 4,
      RADIO: 4,
      VIDEO: 5,
      CINEMA: 4,
      NATIVE: 5,
      ONLINE: 3,
      SOCIAL: 4,
      DISPLAY: 4,
      Overview: 3,
      BROADCAST: 12,
      PRINT_OOH: 3,
      RICHMEDIA: 2,
      OOH_TRANSIT: 3,
      PRINT_MAGAZINE: 3,
      PRINT_NEWSPAPER: 3,
    },
  },
  {
    kind: "spec",
    width: 173,
    column: "TRIM",
    isHidden: false,
    position: 13,
    isLimited: false,
    positionByTab: { PRINT_MAGAZINE: 4, PRINT_NEWSPAPER: 3 },
  },
  {
    kind: "spec",
    width: 235,
    column: "DIMENSIONS",
    isHidden: false,
    position: 14,
    isLimited: false,
    isHiddenByTab: { ONLINE: false, OOH_TRANSIT: true },
    positionByTab: {
      DOOH: 6,
      VIDEO: 4,
      CINEMA: 3,
      NATIVE: 2,
      ONLINE: 3,
      SOCIAL: 2,
      DISPLAY: 2,
      PRINT_OOH: 6,
      OOH_TRANSIT: 26,
    },
  },
  {
    kind: "spec",
    width: 199,
    column: "AVAILABLE_DURATION",
    isHidden: false,
    position: 15,
    isLimited: false,
    isHiddenByTab: {
      CINEMA: true,
      ONLINE: false,
      SOCIAL: true,
      RICHMEDIA: false,
    },
    positionByTab: {
      DOOH: 7,
      VIDEO: 3,
      CINEMA: 7,
      NATIVE: 3,
      ONLINE: 5,
      SOCIAL: 27,
      RICHMEDIA: 3,
    },
  },
  {
    kind: "spec",
    width: 219,
    column: "PHYSICAL_DIMENSION",
    isHidden: false,
    position: 16,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true },
    positionByTab: { PRINT_OOH: 7, OOH_TRANSIT: 27 },
  },
  {
    kind: "spec",
    column: "ARTWORK_DIMENSION",
    isHidden: false,
    position: 17,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true },
    positionByTab: { PRINT_OOH: 8, OOH_TRANSIT: 28 },
  },
  {
    kind: "spec",
    width: 176,
    column: "SAFE_AREA",
    isHidden: false,
    position: 18,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true, PRINT_NEWSPAPER: true },
    positionByTab: {
      PRINT_OOH: 9,
      OOH_TRANSIT: 29,
      PRINT_MAGAZINE: 5,
      PRINT_NEWSPAPER: 20,
    },
  },
  {
    kind: "spec",
    column: "BLEED_AREA",
    isHidden: false,
    position: 19,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true },
    positionByTab: { OOH_TRANSIT: 30 },
  },
  {
    kind: "spec",
    column: "ARTWORK_SCALE",
    isHidden: false,
    position: 18,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true },
    positionByTab: { PRINT_OOH: 8, OOH_TRANSIT: 29 },
  },
  {
    kind: "spec",
    width: 121,
    column: "RESOLUTION",
    isHidden: false,
    position: 21,
    isLimited: false,
    isHiddenByTab: { OOH_TRANSIT: true },
    positionByTab: {
      PRINT_OOH: 11,
      OOH_TRANSIT: 33,
      PRINT_MAGAZINE: 6,
      PRINT_NEWSPAPER: 4,
    },
  },
  {
    kind: "spec",
    column: "BIT_RATE",
    isHidden: false,
    position: 22,
    isLimited: false,
    positionByTab: { VIDEO: 5, CINEMA: 4 },
  },
  {
    kind: "spec",
    column: "SAMPLE_RATE",
    isHidden: false,
    position: 23,
    isLimited: false,
  },
  {
    kind: "spec",
    column: "FRAME_RATE",
    isHidden: false,
    position: 24,
    isLimited: false,
    positionByTab: { VIDEO: 6 },
  },
  {
    kind: "spec",
    width: 160,
    column: "ASPECT_RATIOS",
    isHidden: false,
    position: 28,
    isLimited: false,
    isHiddenByTab: { ONLINE: true },
    positionByTab: {
      VIDEO: 7,
      CINEMA: 5,
      NATIVE: 4,
      ONLINE: 14,
      SOCIAL: 3,
      DISPLAY: 3,
    },
  },
  {
    kind: "spec",
    width: 168,
    column: "FILE_SIZES",
    isHidden: false,
    position: 29,
    isLimited: false,
    isHiddenByTab: {
      CINEMA: true,
      ONLINE: false,
      Overview: true,
      PRINT_OOH: true,
      OOH_TRANSIT: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      DOOH: 9,
      VIDEO: 8,
      CINEMA: 19,
      NATIVE: 5,
      ONLINE: 6,
      SOCIAL: 4,
      DISPLAY: 4,
      Overview: 4,
      PRINT_OOH: 21,
      OOH_TRANSIT: 22,
      PRINT_MAGAZINE: 21,
      PRINT_NEWSPAPER: 5,
    },
  },
  {
    kind: "spec",
    width: 160,
    column: "FILE_FORMATS",
    isHidden: false,
    position: 30,
    isLimited: false,
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: false,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: false,
      SOCIAL: false,
      DISPLAY: false,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: false,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: false,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      DOOH: 8,
      VIDEO: 6,
      CINEMA: 5,
      NATIVE: 6,
      ONLINE: 4,
      SOCIAL: 5,
      DISPLAY: 5,
      Overview: 5,
      PRINT_OOH: 12,
      OOH_TRANSIT: 2,
      PRINT_MAGAZINE: 7,
      PRINT_NEWSPAPER: 6,
    },
  },
  {
    kind: "spec",
    width: 195,
    column: "COPY",
    isHidden: false,
    position: 31,
    isLimited: false,
    positionByTab: { NATIVE: 6, SOCIAL: 7 },
  },
];
const standardColumns = [
  {
    kind: "standard",
    column: "MEDIA_OWNER",
    isHidden: false,
    position: 0,
    isLimited: false,
  },
  {
    kind: "standard",
    column: "PLACEMENT_INFO",
    isHidden: false,
    position: 1,
    isLimited: false,
  },
  {
    kind: "standard",
    width: 160,
    column: "STATUS",
    isHidden: false,
    position: 2,
    isLimited: false,
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: false,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      BVOD: 2,
      DOOH: 2,
      AUDIO: 2,
      RADIO: 2,
      VIDEO: 2,
      CINEMA: 2,
      NATIVE: 2,
      ONLINE: 2,
      SOCIAL: 2,
      DISPLAY: 2,
      Overview: 2,
      BROADCAST: 2,
      PRINT_OOH: 2,
      RICHMEDIA: 2,
      OOH_TRANSIT: 2,
      PRINT_MAGAZINE: 2,
      PRINT_NEWSPAPER: 2,
    },
  },
  {
    kind: "standard",
    width: 160,
    column: "CHANNEL",
    isHidden: false,
    position: 3,
    isLimited: false,
    positionByTab: { Overview: 3 },
  },
  {
    kind: "standard",
    width: 160,
    column: "PUBLICATIONS",
    isHidden: false,
    position: 4,
    isLimited: false,
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: true,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: false,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: true,
      RICHMEDIA: true,
      OOH_TRANSIT: true,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: false,
      PRINT_NEWSPAPER: false,
    },
    positionByTab: {
      BVOD: 2,
      AUDIO: 3,
      RADIO: 3,
      VIDEO: 3,
      CINEMA: 3,
      NATIVE: 3,
      ONLINE: 2,
      SOCIAL: 3,
      DISPLAY: 3,
      Overview: 3,
      BROADCAST: 3,
      RICHMEDIA: 3,
      PRINT_MAGAZINE: 2,
      PRINT_NEWSPAPER: 2,
    },
  },
  {
    kind: "standard",
    column: "CAMPAIGN_CODE",
    isHidden: false,
    position: 5,
    isLimited: false,
    positionByTab: { DOOH: 0, PRINT_OOH: 0 },
  },
  {
    kind: "standard",
    color: "yellow",
    column: "LIVE_DATE",
    isHidden: false,
    position: 50,
    isLimited: false,
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: false,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: false,
      RICHMEDIA: true,
      OOH_TRANSIT: false,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      DOOH: 11,
      PRINT_OOH: 14,
      OOH_TRANSIT: 4,
    },
  },
  {
    kind: "standard",
    color: "red",
    column: "END_DATE",
    isHidden: false,
    position: 53,
    isLimited: false,
    isHiddenByTab: {
      SEM: true,
      BVOD: true,
      DOOH: false,
      AUDIO: true,
      OTHER: true,
      RADIO: true,
      VIDEO: true,
      CINEMA: true,
      NATIVE: true,
      ONLINE: true,
      SOCIAL: true,
      DISPLAY: true,
      Overview: true,
      BROADCAST: true,
      PRINT_OOH: false,
      RICHMEDIA: true,
      OOH_TRANSIT: false,
      LEAD_GEN_FORM: true,
      PRINT_MAGAZINE: true,
      PRINT_NEWSPAPER: true,
    },
    positionByTab: {
      DOOH: 9,
      PRINT_OOH: 12,
      OOH_TRANSIT: 2,
    },
  },
];

const infoSchemaFields = {
  "Copy - Block 2": {
    fieldType: "RICHTEXT",
    position: 23,
  },
  "Social Copy - 3": {
    fieldType: "RICHTEXT",
    position: 54,
  },
  "Social CTA": {
    fieldType: "TEXT",
    position: 55,
  },
  "Dispatched Y/N": {
    fieldType: "DICTIONARY",
    position: 44,
    options: {
      values: {
        "c19d1710-47fe-4113-aeec-a231b09b642d": {
          name: "No",
          color: "red",
          created: 1694585401814,
        },
        "c7cec632-e5a5-4b78-9da6-366f1a94f355": {
          name: "Yes",
          color: "green",
          created: 1694585401814,
        },
      },
      allowTagCreation: true,
    },
  },
  "Rights Expiry Date?": {
    fieldType: "DATE",
    position: 48,
  },
  "Social Copy - 1": {
    fieldType: "RICHTEXT",
    position: 50,
  },
  "Social Copy - 2": {
    fieldType: "RICHTEXT",
    position: 51,
  },
  Market: {
    fieldType: "DICTIONARY",
    position: 53,
    options: {
      values: {
        "167f4fd1-e5dd-4b01-af6d-f0acf6170e61": {
          name: "Sydney Metro",
          color: "none",
          created: 1694585401814,
        },
        "4c2158ed-466d-4ba1-ae77-b74e92ba403b": {
          name: "Perth Metro",
          color: "none",
          created: 1694585401814,
        },
        "576133a0-55c5-4ac8-98f4-a7b230bbe09a": {
          name: "NSW Regional North Coast",
          color: "none",
          created: 1694585401814,
        },
        "747c7d8a-c128-428c-8165-2c06e373828a": {
          name: "Melbourne Metro",
          color: "none",
          created: 1694585401814,
        },
        "820c4cc4-a3b3-4a3c-a881-f9dc0cf6b2c0": {
          name: "Brisbane Metro",
          color: "none",
          created: 1694585401814,
        },
        "9ab6921f-9353-4f07-9dc7-e08b94e15aba": {
          name: "NSW Regional South Coast",
          color: "none",
          created: 1694585401814,
        },
        "d0e4e391-4e4a-4af7-b3c5-5eb26b5244e7": {
          name: "NSW Regional Mid North Coast",
          color: "none",
          created: 1694585401814,
        },
        "df9c4bb6-2e63-47ba-945e-2f031ab852c7": {
          name: "Adelaide Metro",
          color: "none",
          created: 1694585401814,
        },
      },
      allowTagCreation: true,
    },
  },
  "Copy - Block 1": {
    fieldType: "RICHTEXT",
    position: 56,
  },
  "CAD Approved?": {
    fieldType: "DICTIONARY",
    position: 33,
    options: {
      values: {
        "bf0cad05-de36-41af-a85f-512dc3a73b84": {
          name: "Yes",
          color: "green",
          created: 1694585401814,
        },
        "f73948ad-762e-4ab1-a745-23a7285123f3": {
          name: "No",
          color: "red",
          created: 1694585401814,
        },
      },
      allowTagCreation: true,
    },
  },
  "Creative Name": {
    fieldType: "RICHTEXT",
    position: 34,
  },
  Targeting: {
    fieldType: "RICHTEXT",
    position: 35,
  },
  "Copy - Block 3": {
    fieldType: "RICHTEXT",
    position: 24,
  },
  "Call to Action": {
    fieldType: "TEXT",
    position: 25,
  },
  "TVC / Creative Title": {
    fieldType: "RICHTEXT",
    position: 30,
  },
  "Key Number": {
    fieldType: "TEXT",
    position: 31,
  },
  "Creative Rotation": {
    fieldType: "RICHTEXT",
    position: 32,
  },
  "Required Duration": {
    fieldType: "TEXT",
    position: 36,
  },
  "File Name": {
    fieldType: "TEXT",
    systemType: "FILE_NAME",
    position: 37,
  },
  "Delivery Instructions": {
    fieldType: "RICHTEXT",
    position: 38,
  },
  "CAD/ CAB #": {
    fieldType: "TEXT",
    position: 40,
  },
  "Link to Asset": {
    fieldType: "TEXT",
    position: 41,
  },
  "Run as Bonus?": {
    fieldType: "BOOLEAN",
    position: 43,
  },
  Delivery: {
    fieldType: "DATE",
    systemType: "DISPATCH_DATE",
    position: 45,
  },
  Live: {
    fieldType: "DATE",
    systemType: "LIVE_DATE",
    position: 46,
  },
  End: {
    fieldType: "DATE",
    systemType: "END_DATE",
    position: 47,
  },
} as const;

export const defaultTemplateName = "The Spec Sheet Default Template";
export const defaultTemplateDescription =
  "Template created by The Spec Sheet containing best practice";

export const defaultTemplates = [
  {
    label: defaultTemplateName,
    description: defaultTemplateDescription,
    infoSchemaFields,
    standardColumns,
    specColumns,
    tableViewColumns,
  },
] as const;
