import {
  GoMediaExtBookingData,
  JcdExtBookingData,
  MediaWorksExtBookingData,
  OohExtBookingData,
  QmsExtBookingData,
} from "../hardcoded";

export const externalBookingImportType = {
  API: "API",
  JCD: "JCD",
  JCDNZ: "JCDNZ",
  QMS: "QMS",
  GO_MEDIA: "GO_MEDIA",
  MEDIA_WORKS: "MEDIA_WORKS",
  OOH: "OOH",
} as const;

export type ExternalBookingImportType =
  (typeof externalBookingImportType)[keyof typeof externalBookingImportType];

export const externalBookingImportTypeLabels: Record<
  ExternalBookingImportType,
  string
> = {
  [externalBookingImportType.API]: "API",
  [externalBookingImportType.JCD]: "JCD",
  [externalBookingImportType.JCDNZ]: "JCDNZ",
  [externalBookingImportType.QMS]: "QMS",
  [externalBookingImportType.GO_MEDIA]: "GO_MEDIA",
  [externalBookingImportType.MEDIA_WORKS]: "MEDIA_WORKS",
  [externalBookingImportType.OOH]: "OOH",
};

export type ExtBookingData =
  | {
      kind: typeof externalBookingImportType.JCD;
      data: JcdExtBookingData;
    }
  | {
      kind: typeof externalBookingImportType.JCDNZ;
      data: JcdExtBookingData;
    }
  | {
      kind: typeof externalBookingImportType.QMS;
      data: QmsExtBookingData;
    }
  | { kind: typeof externalBookingImportType.API; data: unknown }
  | {
      kind: typeof externalBookingImportType.GO_MEDIA;
      data: GoMediaExtBookingData;
    }
  | {
      kind: typeof externalBookingImportType.MEDIA_WORKS;
      data: MediaWorksExtBookingData;
    }
  | {
      kind: typeof externalBookingImportType.OOH;
      data: OohExtBookingData;
    };
