import {
  CampaignChangeCreatedBy,
  campaignChangeCreatedBy,
  externalBookingImportType,
  ExternalBookingImportType,
} from "@specsheet-common/shared-types";

export const extBookingImportTypeToCampaignChangeCreatedBy = (
  importType: ExternalBookingImportType
): CampaignChangeCreatedBy => {
  switch (importType) {
    case externalBookingImportType.JCD: {
      return campaignChangeCreatedBy.EXT_IMPORT_JCD;
    }
    case externalBookingImportType.JCDNZ: {
      return campaignChangeCreatedBy.EXT_IMPORT_JCDNZ;
    }
    case externalBookingImportType.MEDIA_WORKS: {
      return campaignChangeCreatedBy.EXT_IMPORT_MEDIA_WORKS;
    }
    case externalBookingImportType.QMS: {
      return campaignChangeCreatedBy.EXT_IMPORT_QMS;
    }
    case externalBookingImportType.GO_MEDIA: {
      return campaignChangeCreatedBy.EXT_IMPORT_GO_MEDIA;
    }
    case externalBookingImportType.OOH: {
      return campaignChangeCreatedBy.EXT_IMPORT_OOH;
    }
    case externalBookingImportType.API: {
      throw new Error("Unsupported import type: " + importType);
    }
  }
};
