export const auth0SubRoute = {
  changePassword: "change-password",
  passwordChangedHook: "passwordchangedhook",
  createUser: "create-user",
  resendVerification: "resend-verification",
} as const;

export type Auth0Routes =
  | {
      sub: typeof auth0SubRoute.changePassword;
      method?: "Post";
    }
  | {
      sub: typeof auth0SubRoute.createUser;
      method?: "Post";
    }
  | {
      sub: typeof auth0SubRoute.passwordChangedHook;
      method?: "Post";
    }
  | {
      sub: typeof auth0SubRoute.resendVerification;
      method?: "Post";
    };
