import * as z from "zod";
import { mediaWorksBookingSchema } from "./booking";
import { mediaWorksDigitalPanelSchema } from "./digitalPanel";
import { mediaWorksInstallCompanySchema } from "./installCompany";
import { mediaWorksStaticPanelSchema } from "./staticPanel";

export const mediaWorksPanelKinds = {
  DIGITAL: "DIGITAL",
  STATIC: "STATIC",
} as const;

export const mediaWorksDigitalPanelData = mediaWorksDigitalPanelSchema
  .merge(mediaWorksBookingSchema)
  .merge(mediaWorksInstallCompanySchema);

export const mediaWorksStaticPanelData = mediaWorksStaticPanelSchema
  .merge(mediaWorksBookingSchema)
  .merge(mediaWorksInstallCompanySchema);

export const mediaWorksDigitalData = z.object({
  kind: z.literal(mediaWorksPanelKinds.DIGITAL),
  data: mediaWorksDigitalPanelData,
});

export const mediaWorksStaticData = z.object({
  kind: z.literal(mediaWorksPanelKinds.STATIC),
  data: mediaWorksStaticPanelData,
});

export const mediaWorksExtBookingData = z.union([
  mediaWorksDigitalData,
  mediaWorksStaticData,
]);

export type MediaWorksPanelKinds =
  (typeof mediaWorksPanelKinds)[keyof typeof mediaWorksPanelKinds];
export type MediaWorksStaticData = z.infer<typeof mediaWorksStaticPanelData>;
export type MediaWorksDigitalData = z.infer<typeof mediaWorksDigitalPanelData>;
export type MediaWorksExtBookingData = z.infer<typeof mediaWorksExtBookingData>;
