import * as z from "zod";
import { idFlavor } from "../../common";

export const basicPlacementInfo = z.object({
  name: z.string(),
  placementId: z.string(),
});

export const notificationStructuredMessageKind = {
  externalLegacy: "externalLegacy",
  external: "external",
  campaignShared: "campaignShared",
} as const;

export const legacyExtStructuredNotificationDbZod = z.object({
  ext: z.object({
    placementsAdded: z.array(basicPlacementInfo),
    placementsDeleted: z.array(basicPlacementInfo),
    placementsUpdated: z.array(basicPlacementInfo),
    extCampaignMediaOwner: z.string().or(z.undefined()),
    extCampaignsChanged: z.string().or(z.undefined()),
  }),
});

export type LegacyExtStructuredNotificationDb = z.infer<
  typeof legacyExtStructuredNotificationDbZod
>;

export const extStructuredNotificationDbZod = z.object({
  kind: z.literal(notificationStructuredMessageKind.external),
  extBookingsAdded: z.array(z.string()),
  extBookingsDeleted: z.array(z.string()),
  extBookingsUpdated: z.array(z.string()),
  publisherId: z.string(),
  changedExtCampaignIds: z.array(z.string()),
});

export type ExtStructuredNotificationDb = Omit<
  z.infer<typeof extStructuredNotificationDbZod>,
  | "extBookingsAdded"
  | "extBookingsDeleted"
  | "extBookingsUpdated"
  | "publisherId"
  | "changedExtCampaignIds"
> & {
  extBookingsAdded: idFlavor<"ExtBooking">[];
  extBookingsDeleted: idFlavor<"ExtBooking">[];
  extBookingsUpdated: idFlavor<"ExtBooking">[];
  publisherId: idFlavor<"Publisher">;
  changedExtCampaignIds: idFlavor<"ExtCampaign">[];
};

export const campaignSharedStructuredNotificationZod = z.object({
  version: z.number(),
});

export type CampaignSharedStructuredNotification = z.infer<
  typeof campaignSharedStructuredNotificationZod
>;
