import { z } from "zod";

export const listPublicationGroupsSchema = z.object({
  publisherId: z.string().min(1),
  query: z.string().min(1).optional(),
  page: z
    .string()
    .optional()
    .transform((v) => (v ? parseInt(v) : 1))
    .refine((v) => [v >= 1]),
  rows: z
    .string()
    .optional()
    .transform((v) => (v ? parseInt(v) : 1000))
    .refine((v) => v > 0 && v < 10000),
});

export type ListPublicationsGroupsReq = z.infer<
  typeof listPublicationGroupsSchema
>;
