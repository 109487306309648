import { apiExtBookingChangeData } from "../extBooking";
import * as z from "zod";
import {
  goMediaExtBookingChangeData,
  jcdExtBookingChangeData,
  mediaWorksExtBookingChangeData,
  oohExtBookingChangeData,
  qmsExtBookingChangeData,
} from "../hardcoded";

export const extBookingChangeTypes = {
  JCD: "JCD",
  JCDNZ: "JCDNZ",
  API: "API",
  QMS: "QMS",
  PLACEMENT: "PLACEMENT",
  GO_MEDIA: "GO_MEDIA",
  MEDIA_WORKS: "MEDIA_WORKS",
  OOH: "OOH",
} as const;

export type ExtBookingChangeTypes =
  (typeof extBookingChangeTypes)[keyof typeof extBookingChangeTypes];

export const extBookingDataChange = z.union([
  z.object({
    kind: z.literal(extBookingChangeTypes.JCD),
    data: jcdExtBookingChangeData,
  }),
  z.object({
    kind: z.literal(extBookingChangeTypes.JCDNZ),
    data: jcdExtBookingChangeData,
  }),
  z.object({
    kind: z.literal(extBookingChangeTypes.API),
    data: apiExtBookingChangeData,
  }),
  z.object({
    kind: z.literal(extBookingChangeTypes.QMS),
    data: qmsExtBookingChangeData,
  }),
  z.object({
    kind: z.literal(extBookingChangeTypes.GO_MEDIA),
    data: goMediaExtBookingChangeData,
  }),
  z.object({
    kind: z.literal(extBookingChangeTypes.MEDIA_WORKS),
    data: mediaWorksExtBookingChangeData,
  }),
  z.object({
    kind: z.literal(extBookingChangeTypes.OOH),
    data: oohExtBookingChangeData,
  }),
  z.object({
    kind: z.literal(extBookingChangeTypes.PLACEMENT),
    data: z.object({ before: z.string(), after: z.string() }),
  }),
]);

export type ExtBookingDataChange = z.infer<typeof extBookingDataChange>;
