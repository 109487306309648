import { idFlavor } from "../../common";

export const userSubRoute = {
  createOrgMemberBatch: "orgmember/batch",
  createInWorkspace: "workspace",
  resendInvite: "orgmember/resend",
  resendInvites: "orgmembers/resend",
  revokeInvite: "orgmember/revoke",
  revokeInvites: "orgmembers/revoke",
  onboarding: "onboarding",
} as const;

export type UserRoutes =
  | {
      sub: typeof userSubRoute.createOrgMemberBatch;
      method?: "Post";
    }
  | {
      sub: typeof userSubRoute.resendInvite;
      tailParam: idFlavor<"OrganisationMember">;
      method?: "Get";
    }
  | {
      sub: typeof userSubRoute.resendInvites;
      method?: "Post";
    }
  | {
      sub: typeof userSubRoute.revokeInvite;
      tailParam: idFlavor<"OrganisationMember">;
      method?: "Get";
    }
  | {
      sub: typeof userSubRoute.createInWorkspace;
      method?: "Post";
    }
  | {
      sub: typeof userSubRoute.revokeInvites;
      method?: "Post";
    }
  | {
      sub: typeof userSubRoute.onboarding;
      method?: "Post";
    };
