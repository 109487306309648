import {
  campaignTemplateRoutes,
  campaignUrls,
  infoSchemaUrls,
  placementUrls,
  campaignUserUrls,
} from "./entities";

const coreServerUrls = {
  ...campaignTemplateRoutes,
  ...campaignUrls,
  ...infoSchemaUrls,
  ...placementUrls,
  ...campaignUserUrls,
} as const;

type CoreServerKey = keyof typeof coreServerUrls;

export const getCoreServerUrl = (
  key: CoreServerKey,
  opts?: {
    params?: Record<string, string>;
    omit?: CoreServerKey;
  }
) => {
  let url: string | undefined = coreServerUrls[key];

  if (opts?.omit) {
    const rootUrl: string | undefined = coreServerUrls[opts.omit];
    url = url.replace(rootUrl, "");
  }

  for (const [key, value] of Object.entries(opts?.params ?? {})) {
    url = url.replace(`:${key}`, value);
  }

  return url;
};
