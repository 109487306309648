/* @generated from adl module kachemedia.specsheet.orgworkspace */

import * as ADL from './../../runtime/adl';
import * as common_db from './../../common/db';
import * as common_strings from './../../common/strings';
import * as kachemedia_specsheet_common from './common';
import * as kachemedia_specsheet_db from './db';
import * as kachemedia_specsheet_users from './users';

export interface OrganisationView {
  organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>;
  name: common_strings.StringNE;
  logo: kachemedia_specsheet_common.Url;
}

export function makeOrganisationView(
  input: {
    organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>,
    name: common_strings.StringNE,
    logo: kachemedia_specsheet_common.Url,
  }
): OrganisationView {
  return {
    organisationId: input.organisationId,
    name: input.name,
    logo: input.logo,
  };
}

const OrganisationView_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.orgworkspace","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"organisationId","default":{"kind":"nothing"},"name":"organisationId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Organisation"}},"parameters":[]}]}},{"annotations":[],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[],"serializedName":"logo","default":{"kind":"nothing"},"name":"logo","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"Url"}},"parameters":[]}}]}},"name":"OrganisationView","version":{"kind":"nothing"}}};

export const snOrganisationView: ADL.ScopedName = {moduleName:"kachemedia.specsheet.orgworkspace", name:"OrganisationView"};

export function texprOrganisationView(): ADL.ATypeExpr<OrganisationView> {
  return {value : {typeRef : {kind: "reference", value : snOrganisationView}, parameters : []}};
}

export interface WorkspaceViewWithStats {
  workspaceAccess: kachemedia_specsheet_users.WorkspaceAccess;
  stats: WorkspaceSummaryStats;
}

export function makeWorkspaceViewWithStats(
  input: {
    workspaceAccess: kachemedia_specsheet_users.WorkspaceAccess,
    stats: WorkspaceSummaryStats,
  }
): WorkspaceViewWithStats {
  return {
    workspaceAccess: input.workspaceAccess,
    stats: input.stats,
  };
}

const WorkspaceViewWithStats_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.orgworkspace","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"workspaceAccess","default":{"kind":"nothing"},"name":"workspaceAccess","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"WorkspaceAccess"}},"parameters":[]}},{"annotations":[],"serializedName":"stats","default":{"kind":"nothing"},"name":"stats","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.orgworkspace","name":"WorkspaceSummaryStats"}},"parameters":[]}}]}},"name":"WorkspaceViewWithStats","version":{"kind":"nothing"}}};

export const snWorkspaceViewWithStats: ADL.ScopedName = {moduleName:"kachemedia.specsheet.orgworkspace", name:"WorkspaceViewWithStats"};

export function texprWorkspaceViewWithStats(): ADL.ATypeExpr<WorkspaceViewWithStats> {
  return {value : {typeRef : {kind: "reference", value : snWorkspaceViewWithStats}, parameters : []}};
}

export interface WorkspaceSummaryStats {
  numUsers: number;
  activeCampaigns: number;
  totalCampaigns: number;
}

export function makeWorkspaceSummaryStats(
  input: {
    numUsers: number,
    activeCampaigns: number,
    totalCampaigns: number,
  }
): WorkspaceSummaryStats {
  return {
    numUsers: input.numUsers,
    activeCampaigns: input.activeCampaigns,
    totalCampaigns: input.totalCampaigns,
  };
}

const WorkspaceSummaryStats_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.orgworkspace","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"numUsers","default":{"kind":"nothing"},"name":"numUsers","typeExpr":{"typeRef":{"kind":"primitive","value":"Int32"},"parameters":[]}},{"annotations":[],"serializedName":"activeCampaigns","default":{"kind":"nothing"},"name":"activeCampaigns","typeExpr":{"typeRef":{"kind":"primitive","value":"Int32"},"parameters":[]}},{"annotations":[],"serializedName":"totalCampaigns","default":{"kind":"nothing"},"name":"totalCampaigns","typeExpr":{"typeRef":{"kind":"primitive","value":"Int32"},"parameters":[]}}]}},"name":"WorkspaceSummaryStats","version":{"kind":"nothing"}}};

export const snWorkspaceSummaryStats: ADL.ScopedName = {moduleName:"kachemedia.specsheet.orgworkspace", name:"WorkspaceSummaryStats"};

export function texprWorkspaceSummaryStats(): ADL.ATypeExpr<WorkspaceSummaryStats> {
  return {value : {typeRef : {kind: "reference", value : snWorkspaceSummaryStats}, parameters : []}};
}

/**
 * User profile and their access to an organisation and org's workspaces
 */
export interface UserProfileWithOrgAccess {
  userProfile: kachemedia_specsheet_users.UserProfile;
  workspaceAccess: kachemedia_specsheet_users.WorkspaceAccess[];
  organisationAccess: kachemedia_specsheet_users.OrganisationAccess;
}

export function makeUserProfileWithOrgAccess(
  input: {
    userProfile: kachemedia_specsheet_users.UserProfile,
    workspaceAccess: kachemedia_specsheet_users.WorkspaceAccess[],
    organisationAccess: kachemedia_specsheet_users.OrganisationAccess,
  }
): UserProfileWithOrgAccess {
  return {
    userProfile: input.userProfile,
    workspaceAccess: input.workspaceAccess,
    organisationAccess: input.organisationAccess,
  };
}

const UserProfileWithOrgAccess_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.orgworkspace","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"User profile and their access to an organisation and org's workspaces\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"userProfile","default":{"kind":"nothing"},"name":"userProfile","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"UserProfile"}},"parameters":[]}},{"annotations":[],"serializedName":"workspaceAccess","default":{"kind":"nothing"},"name":"workspaceAccess","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"WorkspaceAccess"}},"parameters":[]}]}},{"annotations":[],"serializedName":"organisationAccess","default":{"kind":"nothing"},"name":"organisationAccess","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"OrganisationAccess"}},"parameters":[]}}]}},"name":"UserProfileWithOrgAccess","version":{"kind":"nothing"}}};

export const snUserProfileWithOrgAccess: ADL.ScopedName = {moduleName:"kachemedia.specsheet.orgworkspace", name:"UserProfileWithOrgAccess"};

export function texprUserProfileWithOrgAccess(): ADL.ATypeExpr<UserProfileWithOrgAccess> {
  return {value : {typeRef : {kind: "reference", value : snUserProfileWithOrgAccess}, parameters : []}};
}

export interface WorkspaceUserAccessReq {
  orgMemberId: common_db.DbKey<kachemedia_specsheet_db.OrganisationMember>;
  workspaceRole: kachemedia_specsheet_db.WorkspaceMemberRole;
}

export function makeWorkspaceUserAccessReq(
  input: {
    orgMemberId: common_db.DbKey<kachemedia_specsheet_db.OrganisationMember>,
    workspaceRole: kachemedia_specsheet_db.WorkspaceMemberRole,
  }
): WorkspaceUserAccessReq {
  return {
    orgMemberId: input.orgMemberId,
    workspaceRole: input.workspaceRole,
  };
}

const WorkspaceUserAccessReq_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.orgworkspace","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"orgMemberId","default":{"kind":"nothing"},"name":"orgMemberId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"OrganisationMember"}},"parameters":[]}]}},{"annotations":[],"serializedName":"workspaceRole","default":{"kind":"nothing"},"name":"workspaceRole","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"WorkspaceMemberRole"}},"parameters":[]}}]}},"name":"WorkspaceUserAccessReq","version":{"kind":"nothing"}}};

export const snWorkspaceUserAccessReq: ADL.ScopedName = {moduleName:"kachemedia.specsheet.orgworkspace", name:"WorkspaceUserAccessReq"};

export function texprWorkspaceUserAccessReq(): ADL.ATypeExpr<WorkspaceUserAccessReq> {
  return {value : {typeRef : {kind: "reference", value : snWorkspaceUserAccessReq}, parameters : []}};
}

export interface WorkspaceUserUpdatesData {
  addedUsers: (WorkspaceUserAccessReq[]|null);
  updateUsers: (WorkspaceUserAccessReq[]|null);
  deleteUsers: (common_db.DbKey<kachemedia_specsheet_db.OrganisationMember>[]|null);
}

export function makeWorkspaceUserUpdatesData(
  input: {
    addedUsers: (WorkspaceUserAccessReq[]|null),
    updateUsers: (WorkspaceUserAccessReq[]|null),
    deleteUsers: (common_db.DbKey<kachemedia_specsheet_db.OrganisationMember>[]|null),
  }
): WorkspaceUserUpdatesData {
  return {
    addedUsers: input.addedUsers,
    updateUsers: input.updateUsers,
    deleteUsers: input.deleteUsers,
  };
}

const WorkspaceUserUpdatesData_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.orgworkspace","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"addedUsers","default":{"kind":"nothing"},"name":"addedUsers","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.orgworkspace","name":"WorkspaceUserAccessReq"}},"parameters":[]}]}]}},{"annotations":[],"serializedName":"updateUsers","default":{"kind":"nothing"},"name":"updateUsers","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.orgworkspace","name":"WorkspaceUserAccessReq"}},"parameters":[]}]}]}},{"annotations":[],"serializedName":"deleteUsers","default":{"kind":"nothing"},"name":"deleteUsers","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"OrganisationMember"}},"parameters":[]}]}]}]}}]}},"name":"WorkspaceUserUpdatesData","version":{"kind":"nothing"}}};

export const snWorkspaceUserUpdatesData: ADL.ScopedName = {moduleName:"kachemedia.specsheet.orgworkspace", name:"WorkspaceUserUpdatesData"};

export function texprWorkspaceUserUpdatesData(): ADL.ATypeExpr<WorkspaceUserUpdatesData> {
  return {value : {typeRef : {kind: "reference", value : snWorkspaceUserUpdatesData}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "kachemedia.specsheet.orgworkspace.OrganisationView" : OrganisationView_AST,
  "kachemedia.specsheet.orgworkspace.WorkspaceViewWithStats" : WorkspaceViewWithStats_AST,
  "kachemedia.specsheet.orgworkspace.WorkspaceSummaryStats" : WorkspaceSummaryStats_AST,
  "kachemedia.specsheet.orgworkspace.UserProfileWithOrgAccess" : UserProfileWithOrgAccess_AST,
  "kachemedia.specsheet.orgworkspace.WorkspaceUserAccessReq" : WorkspaceUserAccessReq_AST,
  "kachemedia.specsheet.orgworkspace.WorkspaceUserUpdatesData" : WorkspaceUserUpdatesData_AST
};
