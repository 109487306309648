import {
  orgMemberRole,
  OrgMemberRole,
  WORKSPACE_PERMISSIONS,
  workspaceMemberRole,
  WorkspaceMemberRole,
} from "@specsheet-common/shared-types";

export function getMaybeElevatedWorkspaceRole({
  isOpsManager,
  workspaceRole,
  organisationRole,
}: {
  isOpsManager: boolean;
  organisationRole: OrgMemberRole | null;
  workspaceRole: WorkspaceMemberRole | null;
}): WorkspaceMemberRole | null {
  if (isOpsManager) {
    return workspaceMemberRole.ADMIN;
  }
  if (organisationRole && organisationRole === orgMemberRole.ADMIN) {
    return workspaceMemberRole.ADMIN;
  }
  return workspaceRole;
}

const getFullWorkspacePermissions = (): WORKSPACE_PERMISSIONS[] => {
  return Object.values(WORKSPACE_PERMISSIONS);
};

export const workspacePermissionsMap: Record<
  WorkspaceMemberRole,
  WORKSPACE_PERMISSIONS[]
> = {
  [workspaceMemberRole.ADMIN]: [...getFullWorkspacePermissions()],
  [workspaceMemberRole.MEMBER]: [
    WORKSPACE_PERMISSIONS.WORKSPACE_VIEW,
    WORKSPACE_PERMISSIONS.CLIENT_CREATE,
    WORKSPACE_PERMISSIONS.CLIENT_CHANGE_IS_ACTIVE_STATUS,
    WORKSPACE_PERMISSIONS.CLIENT_UPDATE,
    WORKSPACE_PERMISSIONS.CLIENT_LIST_VIEW,
    WORKSPACE_PERMISSIONS.CLIENT_VIEW,
    WORKSPACE_PERMISSIONS.TEMPLATE_VIEW,
    WORKSPACE_PERMISSIONS.TEMPLATE_CHANGE_IS_ACTIVE_STATUS,
    WORKSPACE_PERMISSIONS.TEMPLATE_CREATE,
    WORKSPACE_PERMISSIONS.TEMPLATE_UPDATE,
    WORKSPACE_PERMISSIONS.WORKSPACE_MEMBER_LIST_VIEW,
    WORKSPACE_PERMISSIONS.WORKSPACE_MEMBER_VIEW,
    WORKSPACE_PERMISSIONS.USER_GROUP_CREATE,
    WORKSPACE_PERMISSIONS.USER_GROUP_UPDATE,
    WORKSPACE_PERMISSIONS.USER_GROUP_CHANGE_MEMBERS,
    WORKSPACE_PERMISSIONS.USER_GROUP_VIEW,
    WORKSPACE_PERMISSIONS.USER_GROUP_LIST_VIEW,
    WORKSPACE_PERMISSIONS.USER_GROUP_CHANGE_IS_ACTIVE_STATUS,
    WORKSPACE_PERMISSIONS.CAMPAIGN_CREATE,
    WORKSPACE_PERMISSIONS.CAMPAIGN_LIST_VIEW,
    WORKSPACE_PERMISSIONS.CAMPAIGN_VIEW,
    WORKSPACE_PERMISSIONS.CAMPAIGN_UPDATE_DETAILS,
    WORKSPACE_PERMISSIONS.CAMPAIGN_CHANGE_IS_ACTIVE_STATUS,
    WORKSPACE_PERMISSIONS.CAMPAIGN_DUPLICATE,
    WORKSPACE_PERMISSIONS.CAMPAIGN_SHARE,
    WORKSPACE_PERMISSIONS.CAMPAIGN_CHANGE_WORKSPACE,
  ],
  [workspaceMemberRole.GUEST]: [
    WORKSPACE_PERMISSIONS.WORKSPACE_MEMBER_LIST_VIEW,
    WORKSPACE_PERMISSIONS.WORKSPACE_MEMBER_VIEW,
    WORKSPACE_PERMISSIONS.CLIENT_VIEW,
    WORKSPACE_PERMISSIONS.USER_GROUP_VIEW,
    WORKSPACE_PERMISSIONS.USER_GROUP_LIST_VIEW,
    WORKSPACE_PERMISSIONS.CAMPAIGN_LIST_VIEW,
    WORKSPACE_PERMISSIONS.CAMPAIGN_VIEW,
    WORKSPACE_PERMISSIONS.TEMPLATE_VIEW,
  ],
};
