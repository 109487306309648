import z from "zod";

export const oohSizeTypeSchema = z.object({
  sizeType: z.string(),
  pdfFileName: z.string(),
  videoMaxFileSize: z.number().optional(),
  staticMaxFileSize: z.number().optional(),
  videoAspectRatio: z.string().optional(),
  staticAspectRatio: z.string().optional(),
  videoCodec: z.string().optional(),
  videoFrameRate: z.number().optional(),
  videoBitRate: z.number().optional(),
  staticResolution: z.number().optional(),
  staticColour: z.string().optional(),
  format: z.string().optional(),
  videoSupportAudioCodec: z.string().optional(),
  videoSupportAudioBitrate: z.number().optional(),
  sampleRate: z.number().optional(),
  channels: z.string().optional(),
  audioLevels: z.string().optional(),
  maxTruePeak: z.string().optional(),
  stock: z.string().optional(),
  bleedLeft: z.number().optional(),
  bleedTop: z.number().optional(),
  bleedRight: z.number().optional(),
  bleedBottom: z.number().optional(),
  printColour: z.string().optional(),
  printFormat: z.string().optional(),
  printResolution: z.number().optional(),
  trimSize: z.string().optional(),
  additionalInformation: z.string().optional(),
  siteCardImage: z.string().optional(),
});

export type OohSizeType = z.infer<typeof oohSizeTypeSchema>;
