import { z } from "zod";
import { idFlavor, N_Instant } from "../../common";

export type CampaignVersionNumber = number;

export const versionCollaboratorSchema = z.object({
  email: z.string().email(),
});

export type VersionCollaborator = z.infer<typeof versionCollaboratorSchema>;

export interface N_CampaignVersion {
  campaignId: idFlavor<"Campaign">;
  version: CampaignVersionNumber;
  locked: boolean;
  lastUpdated: N_Instant;
  message: string | null;
  name: string | null;
  created: Date;
  userId: idFlavor<"AppUser"> | null;
  collaborators: VersionCollaborator[];
}

export interface N_CampaignVersionInfo {
  campaignVersion: N_CampaignVersion;
  userPublished: string | null;
}
