import {
  N_ArtworkSpec,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";

export const getArtworkScales = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>(
    (scales, spec) =>
      spec.value.kind === "printOOH" && spec.value.value.artworkScale !== null
        ? [
            ...scales,
            {
              ...spec,
              value: spec.value.value.artworkScale.value,
            },
          ]
        : scales,
    []
  );
