import { idFlavor, N_Instant } from "../../common";
import { InfoSchemaFieldView } from "./field";

export const infoSchemaPurpose = {
  CAMPAIGN_TEMPLATE: "CAMPAIGN_TEMPLATE",
  BOOKING: "BOOKING",
  PLACEMENT: "PLACEMENT",
  CAMPAIGN: "CAMPAIGN",
} as const;

export type N_InfoSchemaPurpose =
  (typeof infoSchemaPurpose)[keyof typeof infoSchemaPurpose];

export const infoSchemaCreationType = {
  DIRECT: "DIRECT",
  WITH_CAMPAIGN_TEMPLATE: "WITH_CAMPAIGN_TEMPLATE",
  WITH_INFO_SCHEMA_BLOCK: "WITH_INFO_SCHEMA_BLOCK",
  WITH_INFO_SCHEMA_TABLE_VIEW: "WITH_INFO_SCHEMA_TABLE_VIEW",
} as const;

export type N_InfoSchemaCreationType =
  (typeof infoSchemaCreationType)[keyof typeof infoSchemaCreationType];

export interface N_InfoSchema {
  purpose: N_InfoSchemaPurpose;
  label: string;
  organisationId: idFlavor<"Organisation">;
  authorId: idFlavor<"AppUser">;
  created: N_Instant;
  updated: N_Instant;
  isActive: boolean;
  originalSchemaId: idFlavor<"InfoSchema"> | null;
  creationType: N_InfoSchemaCreationType;
}

export interface N_InfoSchemaView extends N_InfoSchema {
  id: idFlavor<"InfoSchema">;
}

export interface N_SchemaWithFields {
  schema: N_InfoSchemaView;
  fields: InfoSchemaFieldView[];
}
