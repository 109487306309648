import * as z from "zod";
import {
  GoMediaDigitalBooking,
  goMediaDigitalBookingSchema,
} from "./digitalBooking";
import { GoMediaPrintBooking, goMediaPrintBookingSchema } from "./printBooking";
import {
  GoMediaTransitBooking,
  goMediaTransitBookingSchema,
} from "./transitBooking";

export const goMediaPanelKinds = {
  DIGITAL: "DIGITAL",
  PRINT: "PRINT",
  TRANSIT: "TRANSIT",
} as const;

export const goMediaExtBookingData = z.union([
  z.object({
    kind: z.literal(goMediaPanelKinds.PRINT),
    data: goMediaPrintBookingSchema,
  }),
  z.object({
    kind: z.literal(goMediaPanelKinds.TRANSIT),
    data: goMediaTransitBookingSchema,
  }),
  z.object({
    kind: z.literal(goMediaPanelKinds.DIGITAL),
    data: goMediaDigitalBookingSchema,
  }),
]);

export type GoMediaPanelKinds =
  (typeof goMediaPanelKinds)[keyof typeof goMediaPanelKinds];
export type GoMediaExtBooking =
  | GoMediaPrintBooking
  | GoMediaDigitalBooking
  | GoMediaTransitBooking;
export type GoMediaExtBookingData = z.infer<typeof goMediaExtBookingData>;
