import z from "zod";

export const goMediaPrintBookingSchema = z.object({
  campaignId: z.string(),
  campaignName: z.string(),
  advertiser: z.string(),
  billingClient: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  siteId: z.string(),
  panelName: z.string(),
  image: z.string(),
  originalAdSpecs: z.string(),
  direction: z.string(),
  address: z.string(),
  suburb: z.string(),
  postcode: z.string(),
  state: z.string(),
  country: z.string(),
  finishSize: z.string(),
  visualSize: z.string(),
  notes: z.string(),
  bleedTop: z.string(),
  bleedBottom: z.string(),
  bleedLeft: z.string(),
  bleedRight: z.string(),
  illumination: z.string(),
  material: z.string(),
  colour: z.string(),
  resolution: z.string(),
  fileFormat: z.string(),
  artworkScale: z.string(),
  asaAndNztaRequirements: z.string(),
  deadlinesAndDelivery: z.string(),
  proofs: z.string(),
  dispatch: z.string(),
});

export type GoMediaPrintBooking = z.infer<typeof goMediaPrintBookingSchema>;
