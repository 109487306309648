import { Components, Theme } from "@mui/material";

export const MuiAvatarGroup: Components<Theme>["MuiAvatarGroup"] = {
  styleOverrides: {
    avatar: {
      width: 30,
      height: 30,
    },
  },
};
