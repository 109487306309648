import { idFlavor } from "../../common";
export const featureFlags = {
  forbidLoadOnVersionMismatch: "forbidLoadOnVersionMismatch",
  allowRevertingWhenContractVariationExists:
    "allowRevertingWhenContractVariationExists",
  enableDeleting: "enableDeleting",
  useMetaLogRendering: "useMetaLogRendering",
  enableMediaworksImport: "enableMediaworksImport",
  useJcdTestBucket: "useJcdTestBucket",
  recordAllSessions: "recordAllSessions",
  enableDatadog: "enableDatadog",
  useGraphql: "useGraphql",
  maintenanceMode: "maintenanceMode",
  noChangeCountOnBuilder: "noChangeCountOnBuilder",
  cmlChangeBadge: "cmlChangeBadge",
  contractVariationEmails: "contractVariationEmails",
  hideGhostCmls: "hideGhostCmls",
  importExtEmails: "importExtEmails",
} as const;

export type FeatureFlag = (typeof featureFlags)[keyof typeof featureFlags];

interface FeatureFlagUserContext {
  id: idFlavor<"AppUser">;
  name: string;
  email: string;
}

interface FeatureFlagOrganisationContext {
  id: idFlavor<"Organisation">;
  name: string;
}

interface FeatureFlagWorkspaceContext {
  id: idFlavor<"Workspace">;
  name: string;
}

interface FeatureFlagCampaignContext {
  key: idFlavor<"Campaign">;
}

export interface FeatureFlagContext {
  user?: FeatureFlagUserContext;
  workspace?: FeatureFlagWorkspaceContext;
  organisation?: FeatureFlagOrganisationContext;
  campaign?: FeatureFlagCampaignContext;
}
