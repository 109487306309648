import * as z from "zod";
import { goMediaExtBookingData, goMediaPanelKinds } from "./general";

const stringChange = z.object({ before: z.string(), after: z.string() });

export const goMediaExtBookingChangeData = z.union([
  z.object({
    panelKindChanged: z.literal(true),
    change: z.object({
      before: goMediaExtBookingData,
      after: goMediaExtBookingData,
    }),
  }),
  z.object({
    panelKindChanged: z.literal(false),
    data: z.union([
      z.object({
        kind: z.literal(goMediaPanelKinds.DIGITAL),
        change: z.record(z.nativeEnum({}), stringChange),
      }),
      z.object({
        kind: z.literal(goMediaPanelKinds.PRINT),
        change: z.record(z.nativeEnum({}), stringChange),
      }),
      z.object({}),
    ]),
  }),
]);

export type GoMediaExtBookingChangeData = z.infer<
  typeof goMediaExtBookingChangeData
>;
