import {
  PlacementStandardColumns,
  placementStandardColumns,
} from "../placement";
import {
  bookingGroupStandardColumns,
  BookingGroupStandardColumns,
} from "../bookingGroup";

export const bookingStandardColumns = {
  requiredDurationColumn: "requiredDurationColumn",
  deliveryInstructionsColumn: "deliveryInstructionsColumn",
  creativeInstructionsColumn: "creativeInstructionsColumn",
  notesColumn: "notesColumn",
  dispatchDateColumn: "dispatchDateColumn",
  liveDateColumn: "liveDateColumn",
  endDateColumn: "endDateColumn",
  publicationsColumn: "publicationsColumn",
  campaignCodeColumn: "campaignCodeColumn",
} as const;

export type BookingStandardColumns =
  (typeof bookingStandardColumns)[keyof typeof bookingStandardColumns];

export const isBookingStandardColumn = (
  key: string
): key is BookingStandardColumns => {
  return Object.keys(bookingStandardColumns).includes(key);
};

export type AllBookingColumns =
  | BookingStandardColumns
  | PlacementStandardColumns
  | BookingGroupStandardColumns;

export const bookingTableHeaders: Record<AllBookingColumns, string> = {
  [placementStandardColumns.addressColumn]: "Address",
  [placementStandardColumns.artworkScaleColumn]: "Artwork Scale",
  [placementStandardColumns.artWorkDimensionColumn]: "Artwork Dimensions",
  [placementStandardColumns.aspectRatiosColumn]: "Aspect Ratio",
  [placementStandardColumns.bitRateColumn]: "Bit Rate",
  [placementStandardColumns.bleedAreaColumn]: "Bleed Area",
  [placementStandardColumns.copyColumn]: "Copy Required",
  [placementStandardColumns.dimensionsColumn]: "Dimensions (w x h)",
  [placementStandardColumns.documentsColumn]: "Documents",
  [placementStandardColumns.availableDurationColumn]: "Duration",
  [placementStandardColumns.fileFormatsColumn]: "File Format",
  [placementStandardColumns.fileSizesColumn]: "File Size",
  [placementStandardColumns.frameRateColumn]: "Frame Rate",
  [placementStandardColumns.siteIdColumn]: "Site ID",
  [placementStandardColumns.stateColumn]: "State",
  [placementStandardColumns.suburbColumn]: "Suburb",
  [placementStandardColumns.physicalDimensionColumn]: "Physical Dimensions",
  [placementStandardColumns.placementColumn]: "Placement",
  [placementStandardColumns.resolutionColumn]: "Resolution",
  [placementStandardColumns.safeAreaColumn]: "Type/Design Area",
  [placementStandardColumns.sampleRateColumn]: "Sample Rate",
  [placementStandardColumns.statusColumn]: "Status",
  [placementStandardColumns.channelColumn]: "Channel",
  [placementStandardColumns.trimColumn]: "Dimensions (h x w)",
  [bookingStandardColumns.publicationsColumn]: "Publications",
  [placementStandardColumns.mediaOwnerColumn]: "Media Owner",
  [bookingStandardColumns.creativeInstructionsColumn]: "Creative Instructions",
  [bookingStandardColumns.deliveryInstructionsColumn]: "Delivery Instructions",
  [bookingStandardColumns.notesColumn]: "Notes",
  [bookingStandardColumns.dispatchDateColumn]: "Delivery",
  [bookingStandardColumns.endDateColumn]: "Panel End",
  [bookingStandardColumns.liveDateColumn]: "Panel Live",
  [bookingStandardColumns.requiredDurationColumn]: "Required Duration",
  [bookingStandardColumns.campaignCodeColumn]: "Campaign Code",
  [bookingGroupStandardColumns.topGroup]: "Group",
  [bookingGroupStandardColumns.subGroup]: "SubGroup",
} as const;

export const bookingTableExcelColumnsInOrder: AllBookingColumns[] = [
  bookingGroupStandardColumns.topGroup,
  bookingGroupStandardColumns.subGroup,
  placementStandardColumns.mediaOwnerColumn,
  placementStandardColumns.placementColumn,
  placementStandardColumns.channelColumn,
  bookingStandardColumns.publicationsColumn,
  bookingStandardColumns.campaignCodeColumn,
  placementStandardColumns.documentsColumn,
  placementStandardColumns.stateColumn,
  placementStandardColumns.suburbColumn,
  placementStandardColumns.addressColumn,
  placementStandardColumns.siteIdColumn,
  bookingStandardColumns.requiredDurationColumn,
  placementStandardColumns.availableDurationColumn,
  placementStandardColumns.trimColumn,
  placementStandardColumns.dimensionsColumn,
  placementStandardColumns.physicalDimensionColumn,
  placementStandardColumns.artWorkDimensionColumn,
  placementStandardColumns.safeAreaColumn,
  placementStandardColumns.bleedAreaColumn,
  placementStandardColumns.artworkScaleColumn,
  placementStandardColumns.resolutionColumn,
  placementStandardColumns.bitRateColumn,
  placementStandardColumns.sampleRateColumn,
  placementStandardColumns.frameRateColumn,
  placementStandardColumns.aspectRatiosColumn,
  placementStandardColumns.fileSizesColumn,
  placementStandardColumns.fileFormatsColumn,
  placementStandardColumns.copyColumn,
];

export const standardOnlyBookingTableExcelColumnsInOrder: AllBookingColumns[] =
  [
    ...bookingTableExcelColumnsInOrder,
    bookingStandardColumns.dispatchDateColumn,
    bookingStandardColumns.liveDateColumn,
    bookingStandardColumns.endDateColumn,
    bookingStandardColumns.deliveryInstructionsColumn,
    bookingStandardColumns.creativeInstructionsColumn,
    bookingStandardColumns.notesColumn,
  ];
