import {
  N_ArtworkSpec,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { stringifyPrintNewspaperMagazineDimension } from "./printDimension";

export const getTrims = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): (N_NamedAnnotated<string> & { width: number; height: number })[] =>
  specifications.reduce<
    (N_NamedAnnotated<string> & { width: number; height: number })[]
  >(
    (dimensions, spec) =>
      (spec.value.kind === "magazine" || spec.value.kind === "newspaper") &&
      spec.value.value.trim !== null
        ? [
            ...dimensions,
            {
              ...spec,
              value: stringifyPrintNewspaperMagazineDimension(
                spec.value.value.trim.value
              ),
              width: spec.value.value.trim.value.width.value,
              height: spec.value.value.trim.value.height.value,
            },
          ]
        : dimensions,
    []
  );
