import { notNull } from "./typescript";

export const recordsWithStringValuesDiff = <KEYS extends string>({
  original,
  toCompareWith,
}: {
  original: Partial<Record<KEYS, string>>;
  toCompareWith: Partial<Record<KEYS, string>>;
}):
  | {
      isEqual: false;
      diffObj: Partial<
        Record<KEYS, { before: string | null; after: string | null }>
      >;
    }
  | { isEqual: true; diffObj?: never } => {
  const keys: KEYS[] = [
    ...new Set([
      ...(Object.keys(original) as KEYS[]),
      ...(Object.keys(toCompareWith) as KEYS[]),
    ]),
  ];

  const differenceByKey = keys
    .map((key) => {
      const originalValueForKey: string | null = original[key] || null;
      const toCompareWithValueForKey: string | null =
        toCompareWith[key] || null;

      if (originalValueForKey === toCompareWithValueForKey) {
        return null;
      }

      return {
        [key]: { before: originalValueForKey, after: toCompareWithValueForKey },
      };
    })
    .filter(notNull);

  if (differenceByKey.length === 0) {
    return { isEqual: true };
  }

  return {
    isEqual: false,
    diffObj: differenceByKey.reduce((acc, cur) => {
      return { ...acc, ...cur };
    }, {}) as Partial<
      Record<KEYS, { before: string | null; after: string | null }>
    >,
  };
};
