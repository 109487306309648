import { coreApiURL } from "./constants/url";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

const responseInterceptor = (response: AxiosResponse) => {
  return response.data;
};

const AxiosDefaults = {
  headers: { "Content-Type": "application/json" },
};

const AxiosInstance = axios.create({
  ...AxiosDefaults,
  baseURL: coreApiURL,
});

AxiosInstance.interceptors.response.use(responseInterceptor);

export const useApi = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  useEffect(() => {
    const requestInterceptor = async (
      config: InternalAxiosRequestConfig
    ): Promise<InternalAxiosRequestConfig> => {
      const authorizationToken = isAuthenticated
        ? await getAccessTokenSilently()
        : undefined;
      if (authorizationToken && config.headers) {
        config.headers.Authorization = authorizationToken;
      }
      return config;
    };
    AxiosInstance.interceptors.request.use(requestInterceptor);
  }, [getAccessTokenSilently]);

  return AxiosInstance;
};
