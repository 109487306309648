import * as z from "zod";

export const addedBookingZod = z
  .object({
    bookingName: z.string(),
    publisherName: z.string(),
  })
  .or(z.string());
export const deletedBookingZod = z
  .object({
    bookingName: z.string(),
    publisherName: z.string(),
  })
  .or(z.string());
export const updatedBookingZod = z.object({
  name: z.string(),
  publisherName: z.string().or(z.undefined()),
  before: z.string(),
  after: z.string(),
});

export const bookingChangesSummaryZod = z.object({
  added: z.array(addedBookingZod),
  updated: z.array(updatedBookingZod),
  deleted: z.array(deletedBookingZod),
});

export type AddedBooking = z.infer<typeof addedBookingZod>;
export type DeletedBooking = z.infer<typeof deletedBookingZod>;
export type UpdatedBooking = z.infer<typeof updatedBookingZod>;

export type BookingChangesSummary = z.infer<typeof bookingChangesSummaryZod>;

interface VersionSummary {
  updated: {
    name: string;
    publisherName?: string;
    before: string;
    after: string;
  }[];
  added: { bookingName: string; publisherName: string }[];
  deleted: { bookingName: string; publisherName: string }[];
}

export const convertBookingChangeSummaryToVersionSummary = (
  data: BookingChangesSummary
): VersionSummary => {
  const added = data.added.map((a) => {
    if (typeof a === "string") {
      return { bookingName: a, publisherName: "" };
    }

    return a;
  });

  const deleted = data.deleted.map((d) => {
    if (typeof d === "string") {
      return { bookingName: d, publisherName: "" };
    }

    return d;
  });

  return { added, deleted, updated: data.updated };
};
