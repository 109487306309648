/* @generated from adl module kachemedia.specsheet.users */

import * as ADL from './../../runtime/adl';
import * as common_db from './../../common/db';
import * as common_strings from './../../common/strings';
import * as kachemedia_specsheet_common from './common';
import * as kachemedia_specsheet_db from './db';
import * as kachemedia_specsheet_permissions from './permissions';

/**
 * User Profile
 */
export interface UserProfile {
  /**
   * User id
   */
  userId: common_db.DbKey<kachemedia_specsheet_db.AppUser>;
  /**
   * Name
   */
  name: common_strings.StringNE;
  /**
   * Last Name
   */
  lastName: common_strings.StringNE;
  /**
   * Email
   */
  email: common_strings.StringNE;
  /**
   * Profile pic
   */
  profilePic: kachemedia_specsheet_common.Url;
}

export function makeUserProfile(
  input: {
    userId: common_db.DbKey<kachemedia_specsheet_db.AppUser>,
    name: common_strings.StringNE,
    lastName: common_strings.StringNE,
    email: common_strings.StringNE,
    profilePic: kachemedia_specsheet_common.Url,
  }
): UserProfile {
  return {
    userId: input.userId,
    name: input.name,
    lastName: input.lastName,
    email: input.email,
    profilePic: input.profilePic,
  };
}

const UserProfile_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"User Profile\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"User id\n"}],"serializedName":"userId","default":{"kind":"nothing"},"name":"userId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"AppUser"}},"parameters":[]}]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Name\n"}],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Last Name\n"}],"serializedName":"lastName","default":{"kind":"nothing"},"name":"lastName","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Email\n"}],"serializedName":"email","default":{"kind":"nothing"},"name":"email","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Profile pic\n"}],"serializedName":"profilePic","default":{"kind":"nothing"},"name":"profilePic","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"Url"}},"parameters":[]}}]}},"name":"UserProfile","version":{"kind":"nothing"}}};

export const snUserProfile: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"UserProfile"};

export function texprUserProfile(): ADL.ATypeExpr<UserProfile> {
  return {value : {typeRef : {kind: "reference", value : snUserProfile}, parameters : []}};
}

export interface UpdateSelfReq {
  name: common_strings.StringNE;
  lastName: common_strings.StringNE;
  email: common_strings.StringNE;
  newProfilePic: (kachemedia_specsheet_common.ImageHash|null);
  clearPic: boolean;
}

export function makeUpdateSelfReq(
  input: {
    name: common_strings.StringNE,
    lastName: common_strings.StringNE,
    email: common_strings.StringNE,
    newProfilePic?: (kachemedia_specsheet_common.ImageHash|null),
    clearPic?: boolean,
  }
): UpdateSelfReq {
  return {
    name: input.name,
    lastName: input.lastName,
    email: input.email,
    newProfilePic: input.newProfilePic === undefined ? null : input.newProfilePic,
    clearPic: input.clearPic === undefined ? false : input.clearPic,
  };
}

const UpdateSelfReq_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[],"serializedName":"lastName","default":{"kind":"nothing"},"name":"lastName","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[],"serializedName":"email","default":{"kind":"nothing"},"name":"email","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[],"serializedName":"newProfilePic","default":{"kind":"just","value":null},"name":"newProfilePic","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"ImageHash"}},"parameters":[]}]}},{"annotations":[],"serializedName":"clearPic","default":{"kind":"just","value":false},"name":"clearPic","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}}]}},"name":"UpdateSelfReq","version":{"kind":"nothing"}}};

export const snUpdateSelfReq: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"UpdateSelfReq"};

export function texprUpdateSelfReq(): ADL.ATypeExpr<UpdateSelfReq> {
  return {value : {typeRef : {kind: "reference", value : snUpdateSelfReq}, parameters : []}};
}

export interface UpdateSelfPasswordReq {
  oldPassword: common_strings.StringNE;
  newPassword: common_strings.StringNE;
}

export function makeUpdateSelfPasswordReq(
  input: {
    oldPassword: common_strings.StringNE,
    newPassword: common_strings.StringNE,
  }
): UpdateSelfPasswordReq {
  return {
    oldPassword: input.oldPassword,
    newPassword: input.newPassword,
  };
}

const UpdateSelfPasswordReq_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"oldPassword","default":{"kind":"nothing"},"name":"oldPassword","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[],"serializedName":"newPassword","default":{"kind":"nothing"},"name":"newPassword","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}}]}},"name":"UpdateSelfPasswordReq","version":{"kind":"nothing"}}};

export const snUpdateSelfPasswordReq: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"UpdateSelfPasswordReq"};

export function texprUpdateSelfPasswordReq(): ADL.ATypeExpr<UpdateSelfPasswordReq> {
  return {value : {typeRef : {kind: "reference", value : snUpdateSelfPasswordReq}, parameters : []}};
}

/**
 * User profile with access memberships
 */
export interface UserAccessProfile {
  userProfile: UserProfile;
  workspaceAccess: WorkspaceAccess[];
  organisationAccess: OrganisationAccess;
  isOpsManager: boolean;
}

export function makeUserAccessProfile(
  input: {
    userProfile: UserProfile,
    workspaceAccess: WorkspaceAccess[],
    organisationAccess: OrganisationAccess,
    isOpsManager: boolean,
  }
): UserAccessProfile {
  return {
    userProfile: input.userProfile,
    workspaceAccess: input.workspaceAccess,
    organisationAccess: input.organisationAccess,
    isOpsManager: input.isOpsManager,
  };
}

const UserAccessProfile_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"User profile with access memberships\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"userProfile","default":{"kind":"nothing"},"name":"userProfile","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"UserProfile"}},"parameters":[]}},{"annotations":[],"serializedName":"workspaceAccess","default":{"kind":"nothing"},"name":"workspaceAccess","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"WorkspaceAccess"}},"parameters":[]}]}},{"annotations":[],"serializedName":"organisationAccess","default":{"kind":"nothing"},"name":"organisationAccess","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"OrganisationAccess"}},"parameters":[]}},{"annotations":[],"serializedName":"isOpsManager","default":{"kind":"nothing"},"name":"isOpsManager","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}}]}},"name":"UserAccessProfile","version":{"kind":"nothing"}}};

export const snUserAccessProfile: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"UserAccessProfile"};

export function texprUserAccessProfile(): ADL.ATypeExpr<UserAccessProfile> {
  return {value : {typeRef : {kind: "reference", value : snUserAccessProfile}, parameters : []}};
}

export interface WorkspaceView {
  organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>;
  workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>;
  name: common_strings.StringNE;
  logo: kachemedia_specsheet_common.Url;
  isActive: boolean;
}

export function makeWorkspaceView(
  input: {
    organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>,
    workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>,
    name: common_strings.StringNE,
    logo: kachemedia_specsheet_common.Url,
    isActive: boolean,
  }
): WorkspaceView {
  return {
    organisationId: input.organisationId,
    workspaceId: input.workspaceId,
    name: input.name,
    logo: input.logo,
    isActive: input.isActive,
  };
}

const WorkspaceView_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"organisationId","default":{"kind":"nothing"},"name":"organisationId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Organisation"}},"parameters":[]}]}},{"annotations":[],"serializedName":"workspaceId","default":{"kind":"nothing"},"name":"workspaceId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Workspace"}},"parameters":[]}]}},{"annotations":[],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[],"serializedName":"logo","default":{"kind":"nothing"},"name":"logo","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"Url"}},"parameters":[]}},{"annotations":[],"serializedName":"isActive","default":{"kind":"nothing"},"name":"isActive","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}}]}},"name":"WorkspaceView","version":{"kind":"nothing"}}};

export const snWorkspaceView: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"WorkspaceView"};

export function texprWorkspaceView(): ADL.ATypeExpr<WorkspaceView> {
  return {value : {typeRef : {kind: "reference", value : snWorkspaceView}, parameters : []}};
}

export interface WorkspaceAccess {
  workspace: WorkspaceView;
  role: (kachemedia_specsheet_db.WorkspaceMemberRole|null);
  permissions: kachemedia_specsheet_permissions.WORKSPACE_PERMISSIONS[];
}

export function makeWorkspaceAccess(
  input: {
    workspace: WorkspaceView,
    role: (kachemedia_specsheet_db.WorkspaceMemberRole|null),
    permissions: kachemedia_specsheet_permissions.WORKSPACE_PERMISSIONS[],
  }
): WorkspaceAccess {
  return {
    workspace: input.workspace,
    role: input.role,
    permissions: input.permissions,
  };
}

const WorkspaceAccess_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"workspace","default":{"kind":"nothing"},"name":"workspace","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"WorkspaceView"}},"parameters":[]}},{"annotations":[],"serializedName":"role","default":{"kind":"nothing"},"name":"role","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"WorkspaceMemberRole"}},"parameters":[]}]}},{"annotations":[],"serializedName":"permissions","default":{"kind":"nothing"},"name":"permissions","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.permissions","name":"WORKSPACE_PERMISSIONS"}},"parameters":[]}]}}]}},"name":"WorkspaceAccess","version":{"kind":"nothing"}}};

export const snWorkspaceAccess: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"WorkspaceAccess"};

export function texprWorkspaceAccess(): ADL.ATypeExpr<WorkspaceAccess> {
  return {value : {typeRef : {kind: "reference", value : snWorkspaceAccess}, parameters : []}};
}

export interface OrganisationAccess {
  organisation: common_db.WithDbId<kachemedia_specsheet_db.Organisation>;
  orgMemberId: common_db.DbKey<kachemedia_specsheet_db.OrganisationMember>;
  orgMemberRole: kachemedia_specsheet_db.OrganisationMemberRole;
  status: kachemedia_specsheet_db.OrgMemberStatus;
  orgPermissions: kachemedia_specsheet_permissions.ORGANISATION_PERMISSIONS[];
  workspacePermissions: kachemedia_specsheet_permissions.WORKSPACE_PERMISSIONS[];
}

export function makeOrganisationAccess(
  input: {
    organisation: common_db.WithDbId<kachemedia_specsheet_db.Organisation>,
    orgMemberId: common_db.DbKey<kachemedia_specsheet_db.OrganisationMember>,
    orgMemberRole: kachemedia_specsheet_db.OrganisationMemberRole,
    status: kachemedia_specsheet_db.OrgMemberStatus,
    orgPermissions: kachemedia_specsheet_permissions.ORGANISATION_PERMISSIONS[],
    workspacePermissions: kachemedia_specsheet_permissions.WORKSPACE_PERMISSIONS[],
  }
): OrganisationAccess {
  return {
    organisation: input.organisation,
    orgMemberId: input.orgMemberId,
    orgMemberRole: input.orgMemberRole,
    status: input.status,
    orgPermissions: input.orgPermissions,
    workspacePermissions: input.workspacePermissions,
  };
}

const OrganisationAccess_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"organisation","default":{"kind":"nothing"},"name":"organisation","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"WithDbId"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Organisation"}},"parameters":[]}]}},{"annotations":[],"serializedName":"orgMemberId","default":{"kind":"nothing"},"name":"orgMemberId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"OrganisationMember"}},"parameters":[]}]}},{"annotations":[],"serializedName":"orgMemberRole","default":{"kind":"nothing"},"name":"orgMemberRole","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"OrganisationMemberRole"}},"parameters":[]}},{"annotations":[],"serializedName":"status","default":{"kind":"nothing"},"name":"status","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"OrgMemberStatus"}},"parameters":[]}},{"annotations":[],"serializedName":"orgPermissions","default":{"kind":"nothing"},"name":"orgPermissions","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.permissions","name":"ORGANISATION_PERMISSIONS"}},"parameters":[]}]}},{"annotations":[],"serializedName":"workspacePermissions","default":{"kind":"nothing"},"name":"workspacePermissions","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.permissions","name":"WORKSPACE_PERMISSIONS"}},"parameters":[]}]}}]}},"name":"OrganisationAccess","version":{"kind":"nothing"}}};

export const snOrganisationAccess: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"OrganisationAccess"};

export function texprOrganisationAccess(): ADL.ATypeExpr<OrganisationAccess> {
  return {value : {typeRef : {kind: "reference", value : snOrganisationAccess}, parameters : []}};
}

export interface WorkspaceAccessDetails {
  organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>;
  workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>;
  appUserId: common_db.DbKey<kachemedia_specsheet_db.AppUser>;
}

export function makeWorkspaceAccessDetails(
  input: {
    organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>,
    workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>,
    appUserId: common_db.DbKey<kachemedia_specsheet_db.AppUser>,
  }
): WorkspaceAccessDetails {
  return {
    organisationId: input.organisationId,
    workspaceId: input.workspaceId,
    appUserId: input.appUserId,
  };
}

const WorkspaceAccessDetails_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"organisationId","default":{"kind":"nothing"},"name":"organisationId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Organisation"}},"parameters":[]}]}},{"annotations":[],"serializedName":"workspaceId","default":{"kind":"nothing"},"name":"workspaceId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Workspace"}},"parameters":[]}]}},{"annotations":[],"serializedName":"appUserId","default":{"kind":"nothing"},"name":"appUserId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"AppUser"}},"parameters":[]}]}}]}},"name":"WorkspaceAccessDetails","version":{"kind":"nothing"}}};

export const snWorkspaceAccessDetails: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"WorkspaceAccessDetails"};

export function texprWorkspaceAccessDetails(): ADL.ATypeExpr<WorkspaceAccessDetails> {
  return {value : {typeRef : {kind: "reference", value : snWorkspaceAccessDetails}, parameters : []}};
}

export interface WorkspaceAccessReq {
  workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>;
  workspaceRole: kachemedia_specsheet_db.WorkspaceMemberRole;
}

export function makeWorkspaceAccessReq(
  input: {
    workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>,
    workspaceRole: kachemedia_specsheet_db.WorkspaceMemberRole,
  }
): WorkspaceAccessReq {
  return {
    workspaceId: input.workspaceId,
    workspaceRole: input.workspaceRole,
  };
}

const WorkspaceAccessReq_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"workspaceId","default":{"kind":"nothing"},"name":"workspaceId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Workspace"}},"parameters":[]}]}},{"annotations":[],"serializedName":"workspaceRole","default":{"kind":"nothing"},"name":"workspaceRole","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"WorkspaceMemberRole"}},"parameters":[]}}]}},"name":"WorkspaceAccessReq","version":{"kind":"nothing"}}};

export const snWorkspaceAccessReq: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"WorkspaceAccessReq"};

export function texprWorkspaceAccessReq(): ADL.ATypeExpr<WorkspaceAccessReq> {
  return {value : {typeRef : {kind: "reference", value : snWorkspaceAccessReq}, parameters : []}};
}

export interface UserWorkspaceUpdatesData {
  addedWorkspaces: (WorkspaceAccessReq[]|null);
  updateWorkspaces: (WorkspaceAccessReq[]|null);
  deleteWorkspaces: (common_db.DbKey<kachemedia_specsheet_db.Workspace>[]|null);
}

export function makeUserWorkspaceUpdatesData(
  input: {
    addedWorkspaces: (WorkspaceAccessReq[]|null),
    updateWorkspaces: (WorkspaceAccessReq[]|null),
    deleteWorkspaces: (common_db.DbKey<kachemedia_specsheet_db.Workspace>[]|null),
  }
): UserWorkspaceUpdatesData {
  return {
    addedWorkspaces: input.addedWorkspaces,
    updateWorkspaces: input.updateWorkspaces,
    deleteWorkspaces: input.deleteWorkspaces,
  };
}

const UserWorkspaceUpdatesData_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"addedWorkspaces","default":{"kind":"nothing"},"name":"addedWorkspaces","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"WorkspaceAccessReq"}},"parameters":[]}]}]}},{"annotations":[],"serializedName":"updateWorkspaces","default":{"kind":"nothing"},"name":"updateWorkspaces","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"WorkspaceAccessReq"}},"parameters":[]}]}]}},{"annotations":[],"serializedName":"deleteWorkspaces","default":{"kind":"nothing"},"name":"deleteWorkspaces","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Workspace"}},"parameters":[]}]}]}]}}]}},"name":"UserWorkspaceUpdatesData","version":{"kind":"nothing"}}};

export const snUserWorkspaceUpdatesData: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"UserWorkspaceUpdatesData"};

export function texprUserWorkspaceUpdatesData(): ADL.ATypeExpr<UserWorkspaceUpdatesData> {
  return {value : {typeRef : {kind: "reference", value : snUserWorkspaceUpdatesData}, parameters : []}};
}

export interface QueryOrganisationUsersReq {
  organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>;
}

export function makeQueryOrganisationUsersReq(
  input: {
    organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>,
  }
): QueryOrganisationUsersReq {
  return {
    organisationId: input.organisationId,
  };
}

const QueryOrganisationUsersReq_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"organisationId","default":{"kind":"nothing"},"name":"organisationId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Organisation"}},"parameters":[]}]}}]}},"name":"QueryOrganisationUsersReq","version":{"kind":"nothing"}}};

export const snQueryOrganisationUsersReq: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"QueryOrganisationUsersReq"};

export function texprQueryOrganisationUsersReq(): ADL.ATypeExpr<QueryOrganisationUsersReq> {
  return {value : {typeRef : {kind: "reference", value : snQueryOrganisationUsersReq}, parameters : []}};
}

export interface QueryWorkspaceUsersReq {
  workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>;
}

export function makeQueryWorkspaceUsersReq(
  input: {
    workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>,
  }
): QueryWorkspaceUsersReq {
  return {
    workspaceId: input.workspaceId,
  };
}

const QueryWorkspaceUsersReq_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"workspaceId","default":{"kind":"nothing"},"name":"workspaceId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Workspace"}},"parameters":[]}]}}]}},"name":"QueryWorkspaceUsersReq","version":{"kind":"nothing"}}};

export const snQueryWorkspaceUsersReq: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"QueryWorkspaceUsersReq"};

export function texprQueryWorkspaceUsersReq(): ADL.ATypeExpr<QueryWorkspaceUsersReq> {
  return {value : {typeRef : {kind: "reference", value : snQueryWorkspaceUsersReq}, parameters : []}};
}

export interface WorkspaceUserView {
  userProfile: UserProfile;
  workspaceRole: kachemedia_specsheet_db.WorkspaceMemberRole;
  userGroupIds: common_db.DbKey<kachemedia_specsheet_db.UserGroup>[];
  workspaceMemberId: common_db.DbKey<kachemedia_specsheet_db.WorkspaceMember>;
  orgMemberId: common_db.DbKey<kachemedia_specsheet_db.OrganisationMember>;
  status: kachemedia_specsheet_db.OrgMemberStatus;
}

export function makeWorkspaceUserView(
  input: {
    userProfile: UserProfile,
    workspaceRole: kachemedia_specsheet_db.WorkspaceMemberRole,
    userGroupIds: common_db.DbKey<kachemedia_specsheet_db.UserGroup>[],
    workspaceMemberId: common_db.DbKey<kachemedia_specsheet_db.WorkspaceMember>,
    orgMemberId: common_db.DbKey<kachemedia_specsheet_db.OrganisationMember>,
    status: kachemedia_specsheet_db.OrgMemberStatus,
  }
): WorkspaceUserView {
  return {
    userProfile: input.userProfile,
    workspaceRole: input.workspaceRole,
    userGroupIds: input.userGroupIds,
    workspaceMemberId: input.workspaceMemberId,
    orgMemberId: input.orgMemberId,
    status: input.status,
  };
}

const WorkspaceUserView_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"userProfile","default":{"kind":"nothing"},"name":"userProfile","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"UserProfile"}},"parameters":[]}},{"annotations":[],"serializedName":"workspaceRole","default":{"kind":"nothing"},"name":"workspaceRole","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"WorkspaceMemberRole"}},"parameters":[]}},{"annotations":[],"serializedName":"userGroupIds","default":{"kind":"nothing"},"name":"userGroupIds","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"UserGroup"}},"parameters":[]}]}]}},{"annotations":[],"serializedName":"workspaceMemberId","default":{"kind":"nothing"},"name":"workspaceMemberId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"WorkspaceMember"}},"parameters":[]}]}},{"annotations":[],"serializedName":"orgMemberId","default":{"kind":"nothing"},"name":"orgMemberId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"OrganisationMember"}},"parameters":[]}]}},{"annotations":[],"serializedName":"status","default":{"kind":"nothing"},"name":"status","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"OrgMemberStatus"}},"parameters":[]}}]}},"name":"WorkspaceUserView","version":{"kind":"nothing"}}};

export const snWorkspaceUserView: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"WorkspaceUserView"};

export function texprWorkspaceUserView(): ADL.ATypeExpr<WorkspaceUserView> {
  return {value : {typeRef : {kind: "reference", value : snWorkspaceUserView}, parameters : []}};
}

export interface UpdateWorkspaceMemberUserGroups {
  addGroups: (common_db.DbKey<kachemedia_specsheet_db.UserGroup>[]|null);
  removeGroups: (common_db.DbKey<kachemedia_specsheet_db.UserGroup>[]|null);
}

export function makeUpdateWorkspaceMemberUserGroups(
  input: {
    addGroups: (common_db.DbKey<kachemedia_specsheet_db.UserGroup>[]|null),
    removeGroups: (common_db.DbKey<kachemedia_specsheet_db.UserGroup>[]|null),
  }
): UpdateWorkspaceMemberUserGroups {
  return {
    addGroups: input.addGroups,
    removeGroups: input.removeGroups,
  };
}

const UpdateWorkspaceMemberUserGroups_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"addGroups","default":{"kind":"nothing"},"name":"addGroups","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"UserGroup"}},"parameters":[]}]}]}]}},{"annotations":[],"serializedName":"removeGroups","default":{"kind":"nothing"},"name":"removeGroups","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"UserGroup"}},"parameters":[]}]}]}]}}]}},"name":"UpdateWorkspaceMemberUserGroups","version":{"kind":"nothing"}}};

export const snUpdateWorkspaceMemberUserGroups: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"UpdateWorkspaceMemberUserGroups"};

export function texprUpdateWorkspaceMemberUserGroups(): ADL.ATypeExpr<UpdateWorkspaceMemberUserGroups> {
  return {value : {typeRef : {kind: "reference", value : snUpdateWorkspaceMemberUserGroups}, parameters : []}};
}

export interface UpdateWorkspaceMemberUserGroupsReq {
  workspaceMemberId: common_db.DbKey<kachemedia_specsheet_db.WorkspaceMember>;
  workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>;
  updates: UpdateWorkspaceMemberUserGroups;
}

export function makeUpdateWorkspaceMemberUserGroupsReq(
  input: {
    workspaceMemberId: common_db.DbKey<kachemedia_specsheet_db.WorkspaceMember>,
    workspaceId: common_db.DbKey<kachemedia_specsheet_db.Workspace>,
    updates: UpdateWorkspaceMemberUserGroups,
  }
): UpdateWorkspaceMemberUserGroupsReq {
  return {
    workspaceMemberId: input.workspaceMemberId,
    workspaceId: input.workspaceId,
    updates: input.updates,
  };
}

const UpdateWorkspaceMemberUserGroupsReq_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.users","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"workspaceMemberId","default":{"kind":"nothing"},"name":"workspaceMemberId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"WorkspaceMember"}},"parameters":[]}]}},{"annotations":[],"serializedName":"workspaceId","default":{"kind":"nothing"},"name":"workspaceId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Workspace"}},"parameters":[]}]}},{"annotations":[],"serializedName":"updates","default":{"kind":"nothing"},"name":"updates","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.users","name":"UpdateWorkspaceMemberUserGroups"}},"parameters":[]}}]}},"name":"UpdateWorkspaceMemberUserGroupsReq","version":{"kind":"nothing"}}};

export const snUpdateWorkspaceMemberUserGroupsReq: ADL.ScopedName = {moduleName:"kachemedia.specsheet.users", name:"UpdateWorkspaceMemberUserGroupsReq"};

export function texprUpdateWorkspaceMemberUserGroupsReq(): ADL.ATypeExpr<UpdateWorkspaceMemberUserGroupsReq> {
  return {value : {typeRef : {kind: "reference", value : snUpdateWorkspaceMemberUserGroupsReq}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "kachemedia.specsheet.users.UserProfile" : UserProfile_AST,
  "kachemedia.specsheet.users.UpdateSelfReq" : UpdateSelfReq_AST,
  "kachemedia.specsheet.users.UpdateSelfPasswordReq" : UpdateSelfPasswordReq_AST,
  "kachemedia.specsheet.users.UserAccessProfile" : UserAccessProfile_AST,
  "kachemedia.specsheet.users.WorkspaceView" : WorkspaceView_AST,
  "kachemedia.specsheet.users.WorkspaceAccess" : WorkspaceAccess_AST,
  "kachemedia.specsheet.users.OrganisationAccess" : OrganisationAccess_AST,
  "kachemedia.specsheet.users.WorkspaceAccessDetails" : WorkspaceAccessDetails_AST,
  "kachemedia.specsheet.users.WorkspaceAccessReq" : WorkspaceAccessReq_AST,
  "kachemedia.specsheet.users.UserWorkspaceUpdatesData" : UserWorkspaceUpdatesData_AST,
  "kachemedia.specsheet.users.QueryOrganisationUsersReq" : QueryOrganisationUsersReq_AST,
  "kachemedia.specsheet.users.QueryWorkspaceUsersReq" : QueryWorkspaceUsersReq_AST,
  "kachemedia.specsheet.users.WorkspaceUserView" : WorkspaceUserView_AST,
  "kachemedia.specsheet.users.UpdateWorkspaceMemberUserGroups" : UpdateWorkspaceMemberUserGroups_AST,
  "kachemedia.specsheet.users.UpdateWorkspaceMemberUserGroupsReq" : UpdateWorkspaceMemberUserGroupsReq_AST
};
