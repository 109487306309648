import { R } from "./libs";
import { compare2Sets } from "./sets";

export const head = <T>(array: T[]): T | undefined => {
  return array.length ? array[0] : undefined;
};

export const last = <T>(array: T[]): T | undefined => {
  return array.length ? array[array.length - 1] : undefined;
};

export const compare2Arrays = <T extends string | number>(
  firstArray: T[],
  secondArray: T[]
): { remains: T[]; removed: T[]; added: T[] } => {
  const firstSet = new Set(firstArray);
  const secondSet = new Set(secondArray);

  return compare2Sets(firstSet, secondSet);
};

export const groupBy = <T>(
  arr: T[],
  f: (item: T) => string
): Record<string, T> =>
  arr.reduce<Record<string, T>>((coll, item) => {
    const id = f(item);
    return { ...coll, [id]: item };
  }, {});

export const partition = <T>({
  arr,
  chunkSize,
}: {
  arr: T[];
  chunkSize: number;
}): T[][] =>
  arr
    .reduce<T[][]>(
      ([first, ...rest], el) =>
        first.length > chunkSize
          ? [[el], first, ...rest]
          : [[...first, el], ...rest],
      [[]]
    )
    .reverse();

export const unique = <T>(array: T[]): T[] => {
  return [...new Set(array)];
};

/**
 * partition an array by size, and sameness. Consider a list of bookings
 * for the same campaign. We would like to group these into similarly sized
 * chunks so that DB writes can be staggered, but we need to ensure that all
 * writes for the same campaign happen in the same transaction. This function
 * can help by aggregating atems into chinks of chunkSize. When this threshold
 * is met, the next item prcessed that prduces a new value for f(item) will
 * start a new chunk.
 *
 * Assumes the list is sorted by f(item) in advance
 */
export const chunkAndGroupBy = <T>(
  arr: T[],
  chunkSize: number,
  f: (item: T) => unknown
): T[][] => {
  return arr.reduce<T[][]>((coll, item) => {
    const last = R.last(coll) || [];
    const head = R.dropLast(coll, 1);
    const llast = R.last(last);
    if (last.length >= chunkSize) {
      if (llast && f(llast) !== f(item)) {
        return [...coll, [item]];
      }
    }
    return [...head, [...last, item]];
  }, []);
};

export const arrays = {
  compare2Arrays,
  chunkAndGroupBy,
  partition,
  head,
  unique,
  last,
};
