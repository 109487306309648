import * as z from "zod";

export const wysiwygItemKind = {
  listItem: "listItem",
  paragraph: "paragraph",
  numberedList: "numberedList",
  bulletedList: "bulletedList",
} as const;

export type WysiwygItemKind =
  (typeof wysiwygItemKind)[keyof typeof wysiwygItemKind];

export const wysiwygCustomTextZod = z.object({
  text: z.string(),
  bold: z.boolean().or(z.null()),
  italic: z.boolean().or(z.null()),
  underline: z.boolean().or(z.null()),
});

export type N_WysiwygCustomText = z.infer<typeof wysiwygCustomTextZod>;

export const listWysiwygItem_ListItemZod = z.object({
  kind: z.literal(wysiwygItemKind.listItem),
  value: z.array(wysiwygCustomTextZod),
});

export type N_ListWysiwygItem_ListItem = z.infer<
  typeof listWysiwygItem_ListItemZod
>;

export const wysiwygDescendant_ParagraphZod = z.object({
  kind: z.literal(wysiwygItemKind.paragraph),
  value: z.array(wysiwygCustomTextZod),
});

export type N_WysiwygDescendant_Paragraph = z.infer<
  typeof wysiwygDescendant_ParagraphZod
>;

export const wysiwygDescendant_NumberedListZod = z.object({
  kind: z.literal(wysiwygItemKind.numberedList),
  value: z.array(listWysiwygItem_ListItemZod),
});

export type N_WysiwygDescendant_NumberedList = z.infer<
  typeof wysiwygDescendant_NumberedListZod
>;

export const wysiwygDescendant_BulletedListZod = z.object({
  kind: z.literal(wysiwygItemKind.bulletedList),
  value: z.array(listWysiwygItem_ListItemZod),
});

export type N_WysiwygDescendant_BulletedList = z.infer<
  typeof wysiwygDescendant_BulletedListZod
>;

export const wysiwygDescendantZod = z.union([
  wysiwygDescendant_ParagraphZod,
  wysiwygDescendant_NumberedListZod,
  wysiwygDescendant_BulletedListZod,
]);

export const wysiwygDescendantArrZod = z.array(wysiwygDescendantZod);

export type N_WysiwygDescendant = z.infer<typeof wysiwygDescendantZod>;
