import { z } from "zod";

export const goMediaTransitBookingSchema = z.object({
  campaignId: z.string(),
  campaignName: z.string(),
  advertiser: z.string(),
  billingClient: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  city: z.string(),
  sizeName: z.string(),
  siteId: z.string(),
  panelName: z.string(),
  fixtureType: z.string(),
  artworkWidth: z.string(),
  artworkHeight: z.string(),
  artworkBleedTop: z.string(),
  artworkBleedBottom: z.string(),
  artworkBleedLeft: z.string(),
  artworkBleedRight: z.string(),
  artworkScale: z.string(),
  resolution: z.string(),
  fileFormat: z.string(),
  colour: z.string(),
  blackSpecification: z.string(),
  productionComments: z.string(),
  productionSpec1: z.string(),
  productionSpec2: z.string(),
  image: z.string(),
  deadlinesAndDelivery: z.string(),
  proofs: z.string(),
  dispatch: z.string(),
});
export type GoMediaTransitBooking = z.infer<typeof goMediaTransitBookingSchema>;
