import {
  CAMPAIGN_PERMISSIONS,
  idFlavor,
  N_Instant,
  N_LocalDate,
  N_StringKeyMap,
  N_UrlResource,
  NormalizedEmailAddress,
} from "../../common";
import { N_WysiwygDescendant } from "../../elements";
import { N_UserProfile } from "../appUser";
import {
  N_BookingPublication,
  N_BookingViewBasic,
  N_CampaignChangesSummary,
} from "../booking";
import { N_BookingGroup } from "../bookingGroup";
import { CampaignChangeCreatedBy } from "../campaignChange";
import { N_CampaignEdit } from "../campaignEdit";
import { N_CampaignTemplateView } from "../campaignTemplate";
import { CalculatedCampaignUserRole } from "../campaignUserDetails";
import { N_CampaignVersionInfo } from "../campaignVersion";
import { N_ClientView } from "../client";
import { N_InfoSchemaTableViewView, N_SchemaWithFields } from "../infoSchema";
import { N_OrganisationView } from "../organisation";
import { N_PlacementPublication } from "../placement";
import { N_PublicationView } from "../publication";
import { N_WorkspaceView } from "../workspace";
import { BasicInfoForTssCampaign } from "src/entities/external";

export const campaignStatus = {
  Active: "Active",
  Paused: "Paused",
  Draft: "Draft",
  Archived: "Archived",
  Deleted: "Deleted",
} as const;

export type N_CampaignStatus =
  (typeof campaignStatus)[keyof typeof campaignStatus];

export interface UpdatedByUser {
  kind: "user";
  profile?: N_UserProfile;
}

interface UpdatedByExternal {
  kind: "external";
  provider?: string;
}

export type UpdatedBy = UpdatedByUser | UpdatedByExternal;

export interface CampaignBasic {
  id: idFlavor<"Campaign">;
  title: string;
  summary: string;
  status: N_CampaignStatus;
  liveDate: Date;
  endDate: Date | null;
  clientId: idFlavor<"Client">;
  creatorId: idFlavor<"AppUser">;
  created: Date;
  updated: Date;
  jobNumber: string;
  version: number;
  notes: N_WysiwygDescendant[];
  documents: N_UrlResource[];
  workspaceId: idFlavor<"Workspace">;
}

export interface CampaignMinimal {
  id: idFlavor<"Campaign">;
  title: string;
}

export interface N_CampaignView {
  workspace: N_WorkspaceView;
  organisation: N_OrganisationView;
  campaignId: idFlavor<"Campaign">;
  attachedInfoSchemaId: idFlavor<"InfoSchema"> | null;
  title: string;
  summary: string;
  status: N_CampaignStatus;
  liveDate: N_LocalDate;
  endDate: N_LocalDate | null;
  createdBy: N_UserProfile;
  ownedBy: N_UserProfile;
  creationDate: N_Instant;
  client: N_ClientView;
  starred: boolean;
  isPaid: boolean;
  lastUpdated: N_Instant;
  jobNumber: string;
  version: number;
  assignedUsers: N_UserProfile[];
  notes: N_WysiwygDescendant[];
  documents: N_UrlResource[];
  template: N_CampaignTemplateView | null;
  campaignPermissions: CAMPAIGN_PERMISSIONS[];
  userAccessLevel: CalculatedCampaignUserRole | null;
}

export interface N_CampaignWithBookings {
  campaign: N_CampaignView;
  campaignVersion: N_CampaignVersionInfo;
  lastPublishedCampaignVersion: N_CampaignVersionInfo | null;
  changesDetail: N_CampaignEdit[];
  changesSummary: N_CampaignChangesSummary;
  campaignSubscribers: NormalizedEmailAddress[];
  bookings: N_BookingViewBasic[];
  bookingGroups: N_StringKeyMap<idFlavor<"BookingGroup">, N_BookingGroup>;
  placementToPublications: N_PlacementPublication[];
  bookingToPublications: N_BookingPublication[];
  publications: N_StringKeyMap<idFlavor<"Publication">, N_PublicationView>;
  infoSchema: N_SchemaWithFields | null;
  infoSchemaTableView: N_InfoSchemaTableViewView | null;
  unpublishedChangesCounter: number;
  versionsHistory: N_CampaignVersionInfo[];
  externalCampaignsInfo: BasicInfoForTssCampaign[];
  /* We have two updatedBy fields here as TSS-1718 requires the `updatedByNew` field,
    however we need to maintain the existing `updatedBy` field to prevent data mistmatches/crashes
    on release. But for a release cycle `updatedBy` should be essentially deprecated
  */
  updatedBy?: N_UserProfile;
  updatedByNew?: UpdatedBy;
}
