import { idFlavor } from "../../common";

export const publicClientFileSubRoute = {
  download: "download",
} as const;

export interface PublicClientFileRoutes {
  sub: typeof publicClientFileSubRoute.download;
  method?: "Get";
  tailParam: idFlavor<"ClientFile">;
}
