import { idFlavor } from "../../common";

export const workspaceSubRoute = {
  list: "list",
  create: "",
  update: "",
  delete: "",
  updateIsActive: "updateIsActive",
} as const;

export type WorkspaceRoutes =
  | {
      sub: typeof workspaceSubRoute.list;
      method?: "Post";
    }
  | {
      sub: typeof workspaceSubRoute.create;
      method?: "Post";
    }
  | {
      sub: typeof workspaceSubRoute.delete;
      tailParam: idFlavor<"Workspace">;
      method?: "Delete";
    }
  | {
      sub: typeof workspaceSubRoute.update;
      tailParam: idFlavor<"Workspace">;
      method?: "Patch";
    }
  | {
      sub: typeof workspaceSubRoute.updateIsActive;
      method?: "Post";
    };
