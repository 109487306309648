/* @generated from adl module kachemedia.specsheet.export */

import * as ADL from './../../runtime/adl';
import * as kachemedia_specsheet_campaigns from './campaigns';

export interface RowComponent_Booking {
  kind: 'booking';
  value: RowBookingData;
}
export interface RowComponent_Group {
  kind: 'group';
  value: RowGroup;
}

export type RowComponent = RowComponent_Booking | RowComponent_Group;

export interface RowComponentOpts {
  booking: RowBookingData;
  group: RowGroup;
}

export function makeRowComponent<K extends keyof RowComponentOpts>(kind: K, value: RowComponentOpts[K]) { return {kind, value}; }

const RowComponent_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.export","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"booking","default":{"kind":"nothing"},"name":"booking","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"RowBookingData"}},"parameters":[]}},{"annotations":[],"serializedName":"group","default":{"kind":"nothing"},"name":"group","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"RowGroup"}},"parameters":[]}}]}},"name":"RowComponent","version":{"kind":"nothing"}}};

export const snRowComponent: ADL.ScopedName = {moduleName:"kachemedia.specsheet.export", name:"RowComponent"};

export function texprRowComponent(): ADL.ATypeExpr<RowComponent> {
  return {value : {typeRef : {kind: "reference", value : snRowComponent}, parameters : []}};
}

export interface RowBookingData {
  groupType: GroupType;
  booking: kachemedia_specsheet_campaigns.BookingView;
}

export function makeRowBookingData(
  input: {
    groupType: GroupType,
    booking: kachemedia_specsheet_campaigns.BookingView,
  }
): RowBookingData {
  return {
    groupType: input.groupType,
    booking: input.booking,
  };
}

const RowBookingData_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.export","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"groupType","default":{"kind":"nothing"},"name":"groupType","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"GroupType"}},"parameters":[]}},{"annotations":[],"serializedName":"booking","default":{"kind":"nothing"},"name":"booking","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.campaigns","name":"BookingView"}},"parameters":[]}}]}},"name":"RowBookingData","version":{"kind":"nothing"}}};

export const snRowBookingData: ADL.ScopedName = {moduleName:"kachemedia.specsheet.export", name:"RowBookingData"};

export function texprRowBookingData(): ADL.ATypeExpr<RowBookingData> {
  return {value : {typeRef : {kind: "reference", value : snRowBookingData}, parameters : []}};
}

export enum GroupType {
  none,
  top,
  sub,
}

const GroupType_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.export","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"none","default":{"kind":"nothing"},"name":"none","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"top","default":{"kind":"nothing"},"name":"top","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"sub","default":{"kind":"nothing"},"name":"sub","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"GroupType","version":{"kind":"nothing"}}};

export const snGroupType: ADL.ScopedName = {moduleName:"kachemedia.specsheet.export", name:"GroupType"};

export function texprGroupType(): ADL.ATypeExpr<GroupType> {
  return {value : {typeRef : {kind: "reference", value : snGroupType}, parameters : []}};
}

export interface RowGroup {
  name: string;
  start: boolean;
  top: boolean;
  bookingGroup: kachemedia_specsheet_campaigns.BookingGroupView;
}

export function makeRowGroup(
  input: {
    name: string,
    start: boolean,
    top: boolean,
    bookingGroup: kachemedia_specsheet_campaigns.BookingGroupView,
  }
): RowGroup {
  return {
    name: input.name,
    start: input.start,
    top: input.top,
    bookingGroup: input.bookingGroup,
  };
}

const RowGroup_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.export","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"start","default":{"kind":"nothing"},"name":"start","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}},{"annotations":[],"serializedName":"top","default":{"kind":"nothing"},"name":"top","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}},{"annotations":[],"serializedName":"bookingGroup","default":{"kind":"nothing"},"name":"bookingGroup","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.campaigns","name":"BookingGroupView"}},"parameters":[]}}]}},"name":"RowGroup","version":{"kind":"nothing"}}};

export const snRowGroup: ADL.ScopedName = {moduleName:"kachemedia.specsheet.export", name:"RowGroup"};

export function texprRowGroup(): ADL.ATypeExpr<RowGroup> {
  return {value : {typeRef : {kind: "reference", value : snRowGroup}, parameters : []}};
}

export interface Borders {
  top: BorderType;
  bottom: BorderType;
  left: BorderType;
  right: BorderType;
}

export function makeBorders(
  input: {
    top?: BorderType,
    bottom?: BorderType,
    left?: BorderType,
    right?: BorderType,
  }
): Borders {
  return {
    top: input.top === undefined ? 0 : input.top,
    bottom: input.bottom === undefined ? 0 : input.bottom,
    left: input.left === undefined ? 0 : input.left,
    right: input.right === undefined ? 0 : input.right,
  };
}

const Borders_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.export","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"top","default":{"kind":"just","value":"none"},"name":"top","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"BorderType"}},"parameters":[]}},{"annotations":[],"serializedName":"bottom","default":{"kind":"just","value":"none"},"name":"bottom","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"BorderType"}},"parameters":[]}},{"annotations":[],"serializedName":"left","default":{"kind":"just","value":"none"},"name":"left","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"BorderType"}},"parameters":[]}},{"annotations":[],"serializedName":"right","default":{"kind":"just","value":"none"},"name":"right","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"BorderType"}},"parameters":[]}}]}},"name":"Borders","version":{"kind":"nothing"}}};

export const snBorders: ADL.ScopedName = {moduleName:"kachemedia.specsheet.export", name:"Borders"};

export function texprBorders(): ADL.ATypeExpr<Borders> {
  return {value : {typeRef : {kind: "reference", value : snBorders}, parameters : []}};
}

export enum BorderType {
  none,
  thin,
  medium,
}

const BorderType_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.export","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"none","default":{"kind":"nothing"},"name":"none","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"thin","default":{"kind":"nothing"},"name":"thin","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"medium","default":{"kind":"nothing"},"name":"medium","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"BorderType","version":{"kind":"nothing"}}};

export const snBorderType: ADL.ScopedName = {moduleName:"kachemedia.specsheet.export", name:"BorderType"};

export function texprBorderType(): ADL.ATypeExpr<BorderType> {
  return {value : {typeRef : {kind: "reference", value : snBorderType}, parameters : []}};
}

export enum RowType {
  groupStartRow,
  groupEndRow,
  dataRow,
}

const RowType_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.export","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"groupStartRow","default":{"kind":"nothing"},"name":"groupStartRow","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"groupEndRow","default":{"kind":"nothing"},"name":"groupEndRow","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"dataRow","default":{"kind":"nothing"},"name":"dataRow","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"RowType","version":{"kind":"nothing"}}};

export const snRowType: ADL.ScopedName = {moduleName:"kachemedia.specsheet.export", name:"RowType"};

export function texprRowType(): ADL.ATypeExpr<RowType> {
  return {value : {typeRef : {kind: "reference", value : snRowType}, parameters : []}};
}

export interface DataCellStyle {
  borders: Borders;
  groupType: GroupType;
  rowType: RowType;
}

export function makeDataCellStyle(
  input: {
    borders?: Borders,
    groupType?: GroupType,
    rowType?: RowType,
  }
): DataCellStyle {
  return {
    borders: input.borders === undefined ? {top : 0, bottom : 0, left : 0, right : 0} : input.borders,
    groupType: input.groupType === undefined ? 0 : input.groupType,
    rowType: input.rowType === undefined ? 2 : input.rowType,
  };
}

const DataCellStyle_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.export","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"borders","default":{"kind":"just","value":{}},"name":"borders","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"Borders"}},"parameters":[]}},{"annotations":[],"serializedName":"groupType","default":{"kind":"just","value":"none"},"name":"groupType","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"GroupType"}},"parameters":[]}},{"annotations":[],"serializedName":"rowType","default":{"kind":"just","value":"dataRow"},"name":"rowType","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"RowType"}},"parameters":[]}}]}},"name":"DataCellStyle","version":{"kind":"nothing"}}};

export const snDataCellStyle: ADL.ScopedName = {moduleName:"kachemedia.specsheet.export", name:"DataCellStyle"};

export function texprDataCellStyle(): ADL.ATypeExpr<DataCellStyle> {
  return {value : {typeRef : {kind: "reference", value : snDataCellStyle}, parameters : []}};
}

export interface HeaderCellStyle {
  borders: Borders;
}

export function makeHeaderCellStyle(
  input: {
    borders?: Borders,
  }
): HeaderCellStyle {
  return {
    borders: input.borders === undefined ? {top : 0, bottom : 0, left : 0, right : 0} : input.borders,
  };
}

const HeaderCellStyle_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.export","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"borders","default":{"kind":"just","value":{}},"name":"borders","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.export","name":"Borders"}},"parameters":[]}}]}},"name":"HeaderCellStyle","version":{"kind":"nothing"}}};

export const snHeaderCellStyle: ADL.ScopedName = {moduleName:"kachemedia.specsheet.export", name:"HeaderCellStyle"};

export function texprHeaderCellStyle(): ADL.ATypeExpr<HeaderCellStyle> {
  return {value : {typeRef : {kind: "reference", value : snHeaderCellStyle}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "kachemedia.specsheet.export.RowComponent" : RowComponent_AST,
  "kachemedia.specsheet.export.RowBookingData" : RowBookingData_AST,
  "kachemedia.specsheet.export.GroupType" : GroupType_AST,
  "kachemedia.specsheet.export.RowGroup" : RowGroup_AST,
  "kachemedia.specsheet.export.Borders" : Borders_AST,
  "kachemedia.specsheet.export.BorderType" : BorderType_AST,
  "kachemedia.specsheet.export.RowType" : RowType_AST,
  "kachemedia.specsheet.export.DataCellStyle" : DataCellStyle_AST,
  "kachemedia.specsheet.export.HeaderCellStyle" : HeaderCellStyle_AST
};
