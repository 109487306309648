import {
  bookingStandardColumns,
  BookingStandardColumns,
  campaignTemplateStandardColumn,
  PlacementStandardColumns,
  placementStandardColumns,
} from "@specsheet-common/shared-types";

export const convertCampaignTemplateStandardColumnToBookingStandardColumn = (
  col: string
): BookingStandardColumns | PlacementStandardColumns | null => {
  switch (col) {
    case campaignTemplateStandardColumn.MEDIA_OWNER: {
      return placementStandardColumns.mediaOwnerColumn;
    }
    case campaignTemplateStandardColumn.CHANNEL: {
      return placementStandardColumns.channelColumn;
    }
    case campaignTemplateStandardColumn.CAMPAIGN_CODE: {
      return bookingStandardColumns.campaignCodeColumn;
    }
    case campaignTemplateStandardColumn.PLACEMENT_INFO: {
      return placementStandardColumns.placementColumn;
    }
    case campaignTemplateStandardColumn.STATUS: {
      return placementStandardColumns.statusColumn;
    }
    case campaignTemplateStandardColumn.CREATIVE:
    case campaignTemplateStandardColumn.OBJECTIVES:
    case campaignTemplateStandardColumn.CREATIVE_CREATIVE:
    case campaignTemplateStandardColumn.TARGETING:
    case campaignTemplateStandardColumn.MESSAGING: {
      return bookingStandardColumns.creativeInstructionsColumn;
    }
    case campaignTemplateStandardColumn.NOTES: {
      return bookingStandardColumns.notesColumn;
    }
    case campaignTemplateStandardColumn.DISPATCH_DATE: {
      return bookingStandardColumns.dispatchDateColumn;
    }
    case campaignTemplateStandardColumn.DISPATCH_TO:
    case campaignTemplateStandardColumn.DISPATCH_DETAILS: {
      return bookingStandardColumns.deliveryInstructionsColumn;
    }
    case campaignTemplateStandardColumn.END_DATE: {
      return bookingStandardColumns.endDateColumn;
    }
    case campaignTemplateStandardColumn.LIVE_DATE: {
      return bookingStandardColumns.liveDateColumn;
    }
    case campaignTemplateStandardColumn.REQUIRED_DURATION: {
      return bookingStandardColumns.requiredDurationColumn;
    }
    case campaignTemplateStandardColumn.PUBLICATIONS: {
      return bookingStandardColumns.publicationsColumn;
    }
  }
  return null;
};
