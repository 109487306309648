import {
  N_ArtworkSpec,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { stringifyPrintBorderDimensions } from "./stringify";

export const getBleedAreas = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>(
    (areas, spec) =>
      spec.value.kind === "printOOH" && spec.value.value.physicalBleed !== null
        ? [
            ...areas,
            {
              ...spec,
              value: stringifyPrintBorderDimensions(
                spec.value.value.physicalBleed.value
              ),
            },
          ]
        : areas,
    []
  );
