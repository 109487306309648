import { idFlavor, N_Instant } from "../../../common";
import * as z from "zod";
import {
  infoSchemaBooleanFieldValueZod,
  infoSchemaDateFieldValueZod,
  infoSchemaDateRangeFieldValueZod,
  infoSchemaNumberFieldValueZod,
  infoSchemaRichTextFieldValueZod,
  infoSchemaTextFieldValueZod,
  infoSchemaDictionaryFieldTemplateValueZod,
} from "../value";
import { specSheetTab } from "../../placement";
import { campaignTemplateColumnColour } from "../../campaignTemplate";

export const infoSchemaTableViewColumnType = {
  richText: "richText",
  text: "text",
  number: "number",
  date: "date",
  boolean: "boolean",
  dateRange: "dateRange",
  durationRange: "durationRange",
  link: "link",
  duration: "duration",
  singleSelectTag: "singleSelectTag",
  multiSelectTag: "multiSelectTag",
} as const;

export type InfoSchemaTableViewColumnType =
  (typeof infoSchemaTableViewColumnType)[keyof typeof infoSchemaTableViewColumnType];

export const infoSchemaValueViewColumnDataZod = z.union([
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.text),
    value: infoSchemaTextFieldValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.richText),
    value: infoSchemaRichTextFieldValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.number),
    value: infoSchemaNumberFieldValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.date),
    value: infoSchemaDateFieldValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.boolean),
    value: infoSchemaBooleanFieldValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.dateRange),
    value: infoSchemaDateRangeFieldValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.durationRange),
    value: infoSchemaDateRangeFieldValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.link),
    value: infoSchemaTextFieldValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.duration),
    value: infoSchemaDateFieldValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.multiSelectTag),
    value: infoSchemaDictionaryFieldTemplateValueZod,
  }),
  z.object({
    columnType: z.literal(infoSchemaTableViewColumnType.singleSelectTag),
    value: infoSchemaDictionaryFieldTemplateValueZod,
  }),
]);

export type InfoSchemaValueViewColumnData = z.infer<
  typeof infoSchemaValueViewColumnDataZod
>;

export const dB_InfoSchemaTableViewColumnZod = z.object({
  label: z.string().min(1),
  fieldId: z.string().min(1),
  position: z.number(),
  columnType: z.nativeEnum(infoSchemaTableViewColumnType),
  isHidden: z.boolean().or(z.undefined()),
  isActive: z.boolean().or(z.undefined()),
  color: z.nativeEnum(campaignTemplateColumnColour).or(z.undefined()),
  width: z.number().or(z.undefined()),
  positionByTab: z
    .record(z.nativeEnum(specSheetTab), z.number())
    .or(z.undefined()),
  isHiddenByTab: z
    .record(z.nativeEnum(specSheetTab), z.boolean())
    .or(z.undefined()),
  isSticky: z.boolean().optional(),
});

export const dB_InfoSchemaTableViewColumnsZod = z.array(
  dB_InfoSchemaTableViewColumnZod
);

export type DB_InfoSchemaTableViewColumn = Omit<
  z.infer<typeof dB_InfoSchemaTableViewColumnZod>,
  "fieldId"
> & { fieldId: idFlavor<"InfoSchemaField"> };

export interface N_InfoSchemaTableViewColumn
  extends DB_InfoSchemaTableViewColumn {
  isLimited: boolean;
}

export interface N_InfoSchemaTableView {
  label: string;
  columns: N_InfoSchemaTableViewColumn[];
  schemaId: idFlavor<"InfoSchema">;
  organisationId: idFlavor<"Organisation">;
  authorId: idFlavor<"AppUser">;
  created: N_Instant;
  updated: N_Instant;
  isDefault: boolean;
  isActive: boolean;
}

export interface N_InfoSchemaTableViewView extends N_InfoSchemaTableView {
  id: idFlavor<"InfoSchemaTableView">;
}
