import * as z from "zod";
import { sitecardsData } from "../sitecards";
import { qmsBookingSchema } from "./booking";
import { qmsDigitalPanelSchema } from "./digitalPanel";
import { qmsInstallCompanySchema } from "./installCompany";
import { qmsStaticPanelSchema } from "./staticPanel";

export const qmsPanelKinds = {
  DIGITAL: "DIGITAL",
  STATIC: "STATIC",
} as const;

export const qmsDigitalPanelData = qmsDigitalPanelSchema
  .merge(qmsBookingSchema)
  .merge(qmsInstallCompanySchema)
  .merge(sitecardsData);

export const qmsStaticPanelData = qmsStaticPanelSchema
  .merge(qmsBookingSchema)
  .merge(qmsInstallCompanySchema)
  .merge(sitecardsData);

export const qmsDigitalData = z.object({
  kind: z.literal(qmsPanelKinds.DIGITAL),
  data: qmsDigitalPanelData,
});

export const qmsStaticData = z.object({
  kind: z.literal(qmsPanelKinds.STATIC),
  data: qmsStaticPanelData,
});

export const qmsExtBookingData = z.union([qmsDigitalData, qmsStaticData]);

export type QmsPanelKinds = (typeof qmsPanelKinds)[keyof typeof qmsPanelKinds];
export type QmsStaticData = z.infer<typeof qmsStaticPanelData>;
export type QmsDigitalData = z.infer<typeof qmsDigitalPanelData>;
export type QmsExtBookingData = z.infer<typeof qmsExtBookingData>;
