import { notNull } from "../../typescript";
import {
  N_ArtworkSpec,
  N_NamedAnnotated,
  N_VideoAspectRatios,
} from "@specsheet-common/shared-types";

const fromAspectRatios = (aspectRatio: N_VideoAspectRatios): string[] =>
  [
    notNull(aspectRatio.square) ? "1 : 1" : null,
    notNull(aspectRatio.portrait_9_16) ? "9 : 16" : null,
    notNull(aspectRatio.landscape_16_9) ? "16 : 9" : null,
    notNull(aspectRatio.portrait_4_5) ? "4 : 5" : null,
    ...(notNull(aspectRatio.custom)
      ? aspectRatio.custom.map((ar) => `${ar.value.width} : ${ar.value.height}`)
      : []),
    ...(notNull(aspectRatio.other)
      ? aspectRatio.other.map((o) => o.value)
      : []),
  ].filter(notNull);

export const getAspectRatios = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): (N_NamedAnnotated<string> & { width: number; height: number })[] =>
  specifications.reduce<
    (N_NamedAnnotated<string> & { width: number; height: number })[]
  >(
    (aspectRatios, spec) =>
      spec.value.kind === "digitalStatic" ||
      spec.value.kind === "video" ||
      spec.value.kind === "html5"
        ? [
            ...aspectRatios,
            ...[
              spec.value.kind === "digitalStatic" || spec.value.kind === "video"
                ? spec.value.value.aspectRatios
                : null,
              spec.value.kind === "html5" &&
              notNull(spec.value.value.formats.value.video)
                ? spec.value.value.formats.value.video.value.video.aspectRatios
                : null,
            ]
              .filter(notNull)
              .flatMap((ratios) =>
                fromAspectRatios(ratios.value).map((ratio) => {
                  const [width, height] = ratio.split(":");
                  return {
                    ...spec,
                    value: ratio,
                    width: parseInt(width),
                    height: parseInt(height),
                  };
                })
              ),
          ]
        : aspectRatios,
    []
  );
