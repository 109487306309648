import { N_UserProfile } from "../appUser";
import { idFlavor } from "../../common";
import { OrgMemberStatus } from "../orgMember";
import { CampaignMinimal } from "../campaign";

export const workspaceMemberRole = {
  ADMIN: "ADMIN",
  MEMBER: "MEDIA_PLANNER",
  GUEST: "COLLABORATOR",
} as const;

export type WorkspaceMemberRole =
  (typeof workspaceMemberRole)[keyof typeof workspaceMemberRole];

export const workspaceMemberRoleLabels: {
  [key in WorkspaceMemberRole]: string;
} = {
  [workspaceMemberRole.ADMIN]: "Admin",
  [workspaceMemberRole.MEMBER]: "Member",
  [workspaceMemberRole.GUEST]: "Guest",
};

export interface WorkspaceMemberWithAccessDetails {
  userProfile: N_UserProfile;
  role: WorkspaceMemberRole;
  userGroupIds: idFlavor<"UserGroup">[];
  workspaceMemberId: idFlavor<"WorkspaceMember">;
  orgMemberId: idFlavor<"OrganisationMember">;
  status: OrgMemberStatus;
  campaigns?: CampaignMinimal[];
}
