import { idFlavor } from "../../common";
import {
  campaignMetaLogIntent,
  campaignMetaLogMetaIntent,
} from "../campaignMetaLog";
import { z } from "zod";
import { campaignChangeCreatedBy } from "../campaignChange";

export const campaignSubRoute = {
  withBookingsAsAtVersion: "asatversion",
  getInfoForDuplication: "forduplication",
  getChanges: "changes",
  getPaginatedChangeLog: "paginatedChangelog",
  getChangeLogCounts: "getChangeLogCounts",
  create: "",
  duplicate: "duplicate",
  share: "share",
  shareVersion: "shareVersion",
  updateVersion: "updateVersion",
  revertChanges: "revertchanges",
  updateTeamMembers: "updateteammembers",
  toggleFavourite: "togglefavourite",
  requestAllDocuments: "requestAllDocuments",
  saveFilter: "filters",
  fetchFilter: "filters",
  createMetaLog: "createmetalog",
  getMetaLog: "metalog",
  delete: "delete",
  move: "move",
  unversionedChangesCount: "unversionedChangesCount",
} as const;

export type VersionQueryParams =
  | { kind: "latestPublished" | "latest"; queryFor?: "edit" | "view" }
  | { kind: "version"; version: string; queryFor?: "edit" | "view" };

export interface ChangesQueryParams {
  version?: string;
  timeStamp?: string;
}

export const paginatedChangeLogDto = z.object({
  cursor: z.string().optional(),
  intents: z
    .array(
      z.nativeEnum({ ...campaignMetaLogIntent, ...campaignMetaLogMetaIntent })
    )
    .optional(),
  collaborator: z.array(z.string()).optional(),
  lastUpdatedType: z.array(z.nativeEnum(campaignChangeCreatedBy)).optional(),
  search: z.string().optional(),
});

export type PaginatedChangeLogDto = z.infer<typeof paginatedChangeLogDto>;

export const changelogCountsDto = z.object({
  intents: z
    .array(
      z.nativeEnum({ ...campaignMetaLogIntent, ...campaignMetaLogMetaIntent })
    )
    .optional(),
  collaborator: z.array(z.string()).optional(),
  lastUpdatedType: z.array(z.nativeEnum(campaignChangeCreatedBy)).optional(),
  search: z.string().optional(),
});

export type ChangelogCountsDto = z.infer<typeof changelogCountsDto>;

export interface RevertByCmlParams {
  cmlId: idFlavor<"CampaignMetaLog">;
}

export const campaignUrls = {
  "campaign.activate": "campaigns/activate/:campaignId",
  "campaign.archive": "campaigns/archive/:campaignId",
  "campaign.changelog.revert": "campaigns/revertbycml/:campaignId",
  "campaign.changelog.users": "campaigns/getChangeLogUsers/:campaignId",
  "campaign.changelog.types": "campaigns/:campaignId/changelog_types",
  "campaign.claimed_contracts.fetch": "campaigns/claimedContracts/:campaignId",
  "campaign.job_id.check": "campaigns/checkjobid",
  "campaign.list": "campaigns/list/:workspaceId",
  "campaign.meta_log.hide": "campaigns/hideCampaignMetaLog/:campaignMetaLogId",
  "campaign.shared.list": "campaigns/listshared/:workspaceId",
  "campaign.update": `campaigns/:campaignId`,
  "campaign.view": "campaigns/:campaignId",
  campaign: "campaigns",
} as const;

export type CampaignRoutes =
  | {
      sub: typeof campaignSubRoute.withBookingsAsAtVersion;
      tailParam: idFlavor<"Campaign">;
      queryParams?: VersionQueryParams;
      method?: "Get";
    }
  | {
      sub: typeof campaignSubRoute.getInfoForDuplication;
      tailParam: idFlavor<"Campaign">;
      method?: "Get";
    }
  | {
      sub: typeof campaignSubRoute.getChanges;
      tailParam: idFlavor<"Campaign">;
      queryParams?: ChangesQueryParams;
      method?: "Get";
    }
  | {
      sub: typeof campaignSubRoute.getPaginatedChangeLog;
      tailParam: idFlavor<"Campaign">;
      queryParams?: PaginatedChangeLogDto;
      method?: "Get";
    }
  | {
      sub: typeof campaignSubRoute.getChangeLogCounts;
      tailParam: idFlavor<"Campaign">;
      queryParams?: ChangelogCountsDto;
      method?: "Get";
    }
  | {
      sub: typeof campaignSubRoute.unversionedChangesCount;
      tailParam: idFlavor<"Campaign">;
      method?: "Get";
    }
  | {
      sub: typeof campaignSubRoute.create;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.duplicate;
      tailParam: idFlavor<"Campaign">;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.delete;
      tailParam: idFlavor<"Campaign">;
      method?: "Delete";
    }
  | {
      sub: typeof campaignSubRoute.share;
      tailParam: idFlavor<"Campaign">;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.shareVersion;
      tailParam: idFlavor<"Campaign">;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.updateVersion;
      tailParam: idFlavor<"Campaign">;
      method?: "Patch";
    }
  | {
      sub: typeof campaignSubRoute.revertChanges;
      tailParam: idFlavor<"Campaign">;
      method?: "Get";
    }
  | {
      sub: typeof campaignSubRoute.updateTeamMembers;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.updateTeamMembers;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.toggleFavourite;
      tailParam: idFlavor<"Campaign">;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.requestAllDocuments;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.saveFilter;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.fetchFilter;
      tailParam: string;
      method?: "Get";
    }
  | {
      sub: typeof campaignSubRoute.createMetaLog;
      method?: "Post";
    }
  | {
      sub: typeof campaignSubRoute.move;
      tailParam: string;
      method?: "Post";
    };
