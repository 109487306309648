import {
  DB_AnimatedMedia,
  DB_Annotated,
  DB_ArtworkSpec,
  DB_Attributes,
  DB_Audio,
  DB_AuthorNotesReason,
  DB_Border,
  DB_CopyMedia,
  DB_DataAmounts,
  DB_DataAmountType,
  DB_DigitalMediaDimensions,
  DB_DigitalResolutionUnit,
  DB_DigitalStaticMedia,
  DB_DimensionResolutionType,
  DB_DimensionSizeCase,
  DB_Html5Media,
  DB_Illumination,
  DB_Metadata,
  DB_NamedAnnotated,
  DB_Placement,
  DB_Placement_Specs,
  DB_PrintMagazine,
  DB_PrintNewspaper,
  DB_PrintOOH,
  DB_PrintResolutionUnit,
  DB_UrlResource,
  DB_Video,
  N_AnimatedMedia,
  N_Annotated,
  N_ArtworkSpec,
  N_Attributes,
  N_Audio,
  N_AuthorNotesReason,
  N_Border,
  N_CopyMedia,
  N_DataAmounts,
  N_DataAmountType,
  N_DigitalMediaDimensions,
  N_DigitalResolutionUnit,
  N_DigitalStaticMedia,
  N_DimensionResolutionType,
  N_DimensionSizeCase,
  N_Html5Media,
  N_Illumination,
  N_Metadata,
  N_NamedAnnotated,
  N_Placement,
  N_PrintMagazine,
  N_PrintNewspaper,
  N_PrintOOH,
  N_PrintResolutionUnit,
  N_Specifications,
  N_UrlResource,
  N_Video,
} from "@specsheet-common/shared-types";
import { assertNever, notNull } from "../typescript";
import {
  convertPlainUrlToN_DataOrigin_Url,
  DB_PLAIN_URL,
} from "./publisherConverter";

export const convertDbUrlRecourceToNUrlRecource = (
  data: DB_UrlResource
): N_UrlResource => {
  return {
    name: data.name,
    notes: data.notes || null,
    fileSize: data.fileSize || null,
    data: { kind: "url", value: data.data.url },
  };
};

export const convertAuthorNotesReason = (
  data: DB_AuthorNotesReason
): N_AuthorNotesReason => {
  if (data === "clarification") {
    return { kind: "clarification" };
  }

  if (data === "underspecified") {
    return { kind: "underspecified" };
  }

  if ("other_" in data) {
    return { kind: "other_", value: data.other_ };
  }

  assertNever(data);
  throw new Error();
};

export const convertMetadata = (data: DB_Metadata): N_Metadata => {
  return {
    ...data,
    images: data.images.map(convertDbUrlRecourceToNUrlRecource),
    authorNotes: data.authorNotes.map((an) => ({
      ...an,
      reason: convertAuthorNotesReason(an.reason),
    })),
  };
};

export const convertDimensionResolutionType = (
  data: DB_DimensionResolutionType
): N_DimensionResolutionType => {
  if (data === "standard") {
    return { kind: "standard" };
  }

  if (data === "retina") {
    return { kind: "retina" };
  }

  if ("other" in data) {
    return { kind: "other", value: data.other };
  }

  assertNever(data);
  throw new Error();
};

export const convertDimensionSizeCase = (
  data: DB_DimensionSizeCase
): N_DimensionSizeCase => {
  if (data === "collapsed") {
    return { kind: "collapsed" };
  }

  if (data === "expanded") {
    return { kind: "expanded" };
  }

  if (data === "regular") {
    return { kind: "regular" };
  }

  if ("other" in data) {
    return { kind: "other", value: data.other };
  }

  assertNever(data);
  throw new Error();
};

export const convertDigitalResolutionUnit = (
  data: DB_DigitalResolutionUnit
): N_DigitalResolutionUnit => {
  if (data === "DPI") {
    return { kind: "DPI" };
  }

  if (data === "PPI") {
    return { kind: "PPI" };
  }

  if ("other" in data) {
    return { kind: "other", value: data.other };
  }

  assertNever(data);
  throw new Error();
};

export const convertBorder = (data: DB_Border): N_Border => {
  if (data === "singlePixel") {
    return { kind: "singlePixel" };
  }

  if ("other" in data) {
    return { kind: "other", value: data.other };
  }

  assertNever(data);
  throw new Error();
};

export const convertDataAmountType = (
  data: DB_DataAmountType
): N_DataAmountType => {
  if (data === "total") {
    return { kind: "total" };
  }

  if (data === "adVerificationScripts") {
    return { kind: "adVerificationScripts" };
  }

  if (data === "userInitiated") {
    return { kind: "userInitiated" };
  }

  if (data === "hostedSharedLibrary") {
    return { kind: "hostedSharedLibrary" };
  }

  if (data === "initial") {
    return { kind: "initial" };
  }

  if (data === "polite") {
    return { kind: "polite" };
  }

  if ("other" in data) {
    return { kind: "other", value: data.other };
  }

  assertNever(data);
  throw new Error();
};

export const convertPrintResolutionUnit = (
  data: DB_PrintResolutionUnit
): N_PrintResolutionUnit => {
  if (data === "DPI") {
    return { kind: "DPI" };
  }

  if (data === "LPI") {
    return { kind: "LPI" };
  }

  if ("other" in data) {
    return { kind: "other", value: data.other };
  }

  assertNever(data);
  throw new Error();
};

export const convertIllumination = (data: DB_Illumination): N_Illumination => {
  if ("backlit" in data) {
    return { kind: "backlit", value: data.backlit };
  }

  if ("frontlit" in data) {
    return { kind: "frontlit", value: data.frontlit };
  }

  if ("frontlitTop" in data) {
    return { kind: "frontlitTop", value: data.frontlitTop };
  }

  if ("frontlitBottom" in data) {
    return { kind: "frontlitBottom", value: data.frontlitBottom };
  }

  assertNever(data);
  throw new Error();
};

export const convertNamedAnnotated = <T>(
  data: DB_NamedAnnotated<T>
): N_NamedAnnotated<T> => {
  return {
    ...data,
    metadata: data.metadata ? convertMetadata(data.metadata) : null,
  };
};

export const convertAnnotated = <T>(data: DB_Annotated<T>): N_Annotated<T> => {
  return {
    ...data,
    metadata: data?.metadata ? convertMetadata(data.metadata) : null,
  };
};

export const convertAttributes = (data: DB_Attributes): N_Attributes => {
  return data.map((val) => ({
    ...val,
    metadata: val.metadata ? convertMetadata(val.metadata) : null,
  }));
};

export const convertDataAmounts = (data: DB_DataAmounts): N_DataAmounts => {
  return data.map((da) => ({
    ...convertNamedAnnotated(da),
    value: {
      ...da.value,
      kind: convertDataAmountType(da.value.kind),
    },
  }));
};

export const convertDigitalMediaDimentions = (
  data: DB_DigitalMediaDimensions
): N_DigitalMediaDimensions => {
  return data.map((dd) => ({
    ...convertNamedAnnotated(dd),
    value: {
      ...dd.value,
      resolutionType: dd.value.resolutionType
        ? convertDimensionResolutionType(dd.value.resolutionType)
        : null,
      sizeCase: dd.value.sizeCase
        ? convertDimensionSizeCase(dd.value.sizeCase)
        : null,
    },
  }));
};

export const convertDigitalStatic = (
  data: DB_DigitalStaticMedia
): N_DigitalStaticMedia => {
  return {
    ...data,
    dimensions: {
      ...convertAnnotated(data.dimensions),
      value: convertDigitalMediaDimentions(data.dimensions.value),
    },
    maxFileSize: data.maxFileSize ? convertAnnotated(data.maxFileSize) : null,
    safeZone: data.safeZone ? convertAnnotated(data.safeZone) : null,
    DPI: data.DPI ? convertAnnotated(data.DPI) : null,
    resolution: data.resolution
      ? {
          ...convertAnnotated(data.resolution),
          value: {
            ...data.resolution.value,
            unit: convertDigitalResolutionUnit(data.resolution.value.unit),
          },
        }
      : null,
    aspectRatios: data.aspectRatios
      ? {
          ...convertAnnotated(data.aspectRatios),
          value: {
            landscape_16_9: data.aspectRatios.value.landscape_16_9 ?? null,
            portrait_9_16: data.aspectRatios.value.portrait_9_16 ?? null,
            portrait_4_5: data.aspectRatios.value.portrait_4_5 ?? null,
            square: data.aspectRatios.value.square ?? null,
            other: data.aspectRatios.value.other.map(convertAnnotated),
            custom: data.aspectRatios.value.custom.map(convertAnnotated),
          },
        }
      : null,
    color: data.color
      ? convertAnnotated({
          ...data.color,
          value: {
            ...data.color.value,
            RGB: data.color.value.RGB ?? null,
            HEX: data.color.value.HEX ?? null,
          },
        })
      : null,
    border: data.border
      ? {
          ...convertAnnotated(data.border),
          value: convertBorder(data.border.value),
        }
      : null,
    formats: data.formats
      ? {
          ...convertAnnotated(data.formats),
          value: {
            tiff: data.formats.value.tiff ?? null,
            ai: data.formats.value.ai ?? null,
            eps: data.formats.value.eps ?? null,
            psdLayered: data.formats.value.psdLayered ?? null,
            psdFlattened: data.formats.value.psdFlattened ?? null,
            png: data.formats.value.png ?? null,
            gif: data.formats.value.gif ?? null,
            jpg: data.formats.value.jpg ?? null,
            bmp: data.formats.value.bmp ?? null,
            svg: data.formats.value.svg ?? null,
            other: data.formats.value.other.map(convertAnnotated),
          },
        }
      : null,
    attributes: convertAttributes(data.attributes),
  };
};

export const convertAnimated = (data: DB_AnimatedMedia): N_AnimatedMedia => {
  return {
    ...data,
    dimensions: {
      ...convertAnnotated(data.dimensions),
      value: convertDigitalMediaDimentions(data.dimensions.value),
    },
    durations: {
      ...convertAnnotated(data.durations),
      value: data.durations.value.map((v) =>
        convertNamedAnnotated({
          ...v,
          value: {
            hours: v.value.hours ?? null,
            minutes: v.value.minutes ?? null,
            milliSeconds: v.value.milliSeconds ?? null,
            seconds: v.value.seconds ?? null,
          },
        })
      ),
    },
    loop: data.loop ? convertAnnotated(data.loop) : null,
    loopCount: data.loopCount ? convertAnnotated(data.loopCount) : null,
    endWithStaticFrame: convertAnnotated(data.endWithStaticFrame),
    safeZone: data.safeZone ? convertAnnotated(data.safeZone) : null,
    DPI: data.DPI ? convertAnnotated(data.DPI) : null,
    resolution: data.resolution
      ? {
          ...convertAnnotated(data.resolution),
          value: {
            ...data.resolution.value,
            unit: convertDigitalResolutionUnit(data.resolution.value.unit),
          },
        }
      : null,
    color: data.color
      ? convertAnnotated({
          ...data.color,
          value: {
            RGB: data.color.value.RGB ?? null,
          },
        })
      : null,
    border: data.border
      ? {
          ...convertAnnotated(data.border),
          value: convertBorder(data.border.value),
        }
      : null,
    formats: {
      ...convertAnnotated(data.formats),
      value: {
        ...data.formats.value,
        gif: data.formats.value.gif ?? null,
        other: data.formats.value.other
          ? data.formats.value.other.map(convertAnnotated)
          : [],
      },
    },
    maxFileSize: data.maxFileSize ? convertAnnotated(data.maxFileSize) : null,
    attributes: convertAttributes(data.attributes),
  };
};

export const convertAudio = (data: DB_Audio): N_Audio => {
  return {
    ...data,
    durations: {
      ...convertAnnotated(data.durations),
      value: data.durations.value.map((v) =>
        convertNamedAnnotated({
          ...v,
          value: {
            hours: v.value.hours ?? null,
            minutes: v.value.minutes ?? null,
            milliSeconds: v.value.milliSeconds ?? null,
            seconds: v.value.seconds ?? null,
          },
        })
      ),
    },
    formats: {
      ...convertAnnotated(data.formats),
      value: {
        WAV: data.formats.value.WAV ?? null,
        AAC: data.formats.value.AAC ?? null,
        AIFF: data.formats.value.AIFF ?? null,
        MP3: data.formats.value.MP3 ?? null,
        other: data.formats.value.other.map(convertAnnotated),
      },
    },
    codecs: data.codecs
      ? {
          ...convertAnnotated(data.codecs),
          value: data.codecs.value.map(convertNamedAnnotated),
        }
      : null,
    bitrate: data.bitrate ? convertAnnotated(data.bitrate) : null,
    sampleRate: data.sampleRate ? convertAnnotated(data.sampleRate) : null,
    decibels: data.decibels ? convertAnnotated(data.decibels) : null,
    maxFileSize: data.maxFileSize ? convertAnnotated(data.maxFileSize) : null,
    adServing: data.adServing ? convertAnnotated(data.adServing) : null,
    tracking: data.tracking ? convertAnnotated(data.tracking) : null,
    attributes: convertAttributes(data.attributes),
  };
};

export const convertVideo = (data: DB_Video): N_Video => {
  return {
    ...data,
    dimensions: {
      ...convertAnnotated(data.dimensions),
      value: convertDigitalMediaDimentions(data.dimensions.value),
    },
    orientation: data.orientation
      ? {
          ...convertAnnotated(data.orientation),
          value: {
            square: data.orientation.value.square ?? null,
            portrait: data.orientation.value.portrait ?? null,
            landscape: data.orientation.value.landscape ?? null,
            other: data.orientation.value.other.map(convertAnnotated),
          },
        }
      : null,
    aspectRatios: data.aspectRatios
      ? {
          ...convertAnnotated(data.aspectRatios),
          value: {
            landscape_16_9: data.aspectRatios.value.landscape_16_9 ?? null,
            portrait_9_16: data.aspectRatios.value.portrait_9_16 ?? null,
            portrait_4_5: data.aspectRatios.value.portrait_4_5 ?? null,
            square: data.aspectRatios.value.square ?? null,
            other: data.aspectRatios.value.other.map(convertAnnotated),
            custom: data.aspectRatios.value.custom.map(convertAnnotated),
          },
        }
      : null,
    safeZone: data.safeZone ? convertAnnotated(data.safeZone) : null,
    durations: data.durations
      ? {
          ...convertAnnotated(data.durations),
          value: data.durations.value.map((v) =>
            convertNamedAnnotated({
              ...v,
              value: {
                hours: v.value.hours ?? null,
                minutes: v.value.minutes ?? null,
                milliSeconds: v.value.milliSeconds ?? null,
                seconds: v.value.seconds ?? null,
              },
            })
          ),
        }
      : null,
    formats: {
      ...convertAnnotated(data.formats),
      value: {
        ThreeGP: data.formats.value.ThreeGP ?? null,
        OGG: data.formats.value.OGG ?? null,
        MOV: data.formats.value.MOV ?? null,
        FLV: data.formats.value.FLV ?? null,
        AVI: data.formats.value.AVI ?? null,
        WMV: data.formats.value.WMV ?? null,
        WebM: data.formats.value.WebM ?? null,
        MP4: data.formats.value.MP4 ?? null,
        other: data.formats.value.other.map(convertAnnotated),
      },
    },
    codec: data.codec
      ? {
          ...convertAnnotated(data.codec),
          value: {
            ...data.codec.value,
            QuickTime: data.codec.value.QuickTime ?? null,
            ProRes422: data.codec.value.ProRes422 ?? null,
            Webm_vp9: data.codec.value.Webm_vp9 ?? null,
            Webm_vp8: data.codec.value.Webm_vp8 ?? null,
            mpeg4: data.codec.value.mpeg4 ?? null,
            mpeg2: data.codec.value.mpeg2 ?? null,
            divx: data.codec.value.divx ?? null,
            hevc_h265: data.codec.value.hevc_h265 ?? null,
            avc_h264: data.codec.value.avc_h264 ?? null,
            other: data.codec.value.other.map(convertAnnotated),
          },
        }
      : null,
    frameRate: data.frameRate ? convertAnnotated(data.frameRate) : null,
    profile: data.profile ? convertAnnotated(data.profile) : null,
    profileLevel: data.profileLevel
      ? convertAnnotated(data.profileLevel)
      : null,
    bitrate: data.bitrate ? convertAnnotated(data.bitrate) : null,
    fieldRender: data.fieldRender ? convertAnnotated(data.fieldRender) : null,
    loop: data.loop ? convertAnnotated(data.loop) : null,
    loopCount: data.loopCount ? convertAnnotated(data.loopCount) : null,
    audio: data.audio
      ? {
          ...convertAnnotated(data.audio),
          value: {
            ...data.audio.value,
            formats: {
              ...convertAnnotated(data.audio.value.formats),
              value: {
                ...data.audio.value.formats.value,
                AIFF: data.audio.value.formats.value.AIFF ?? null,
                AAC: data.audio.value.formats.value.AAC ?? null,
                MP3: data.audio.value.formats.value.MP3 ?? null,
                WAV: data.audio.value.formats.value.WAV ?? null,
                other:
                  data.audio.value.formats.value.other.map(convertAnnotated),
              },
            },
            audioBitrate: data.audio.value.audioBitrate
              ? convertAnnotated(data.audio.value.audioBitrate)
              : null,
            decibels: data.audio.value.decibels
              ? convertAnnotated(data.audio.value.decibels)
              : null,
            sampleRate: data.audio.value.sampleRate
              ? convertAnnotated(data.audio.value.sampleRate)
              : null,
          },
        }
      : null,
    subtitles: data.subtitles ? convertAnnotated(data.subtitles) : null,
    script: data.script ? convertAnnotated(data.script) : null,
    dataAmounts: data.dataAmounts
      ? {
          ...convertAnnotated(data.dataAmounts),
          value: convertDataAmounts(data.dataAmounts.value),
        }
      : null,
    maxFileSize: data.maxFileSize ? convertAnnotated(data.maxFileSize) : null,
    attributes: convertAttributes(data.attributes),
    accepted3rdPartyVendors: data.accepted3rdPartyVendors
      ? convertAttributes(data.accepted3rdPartyVendors)
      : null,
  };
};

export const convertHtml = (data: DB_Html5Media): N_Html5Media => {
  return {
    ...data,
    attributes: convertAttributes(data.attributes),
    dimensions: {
      ...convertAnnotated(data.dimensions),
      value: convertDigitalMediaDimentions(data.dimensions.value),
    },
    safeZone: data.safeZone ? convertAnnotated(data.safeZone) : null,
    DPI: data.DPI ? convertAnnotated(data.DPI) : null,
    resolution: data.resolution
      ? {
          ...convertAnnotated(data.resolution),
          value: {
            ...data.resolution.value,
            unit: convertDigitalResolutionUnit(data.resolution.value.unit),
          },
        }
      : null,
    color: data.color
      ? convertAnnotated({
          ...data.color,
          value: {
            RGB: data.color.value.RGB ?? null,
            HEX: data.color.value.HEX ?? null,
          },
        })
      : null,
    border: data.border
      ? {
          ...convertAnnotated(data.border),
          value: convertBorder(data.border.value),
        }
      : null,
    dataAmounts: data.dataAmounts
      ? {
          ...convertAnnotated(data.dataAmounts),
          value: convertDataAmounts(data.dataAmounts.value),
        }
      : null,
    accepted3rdPartyVendors: data.accepted3rdPartyVendors
      ? convertAttributes(data.accepted3rdPartyVendors)
      : null,
    formats: {
      ...convertAnnotated(data.formats),
      value: {
        ...data.formats.value,
        video: data.formats.value.video
          ? {
              ...convertAnnotated(data.formats.value.video),
              value: {
                ...data.formats.value.video.value,
                video: convertVideo(data.formats.value.video.value.video),
              },
            }
          : null,
        web: convertAnnotated({
          ...data.formats.value.web,
          value: {
            JS: data.formats.value.web.value.JS ?? null,
            CSS: data.formats.value.web.value.CSS ?? null,
            HTML5: data.formats.value.web.value.HTML5 ?? null,
          },
        }),
        animated: {
          ...convertAnnotated(data.formats.value.animated),
          value: {
            gif: data.formats.value.animated.value.gif ?? null,
            other:
              data.formats.value.animated.value.other?.map(convertAnnotated) ??
              [],
          },
        },
        static: {
          ...convertAnnotated(data.formats.value.static),
          value: {
            tiff: data.formats.value.static.value.tiff ?? null,
            ai: data.formats.value.static.value.ai ?? null,
            eps: data.formats.value.static.value.eps ?? null,
            psdLayered: data.formats.value.static.value.psdLayered ?? null,
            psdFlattened: data.formats.value.static.value.psdFlattened ?? null,
            png: data.formats.value.static.value.png ?? null,
            gif: data.formats.value.static.value.gif ?? null,
            jpg: data.formats.value.static.value.jpg ?? null,
            bmp: data.formats.value.static.value.bmp ?? null,
            svg: data.formats.value.static.value.svg ?? null,
            other: data.formats.value.static.value.other.map(convertAnnotated),
          },
        },
      },
    },
    durations: data.durations
      ? {
          ...convertAnnotated(data.durations),
          value: data.durations.value.map((v) =>
            convertNamedAnnotated({
              ...v,
              value: {
                hours: v.value.hours ?? null,
                minutes: v.value.minutes ?? null,
                milliSeconds: v.value.milliSeconds ?? null,
                seconds: v.value.seconds ?? null,
              },
            })
          ),
        }
      : null,
    frameRate: data.frameRate ? convertAnnotated(data.frameRate) : null,
    loop: data.loop ? convertAnnotated(data.loop) : null,
    loopCount: data.loopCount ? convertAnnotated(data.loopCount) : null,
  };
};

export const convertCopy = (data: DB_CopyMedia): N_CopyMedia => {
  return {
    ...data,
    attributes: convertAttributes(data.attributes),
    text: {
      ...convertAnnotated(data.text),
      value: data.text.value.map((v) =>
        convertNamedAnnotated({
          ...v,
          value: {
            minCharacters: v.value.minCharacters ?? null,
            maxCharacters: v.value.maxCharacters ?? null,
            maxFileSize: v.value.maxFileSize ?? null,
          },
        })
      ),
    },
  };
};
export const convertPrintNewspaper = (
  data: DB_PrintNewspaper
): N_PrintNewspaper => {
  return {
    ...data,
    attributes: convertAttributes(data.attributes),
    dimension: notNull(data.dimension)
      ? convertAnnotated(data.dimension)
      : null,
    formats: {
      ...convertAnnotated(data.formats),
      value: {
        eps: data.formats.value.eps ?? null,
        ai: data.formats.value.ai ?? null,
        psd: data.formats.value.psd ?? null,
        indd: data.formats.value.indd ?? null,
        quarkXPress: data.formats.value.quarkXPress ?? null,
        pdf: data.formats.value.pdf ?? null,
        other: data.formats.value.other.map(convertAnnotated),
      },
    },
    maxFileSize: data.maxFileSize ? convertAnnotated(data.maxFileSize) : null,
    resolution: data.resolution
      ? {
          ...convertAnnotated(data.resolution),
          value: {
            ...data.resolution.value,
            unit: convertPrintResolutionUnit(data.resolution.value.unit),
          },
        }
      : null,
    additionalPrintDimensions: data.additionalPrintDimensions
      ? {
          ...convertAnnotated(data.additionalPrintDimensions),
          value: data.additionalPrintDimensions.value.map(
            convertNamedAnnotated
          ),
        }
      : null,
    bleed: data.bleed ? convertAnnotated(data.bleed) : null,
    blackInkLimit: data.blackInkLimit
      ? convertAnnotated(data.blackInkLimit)
      : null,
    dotGain: data.dotGain ? convertAnnotated(data.dotGain) : null,
    font: data.font ? convertAnnotated(data.font) : null,
    placementInNewspaper: data.placementInNewspaper
      ? convertAnnotated(data.placementInNewspaper)
      : null,
    colourProfile: data.colourProfile
      ? convertAnnotated(data.colourProfile)
      : null,
    separationProfile: data.separationProfile
      ? convertAnnotated(data.separationProfile)
      : null,
    finishedTrimBleed: data.finishedTrimBleed
      ? convertAnnotated(data.finishedTrimBleed)
      : null,
    trim: data.trim ? convertAnnotated(data.trim) : null,
    highlightSet: data.highlightSet
      ? convertAnnotated(data.highlightSet)
      : null,
    spread: data.spread ? convertAnnotated(data.spread) : null,
    inkWeightCoverage: data.inkWeightCoverage
      ? convertAnnotated(data.inkWeightCoverage)
      : null,
    textFreeGutter: data.textFreeGutter
      ? convertAnnotated(data.textFreeGutter)
      : null,
    textSafeArea: data.textSafeArea
      ? convertAnnotated(data.textSafeArea)
      : null,
  };
};

export const convertPrintMagazine = (
  data: DB_PrintMagazine
): N_PrintMagazine => {
  return {
    ...data,
    attributes: convertAttributes(data.attributes),
    formats: {
      ...convertAnnotated(data.formats),
      value: {
        eps: data.formats.value.eps ?? null,
        ai: data.formats.value.ai ?? null,
        psd: data.formats.value.psd ?? null,
        indd: data.formats.value.indd ?? null,
        quarkXPress: data.formats.value.quarkXPress ?? null,
        pdf: data.formats.value.pdf ?? null,
        other: data.formats.value.other.map(convertAnnotated),
      },
    },
    maxFileSize: data.maxFileSize ? convertAnnotated(data.maxFileSize) : null,
    resolution: data.resolution
      ? {
          ...convertAnnotated(data.resolution),
          value: {
            ...data.resolution.value,
            unit: convertPrintResolutionUnit(data.resolution.value.unit),
          },
        }
      : null,
    bleed: data.bleed ? convertAnnotated(data.bleed) : null,
    font: data.font ? convertAnnotated(data.font) : null,
    colourProfile: data.colourProfile
      ? convertAnnotated(data.colourProfile)
      : null,
    separationProfile: data.separationProfile
      ? convertAnnotated(data.separationProfile)
      : null,
    finishedTrimBleed: data.finishedTrimBleed
      ? convertAnnotated(data.finishedTrimBleed)
      : null,
    trim: data.trim ? convertAnnotated(data.trim) : null,
    inkWeightCoverage: data.inkWeightCoverage
      ? convertAnnotated(data.inkWeightCoverage)
      : null,
    dotGain: data.dotGain ? convertAnnotated(data.dotGain) : null,
    textFreeGutter: data.textFreeGutter
      ? convertAnnotated(data.textFreeGutter)
      : null,
    textSafeArea: data.textSafeArea
      ? convertAnnotated(data.textSafeArea)
      : null,
    binding: data.binding ? convertAnnotated(data.binding) : null,
    design: data.design ? convertAnnotated(data.design) : null,
    placementInMagazine: data.placementInMagazine
      ? convertAnnotated(data.placementInMagazine)
      : null,
    finish: data.finish ? convertAnnotated(data.finish) : null,
    scaling: data.scaling ? convertAnnotated(data.scaling) : null,
    paperType: data.paperType ? convertAnnotated(data.paperType) : null,
  };
};

export const convertPrintOOH = (data: DB_PrintOOH): N_PrintOOH => {
  return {
    ...data,
    attributes: convertAttributes(data.attributes),
    formats: data.formats
      ? {
          ...convertAnnotated(data.formats),
          value: {
            eps: data.formats.value.eps ?? null,
            ai: data.formats.value.ai ?? null,
            psd: data.formats.value.psd ?? null,
            indd: data.formats.value.indd ?? null,
            quarkXPress: data.formats.value.quarkXPress ?? null,
            pdf: data.formats.value.pdf ?? null,
            other: data.formats.value.other.map(convertAnnotated),
          },
        }
      : null,
    maxFileSize: data.maxFileSize ? convertAnnotated(data.maxFileSize) : null,
    resolution: data.resolution
      ? {
          ...convertAnnotated(data.resolution),
          value: {
            ...data.resolution.value,
            unit: convertPrintResolutionUnit(data.resolution.value.unit),
          },
        }
      : null,
    font: data.font ? convertAnnotated(data.font) : null,
    colourProfile: data.colourProfile
      ? convertAnnotated(data.colourProfile)
      : null,
    artworkBleed: data.artworkBleed
      ? convertAnnotated(data.artworkBleed)
      : null,
    artworkDimension: data.artworkDimension
      ? {
          ...convertAnnotated(data.artworkDimension),
          value: {
            ...data.artworkDimension.value,
            visualSize: data.artworkDimension.value.visualSize
              ? convertAnnotated(data.artworkDimension.value.visualSize)
              : null,
            finishSize: data.artworkDimension.value.finishSize
              ? convertAnnotated(data.artworkDimension.value.finishSize)
              : null,
          },
        }
      : null,
    artworkScale: data.artworkScale
      ? convertAnnotated(data.artworkScale)
      : null,
    edging: data.edging ? convertAnnotated(data.edging) : null,
    finishedEdgedDimensions: data.finishedEdgedDimensions
      ? convertAnnotated(data.finishedEdgedDimensions)
      : null,
    blackSpecification: data.blackSpecification
      ? convertAnnotated(data.blackSpecification)
      : null,
    install: data.install ? convertAnnotated(data.install) : null,
    illumination: data.illumination
      ? {
          ...convertAnnotated(data.illumination),
          value: convertIllumination(data.illumination.value),
        }
      : null,
    material: data.material ? convertAnnotated(data.material) : null,
    materialAttributes: data.materialAttributes
      ? convertAttributes(data.materialAttributes)
      : null,
    physicalBleed: data.physicalBleed
      ? convertAnnotated(data.physicalBleed)
      : null,
    physicalDimension: data.physicalDimension
      ? {
          ...convertAnnotated(data.physicalDimension),
          value: {
            ...data.physicalDimension.value,
            visualSize: data.physicalDimension.value.visualSize
              ? convertAnnotated(data.physicalDimension.value.visualSize)
              : null,
            finishSize: data.physicalDimension.value.finishSize
              ? convertAnnotated(data.physicalDimension.value.finishSize)
              : null,
          },
        }
      : null,
    visualSafeArea: data.visualSafeArea
      ? convertAnnotated(data.visualSafeArea)
      : null,
  };
};

export const convertArtworkSpec = (
  dbArtWorkSpec: DB_ArtworkSpec
): N_ArtworkSpec => {
  if ("digitalStatic" in dbArtWorkSpec) {
    return {
      kind: "digitalStatic",
      value: convertDigitalStatic(dbArtWorkSpec.digitalStatic),
    };
  }
  if ("animated" in dbArtWorkSpec) {
    return {
      kind: "animated",
      value: convertAnimated(dbArtWorkSpec.animated),
    };
  }
  if ("html5" in dbArtWorkSpec) {
    return { kind: "html5", value: convertHtml(dbArtWorkSpec.html5) };
  }
  if ("video" in dbArtWorkSpec) {
    return { kind: "video", value: convertVideo(dbArtWorkSpec.video) };
  }
  if ("audio" in dbArtWorkSpec) {
    return { kind: "audio", value: convertAudio(dbArtWorkSpec.audio) };
  }
  if ("copy" in dbArtWorkSpec) {
    return { kind: "copy", value: convertCopy(dbArtWorkSpec.copy) };
  }
  if ("newspaper" in dbArtWorkSpec) {
    return {
      kind: "newspaper",
      value: convertPrintNewspaper(dbArtWorkSpec.newspaper),
    };
  }
  if ("magazine" in dbArtWorkSpec) {
    return {
      kind: "magazine",
      value: convertPrintMagazine(dbArtWorkSpec.magazine),
    };
  }
  if ("printOOH" in dbArtWorkSpec) {
    return {
      kind: "printOOH",
      value: convertPrintOOH(dbArtWorkSpec.printOOH),
    };
  }
  assertNever(dbArtWorkSpec);
  throw new Error();
};

export const dbPlacementSpecsToSpecs = (
  dbPlacementSpecs: DB_Placement_Specs
): N_Specifications => {
  return {
    kind: "components",
    value: (dbPlacementSpecs.components || []).map((component) => ({
      ...convertNamedAnnotated(component),
      value: convertArtworkSpec(component.value),
    })),
  };
};

export const dbPlacementToPlacement = (
  dbPlacement: DB_Placement
): N_Placement => {
  return {
    ...dbPlacement,
    originalSpecs: dbPlacement.originalSpecs.map(
      convertDbUrlRecourceToNUrlRecource
    ),
    publisher: {
      ...dbPlacement.publisher,
      logo: dbPlacement.publisher?.logo
        ? convertPlainUrlToN_DataOrigin_Url(
            dbPlacement.publisher.logo as unknown as DB_PLAIN_URL
          )
        : null,
    },
    images: dbPlacement.images.map(convertDbUrlRecourceToNUrlRecource),
    documents: dbPlacement.documents.map(convertDbUrlRecourceToNUrlRecource),
    specifications: dbPlacementSpecsToSpecs(dbPlacement.specifications),
    attributes: convertAttributes(dbPlacement.attributes),
  };
};
