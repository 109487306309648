import { idFlavor, N_Url, WORKSPACE_PERMISSIONS } from "../../common";
import { WorkspaceMemberRole } from "../workspaceMember";

export const workspaceStatus = {
  Active: "Active",
  Removed: "Removed",
} as const;

export type N_WorkspaceStatus =
  (typeof workspaceStatus)[keyof typeof workspaceStatus];

export interface N_WorkspaceView {
  organisationId: idFlavor<"Organisation">;
  workspaceId: idFlavor<"Workspace">;
  name: string;
  logo: N_Url;
  isActive: boolean;
  status: N_WorkspaceStatus;
}

export interface N_WorkspaceSummaryStats {
  numUsers: number;
  activeCampaigns: number;
  totalCampaigns: number;
}

export interface BasicWorkspaceAccessInfo {
  id: idFlavor<"Workspace">;
  name: string;
  logo: N_Url;
  role: WorkspaceMemberRole;
  isPaid: boolean;
  permissions: WORKSPACE_PERMISSIONS[];
}
