import {
  idFlavor,
  N_ContactDetails,
  N_Instant,
  N_LocalDate,
  N_SourceType,
  N_UrlResource,
} from "../../common";
import { N_Attributes, N_Specifications } from "../../adl";
import * as z from "zod";
import { N_Publisher, N_PublisherView } from "../publisher";
import { N_PublicationView } from "../publication";
import { N_Country } from "../country";

export const placementCategory = {
  AUDIO: "AUDIO",
  RADIO: "RADIO",
  BROADCAST: "BROADCAST",
  BVOD: "BVOD",
  CINEMA: "CINEMA",
  DISPLAY: "DISPLAY",
  NATIVE: "NATIVE",
  ONLINE: "ONLINE",
  DOOH: "DOOH",
  PRINT_OOH: "PRINT_OOH",
  OOH_TRANSIT: "OOH_TRANSIT",
  PRINT_MAGAZINE: "PRINT_MAGAZINE",
  PRINT_NEWSPAPER: "PRINT_NEWSPAPER",
  RICHMEDIA: "RICHMEDIA",
  SEM: "SEM",
  SOCIAL: "SOCIAL",
  VIDEO: "VIDEO",
  LEAD_GEN_FORM: "LEAD_GEN_FORM",
  OTHER: "OTHER",
} as const;

export const specSheetTabSpecial = {
  Overview: "Overview",
} as const;

export type SpecSheetTabSpecial =
  (typeof specSheetTabSpecial)[keyof typeof specSheetTabSpecial];

export const specSheetTabSpecialLabel = {
  [specSheetTabSpecial.Overview]: "Overview",
};

export type SpecSheetTab = N_PlacementCategory | SpecSheetTabSpecial;
export const specSheetTab: Record<SpecSheetTab, SpecSheetTab> = {
  ...specSheetTabSpecial,
  ...placementCategory,
} as const;

export const allSpecSheetTabs: SpecSheetTab[] = [
  specSheetTabSpecial.Overview,
  ...Object.values(placementCategory),
];

export const oohCategories: N_PlacementCategory[] = [
  placementCategory.DOOH,
  placementCategory.PRINT_OOH,
  placementCategory.OOH_TRANSIT,
];

export const placementCategoryLabels: Record<N_PlacementCategory, string> = {
  [placementCategory.AUDIO]: "Audio Digital",
  [placementCategory.RADIO]: "Radio",
  [placementCategory.BROADCAST]: "Broadcast",
  [placementCategory.BVOD]: "BVOD",
  [placementCategory.CINEMA]: "Cinema",
  [placementCategory.DISPLAY]: "Display",
  [placementCategory.NATIVE]: "Native",
  [placementCategory.ONLINE]: "Online",
  [placementCategory.DOOH]: "OOH Digital",
  [placementCategory.PRINT_OOH]: "OOH Print",
  [placementCategory.OOH_TRANSIT]: "OOH Transit",
  [placementCategory.PRINT_MAGAZINE]: "Print Magazine",
  [placementCategory.PRINT_NEWSPAPER]: "Print Newspaper",
  [placementCategory.RICHMEDIA]: "Rich Media",
  [placementCategory.SEM]: "SEM",
  [placementCategory.SOCIAL]: "Social",
  [placementCategory.VIDEO]: "Video",
  [placementCategory.LEAD_GEN_FORM]: "Lead Gen Form",
  [placementCategory.OTHER]: "Other",
} as const;

export const campaignTabLabels: Record<SpecSheetTab, string> = {
  ...specSheetTabSpecialLabel,
  ...placementCategoryLabels,
};

export const placementCategoryLabelsExcel: Record<SpecSheetTab, string> = {
  ...specSheetTabSpecialLabel,
  ...placementCategoryLabels,
} as const;

export const placementCategoryOrder: Record<N_PlacementCategory, number> = {
  [placementCategory.AUDIO]: 0,
  [placementCategory.RADIO]: 1,
  [placementCategory.BVOD]: 2,
  [placementCategory.BROADCAST]: 3,
  [placementCategory.CINEMA]: 4,
  [placementCategory.DISPLAY]: 5,
  [placementCategory.NATIVE]: 6,
  [placementCategory.ONLINE]: 7,
  [placementCategory.DOOH]: 8,
  [placementCategory.PRINT_OOH]: 9,
  [placementCategory.OOH_TRANSIT]: 10,
  [placementCategory.PRINT_MAGAZINE]: 11,
  [placementCategory.PRINT_NEWSPAPER]: 12,
  [placementCategory.RICHMEDIA]: 13,
  [placementCategory.SEM]: 14,
  [placementCategory.SOCIAL]: 15,
  [placementCategory.VIDEO]: 16,
  [placementCategory.LEAD_GEN_FORM]: 17,
  [placementCategory.OTHER]: 18,
};

export type N_PlacementCategory =
  (typeof placementCategory)[keyof typeof placementCategory];

export const placementLocationZod = z.object({
  siteNumber: z.string().nullable(),
  direction: z.string().nullable(),
  address: z.string().nullable(),
  suburb: z.string().nullable(),
  postcode: z.string().nullable(),
  state: z.string().nullable(),
  country: z.string().nullable(),
});

export type N_PlacementLocation = z.infer<typeof placementLocationZod>;

export interface N_Device {
  desktop: {} | null;
  tablet: {} | null;
  mobile: {} | null;
  connectedTv: {} | null;
}

export const placementAttributeName = {
  PlacementNotes: "PlacementNotes",
} as const;

export type PlacementAttributeName =
  (typeof placementAttributeName)[keyof typeof placementAttributeName];

export const placementAttributeNames: Record<PlacementAttributeName, string> = {
  [placementAttributeName.PlacementNotes]: "Placement Notes",
} as const;

export const placementStatus = {
  Deprecated: "Deprecated",
  Verified: "Verified",
  PendingVerification: "PendingVerification",
  MediaPlannerAdded: "MediaPlannerAdded",
  LinkToSpec: "LinkToSpec",
  Draft: "Draft",
  TssManaged: "TssManaged",
} as const;

export type PlacementStatus =
  (typeof placementStatus)[keyof typeof placementStatus];

export const placementStatusOrder: Record<PlacementStatus, number> = {
  [placementStatus.Verified]: 0,
  [placementStatus.TssManaged]: 1,
  [placementStatus.LinkToSpec]: 2,
  [placementStatus.MediaPlannerAdded]: 3,
  [placementStatus.PendingVerification]: 4,
  [placementStatus.Draft]: 5,
  [placementStatus.Deprecated]: 6,
};

export const placementStatusLabels: Record<PlacementStatus, string> = {
  [placementStatus.Deprecated]: "Deprecated",
  [placementStatus.PendingVerification]: "Pending Verification",
  [placementStatus.Verified]: "Verified",
  [placementStatus.MediaPlannerAdded]: "Media Planner Added",
  [placementStatus.LinkToSpec]: "Link To Spec",
  [placementStatus.Draft]: "Draft",
  [placementStatus.TssManaged]: "TSS Managed",
} as const;

export interface N_Placement {
  name: string;
  description: string;
  summary: string;
  searchContent: string;
  device: N_Device | null;
  category: N_PlacementCategory;
  publisherId: idFlavor<"Publisher">;
  publisher: N_Publisher;
  originalSpecs: N_UrlResource[];
  images: N_UrlResource[];
  documents: N_UrlResource[];
  location: N_PlacementLocation | null;
  specifications: N_Specifications;
  isDraft: boolean;
  isVerified: boolean;
  isTssManaged: boolean;
  isDeprecated: boolean;
  isLinkToSpec: boolean;
  deprecatedAt: number | null;
  placementCountry?: Array<{
    country: N_Country;
  }>;
  activeStart: N_LocalDate | null;
  activeEnd: N_LocalDate | null;
  sourceType: N_SourceType;
  mediaOwnerContactDetails: N_ContactDetails | null;
  publicSearchable: boolean;
  attributes: N_Attributes;
  version: number;
  authorUserId: idFlavor<"AppUser"> | null;
  campaignId: idFlavor<"Campaign"> | null;
  created: N_Instant;
  updated: N_Instant;
  lastVerified: N_Instant | null;
}

export interface N_PlacementView extends N_Placement {
  id: idFlavor<"Placement">;
}

export interface N_PlacementWithPublisherAndPublications {
  placement: N_PlacementView;
  publisher: N_PublisherView;
  publications: N_PublicationView[];
  countries?: N_Country[];
}

export interface N_PlacementSearchOptionBySearchTerm {
  kind: "bySearchTerm";
  label?: string;
  searchTerm: string;
}

export interface N_PlacementSearchOptionByName {
  kind: "byName";
  name: string;
}

export interface N_PlacementSearchOptionByPublisher {
  kind: "byPublisher";
  name: string;
  publisherId: idFlavor<"Publisher">;
}

export interface N_PlacementSearchOptionByPublication {
  kind: "byPublication";
  name: string;
  publicationId: idFlavor<"Publication">;
}

export interface N_PlacementSearchOptionByCategory {
  kind: "byCategory";
  name: string;
  category: N_PlacementCategory;
}

export interface N_PlacementSearchOptionsByCountry {
  kind: "byCountry";
  name: string;
  code: string;
  placementsCount: number;
  countryId: idFlavor<"Country">;
}

export interface N_PlacementSearchOptions {
  byTermOptions: N_PlacementSearchOptionBySearchTerm[];
  byCategoryOptions: N_PlacementSearchOptionByCategory[];
  byPublicationOptions: N_PlacementSearchOptionByPublication[];
  byPublisherOptions: N_PlacementSearchOptionByPublisher[];
  byCountryOptions: N_PlacementSearchOptionsByCountry[];
}

export type N_PlacementSearchOption =
  | N_PlacementSearchOptionBySearchTerm
  | N_PlacementSearchOptionByName
  | N_PlacementSearchOptionByPublisher
  | N_PlacementSearchOptionByPublication
  | N_PlacementSearchOptionByCategory;

export interface N_PlacementSearchTermSuggestions {
  search_term: string;
  search_position: number;
}
