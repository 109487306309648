import { DateFormat } from "@adltools/dateformats";
import { FieldFns, mkLocalDateFieldFns } from "@adltools/fields";
import * as React from "react";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// tslint:disable-next-line: no-import-side-effect no-submodule-imports
import "react-datepicker/dist/react-datepicker.css";
import { Form, Input, Label, Popup } from "semantic-ui-react";

import { maybeField, nullableField } from "./adl-field";
import * as common from "./adl-gen/common";
import * as systypes from "./adl-gen/sys/types";
import { UpdateFn, VEditor } from "./adl-veditor";

interface LocalDateFieldProps {
  text: string;
  onChange(event: string): void;
  fieldFns: FieldFns<unknown>;
  format: DateFormat;
}

type DatePickerVal =
  | Date
  | [Date | null, Date | null]
  | /* for selectsRange */ null;

class LocalDateField extends React.PureComponent<LocalDateFieldProps> {
  render() {
    const opts = {
      style: { width: this.props.fieldFns.width + "em" },
      error: false,
    };
    const validationError = this.props.fieldFns.validate(this.props.text);
    let errlabel: JSX.Element | null = null;
    if (validationError) {
      opts.error = true;
      errlabel = <Label color="red">{validationError}</Label>;
    }
    const ndate = this.props.format.parse(this.props.text);
    const currentDate =
      ndate === null ? null : new Date(ndate.year, ndate.month - 1, ndate.day);

    return (
      <Form.Field>
        <Popup
          trigger={
            <Input
              type="text"
              value={this.props.text}
              onChange={this.onChange}
              {...opts}
            />
          }
          on="click"
          hideOnScroll
        >
          <DateCalendar
            value={currentDate}
            onChange={(m) => this.onPickerChange(m)}
          />
        </Popup>
        {errlabel}
      </Form.Field>
    );
  }

  onPickerChange = (dv: DatePickerVal) => {
    if (dv === null) {
      this.props.onChange("");
    } else {
      const d = dv as Date;
      this.props.onChange(
        this.props.format.format({
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate(),
        })
      );
    }
  };

  // tslint:disable-next-line: no-any
  onChange = (event: any) => {
    this.props.onChange(event.target.value);
  };
}

interface LocalDateVEditorState {
  content: string;
}

function dateVEditor<T>(
  format: DateFormat,
  fieldfns: FieldFns<T>
): VEditor<T, LocalDateVEditorState, string> {
  const initialState = { content: "" };

  function stateFromValue(value: T): LocalDateVEditorState {
    return { content: fieldfns.toText(value) };
  }

  function validate(state: LocalDateVEditorState): string[] {
    const err = fieldfns.validate(state.content);
    if (err === null) {
      return [];
    } else {
      return [err];
    }
  }

  function valueFromState(state: LocalDateVEditorState): T {
    const err = fieldfns.validate(state.content);
    if (err) {
      throw new Error(err);
    }
    return fieldfns.fromText(state.content);
  }

  function update(
    _state: LocalDateVEditorState,
    event: string
  ): LocalDateVEditorState {
    return { content: event };
  }

  function render(
    state: LocalDateVEditorState,
    canEdit: boolean,
    onUpdate: UpdateFn<string>
  ): JSX.Element {
    if (canEdit) {
      return (
        <LocalDateField
          text={state.content}
          onChange={onUpdate}
          fieldFns={fieldfns}
          format={format}
        />
      );
    } else {
      return <div>{state.content}</div>;
    }
  }

  return {
    initialState,
    stateFromValue,
    validate,
    valueFromState,
    update,
    render,
  };
}

export function localDateVEditor(
  format: DateFormat
): VEditor<common.LocalDate, unknown, unknown> {
  return dateVEditor(format, mkLocalDateFieldFns(format));
}

export function maybeLocalDateVEditor(
  format: DateFormat
): VEditor<systypes.Maybe<common.LocalDate>, unknown, unknown> {
  return dateVEditor(format, maybeField(mkLocalDateFieldFns(format)));
}

export function nullableLocalDateVEditor(
  format: DateFormat
): VEditor<common.LocalDate | null, unknown, unknown> {
  return dateVEditor(format, nullableField(mkLocalDateFieldFns(format)));
}
