import { idFlavor, instantZod } from "../../../common";
import { dictionaryTagColor, fieldType } from "../field";
import * as z from "zod";
import { wysiwygDescendantArrZod } from "../../../elements";

export const infoSchemaBaseFieldValueZod = z.object({
  fieldId: z.string().min(1),
  kind: z.nativeEnum(fieldType),
});

export type InfoSchemaBaseFieldValue = z.infer<
  typeof infoSchemaBaseFieldValueZod
> & {
  fieldId: idFlavor<"InfoSchemaField">;
};

export const infoSchemaTextFieldValueWithoutIdZod = z.object({
  kind: z.literal(fieldType.TEXT),
  value: z.string().or(z.null()).or(z.undefined()),
  characterLimit: z.number().nullish(),
  mixedLimit: z.boolean().optional(),
});

export const infoSchemaDictionaryFieldValueWithoutIdZod = z.object({
  kind: z.literal(fieldType.DICTIONARY),
  value: z
    .array(z.object({ id: z.string(), name: z.string().optional() }))
    .or(z.null())
    .or(z.undefined()),
});

export const infoSchemaDictionaryTemplateValue = z.object({
  id: z.string(),
  name: z.string(),
  color: z.nativeEnum(dictionaryTagColor),
});

export const infoSchemaDictionaryFieldTemplateValueWithoutIdZod = z.object({
  kind: z.literal(fieldType.DICTIONARY),
  value: z
    .array(infoSchemaDictionaryTemplateValue)
    .or(z.null())
    .or(z.undefined()),
});

export type InfoSchemaDictionaryTemplateValue = z.infer<
  typeof infoSchemaDictionaryTemplateValue
>;

export type InfoSchemaTextFieldValueWithoutIdZod = z.infer<
  typeof infoSchemaTextFieldValueWithoutIdZod
>;

export const infoSchemaTextFieldValueZod = infoSchemaBaseFieldValueZod.merge(
  infoSchemaTextFieldValueWithoutIdZod
);

export const infoSchemaDictionaryFieldValueZod =
  infoSchemaBaseFieldValueZod.merge(infoSchemaDictionaryFieldValueWithoutIdZod);

export const infoSchemaDictionaryFieldTemplateValueZod =
  infoSchemaBaseFieldValueZod.merge(
    infoSchemaDictionaryFieldTemplateValueWithoutIdZod
  );

export type InfoSchemaDictionaryFieldValue = z.infer<
  typeof infoSchemaDictionaryFieldValueZod
>;

export type InfoSchemaDictionaryTemplateFieldValue = z.infer<
  typeof infoSchemaDictionaryFieldTemplateValueZod
>;

export type InfoSchemaTextFieldValue = z.infer<
  typeof infoSchemaTextFieldValueZod
>;

export const infoSchemaRichTextFieldValueWithoutIdZod = z.object({
  kind: z.literal(fieldType.RICHTEXT),
  value: wysiwygDescendantArrZod.or(z.null()).or(z.undefined()),
  characterLimit: z.number().nullish(),
  mixedLimit: z.boolean().optional(),
});

export type InfoSchemaRichTextFieldValueWithoutIdZod = z.infer<
  typeof infoSchemaRichTextFieldValueWithoutIdZod
>;

export const infoSchemaRichTextFieldValueZod =
  infoSchemaBaseFieldValueZod.merge(infoSchemaRichTextFieldValueWithoutIdZod);

export type InfoSchemaRichTextFieldValue = z.infer<
  typeof infoSchemaRichTextFieldValueZod
>;

export const infoSchemaNumberFieldValueWithoutIdZod = z.object({
  kind: z.literal(fieldType.NUMBER),
  value: z.number().or(z.null()).or(z.undefined()),
});

export type InfoSchemaNumberFieldValueWithoutIdZod = z.infer<
  typeof infoSchemaNumberFieldValueWithoutIdZod
>;

export const infoSchemaNumberFieldValueZod = infoSchemaBaseFieldValueZod.merge(
  infoSchemaNumberFieldValueWithoutIdZod
);

export type InfoSchemaNumberFieldValue = z.infer<
  typeof infoSchemaNumberFieldValueZod
>;

export const infoSchemaDateFieldValueWithoutIdZod = z.object({
  kind: z.literal(fieldType.DATE),
  value: instantZod.nullish(),
  offsetValue: z.number().nullish(),
  fallbackValue: instantZod.nullish(),
});

export type InfoSchemaDateFieldValueWithoutIdZod = z.infer<
  typeof infoSchemaDateFieldValueWithoutIdZod
>;

export const infoSchemaDateFieldValueZod = infoSchemaBaseFieldValueZod.merge(
  infoSchemaDateFieldValueWithoutIdZod
);

export type InfoSchemaDateFieldValue = z.infer<
  typeof infoSchemaDateFieldValueZod
>;

export const infoSchemaBooleanFieldValueWithoutIdZod = z.object({
  kind: z.literal(fieldType.BOOLEAN),
  value: z.boolean().or(z.null()).or(z.undefined()),
});

export type InfoSchemaBooleanFieldValueWithoutIdZod = z.infer<
  typeof infoSchemaBooleanFieldValueWithoutIdZod
>;

export const infoSchemaBooleanFieldValueZod = infoSchemaBaseFieldValueZod.merge(
  infoSchemaBooleanFieldValueWithoutIdZod
);

export type InfoSchemaBooleanFieldValue = z.infer<
  typeof infoSchemaBooleanFieldValueZod
>;

export const infoSchemaRangeZod = z.object({
  from: instantZod,
  to: instantZod,
});

export type InfoSchemaRangeValue = z.infer<typeof infoSchemaRangeZod>;

export const infoSchemaDateRangeFieldValueWithoutIdZod = z.object({
  kind: z.literal(fieldType.DATERANGE),
  value: infoSchemaRangeZod.or(z.null()).or(z.undefined()),
});

export type InfoSchemaDateRangeFieldValueWithoutIdZod = z.infer<
  typeof infoSchemaDateRangeFieldValueWithoutIdZod
>;

export const infoSchemaDateRangeFieldValueZod =
  infoSchemaBaseFieldValueZod.merge(infoSchemaDateRangeFieldValueWithoutIdZod);

export type InfoSchemaDateRangeFieldValue = z.infer<
  typeof infoSchemaDateRangeFieldValueZod
>;

export const infoSchemaFieldValueZod = z.union([
  infoSchemaTextFieldValueZod,
  infoSchemaRichTextFieldValueZod,
  infoSchemaNumberFieldValueZod,
  infoSchemaBooleanFieldValueZod,
  infoSchemaDateFieldValueZod,
  infoSchemaDateRangeFieldValueZod,
  infoSchemaDictionaryFieldValueZod,
]);

export type N_InfoSchemaFieldValue = z.infer<typeof infoSchemaFieldValueZod>;

export const infoSchemaFieldValueWithoutIdZod = z.union([
  infoSchemaTextFieldValueZod.omit({ fieldId: true }),
  infoSchemaRichTextFieldValueZod.omit({ fieldId: true }),
  infoSchemaNumberFieldValueZod.omit({ fieldId: true }),
  infoSchemaBooleanFieldValueZod.omit({ fieldId: true }),
  infoSchemaDateFieldValueZod.omit({ fieldId: true }),
  infoSchemaDateRangeFieldValueZod.omit({ fieldId: true }),
  infoSchemaDictionaryFieldValueZod.omit({ fieldId: true }),
]);

export type InfoSchemaFieldValueWithoutIdZod = z.infer<
  typeof infoSchemaFieldValueWithoutIdZod
>;

export const infoSchemaFieldValueValuesZod = z.record(
  z.string(),
  infoSchemaFieldValueWithoutIdZod
);

export type InfoSchemaFieldValueValues = Record<
  idFlavor<"InfoSchemaField">,
  z.infer<typeof infoSchemaFieldValueWithoutIdZod>
>;

export interface N_InfoSchemaValue {
  schemaId: idFlavor<"InfoSchema">;
  fieldValues: InfoSchemaFieldValueValues;
  organisationId: idFlavor<"Organisation">;
  authorId: idFlavor<"AppUser">;
  bookingId: idFlavor<"Booking"> | null;
  created: Date;
  updated: Date;
  isActive: boolean;
}

export interface N_InfoSchemaValueView extends N_InfoSchemaValue {
  id: idFlavor<"InfoSchemaValue">;
}
