import {
  ORGANISATION_PERMISSIONS,
  orgMemberRole,
  OrgMemberRole,
  workspaceMemberRole,
} from "@specsheet-common/shared-types";

export function getMaybeElevatedOrganisationRole({
  isOpsManager,
  organisationRole,
}: {
  isOpsManager: boolean;
  organisationRole: OrgMemberRole | null;
}): OrgMemberRole | null {
  if (isOpsManager) {
    return workspaceMemberRole.ADMIN;
  }

  return organisationRole;
}

const getFullOrganisationPermissions = (): ORGANISATION_PERMISSIONS[] => {
  return Object.values(ORGANISATION_PERMISSIONS);
};

export const orgPermissionsMap: Record<
  OrgMemberRole,
  ORGANISATION_PERMISSIONS[]
> = {
  [orgMemberRole.ADMIN]: [...getFullOrganisationPermissions()],
  [orgMemberRole.BASIC]: [
    ORGANISATION_PERMISSIONS.ORG_MEMBER_LIST_VIEW,
    ORGANISATION_PERMISSIONS.ORG_MEMBER_VIEW,
    ORGANISATION_PERMISSIONS.WORKSPACE_LIST_VIEW,
  ],
  [orgMemberRole.GUEST]: [],
};
