import { N_WysiwygDescendant } from "../../elements";
import {
  CampaignSharedStructuredNotification,
  ExtStructuredNotificationDb,
  LegacyExtStructuredNotificationDb,
} from "./structuredNotification";
import { idFlavor } from "../../common";

//Interfaces marked as View - are for the FE, these are not completelly the same.

export const notificationCategory = {
  ANNOUNCEMENT: "ANNOUNCEMENT",
  TEAM_MEMBER_ADDED: "TEAM_MEMBER_ADDED",
  TEAM_MEMBER_REMOVED: "TEAM_MEMBER_REMOVED",
  CAMPAIGN_SHARED: "CAMPAIGN_SHARED",
  EXTERNAL: "EXTERNAL",
  EXTERNAL_NEW: "EXTERNAL_NEW",
} as const;

export type NotificationCategory =
  (typeof notificationCategory)[keyof typeof notificationCategory];

export interface ExternalNotificationData {
  category: typeof notificationCategory.EXTERNAL;
  structuredNotification: LegacyExtStructuredNotificationDb;
  campaignId: idFlavor<"Campaign">;
}

export interface ExternalNewNotificationData {
  category: typeof notificationCategory.EXTERNAL_NEW;
  structuredNotification: ExtStructuredNotificationDb;
  campaignId: idFlavor<"Campaign">;
}

export interface ExternalNotificationViewData {
  category: typeof notificationCategory.EXTERNAL;
  structuredNotification: LegacyExtStructuredNotificationDb;
  campaignId: idFlavor<"Campaign">;
  campaignTitle: string;
  workspaceId: idFlavor<"Workspace">;
  workspaceName: string;
  organisationName: string;
}

export interface ExternalNewNotificationViewData {
  category: typeof notificationCategory.EXTERNAL_NEW;
  mediaOwnerName: string;
  addedBookings: string[];
  removedBookings: string[];
  updatedBookings: string[];
  updatedExtCampaigns: string[];
  campaignId: idFlavor<"Campaign">;
  campaignTitle: string;
  workspaceId: idFlavor<"Workspace">;
  workspaceName: string;
  organisationName: string;
}

export interface AnnouncementNotificationData {
  category: typeof notificationCategory.ANNOUNCEMENT;
  campaignId?: idFlavor<"Campaign">;
  organisationId?: idFlavor<"Organisation">;
  userId?: idFlavor<"AppUser">;
  subject: string;
  messageText?: string;
  formattedMessage?: N_WysiwygDescendant[];
  workspaceId?: idFlavor<"Workspace">;
  initiatingUserId?: idFlavor<"AppUser">;
}

export interface AnnouncementNotificationViewData {
  category: typeof notificationCategory.ANNOUNCEMENT;
  campaignId?: idFlavor<"Campaign">;
  organisationId?: idFlavor<"Organisation">;
  userId?: idFlavor<"AppUser">;
  subject: string;
  messageText?: string;
  formattedMessage?: N_WysiwygDescendant[];
  workspaceId?: idFlavor<"Workspace">;
  initiatingUserId?: idFlavor<"AppUser">;
}

export interface TeamMemberAddedRemovedNotificationData {
  category:
    | typeof notificationCategory.TEAM_MEMBER_ADDED
    | typeof notificationCategory.TEAM_MEMBER_REMOVED;
  campaignId: idFlavor<"Campaign">;
  userId: idFlavor<"AppUser">;
  initiatingUserId: idFlavor<"AppUser">;
}

export interface TeamMemberAddedRemovedNotificationViewData {
  category:
    | typeof notificationCategory.TEAM_MEMBER_ADDED
    | typeof notificationCategory.TEAM_MEMBER_REMOVED;
  campaignId: idFlavor<"Campaign">;
  userId: idFlavor<"AppUser">;
  initiatingUserId: idFlavor<"AppUser">;
  campaignTitle: string;
  addedRemovedBy: string;
  workspaceId: idFlavor<"Workspace">;
  workspaceName: string;
  organisationName: string;
}

export interface CampaignSharedNotificationData {
  category: typeof notificationCategory.CAMPAIGN_SHARED;
  campaignId: idFlavor<"Campaign">;
  structuredNotification: CampaignSharedStructuredNotification;
  initiatingUserId: idFlavor<"AppUser">;
}

export interface CampaignSharedNotificationViewData {
  category: typeof notificationCategory.CAMPAIGN_SHARED;
  campaignId: idFlavor<"Campaign">;
  structuredNotification: CampaignSharedStructuredNotification;
  sharedByName: string;
  campaignTitle: string;
  workspaceId: idFlavor<"Workspace">;
  workspaceName: string;
  organisationName: string;
}

export type NotificationByCategoryData =
  | ExternalNotificationData
  | AnnouncementNotificationData
  | CampaignSharedNotificationData
  | TeamMemberAddedRemovedNotificationData
  | ExternalNewNotificationData;

export type NotificationByCategoryViewData =
  | ExternalNotificationViewData
  | AnnouncementNotificationViewData
  | CampaignSharedNotificationViewData
  | TeamMemberAddedRemovedNotificationViewData
  | ExternalNewNotificationViewData;

export interface N_NotificationView {
  notificationId: idFlavor<"Notification">;
  isRead: boolean;
  created: Date;
  byCategoryData: NotificationByCategoryViewData;
}
