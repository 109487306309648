import {
  N_Placement,
  PlacementStatus,
  placementStatus,
  sourceType,
} from "@specsheet-common/shared-types";

export const getPlacementStatus = (
  placement: Omit<N_Placement, "attributes" | "publisher">
): PlacementStatus => {
  const isCustom = placement.sourceType === sourceType.custom;
  const isDepricated = placement.isDeprecated;
  const isTssManaged = placement.isTssManaged;
  const isVerified = placement.isVerified;
  const isLink = placement.isLinkToSpec;

  if (isVerified) {
    return placementStatus.Verified;
  }

  if (isDepricated) {
    return placementStatus.Deprecated;
  }

  if (isLink) {
    return placementStatus.LinkToSpec;
  }

  if (isTssManaged) {
    return placementStatus.PendingVerification;
  }

  if (isCustom) {
    return placementStatus.MediaPlannerAdded;
  }

  return placementStatus.PendingVerification;
};
