import { z } from "zod";
import { idFlavor } from "../../common";

export const campaignMetaLogRecordType = {
  UNKNOWN: "UNKNOWN",
  CAMPAIGN: "CAMPAIGN",
  BOOKINGISCONFIG: "BOOKINGISCONFIG",
  CAMPAIGNVERSION: "CAMPAIGNVERSION",
  BOOKING: "BOOKING",
  BOOKINGGROUP: "BOOKINGGROUP",
  PLACEMENT: "PLACEMENT",
  COLUMN: "COLUMN",
  EXTCONTRACT: "EXTCONTRACT",
  COLLABORATOR: "COLLABORATOR",
  DOCUMENT: "DOCUMENT",
} as const;

export const campaignMetaLogIntent = {
  UNKNOWN: "UNKNOWN",
  CREATE: "CREATE",
  AMEND: "AMEND",
  ARCHIVE: "ARCHIVE",
  UNARCHIVE: "UNARCHIVE",
  ACTIVATE: "ACTIVATE",
  MOVE: "MOVE",
  DUPLICATE: "DUPLICATE",
  DELETE: "DELETE",
  GROUP: "GROUP",
  UNGROUP: "UNGROUP",
  SHARE: "SHARE",
  REVERT: "REVERT",
  DOWNLOAD: "DOWNLOAD",
} as const;

export const campaignMetaLogMetaIntent = {
  VARIATION: "VARIATION",
} as const;

/* Following two types are for front-end rendering */
export const intentToLabelsMap: Record<CampaignMetaLogIntent, string> = {
  UNKNOWN: "Other Change",
  CREATE: "Added",
  AMEND: "Amended",
  ARCHIVE: "Archived",
  UNARCHIVE: "Unarchived",
  ACTIVATE: "Activated",
  MOVE: "Moved",
  DUPLICATE: "Duplicated",
  DELETE: "Deleted",
  GROUP: "Grouped",
  UNGROUP: "Ungrouped",
  SHARE: "Version",
  REVERT: "Reverted",
  DOWNLOAD: "Downloaded",
} as const;

export const intentToColoursMap: Record<CampaignMetaLogIntent, string> = {
  UNKNOWN: "gray",
  CREATE: "green",
  AMEND: "blue",
  ARCHIVE: "purple",
  UNARCHIVE: "purple",
  ACTIVATE: "purple",
  MOVE: "blue",
  DUPLICATE: "green",
  DELETE: "error",
  GROUP: "blue",
  UNGROUP: "error",
  SHARE: "blue",
  REVERT: "yellow",
  DOWNLOAD: "green",
} as const;

export type CampaignMetaLogRecordType =
  (typeof campaignMetaLogRecordType)[keyof typeof campaignMetaLogRecordType];

export type CampaignMetaLogIntent =
  (typeof campaignMetaLogIntent)[keyof typeof campaignMetaLogIntent];

export type CampaignMetaLogMetaIntent =
  (typeof campaignMetaLogMetaIntent)[keyof typeof campaignMetaLogMetaIntent];

// TODO: use these schemas on WRITE to ensure data is being wirrten into meta_data column
// correctly
export const duplicateMetaData = z.object({
  type: z.literal("CAMPAIGN"),
  intent: z.literal("DUPLICATE"),
  originalCampaignId: z.string(),
});

export const revertMetaData = z.object({
  type: z.literal("CAMPAIGN"),
  intent: z.literal("REVERT"),
  revertedToTimestamp: z.date(),
});

type CampaignMetaLogMetaData_Revert = z.infer<typeof revertMetaData>;

type CampaingMetaLogMetaData_CampaignDuplicate = z.infer<
  typeof duplicateMetaData
>;

export type CampaignMetaLogMetaData =
  | CampaingMetaLogMetaData_CampaignDuplicate
  | CampaignMetaLogMetaData_Revert;

export interface CampaignMetaLog {
  id: idFlavor<"CampaignMetaLog">;
}
