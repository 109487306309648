import z from "zod";

export const qmsStaticPanelProperties = {
  optionDetails: "optionDetails",
  clientName: "clientName",
  quantity: "quantity",
  size: "size",
  state: "state",
  panel: "panel",
  panelAddress: "panelAddress",
  panelGradeName: "panelGradeName",
  startDate: "startDate",
  fixtureType: "fixtureType",
  illuminationType: "illuminationType",
  visualWidth: "visualWidth",
  visualHeight: "visualHeight",
  bleedWidth: "bleedWidth",
  bleedHeight: "bleedHeight",
  bleedTop: "bleedTop",
  bleedBottom: "bleedBottom",
  bleedLeft: "bleedLeft",
  bleedRight: "bleedRight",
  physicalWidth: "physicalWidth",
  physicalHeight: "physicalHeight",
  productionComments: "productionComments",
  productionSpecs1: "productionSpecs1",
  panelCensorships: "panelCensorships",
  panelProhibitions: "panelProhibitions",
  installCompanyName: "installCompanyName",
  panelDirection: "panelDirection",
  dimensions: "dimensions",
  illuminated: "illuminated",
};

export const qmsStaticPanelSchema = z.object({
  optionDetails: z.string(),
  clientName: z.string(),
  quantity: z.string(),
  size: z.string(),
  state: z.string(),
  panel: z.string(),
  panelAddress: z.string(),
  panelGradeName: z.string(),
  startDate: z.string(),
  fixtureType: z.string(),
  illuminationType: z.string(),
  visualWidth: z.string(),
  visualHeight: z.string(),
  bleedWidth: z.string(),
  bleedHeight: z.string(),
  bleedTop: z.string(),
  bleedBottom: z.string(),
  bleedLeft: z.string(),
  bleedRight: z.string(),
  physicalWidth: z.string(),
  physicalHeight: z.string(),
  productionComments: z.string(),
  productionSpecs1: z.string(),
  panelCensorships: z.string(),
  panelProhibitions: z.string(),
  installCompanyName: z.string(),
  panelDirection: z.string(),
  dimensions: z.string(),
  illuminated: z.string(),
});

export type QmsStaticPanel = z.infer<typeof qmsStaticPanelSchema>;
