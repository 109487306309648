import {
  N_ArtworkSpec,
  N_NamedAnnotated,
  N_PrintResolutionUnit,
} from "@specsheet-common/shared-types";

const getDigitalResolutionUnit = (unit: N_PrintResolutionUnit): string => {
  switch (unit.kind) {
    case "DPI":
      return "DPI";
    case "LPI":
      return "LPI";
    case "other":
      return unit.value;
  }
};

export const getResolutions = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>(
    (resolutions, spec) =>
      (spec.value.kind === "printOOH" ||
        spec.value.kind === "magazine" ||
        spec.value.kind === "newspaper") &&
      spec.value.value.resolution !== null
        ? [
            ...resolutions,
            {
              ...spec,
              value: `${
                spec.value.value.resolution.value.amount
              } ${getDigitalResolutionUnit(
                spec.value.value.resolution.value.unit
              )}`,
            },
          ]
        : resolutions,
    []
  );
