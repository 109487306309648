import * as z from "zod";
import { oohExtBookingData, oohPanelKinds } from "./general";

const stringChange = z.object({ before: z.string(), after: z.string() });

export const oohExtBookingChangeData = z.union([
  z.object({
    panelKindChanged: z.literal(true),
    change: z.object({ before: oohExtBookingData, after: oohExtBookingData }),
  }),
  z.object({
    panelKindChanged: z.literal(false),
    data: z.union([
      z.object({
        kind: z.literal(oohPanelKinds.DIGITAL),
        change: z.record(z.nativeEnum({}), stringChange),
      }),
      z.object({
        kind: z.literal(oohPanelKinds.CLASSIC),
        change: z.record(z.nativeEnum({}), stringChange),
      }),
      z.object({}),
    ]),
  }),
]);

export type OohExtBookingChangeData = z.infer<typeof oohExtBookingChangeData>;
