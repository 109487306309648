import {
  DB_Placement,
  DB_UrlResource,
  N_PlacementView,
  N_UrlResource,
} from "@specsheet-common/shared-types";
import { dbPlacementToPlacement } from "./placementSpecsConverter";

export const convertDbPlacementToPlacementView = (
  data: DB_Placement
): N_PlacementView => {
  return {
    ...dbPlacementToPlacement(data),
    id: data.id,
  };
};

export const convertNUrlResourceToDbUrlResource = (
  data: N_UrlResource
): DB_UrlResource => {
  return {
    name: data.name,
    notes: data.notes,
    fileSize: data.fileSize,
    data: { url: data.data.value },
  };
};
