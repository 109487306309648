import MuiDialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

export const DialogTitleStyled = (
  props: DialogTitleProps & {
    onClose?(): void;
    title: string;
    subTitle?: string;
    subPosition?: "below" | "above";
  }
) => {
  const { title, subTitle, onClose, subPosition, ...rest } = props;

  return (
    <MuiDialogTitle {...rest}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        color={(theme) => theme.colors.colorCharcoal}
      >
        <Stack maxWidth={"95%"} spacing={1}>
          {subTitle && (!subPosition || subPosition === "above") && (
            <Typography variant="small" noWrap>
              {subTitle}
            </Typography>
          )}
          <Tooltip title={title}>
            <Typography
              variant="base"
              sx={{ fontSize: 20 }}
              noWrap
              gutterBottom={false}
            >
              {title}
            </Typography>
          </Tooltip>
          {subTitle && subPosition === "below" && (
            <Typography variant="small" noWrap>
              {subTitle}
            </Typography>
          )}
        </Stack>
        {onClose && (
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Stack>
    </MuiDialogTitle>
  );
};
