import {auth0SubRoute} from "../auth0";

export const authSubRoute = {
  login: "login",
  startOauth: "oauth",
  handleRedirect: "redirect",
  postAuth0Login: "post-auth0-login",
  whoAmI: "whoami",
  activateUser: "activate-user"
} as const;

export type AuthRoutes =
  | {
      sub: typeof authSubRoute.login;
      method?: "Post";
    }
  | {
      sub: typeof authSubRoute.startOauth;
      method?: "Post";
    }
  | {
      sub: typeof authSubRoute.handleRedirect;
      method?: "Post";
    }
  | {
      sub: typeof authSubRoute.postAuth0Login;
      method?: "Post";
    }
  | {
    sub: typeof authSubRoute.activateUser;
    method?: "Post";
  }
  | {
      sub: typeof authSubRoute.whoAmI;
      method?: "Get";
    };
