import * as z from "zod";
import {
  billboardJCDProperties,
  digitalJCDProperties,
  furnitureJCDProperties,
  jcdExtBookingData,
  jcdPanelKinds,
  transitGeneralJCDProperties,
  transitPanelJCDProperties,
} from "./general";

const stringChange = z.object({ before: z.string(), after: z.string() });

export const jcdExtBookingChangeData = z.union([
  z.object({
    panelKindChanged: z.literal(true),
    change: z.object({ before: jcdExtBookingData, after: jcdExtBookingData }),
  }),
  z.object({
    panelKindChanged: z.literal(false),
    data: z.union([
      z.object({
        kind: z.literal(jcdPanelKinds.BILLBOARDS),
        change: z.record(z.nativeEnum(billboardJCDProperties), stringChange),
      }),
      z.object({
        kind: z.literal(jcdPanelKinds.FURNITURE),
        change: z.record(z.nativeEnum(furnitureJCDProperties), stringChange),
      }),
      z.object({
        kind: z.literal(jcdPanelKinds.DIGITAL),
        change: z.record(z.nativeEnum(digitalJCDProperties), stringChange),
      }),
      z.object({
        kind: z.literal(jcdPanelKinds.TRANSIT_GENERAL),
        change: z.record(
          z.nativeEnum(transitGeneralJCDProperties),
          stringChange
        ),
      }),
      z.object({
        kind: z.literal(jcdPanelKinds.TRANSIT_PANEL),
        change: z.record(z.nativeEnum(transitPanelJCDProperties), stringChange),
      }),
      z.object({}),
    ]),
  }),
]);

export type JcdExtBookingChangeData = z.infer<typeof jcdExtBookingChangeData>;
