import React from "react";
import { Navigate } from "react-router-dom";
import { adminRoutePaths } from "@specsheet-common/shared-types";
import { useAuth } from "../../../hooks/contexts/useAuth";

export const AuthGuard: React.FC = ({ children }) => {
  const { isAuthenticated, isOpsManager } = useAuth();

  if (!isAuthenticated || !isOpsManager) {
    return <Navigate to={adminRoutePaths.login} />;
  }

  return <>{children}</>;
};
