/* @generated from adl */
import { declResolver, ScopedDecl } from "./runtime/adl";
import { _AST_MAP as adlc_config_typescript } from "./adlc/config/typescript";
import { _AST_MAP as common } from "./common";
import { _AST_MAP as common_adminui_api } from "./common/adminui/api";
import { _AST_MAP as common_adminui_db } from "./common/adminui/db";
import { _AST_MAP as common_config_aws } from "./common/config/aws";
import { _AST_MAP as common_config_db } from "./common/config/db";
import { _AST_MAP as common_config_emailer } from "./common/config/emailer";
import { _AST_MAP as common_config_frontend } from "./common/config/frontend";
import { _AST_MAP as common_config_google } from "./common/config/google";
import { _AST_MAP as common_config_log } from "./common/config/log";
import { _AST_MAP as common_db } from "./common/db";
import { _AST_MAP as common_http } from "./common/http";
import { _AST_MAP as common_strings } from "./common/strings";
import { _AST_MAP as common_tabular } from "./common/tabular";
import { _AST_MAP as common_ui } from "./common/ui";
import { _AST_MAP as kachemedia_specsheet_annotations } from "./kachemedia/specsheet/annotations";
import { _AST_MAP as kachemedia_specsheet_artworkspec } from "./kachemedia/specsheet/artworkspec";
import { _AST_MAP as kachemedia_specsheet_auth } from "./kachemedia/specsheet/auth";
import { _AST_MAP as kachemedia_specsheet_campaigns } from "./kachemedia/specsheet/campaigns";
import { _AST_MAP as kachemedia_specsheet_clients } from "./kachemedia/specsheet/clients";
import { _AST_MAP as kachemedia_specsheet_common } from "./kachemedia/specsheet/common";
import { _AST_MAP as kachemedia_specsheet_db } from "./kachemedia/specsheet/db";
import { _AST_MAP as kachemedia_specsheet_eventlog } from "./kachemedia/specsheet/eventlog";
import { _AST_MAP as kachemedia_specsheet_export } from "./kachemedia/specsheet/export";
import { _AST_MAP as kachemedia_specsheet_notifications } from "./kachemedia/specsheet/notifications";
import { _AST_MAP as kachemedia_specsheet_ooh } from "./kachemedia/specsheet/ooh";
import { _AST_MAP as kachemedia_specsheet_orgworkspace } from "./kachemedia/specsheet/orgworkspace";
import { _AST_MAP as kachemedia_specsheet_permissions } from "./kachemedia/specsheet/permissions";
import { _AST_MAP as kachemedia_specsheet_qms } from "./kachemedia/specsheet/qms";
import { _AST_MAP as kachemedia_specsheet_requests } from "./kachemedia/specsheet/requests";
import { _AST_MAP as kachemedia_specsheet_uiconfig } from "./kachemedia/specsheet/uiconfig";
import { _AST_MAP as kachemedia_specsheet_users } from "./kachemedia/specsheet/users";
import { _AST_MAP as kachemedia_specsheet_utils } from "./kachemedia/specsheet/utils";
import { _AST_MAP as sys_adlast } from "./sys/adlast";
import { _AST_MAP as sys_annotations } from "./sys/annotations";
import { _AST_MAP as sys_dynamic } from "./sys/dynamic";
import { _AST_MAP as sys_types } from "./sys/types";

export const ADL: { [key: string]: ScopedDecl } = {
  ...adlc_config_typescript,
  ...common,
  ...common_adminui_api,
  ...common_adminui_db,
  ...common_config_aws,
  ...common_config_db,
  ...common_config_emailer,
  ...common_config_frontend,
  ...common_config_google,
  ...common_config_log,
  ...common_db,
  ...common_http,
  ...common_strings,
  ...common_tabular,
  ...common_ui,
  ...kachemedia_specsheet_annotations,
  ...kachemedia_specsheet_artworkspec,
  ...kachemedia_specsheet_auth,
  ...kachemedia_specsheet_campaigns,
  ...kachemedia_specsheet_clients,
  ...kachemedia_specsheet_common,
  ...kachemedia_specsheet_db,
  ...kachemedia_specsheet_eventlog,
  ...kachemedia_specsheet_export,
  ...kachemedia_specsheet_notifications,
  ...kachemedia_specsheet_ooh,
  ...kachemedia_specsheet_orgworkspace,
  ...kachemedia_specsheet_permissions,
  ...kachemedia_specsheet_qms,
  ...kachemedia_specsheet_requests,
  ...kachemedia_specsheet_uiconfig,
  ...kachemedia_specsheet_users,
  ...kachemedia_specsheet_utils,
  ...sys_adlast,
  ...sys_annotations,
  ...sys_dynamic,
  ...sys_types,
};

export const RESOLVER = declResolver(ADL);
