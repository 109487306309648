import { idFlavor, N_Url, ORGANISATION_PERMISSIONS } from "../../common";
import * as z from "zod";
import { OrgMemberRole, OrgMemberStatus } from "../orgMember";

export interface N_OrganisationView {
  organisationId: idFlavor<"Organisation">;
  name: string;
  logo: N_Url;
  config: OrganisationConfig | null;
}

export const organisationConfigZod = z.object({
  hideJobId: z.boolean(),
});

export type OrganisationConfig = z.infer<typeof organisationConfigZod>;

export interface BasicOrgAccessInfo {
  id: idFlavor<"Organisation">;
  name: string;
  logo: N_Url;
  config: OrganisationConfig | null;
  role: OrgMemberRole;
  status: OrgMemberStatus;
  permissions: ORGANISATION_PERMISSIONS[];
  orgMemberId: idFlavor<"OrganisationMember">;
  hidden: boolean;
}
