import {
  InfoSchemaFieldValueValues,
  InfoSchemaFieldValueWithoutIdZod,
  infoSchemaFieldValueWithoutIdZod,
  infoSchemaTableViewColumnType as columnType,
  InfoSchemaTableViewColumnType,
  InfoSchemaFieldView,
  N_WysiwygDescendant,
  InfoSchemaOptions,
} from "@specsheet-common/shared-types";
import { convertInfoSchemaTableViewToFieldTypes } from "./convertInfoSchemaTableViewToFieldTypes";
import {
  DEFAULT_DATE_FORMAT,
  formatDateAsDDMMYYY,
  formatDateAsMMDDYYYY,
  formatInstant,
  templatedStringForDurationCell,
} from "../dateHelpers";
import { notNull, notNullish } from "../typescript";

const formatLink = (link: string): string => {
  const [, label, url] = link.match(/<(.*?)> (.*)/) || ["", ""];
  const trimmedUrl = url?.trim();
  const formattedUrl =
    trimmedUrl && trimmedUrl !== "https://" ? `(${trimmedUrl ?? ""})` : "";
  return `${label}${
    label !== "" && formattedUrl !== "" ? " " : ""
  }${formattedUrl}`;
};

const formatBool = (bool?: boolean | null): string => {
  return bool ? "True" : "False";
};

const textToRichText = (value?: string | null): N_WysiwygDescendant[] | null =>
  value
    ? [
        {
          kind: "paragraph",
          value: [{ text: value, bold: null, italic: null, underline: null }],
        },
      ]
    : null;

const convertWysiwygDescendantToRichTextElement = (
  data: N_WysiwygDescendant
): string | null => {
  switch (data.kind) {
    case "paragraph": {
      return data.value
        .map((value) => value.text)
        .filter(notNull)
        .join(" ");
    }
    case "numberedList": {
      return data.value
        .map((el, index) => {
          return [`${index + 1}.`, ...el.value.map((value) => value.text)].join(
            " "
          );
        })
        .join(", ");
    }
    case "bulletedList": {
      return data.value
        .map((el) => {
          return [`*.`, ...el.value.map((value) => value.text)].join(" ");
        })
        .join(", ");
    }
  }
};

const textArrayToRichText = (
  values?: string[] | null
): N_WysiwygDescendant[] | null =>
  (values?.length ?? 0) > 0
    ? values!.map((value) => ({
        kind: "paragraph",
        value: [
          {
            text: value,
            bold: null,
            italic: null,
            underline: null,
          },
        ],
      }))
    : null;

const textToDictionary = (
  value?: string | null,
  options?: InfoSchemaOptions
): Array<{ id: string }> | null => {
  if (notNullish(value) && options?.values) {
    const matchedValue = Object.entries(options.values).find(
      ([_, entry]) => value === entry.name
    );

    if (matchedValue) {
      return [{ id: matchedValue[0] }];
    }
  }
  return null;
};

const defaultHandling: Record<
  InfoSchemaTableViewColumnType,
  | ((
      value?: InfoSchemaFieldValueWithoutIdZod,
      options?: InfoSchemaFieldView["options"]
    ) => InfoSchemaFieldValueValues[""]["value"])
  | null
> = {
  [columnType.number]: null,
  [columnType.date]: null,
  [columnType.text]: null,
  [columnType.richText]: null,
  [columnType.boolean]: null,
  [columnType.dateRange]: null,
  [columnType.durationRange]: null,
  [columnType.link]: null,
  [columnType.duration]: null,
  [columnType.singleSelectTag]: null,
  [columnType.multiSelectTag]: null,
};

const mapping: Record<
  InfoSchemaTableViewColumnType,
  Record<
    InfoSchemaTableViewColumnType,
    | ((
        value?: InfoSchemaFieldValueWithoutIdZod,
        options?: InfoSchemaOptions
      ) => InfoSchemaFieldValueValues[""]["value"])
    | null
  >
> = {
  [columnType.text]: {
    ...defaultHandling,
    [columnType.richText]: (value) =>
      value?.kind === "TEXT" && notNullish(value.value)
        ? textToRichText(value.value)
        : null,
    [columnType.link]: (value) =>
      value?.kind === "TEXT" && notNullish(value.value)
        ? `<${value.value}> `
        : null,
    [columnType.singleSelectTag]: (value, options) =>
      value?.kind === "TEXT" ? textToDictionary(value.value, options) : null,
    [columnType.multiSelectTag]: (value, options) =>
      value?.kind === "TEXT" ? textToDictionary(value.value, options) : null,
  },
  [columnType.number]: {
    ...defaultHandling,
    [columnType.text]: (value) =>
      value?.kind === "NUMBER" ? value.value?.toString() : null,
    [columnType.richText]: (value) =>
      value?.kind === "NUMBER" ? textToRichText(value.value?.toString()) : null,
    [columnType.link]: (value) =>
      value?.kind === "NUMBER" && value.value
        ? `<${value.value.toString()}> `
        : null,
    [columnType.singleSelectTag]: (value, options) =>
      value?.kind === "NUMBER"
        ? textToDictionary(value.value?.toString(), options)
        : null,
    [columnType.multiSelectTag]: (value, options) =>
      value?.kind === "NUMBER"
        ? textToDictionary(value.value?.toString(), options)
        : null,
  },
  [columnType.date]: {
    ...defaultHandling,
    [columnType.text]: (value) =>
      value?.kind === "DATE" && value.value
        ? formatInstant(value.value, DEFAULT_DATE_FORMAT)
        : null,
    [columnType.richText]: (value) =>
      value?.kind === "DATE" && value.value
        ? textToRichText(formatInstant(value.value, DEFAULT_DATE_FORMAT))
        : null,
    [columnType.singleSelectTag]: (value, options) =>
      value?.kind === "DATE" && value.value
        ? textToDictionary(
            formatInstant(value.value, DEFAULT_DATE_FORMAT),
            options
          )
        : null,
    [columnType.multiSelectTag]: (value, options) =>
      value?.kind === "DATE" && value.value
        ? textToDictionary(
            formatInstant(value.value, DEFAULT_DATE_FORMAT),
            options
          )
        : null,
  },
  [columnType.link]: {
    ...defaultHandling,
    [columnType.text]: (value) =>
      value?.kind === "TEXT" && notNullish(value.value)
        ? formatLink(value.value)
        : null,
    [columnType.richText]: (value) =>
      value?.kind === "TEXT" && notNullish(value.value)
        ? textToRichText(formatLink(value.value))
        : null,
    [columnType.singleSelectTag]: (value, options) =>
      value?.kind === "TEXT" && notNullish(value.value)
        ? textToDictionary(formatLink(value.value), options)
        : null,
    [columnType.multiSelectTag]: (value, options) =>
      value?.kind === "TEXT" && notNullish(value.value)
        ? textToDictionary(formatLink(value.value), options)
        : null,
  },
  [columnType.boolean]: {
    ...defaultHandling,
    [columnType.text]: (value) =>
      value?.kind === "BOOLEAN" ? formatBool(value.value) : null,
    [columnType.richText]: (value) =>
      value?.kind === "BOOLEAN"
        ? textToRichText(formatBool(value.value))
        : null,
    [columnType.number]: (value) =>
      value?.kind === "BOOLEAN" ? +(value.value ?? 0) : null,
    [columnType.singleSelectTag]: (value, options) =>
      value?.kind === "BOOLEAN"
        ? textToDictionary(formatBool(value.value), options)
        : null,
    [columnType.multiSelectTag]: (value, options) =>
      value?.kind === "BOOLEAN"
        ? textToDictionary(formatBool(value.value), options)
        : null,
  },
  [columnType.duration]: {
    ...defaultHandling,
    [columnType.text]: (value) =>
      value?.kind === "DATE" && notNullish(value.value)
        ? templatedStringForDurationCell(value.value)
        : null,
    [columnType.richText]: (value) =>
      value?.kind === "DATE" && notNullish(value.value)
        ? textToRichText(templatedStringForDurationCell(value.value))
        : null,
    [columnType.singleSelectTag]: (value, options) =>
      value?.kind === "DATE" && notNullish(value.value)
        ? textToDictionary(templatedStringForDurationCell(value.value), options)
        : null,
    [columnType.multiSelectTag]: (value, options) =>
      value?.kind === "DATE" && notNullish(value.value)
        ? textToDictionary(templatedStringForDurationCell(value.value), options)
        : null,
  },
  [columnType.singleSelectTag]: {
    ...defaultHandling,
    [columnType.text]: (value, options) => {
      if (value?.kind === "DICTIONARY" && options?.values) {
        const firstId = value.value?.[0]?.id;
        return firstId ? options?.values[firstId]?.name : null;
      }
      return null;
    },
    [columnType.richText]: (value, options) => {
      if (value?.kind === "DICTIONARY" && options?.values) {
        const firstId = value.value?.[0]?.id;
        return firstId ? textToRichText(options?.values[firstId]?.name) : null;
      }
      return null;
    },
    [columnType.link]: (value, options) => {
      if (value?.kind === "DICTIONARY" && options?.values) {
        const firstId = value.value?.[0]?.id;
        const parsedValue = options?.values[firstId ?? ""]?.name;
        return parsedValue ? `<${parsedValue}> ` : null;
      }
      return null;
    },
    [columnType.multiSelectTag]: (value, options) =>
      value?.kind === "DICTIONARY" ? value.value : null,
  },
  [columnType.multiSelectTag]: {
    ...defaultHandling,
    [columnType.text]: (value, options) => {
      if (value?.kind === "DICTIONARY" && options?.values) {
        return value.value
          ?.flatMap((value) => {
            const name = options?.values?.[value.id ?? ""]?.name;
            return name ? [name] : [];
          })
          .join(", ");
      }
      return null;
    },
    [columnType.richText]: (value, options) => {
      if (value?.kind === "DICTIONARY" && options?.values) {
        return textArrayToRichText(
          value.value?.flatMap((value) => {
            const name = options?.values?.[value.id ?? ""]?.name;
            return name ? [name] : [];
          })
        );
      }
      return null;
    },
    [columnType.singleSelectTag]: (value) => {
      if (value?.kind === "DICTIONARY" && notNullish(value.value)) {
        const firstValue = value.value.sort((left, right) =>
          String(left.name).localeCompare(String(right.name))
        )[0];

        return firstValue ? [firstValue] : null;
      }
      return null;
    },
  },
  [columnType.richText]: {
    ...defaultHandling,
    [columnType.text]: (value) => {
      if (value?.kind === "RICHTEXT") {
        return value.value
          ?.map(convertWysiwygDescendantToRichTextElement)
          .filter(notNull)
          .join(" ");
      }

      return null;
    },
  },
  [columnType.durationRange]: defaultHandling,
  [columnType.dateRange]: defaultHandling,
};

export const convertInfoSchemaColumnType = (
  oldColumnType: InfoSchemaTableViewColumnType,
  newColumnType: InfoSchemaTableViewColumnType,
  value: unknown,
  options: InfoSchemaOptions
): InfoSchemaFieldValueValues[""]["value"] => {
  const parsedValue = infoSchemaFieldValueWithoutIdZod.safeParse({
    kind: convertInfoSchemaTableViewToFieldTypes(oldColumnType),
    value,
  });
  return mapping[oldColumnType][newColumnType]?.(
    parsedValue.success ? parsedValue.data : undefined,
    options
  );
};

export const isInfoSchemaTypeChangeSafe = (
  oldColumnType?: InfoSchemaTableViewColumnType,
  newColumnType?: InfoSchemaTableViewColumnType
) => {
  if (!oldColumnType || !newColumnType || oldColumnType === newColumnType) {
    return true;
  }
  return mapping[oldColumnType][newColumnType] !== null;
};
