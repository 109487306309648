import {
  infoSchemaFieldValueValuesZod,
  infoSchemaFieldValueWithoutIdZod,
} from "../value";
import * as z from "zod";
import { idFlavor } from "../../../common";
import { infoSchemaTableViewColumnType } from "../tableView";

export const infoSchemaValueChangeLogType = {
  UPDATED: "UPDATED",
  CREATED: "CREATED",
  DUPLICATED: "DUPLICATED",
} as const;

export type InfoSchemaValueChangeLogType =
  (typeof infoSchemaValueChangeLogType)[keyof typeof infoSchemaValueChangeLogType];

export const infoSchemaValueChangeSourceType = {
  TABLE: "TABLE",
  BLOCK: "BLOCK",
  DUPLICATED: "DUPLICATED",
} as const;

export type InfoSchemaUpdateSource =
  | {
      kind: typeof infoSchemaValueChangeSourceType.TABLE;
      id: idFlavor<"InfoSchemaTableView">;
    }
  | {
      kind: typeof infoSchemaValueChangeSourceType.BLOCK;
      id: idFlavor<"InfoSchemaBlock">;
    };

export type InfoSchemaValueChangeSourceType =
  (typeof infoSchemaValueChangeSourceType)[keyof typeof infoSchemaValueChangeSourceType];

export const infoSchemaUpdatesZod = z.record(
  z.string(),
  z.object({
    before: infoSchemaFieldValueWithoutIdZod
      .and(
        z.object({
          columnType: z.nativeEnum(infoSchemaTableViewColumnType).optional(),
          columnName: z.string().optional(),
        })
      )
      .or(z.null())
      .or(z.undefined()),
    after: infoSchemaFieldValueWithoutIdZod
      .and(
        z.object({
          columnType: z.nativeEnum(infoSchemaTableViewColumnType).optional(),
          columnName: z.string().optional(),
        })
      )
      .or(z.null())
      .or(z.undefined()),
  })
);

export type InfoSchemaUpdates = z.infer<typeof infoSchemaUpdatesZod>;

export const infoSchemaValueChangeDataZod = z.object({
  before: infoSchemaFieldValueValuesZod.or(z.null()).or(z.undefined()),
  after: infoSchemaFieldValueValuesZod.or(z.null()).or(z.undefined()),
  updates: infoSchemaUpdatesZod,
});

export type InfoSchemaValueChangeData = z.infer<
  typeof infoSchemaValueChangeDataZod
>;

export interface InfoSchemaValueChangeLogBasicView {
  id: idFlavor<"InfoSchemaValueChangeLog">;
  changeType: InfoSchemaValueChangeLogType;
  data: InfoSchemaValueChangeData["updates"];
}
