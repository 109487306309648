import { idFlavor } from "../../common";

export const campaignTemplateSubRoute = {
  list: "list",
  view: "",
  create: "",
  update: "",
  delete: "",
  addColumn: "addColumn",
  updateColumnWidth: "updateColumnWidth",
  updateColumns: "updateColumns",
} as const;

export const campaignTemplateRoutes = {
  campaign_template: "campaigntemplates",
  "campaign_template.column.update": "campaigntemplates/updateColumn",
  "campaign_template.column.move": "campaigntemplates/column/move",
};

export type CampaignTemplateRoutes =
  | {
      sub: typeof campaignTemplateSubRoute.list;
      tailParam: idFlavor<"Workspace">;
      method?: "Get";
    }
  | {
      sub: typeof campaignTemplateSubRoute.view;
      tailParam: idFlavor<"CampaignTemplate">;
      method?: "Get";
    }
  | {
      sub: typeof campaignTemplateSubRoute.create;
      method?: "Post";
    }
  | {
      sub: typeof campaignTemplateSubRoute.update;
      tailParam: idFlavor<"CampaignTemplate">;
      method?: "Patch";
    }
  | {
      sub: typeof campaignTemplateSubRoute.delete;
      tailParam: idFlavor<"CampaignTemplate">;
      method?: "Delete";
    }
  | {
      sub: typeof campaignTemplateSubRoute.addColumn;
      method?: "Post";
    }
  | {
      sub: typeof campaignTemplateSubRoute.updateColumnWidth;
      method?: "Post";
    }
  | {
      sub: typeof campaignTemplateSubRoute.updateColumns;
      method?: "Post";
    };
