import { observer } from "mobx-react";
import React from "react";
import styles from "./notification.css";
import { NotificationIcon } from "../../../temporaryComponentsToShare/icons/icons";

interface NotificationModalContentProps {
  content: string;
}

/** Share spec sheet modal content */
@observer
export class NotificationModalContent extends React.Component<NotificationModalContentProps> {
  /** Renders the content of the modal */
  render() {
    return (
      <div className={styles.container}>
        <p className={styles.content}>{this.props.content}</p>
      </div>
    );
  }
}

interface NotificationModalHeaderProps {
  title: string;
}

/** Share spec sheet modal content */
export class NotificationModalHeader extends React.Component<NotificationModalHeaderProps> {
  /** Renders the modal header */
  render() {
    return (
      <div className={styles.header}>
        <NotificationIcon className={styles.icon} color="blue" />
        <h6 className={styles.title}>{this.props.title}</h6>
      </div>
    );
  }
}
