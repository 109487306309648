import {
  N_Placement,
  placementCategoryLabels,
  placementStandardColumns,
} from "@specsheet-common/shared-types";
import { getPlacementStatus } from "./getPlacementStatus";
import {
  getPrintDimensions,
  getDurations,
  getTrims,
  getDimensions,
  getPhysicalDimensions,
  getSafeAreas,
  getDesignAreas,
  getArtWorkDimensions,
  getBleedAreas,
  getArtworkScales,
  getAspectRatios,
  getBitRates,
  hasCopyPlacement,
  getFileFormats,
  getFileSizes,
  getFrameRates,
  getResolutions,
} from "./specifications";
import { getSampleRates } from "./specifications/sampleRate";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getStandardColumnValueForPlacement = (
  placement: Omit<N_Placement, "attributes">
) => {
  return {
    [placementStandardColumns.placementColumn]: () => ({
      name: placement.name,
      summary: placement.summary,
    }),
    [placementStandardColumns.channelColumn]: () =>
      placementCategoryLabels[placement.category],
    [placementStandardColumns.statusColumn]: () =>
      getPlacementStatus(placement),
    [placementStandardColumns.addressColumn]: () =>
      placement.location?.address || null,
    [placementStandardColumns.siteIdColumn]: () =>
      placement.location?.siteNumber || null,
    [placementStandardColumns.stateColumn]: () =>
      placement.location?.state || null,
    [placementStandardColumns.suburbColumn]: () =>
      placement.location?.suburb || null,
    [placementStandardColumns.documentsColumn]: () =>
      placement.documents || null,
    [placementStandardColumns.availableDurationColumn]: () =>
      getDurations(placement.specifications.value),
    [placementStandardColumns.trimColumn]: () => ({
      trims: getTrims(placement.specifications.value),
      dimensions: getPrintDimensions(placement.specifications.value),
    }),
    [placementStandardColumns.dimensionsColumn]: () =>
      getDimensions(placement.specifications.value),
    [placementStandardColumns.physicalDimensionColumn]: () =>
      getPhysicalDimensions(placement.specifications.value),
    [placementStandardColumns.artWorkDimensionColumn]: () =>
      getArtWorkDimensions(placement.specifications.value),
    [placementStandardColumns.safeAreaColumn]: () => ({
      safeAreas: getSafeAreas(placement.specifications.value),
      designAreas: getDesignAreas(
        placement.specifications.value,
        placement.category
      ),
    }),
    [placementStandardColumns.bleedAreaColumn]: () =>
      getBleedAreas(placement.specifications.value),
    [placementStandardColumns.artworkScaleColumn]: () =>
      getArtworkScales(placement.specifications.value),
    [placementStandardColumns.aspectRatiosColumn]: () =>
      getAspectRatios(placement.specifications.value),
    [placementStandardColumns.bitRateColumn]: () =>
      getBitRates(placement.specifications.value),
    [placementStandardColumns.copyColumn]: () =>
      hasCopyPlacement(placement.specifications.value),
    [placementStandardColumns.fileFormatsColumn]: () =>
      getFileFormats(placement.specifications.value),
    [placementStandardColumns.fileSizesColumn]: () =>
      getFileSizes(placement.specifications.value),
    [placementStandardColumns.frameRateColumn]: () =>
      getFrameRates(placement.specifications.value),
    [placementStandardColumns.resolutionColumn]: () =>
      getResolutions(placement.specifications.value),
    [placementStandardColumns.sampleRateColumn]: () =>
      getSampleRates(placement.specifications.value),
    [placementStandardColumns.mediaOwnerColumn]: () => placement.publisher.name,
  };
};
