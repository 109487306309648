import {
  DB_ListWysiwygItem,
  DB_WysiwygDescendant,
  N_ListWysiwygItem_ListItem,
  N_WysiwygDescendant,
} from "@specsheet-common/shared-types";
import { assertNever } from "../typescript";

const convertLegacyDBWysiwygDescendantList = (
  data: DB_ListWysiwygItem
): N_ListWysiwygItem_ListItem => {
  return { kind: "listItem", value: data.listItem };
};

export const convertFromDBWysiwygDescendant = (
  data: DB_WysiwygDescendant
): N_WysiwygDescendant => {
  if ("paragraph" in data) {
    return { kind: "paragraph", value: data.paragraph };
  }
  if ("numberedList" in data) {
    return {
      kind: "numberedList",
      value: data.numberedList.map(convertLegacyDBWysiwygDescendantList),
    };
  }
  if ("bulletedList" in data) {
    return {
      kind: "bulletedList",
      value: data.bulletedList.map(convertLegacyDBWysiwygDescendantList),
    };
  }
  return assertNever(data);
};

export const convertToDBWysiwygDescendant = (
  data: N_WysiwygDescendant
): DB_WysiwygDescendant => {
  switch (data.kind) {
    case "paragraph": {
      return { paragraph: data.value };
    }
    case "numberedList": {
      return { numberedList: data.value.map((d) => ({ listItem: d.value })) };
    }
    case "bulletedList": {
      return { bulletedList: data.value.map((d) => ({ listItem: d.value })) };
    }
    default: {
      return assertNever(data);
    }
  }
};
