import { Components, Theme } from "@mui/material";

export const MuiToggleButtonGroup: Components<Theme>["MuiToggleButtonGroup"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1),
      backgroundColor: theme.newColors.neutral.gray100,
      borderRadius: theme.shape.borderRadius,
      display: "flex",
      gap: "8px",
      "& .MuiToggleButtonGroup-grouped": {
        "&.Mui-disabled": {
          border: 0,
        },
        "&:not(:first-of-type)": {
          borderRadius: theme.shape.borderRadius,
        },
        "&:first-of-type": {
          borderRadius: theme.shape.borderRadius,
        },
      },
    }),
    grouped: ({ theme }) => ({
      ["&.Mui-selected"]: {
        backgroundColor: theme.newColors.neutral.white,
      },
      ["&:hover"]: {
        backgroundColor: theme.newColors.neutral.white,
        color: theme.newColors.neutral.primary,
      },
    }),
  },
};
