/** Sleeps for a while */
export function sleep(millis: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, millis);
  });
}

export function debounceAsync<T, CallbackArgs>(
  delayMs: number,
  callback: (args: CallbackArgs) => Promise<T>
): (args: CallbackArgs) => Promise<T> {
  let callScheduled: number | null = null;

  return (args: CallbackArgs) => {
    if (callScheduled) {
      window.clearTimeout(callScheduled);
    }

    return new Promise<T>((resolve) => {
      // eslint-disable-next-line promise/param-names
      const timeoutPromise = new Promise<void>((resolveL) => {
        callScheduled = window.setTimeout(resolveL, delayMs);
      });
      // eslint-disable-next-line no-void
      void timeoutPromise.then(async () => {
        // eslint-disable-next-line promise/no-callback-in-promise
        resolve(await callback(args));
      });
    });
  };
}
