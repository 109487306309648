import { z, ZodType } from "zod";
import * as R from "remeda";
import { N_BitRate } from "src/adl";
import { DB_DataAmount } from "src/main";

const dimensionsOverridesRegex = /^(.+): *(\d+) *x *(\d+) *$/;
const dimensionssRegex = /^(\d+) *x *(\d+)$/;
interface DimensionOverride {
  name: string;
  width: number;
  height: number;
}

const emptyStringSchema = z
  .string()
  .min(0)
  .max(0)
  .transform(() => undefined);

const nonEmptyStringSchema = z.string().min(1);

const maybeEmpty = <T extends ZodType>(
  schema: T
): z.ZodUnion<[z.ZodEffects<z.ZodString, undefined, string>, T]> =>
  z.union([emptyStringSchema, schema]);

const jcdDigitalBitrateSchema = maybeEmpty(
  nonEmptyStringSchema.regex(/^[\.\d]+\s+(Kbps|Mbps)$/i).transform((s) => {
    const [value, unit] = s.split(/\s+/);
    const result: N_BitRate | null =
      unit === "Kbps"
        ? { value: parseFloat(value), unit: "Kbps" }
        : unit === "Mbps"
        ? { value: parseFloat(value), unit: "Mbps" }
        : null;
    return result;
  })
);

const jcdDigitalFileSize = maybeEmpty(
  nonEmptyStringSchema.transform((s): DB_DataAmount => {
    const [rawAmount, rawUnit] = s.split(/\s+/);
    const amount = parseFloat(rawAmount);
    if (isNaN(amount)) {
      throw new Error(`unable to parse file size amount from '${s}'`);
    }
    const unit = z
      .union([z.literal("kb"), z.literal("mb"), z.literal("gb")])
      .safeParse(rawUnit?.toLowerCase());
    if (!unit.success) {
      throw new Error(`unable to parse file size unit from '${s}'`);
    }
    switch (unit.data) {
      case "kb":
      case "mb":
      case "gb": {
        return {
          amount,
          unit: unit.data,
        };
      }
      default: {
        throw new Error(`unable to parse file size from '${s}'`);
      }
    }
  })
);

const jcdDigitalAspectRatioSchema = maybeEmpty(
  nonEmptyStringSchema.regex(/^[\.\d]+:[\.\d]+$/).transform((s) => {
    const [width, height] = s.split(/:/);
    return { width: parseFloat(width), height: parseFloat(height) };
  })
);

const commaSeparatedStrings = maybeEmpty(
  nonEmptyStringSchema.transform((value) => {
    const fileFormats = value?.split(",").map((s) => s.trim());
    return fileFormats?.length ? fileFormats : null;
  })
);

const jcdDigitalResolutionSchema = maybeEmpty(
  nonEmptyStringSchema
    .refine((value) => {
      return !!parseFloat(value);
    })
    .transform(parseFloat)
);

const jcdDigitalDimensionsOverrideSchema = maybeEmpty(
  nonEmptyStringSchema
    .refine((value) => {
      const dims = value.split(",").map((s) => s.trim());
      // 👋 don't delete this commented debugging block, someone will
      // need it one day
      // for (const dim of dims.map((s) => s.trim())) {
      //   console.log("DIM", dim, dimensionsOverridesRegex.test(dim));
      // }
      return !dims.some((dim) => !dimensionsOverridesRegex.test(dim));
    })
    .transform((value) => {
      const dimensionsOverrides: DimensionOverride[] = value
        ?.split(",")
        .map((dimString) => {
          const dim = dimString.trim();
          const [, name, width, height] =
            dim.match(dimensionsOverridesRegex) || [];
          if (!(name && width && height)) {
            return null;
          }
          return { name, width: Number(width), height: Number(height) };
        })
        .filter(R.isNonNull);
      return dimensionsOverrides;
    })
);

const jcdDigitalDimensionsSchema = maybeEmpty(
  nonEmptyStringSchema
    .refine((value) => {
      return dimensionssRegex.test(value);
    })
    .transform((value) => {
      const [width, height] = value.split("x").map((s) => s.trim()) || [];
      return { width, height };
    })
);

const numberSchema = maybeEmpty(
  nonEmptyStringSchema.regex(/^[\.\d]+$/).transform(parseFloat)
);

const jcdColourProfileSchema = maybeEmpty(
  commaSeparatedStrings.transform((values) => {
    const allowed = ["RGB", "CMYK", "HEX"];
    const filtered = values
      ?.filter((value) => allowed.includes(value))
      .map((s) => s as "HEX" | "RGB" | "CMYK");
    return filtered;
  })
);

const booleanSchema = maybeEmpty(
  nonEmptyStringSchema.transform((s) => s === "Yes")
);

export const jcdStaticPlacementSchema = z
  .object({
    pdf: z.string(),
    summary: z.string().optional(),
    description: z.string().optional(),
    "scaling (%)": z.string().optional(),
    "artwork_bleed_top (mm)": numberSchema,
    "artwork_bleed_bottom (mm)": numberSchema,
    "artwork_bleed_left (mm)": numberSchema,
    "artwork_bleed_right (mm)": numberSchema,
    artwork_setup_notes: z.string().optional(),
    file_format: commaSeparatedStrings,
    file_format_notes: z.string().optional(),
    "file_weight (MB)": numberSchema,
    "resolution (DPI)": numberSchema,
    resolutio_notes: z.string().optional(),
    colour_profile: jcdColourProfileSchema,
    black_specification: z.string().optional(),
    approved_substrate: z.string().optional(),
    fonts: z.string().optional(),
    "Bar Codes": z.string().optional(),
    "Placement Note": z.string().optional(),
    Finishing: z.string().optional(),
    Spares: z.string().optional(),
    Labelling: z.string().optional(),
    "File Transfer": z.string().optional(),
    Deadlines: z.string().optional(),
    "Production Information": z.string().optional(),
    "Non-Conformance": z.string().optional(),
    Contact: z.string().optional(),
    "Unique Placement": booleanSchema,
    images: z.string().optional(),
    dimensions_override: z.string().optional(),
  })
  .transform(
    ({
      Contact: contact,
      Spares: spares,
      Labelling: labelling,
      "File Transfer": fileTransfer,
      Deadlines: deadlines,
      "Production Information": productionInformation,
      "Non-Conformance": nonConformance,
      "scaling (%)": scaling,
      artwork_setup_notes: artworkSetupNotes,
      file_format: format,
      file_format_notes: fileFormatNotes,
      "file_weight (MB)": fileWeight,
      "resolution (DPI)": resolution,
      resolutio_notes: resolutionNotes,
      colour_profile: colourProfile,
      black_specification: blackSpecification,
      approved_substrate: approvedSubstrate,
      "Unique Placement": uniquePlacement,
      Finishing: finishing,
      "Bar Codes": barCodes,
      "Placement Note": placementNote,
      ...data
    }) => {
      return {
        ...data,
        contact,
        barCodes,
        spares,
        labelling,
        fileTransfer,
        deadlines,
        productionInformation,
        nonConformance,
        scaling,
        artworkSetupNotes,
        format,
        fileFormatNotes,
        fileWeight,
        resolution,
        resolutionNotes,
        colourProfile,
        blackSpecification,
        approvedSubstrate,
        uniquePlacement,
        finishing,
        placementNote,
      };
    }
  )
  .transform(
    ({
      "artwork_bleed_top (mm)": top,
      "artwork_bleed_bottom (mm)": bottom,
      "artwork_bleed_left (mm)": left,
      "artwork_bleed_right (mm)": right,
      ...data
    }) => {
      return {
        ...data,
        artworkBleed:
          top && bottom && left && right
            ? { top, bottom, left, right }
            : undefined,
      };
    }
  );

export const jcdDigitalPlacementSchema = z
  .object({
    pdf: z.string(),
    summary: z.string().optional(),
    description: z.string().optional(),
    component_note: z.string().optional(),
    dimensions: jcdDigitalDimensionsSchema,
    static_file_format: commaSeparatedStrings,
    static_file_format_notes: z.string().optional(),
    "resolution (dpi)": jcdDigitalResolutionSchema,
    colour_profile: jcdColourProfileSchema,
    animated_file_format: commaSeparatedStrings,
    animated_file_format_notes: z.string().optional(),
    codec: commaSeparatedStrings,
    bitrate: jcdDigitalBitrateSchema,
    aspect_ratio: jcdDigitalAspectRatioSchema,
    "duration (s)": numberSchema,
    "frame_rate (fps)": numberSchema,
    "animated_file_weight (MB)": numberSchema,
    Fonts: z.string().optional(),
    "File Transfer": z.string().optional(),
    "Display Approval": z.string().optional(),
    "Unique Placement": booleanSchema,
    "Artwork Checklist": z.string().optional(),
    "Creative Restrictions": z.string().optional(),
    Contact: z.string().optional(),
    images: z.string().optional(),
    dimensions_override: jcdDigitalDimensionsOverrideSchema,
    static_file_weight: jcdDigitalFileSize,
  })
  .transform(
    ({
      dimensions_override: dimensionsOverride,
      static_file_format: staticFormats,
      animated_file_format: animatedFormats,
      "resolution (dpi)": resolution,
      colour_profile: colourProfile,
      Fonts: fonts,
      animated_file_format_notes: animatedFileFormatNotes,
      static_file_format_notes: staticFileFormatNotes,
      codec: codecs,
      aspect_ratio: aspectRatio,
      "duration (s)": duration,
      "frame_rate (fps)": frameRate,
      "animated_file_weight (MB)": animatedFileWeight,
      component_note: componentNote,
      "File Transfer": fileTransfer,
      "Display Approval": displayApproval,
      "Unique Placement": uniquePlacement,
      "Artwork Checklist": artworkChecklist,
      "Creative Restrictions": creativeRestrictions,
      Contact: contact,
      bitrate,
      static_file_weight: staticFileWeight,
      ...data
    }) => {
      return {
        ...data,
        bitrate,
        resolution,
        dimensionsOverride,
        staticFormats,
        animatedFormats,
        colourProfile,
        fonts,
        animatedFileFormatNotes,
        staticFileFormatNotes,
        codecs,
        aspectRatio,
        duration,
        frameRate,
        animatedFileWeight,
        componentNote,
        fileTransfer,
        displayApproval,
        uniquePlacement,
        artworkChecklist,
        creativeRestrictions,
        contact,
        staticFileWeight,
      };
    }
  );

// straight copy of existing one
export const jcdFurniturePlacementSchema = jcdStaticPlacementSchema;

export type JcdDigitalPlacement = z.infer<typeof jcdDigitalPlacementSchema>;
export type JcdStaticPlacement = z.infer<typeof jcdStaticPlacementSchema>;
export type JcdFurniturePlacement = z.infer<typeof jcdFurniturePlacementSchema>;
