import z from "zod";

export const mediaWorksDigitalPanelSchema = z.object({
  optionDetails: z.string(),
  clientName: z.string(),
  size: z.string(),
  panel: z.string(),
  panelAddress: z.string(),
  subareaName: z.string(),
  region: z.string(),
  bookingStartDate: z.string(),
  bookingEndDate: z.string(),
  // shareOfTimeLite: z.string(),
  materialType: z.string(),
  visualWidth: z.string(),
  visualHeight: z.string(),
  pixelWidth: z.string(),
  pixelHeight: z.string(),
  installCompanyName: z.string(),
  productionComments: z.string(),
  panelCensorships: z.string(),
  panelProhibitions: z.string(),
  panelDirection: z.string(),
  dimensions: z.string(),
  digitalAspectRatio: z.string(),
  artworkDeadline: z.string(),
  productionSpecs1: z.string(),
});

export type MediaWorksDigitalPanel = z.infer<
  typeof mediaWorksDigitalPanelSchema
>;
