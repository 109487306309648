import { N_UserProfile } from "../appUser";
import { BasicWorkspaceAccessInfo } from "../workspace";
import { BasicOrgAccessInfo } from "../organisation";

export const orgMemberStatus = {
  Pending: "Pending",
  Active: "Active",
  Deactivated: "Deactivated",
  Removed: "Removed",
} as const;

export type OrgMemberStatus =
  (typeof orgMemberStatus)[keyof typeof orgMemberStatus];

export const orgMemberStatusOrder: Record<OrgMemberStatus, number> = {
  [orgMemberStatus.Pending]: 0,
  [orgMemberStatus.Active]: 1,
  [orgMemberStatus.Deactivated]: 2,
  [orgMemberStatus.Removed]: 3,
};

export const orgMemberRole = {
  ADMIN: "ADMIN",
  BASIC: "BASIC",
  GUEST: "GUEST",
} as const;

export type OrgMemberRole = (typeof orgMemberRole)[keyof typeof orgMemberRole];

export const orgMemberRoleLabels: {
  [key in OrgMemberRole]: string;
} = {
  [orgMemberRole.ADMIN]: "Admin",
  [orgMemberRole.BASIC]: "Member",
  [orgMemberRole.GUEST]: "Guest",
};

export const orgMemberRoleOrder: Record<OrgMemberRole, number> = {
  [orgMemberRole.ADMIN]: 0,
  [orgMemberRole.BASIC]: 1,
  [orgMemberRole.GUEST]: 2,
} as const;

export interface OrgMemberWithAccessDetails {
  userProfile: N_UserProfile;
  workspacesAccess: BasicWorkspaceAccessInfo[];
  organisationAccess: BasicOrgAccessInfo;
}
