import { Components, Theme, outlinedInputClasses } from "@mui/material";

export const MuiOutlinedInput: Components<Theme>["MuiOutlinedInput"] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      padding: theme.spacing(0, 2),
      ...(ownerState && ownerState.disabled
        ? { background: theme.newColors.neutral.gray100 }
        : {}),
      [`& .${outlinedInputClasses.notchedOutline}`]:
        ownerState && ownerState.disabled
          ? { borderColor: `${theme.newColors.neutral.gray200} !important;` }
          : {},
    }),
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(1, 2),
    }),
    input: ({ theme }) => ({
      ...theme.typography.body2,
      padding: theme.spacing(2, 0),
      height: "initial",
    }),
    inputSizeSmall: ({ theme }) => ({
      ...theme.typography.body2,
      padding: 0,
    }),
    notchedOutline: ({ theme }) => ({
      borderColor: theme.newColors.neutral.gray200,
    }),
  },
};
