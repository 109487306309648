import { Components, Theme } from "@mui/material";

export const MuiAvatar: Components<Theme>["MuiAvatar"] = {
  styleOverrides: {
    root: {
      width: 30,
      height: 30,
    },
  },
};
