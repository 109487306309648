import { getPrintDimensionUnit } from "./printDimension";
import {
  N_PrintBorderDimensions,
  N_PrintDimensions,
  N_SampleRate,
} from "@specsheet-common/shared-types";

export const stringifyPrintDimension = (
  dimensions: N_PrintDimensions
): string =>
  `${dimensions.width.value} ${getPrintDimensionUnit(
    dimensions.width.unit
  )} x ${dimensions.height.value} ${getPrintDimensionUnit(
    dimensions.height.unit
  )}`;

export const stringifyPrintBorderDimensions = (
  bleedArea: N_PrintBorderDimensions
): string =>
  `T:${bleedArea.top.value}, B:${bleedArea.bottom.value}, L:${
    bleedArea.left.value
  }, R:${bleedArea.right.value} ${getPrintDimensionUnit(bleedArea.right.unit)}`;

export const stringifySampleRate = (sampleRate: N_SampleRate): string =>
  `${sampleRate.value} kHz`;
