import { Service } from "../service";
import {
  ConcatArgument,
  FieldPredicate,
  makeFieldLike,
  makeTableQuery,
} from "src/adl-gen/common/tabular";
import { WithDbId } from "src/adl-gen/common/db";
import { DbRow } from "src/adl-gen/common/adminui/api";
import {
  debounceAsync,
  DEFAULT_DEBOUNCE_TIME,
} from "@specsheet-common/shared-tools";

export const fetchTableAdmin = debounceAsync(
  DEFAULT_DEBOUNCE_TIME,
  async (args: {
    service: Pick<Service, "adminQuery">;
    term?: string;
    tableName: string;
    searchFields: ConcatArgument[] | null;
    filter?: FieldPredicate;
  }): Promise<WithDbId<DbRow>[]> => {
    const { service, term, tableName, searchFields, filter } = args;

    const getFilter = (): FieldPredicate | undefined => {
      if (filter) {
        if (term && searchFields) {
          return {
            kind: "and",
            value: [
              filter,
              {
                kind: "like",
                value: makeFieldLike({
                  field: {
                    kind: "concat",
                    value: searchFields,
                  },
                  pattern: "%" + term + "%",
                  caseSensitive: false,
                }),
              },
            ],
          };
        }

        return filter;
      }

      if (term && searchFields) {
        return {
          kind: "like",
          value: makeFieldLike({
            field: {
              kind: "concat",
              value: searchFields,
            },
            pattern: "%" + term + "%",
            caseSensitive: false,
          }),
        };
      }
      return undefined;
    };

    const termQuery = makeTableQuery({
      filter: getFilter(),
      count: 100,
    });

    const data = await service.adminQuery({
      query: termQuery,
      table: tableName,
      columns: [],
    });

    return data.items;
  }
);
