/* @generated from adl module kachemedia.specsheet.utils */

import * as ADL from './../../runtime/adl';
import * as common_db from './../../common/db';
import * as kachemedia_specsheet_db from './db';

/**
 * AppUser with membership info in a single workspace, org, and ops.
 */
export interface AppUserMembership {
  appUser: kachemedia_specsheet_db.AppUser;
  workspaceMembership: (kachemedia_specsheet_db.WorkspaceMember|null);
  organisationMembership: (common_db.WithDbId<kachemedia_specsheet_db.OrganisationMember>|null);
  operationsMembership: (kachemedia_specsheet_db.OperationsMember|null);
  organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>;
}

export function makeAppUserMembership(
  input: {
    appUser: kachemedia_specsheet_db.AppUser,
    workspaceMembership: (kachemedia_specsheet_db.WorkspaceMember|null),
    organisationMembership: (common_db.WithDbId<kachemedia_specsheet_db.OrganisationMember>|null),
    operationsMembership: (kachemedia_specsheet_db.OperationsMember|null),
    organisationId: common_db.DbKey<kachemedia_specsheet_db.Organisation>,
  }
): AppUserMembership {
  return {
    appUser: input.appUser,
    workspaceMembership: input.workspaceMembership,
    organisationMembership: input.organisationMembership,
    operationsMembership: input.operationsMembership,
    organisationId: input.organisationId,
  };
}

const AppUserMembership_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.utils","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"AppUser with membership info in a single workspace, org, and ops.\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"appUser","default":{"kind":"nothing"},"name":"appUser","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"AppUser"}},"parameters":[]}},{"annotations":[],"serializedName":"workspaceMembership","default":{"kind":"nothing"},"name":"workspaceMembership","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"WorkspaceMember"}},"parameters":[]}]}},{"annotations":[],"serializedName":"organisationMembership","default":{"kind":"nothing"},"name":"organisationMembership","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"WithDbId"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"OrganisationMember"}},"parameters":[]}]}]}},{"annotations":[],"serializedName":"operationsMembership","default":{"kind":"nothing"},"name":"operationsMembership","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"OperationsMember"}},"parameters":[]}]}},{"annotations":[],"serializedName":"organisationId","default":{"kind":"nothing"},"name":"organisationId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Organisation"}},"parameters":[]}]}}]}},"name":"AppUserMembership","version":{"kind":"nothing"}}};

export const snAppUserMembership: ADL.ScopedName = {moduleName:"kachemedia.specsheet.utils", name:"AppUserMembership"};

export function texprAppUserMembership(): ADL.ATypeExpr<AppUserMembership> {
  return {value : {typeRef : {kind: "reference", value : snAppUserMembership}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "kachemedia.specsheet.utils.AppUserMembership" : AppUserMembership_AST
};
