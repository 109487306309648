/* @generated from adl module kachemedia.specsheet.annotations */

import * as ADL from './../../runtime/adl';

export enum StatusLabelColor {
  orange,
  green,
  blue,
  red,
}

const StatusLabelColor_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.annotations","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Status label color annotation\n"}],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"orange","default":{"kind":"nothing"},"name":"orange","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"green","default":{"kind":"nothing"},"name":"green","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"blue","default":{"kind":"nothing"},"name":"blue","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"red","default":{"kind":"nothing"},"name":"red","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"StatusLabelColor","version":{"kind":"nothing"}}};

export const snStatusLabelColor: ADL.ScopedName = {moduleName:"kachemedia.specsheet.annotations", name:"StatusLabelColor"};

export function texprStatusLabelColor(): ADL.ATypeExpr<StatusLabelColor> {
  return {value : {typeRef : {kind: "reference", value : snStatusLabelColor}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "kachemedia.specsheet.annotations.StatusLabelColor" : StatusLabelColor_AST
};
