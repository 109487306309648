import {
  DB_CampaignTemplateSpecColumn,
  DB_CampaignTemplateStandardColumn,
} from "@specsheet-common/shared-types";
import { notNullish } from "../typescript";
import { getSpecColumns, specColumns } from "./getSpecColumns";
import {
  getStandardFixedColumns,
  standardColumnsSet,
  standardFixedColumns,
  standardFixedColumnsSet,
} from "./getStandardColumns";

interface CampaignTemplateType {
  specColumns: DB_CampaignTemplateSpecColumn[] | undefined;
  standardColumns: DB_CampaignTemplateStandardColumn[];
}

export const isTemplateOld = <
  CAMPAIGN_TEMPLATE_TYPE extends CampaignTemplateType
>(
  template: CAMPAIGN_TEMPLATE_TYPE
): boolean => {
  return notNullish(template.specColumns)
    ? template.specColumns.length === 0
    : true;
};

export const normalizeCampaignTemplate = <
  CAMPAIGN_TEMPLATE_TYPE extends CampaignTemplateType
>(
  template: CAMPAIGN_TEMPLATE_TYPE
): CAMPAIGN_TEMPLATE_TYPE => {
  const isOld = isTemplateOld(template);

  if (isOld) {
    const standardCampaignColumnsSet = new Set(
      template.standardColumns.map((sc) => sc.column)
    );

    const missedStandardColumns = getStandardFixedColumns()
      .filter((sc) => !standardCampaignColumnsSet.has(sc.column))
      .map((c, i) => ({ ...c, position: i }));

    const standardCampaignColumns = [
      ...[...missedStandardColumns, ...(template.standardColumns || [])]
        .filter((sc) => standardFixedColumnsSet.has(sc.column))
        .map((sc, i) => ({
          ...sc,
          position: i,
        })),
      ...template.standardColumns
        .filter((sc) => standardColumnsSet.has(sc.column))
        .map((sc) => ({
          ...sc,
          position:
            sc.position + standardFixedColumns.length + specColumns.length,
        })),
    ];

    return {
      ...template,
      standardColumns: standardCampaignColumns,
      specColumns: getSpecColumns(standardFixedColumns.length),
    };
  }

  return template;
};
