import { bucketKind, BucketKind, N_Url } from "@specsheet-common/shared-types";
import { assertNever } from "./typescript";

export const UPLOADS_BASE_URL =
  "https://s3-ap-southeast-2.amazonaws.com/com-hx-specsheet-public-assets/";

const UPLOADS_BASE_URLS_LEGACY = [
  "https://com-hx-specsheet-public-assets.s3-ap-southeast-2.amazonaws.com/",
  "https://com-hx-specsheet-public-assets.s3.ap-southeast-2.amazonaws.com/",
];

export const forTestsS3helpers = { UPLOADS_BASE_URL, UPLOADS_BASE_URLS_LEGACY };

export const convertNameToS3Url = (fileName: string): N_Url => {
  if (fileName === "") {
    return "";
  }
  return `${UPLOADS_BASE_URL}uploads/${fileName}`;
};

export const convertS3UrlToPath = (url: N_Url): string => {
  if (url.startsWith(UPLOADS_BASE_URL)) {
    return url.replace(UPLOADS_BASE_URL, "").replace(/\+/g, " ");
  }

  for (const legacyBaseUrl of UPLOADS_BASE_URLS_LEGACY) {
    if (url.startsWith(legacyBaseUrl)) {
      return url.replace(legacyBaseUrl, "").replace(/\+/g, " ");
    }
  }
  return url.replace(/\+/g, " ");
};

export const isExternalUrl = (url: N_Url): boolean => {
  return [UPLOADS_BASE_URL, ...UPLOADS_BASE_URLS_LEGACY].reduce(
    (result, base_url) => !url.startsWith(base_url) && result,
    true
  );
};

export const isInternalUrl = (url: N_Url): boolean => {
  return [UPLOADS_BASE_URL, ...UPLOADS_BASE_URLS_LEGACY].some((base_url) =>
    url.startsWith(base_url)
  );
};

export const getFullBucketPath = ({
  kind,
  bucketPrefix,
}: {
  kind: BucketKind;
  bucketPrefix: string;
}): string => {
  switch (kind) {
    case bucketKind.CLIENT_FILES: {
      return `https://s3-ap-southeast-2.amazonaws.com/${bucketPrefix}-client-files-bucket`;
    }
    case bucketKind.PUBLIC_LEGACY: {
      return `https://s3-ap-southeast-2.amazonaws.com/com-hx-specsheet-public-assets/uploads`;
    }
    case bucketKind.PRIVATE_FILES: {
      return `https://s3-ap-southeast-2.amazonaws.com/${bucketPrefix}-private-files-bucket`;
    }
    case bucketKind.CORE_CLIENT: {
      return `https://${bucketPrefix}-core-client-bucket.s3.ap-southeast-2.amazonaws.com`;
    }
    case bucketKind.PUBLIC_FILES: {
      return `https://s3-ap-southeast-2.amazonaws.com/${bucketPrefix}-public-files-bucket`;
    }
    case bucketKind.EXT_QUARANTINE_FILES: {
      return `https://s3-ap-southeast-2.amazonaws.com/${bucketPrefix}-ext-quarantine-bucket`;
    }
    default: {
      return assertNever(kind);
    }
  }
};

export const getBucketPath = ({
  kind,
  bucketPrefix,
}: {
  kind: BucketKind;
  bucketPrefix?: string;
}): string => {
  switch (kind) {
    case bucketKind.CLIENT_FILES: {
      return `${bucketPrefix}-client-files-bucket`;
    }
    case bucketKind.PUBLIC_LEGACY: {
      return `com-hx-specsheet-public-assets`;
    }
    case bucketKind.PRIVATE_FILES: {
      return `${bucketPrefix}-private-files-bucket`;
    }
    case bucketKind.CORE_CLIENT: {
      return `${bucketPrefix}-core-client-bucket`;
    }
    case bucketKind.PUBLIC_FILES: {
      return `${bucketPrefix}-public-files-bucket`;
    }
    case bucketKind.EXT_QUARANTINE_FILES: {
      return `${bucketPrefix}-ext-quarantine-bucket`;
    }
    default: {
      return assertNever(kind);
    }
  }
};
