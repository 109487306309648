import { N_ChangedValue, idFlavor, refString } from "../../common";
import { N_MaterialInstructions } from "./general";
import { N_BookingGroupRefName, PartialBooking } from "../bookingGroup";
import { ConsolidationType } from "../bookingConsolidation";
import { campaignChangeKind } from "../campaignEdit";
import { ExtCampaignKind } from "../external";

export interface N_BookingDuplicated {
  bookingId: idFlavor<"Booking">;
}

export interface N_BookingUpdatedValues {
  groupChanged?: N_ChangedValue<N_BookingGroupRefName | undefined> | undefined;
  dispatchDateChanged?: N_ChangedValue<string | undefined> | undefined;
  dispatchDetailsChanged?: N_ChangedValue<string> | undefined;
  dispatchToChanged?: N_ChangedValue<string> | undefined;
  durationChanged?: N_ChangedValue<string | undefined> | undefined;
  liveDateChanged?: N_ChangedValue<string | undefined> | undefined;
  endDateChanged?: N_ChangedValue<string | undefined> | undefined;
  materialInstructionsChanged?:
    | N_ChangedValue<N_MaterialInstructions | undefined>
    | undefined;
  notesChanged?: N_ChangedValue<string> | undefined;
}

/* This is for temporal work such that we have a reliable set of values 
which represent booking columns which can be updated to use within switch statements */
export const bookingUpdateableValues = {
  liveDate: "liveDate",
  endDate: "endDate",
  duration: "duration",
  dispatchDate: "dispatchDate",
  dispatchTo: "dispatchTo",
  dispatchDetails: "dispatchDetails",
  notes: "notes",
  materialInstructions: "materialInstructions",
  groupId: "groupId",
};

export const bookingGroupUpdateableValues = {
  name: "name",
  parentId: "parentId",
};

export interface N_BookingUpdated {
  updates: N_BookingUpdatedValues;
}

export const bookingEditKind = {
  bookingDuplicated: "bookingDuplicated",
  bookingAdded: "bookingAdded",
  bookingMoved: "bookingMoved",
  bookingDeleted: "bookingDeleted",
  bookingDeletedCml: "bookingDeletedCml",
  bookingPublicationsUpdated: "bookingPublicationsUpdated",
  bookingUpdated: "bookingUpdated",
  bookingUpdatedCml: "bookingUpdatedCml",
  bookingGrouped: "bookingGrouped",
  bookingUngrouped: "bookingUngrouped",
  multiRowDeleted: "multiRowDeleted",
  multiRowAdded: "multiRowAdded",
} as const;

export type BookingEditKind =
  (typeof bookingEditKind)[keyof typeof bookingEditKind];
export interface SimplifiedTimelineBooking {
  id: idFlavor<"Booking">;
  extCampaignRef: refString<"ExtCampaign"> | idFlavor<"ExtCampaign"> | null;
  bookingConsolidation: {
    consolidationType: ConsolidationType;
    consolidationValue: string | null;
  } | null;
  updated?: number;
}

export interface SimplifiedTimelineInfoSchemaValue {
  id: idFlavor<"InfoSchemaValue">;
  fileName: string | null;
}

export interface N_BookingEdit_BookingMoved {
  kind: typeof bookingEditKind.bookingMoved;
  booking: SimplifiedTimelineBooking;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  // before and after group name
  updates: N_ChangedValue<string | undefined>;
}
export interface N_BookingEdit_BookingGrouped {
  kind: typeof bookingEditKind.bookingGrouped;
  booking: SimplifiedTimelineBooking;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  // before and after group name
  updates: N_ChangedValue<string | undefined>;
}

export interface N_BookingEdit_BookingUngrouped {
  kind: typeof bookingEditKind.bookingUngrouped;
  booking: SimplifiedTimelineBooking;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  // before and after group name
  updates: N_ChangedValue<string | undefined>;
}

export interface N_BookingEdit_BookingDuplicated {
  kind: typeof bookingEditKind.bookingDuplicated;
  booking: SimplifiedTimelineBooking;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  duplicated: N_BookingDuplicated;
}
export interface N_BookingEdit_BookingAdded {
  kind: typeof bookingEditKind.bookingAdded;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  booking: SimplifiedTimelineBooking;
}
export interface N_BookingEdit_BookingDeleted {
  kind: typeof bookingEditKind.bookingDeleted;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  booking: SimplifiedTimelineBooking;
}

export interface N_BookingEdit_BookingDeletedCml {
  kind: typeof bookingEditKind.bookingDeletedCml;
  bookings: PartialBooking[];
  extContracts: Record<
    refString<"ExtCampaign">,
    (PartialBooking & {
      extCampaignKind: ExtCampaignKind;
      extCampaignId: idFlavor<"ExtCampaign">;
      campaignCode: string;
      mediaOwnerRaw: string;
      mediaOwnerPrettified: string;
    })[]
  >;
}

export interface N_BookingEdit_BookingPublicationEditCML {
  kind: typeof bookingEditKind.bookingPublicationsUpdated;
  booking: PartialBooking;
  publicationsAdded: { id: idFlavor<"Publication">; name: string }[];
  publicationsDeleted: { id: idFlavor<"Publication">; name: string }[];
}

export interface N_BookingEdit_BookingUpdated {
  kind: typeof bookingEditKind.bookingUpdated;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  booking: SimplifiedTimelineBooking;
  updated: N_BookingUpdated;
  infoSchemaValue?: SimplifiedTimelineInfoSchemaValue;
}
export interface N_BookingEdit_BookingUpdatedCML {
  kind: typeof bookingEditKind.bookingUpdatedCml;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  booking: SimplifiedTimelineBooking;
  updated: N_BookingUpdated;
  infoSchemaValue?: SimplifiedTimelineInfoSchemaValue;
}

export interface N_BookingEdit_MultiRowAdded {
  kind: typeof campaignChangeKind.multiRowAdded;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  booking: SimplifiedTimelineBooking;
  infoSchemaValue: SimplifiedTimelineInfoSchemaValue;
}
export interface N_BookingEdit_MultiRowDeleted {
  kind: typeof campaignChangeKind.multiRowDeleted;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  booking: SimplifiedTimelineBooking;
  infoSchemaValue: SimplifiedTimelineInfoSchemaValue;
}

/**
 * Details for an edit to a booking
 */
export type N_BookingEdit =
  | N_BookingEdit_BookingDuplicated
  | N_BookingEdit_BookingAdded
  | N_BookingEdit_BookingMoved
  | N_BookingEdit_BookingDeleted
  | N_BookingEdit_BookingDeletedCml
  | N_BookingEdit_BookingUpdated
  | N_BookingEdit_BookingUpdatedCML
  | N_BookingEdit_MultiRowAdded
  | N_BookingEdit_MultiRowDeleted
  | N_BookingEdit_BookingGrouped
  | N_BookingEdit_BookingPublicationEditCML
  | N_BookingEdit_BookingUngrouped;
