import { idFlavor } from "../../common";

export const bookingGroupSubRoute = {
  create: "",
  update: "update",
  addbookings: "addbookings",
  addgroups: "addgroups",
  ungroup: "ungroup",
  delete: "delete",
} as const;

export type BookingGroupRoutes =
  | {
      sub: typeof bookingGroupSubRoute.create;
      method?: "Post";
    }
  | {
      sub: typeof bookingGroupSubRoute.update;
      tailParam: idFlavor<"BookingGroup">;
      method?: "Post";
    }
  | {
      sub: typeof bookingGroupSubRoute.addbookings;
      tailParam: idFlavor<"BookingGroup">;
      method?: "Post";
    }
  | {
      sub: typeof bookingGroupSubRoute.addgroups;
      tailParam: idFlavor<"BookingGroup">;
      method?: "Post";
    }
  | {
      sub: typeof bookingGroupSubRoute.ungroup;
      method?: "Post";
    }
  | {
      sub: typeof bookingGroupSubRoute.delete;
      tailParam: idFlavor<"BookingGroup">;
      method?: "Post";
    };
