import {
  DB_CampaignTemplateStandardColumn,
  campaignTemplateStandardColumn,
} from "@specsheet-common/shared-types";
import { specColumns } from "./getSpecColumns";

export const standardFixedColumns = [
  campaignTemplateStandardColumn.MEDIA_OWNER,
  campaignTemplateStandardColumn.PLACEMENT_INFO,
  campaignTemplateStandardColumn.STATUS,
  campaignTemplateStandardColumn.CAMPAIGN_CODE,
  campaignTemplateStandardColumn.CHANNEL,
  campaignTemplateStandardColumn.PUBLICATIONS,
  campaignTemplateStandardColumn.REQUIRED_DURATION,
] as const;

export const standardFixedColumnsSet = new Set<string>(standardFixedColumns);

export const standardColumns = [
  campaignTemplateStandardColumn.NOTES,
  campaignTemplateStandardColumn.CREATIVE,
  campaignTemplateStandardColumn.DISPATCH_DATE,
  campaignTemplateStandardColumn.DISPATCH_DETAILS,
  campaignTemplateStandardColumn.LIVE_DATE,
  campaignTemplateStandardColumn.END_DATE,
] as const;

export const standardColumnsSet = new Set<string>(standardColumns);

export const getStandardFixedColumns =
  (): DB_CampaignTemplateStandardColumn[] =>
    standardFixedColumns.map((column, i) => ({
      column,
      position: i,
      isHidden: false,
      isLimited: false,
      channels: undefined,
    }));

export const getStandardColumns = (): DB_CampaignTemplateStandardColumn[] =>
  standardColumns.map((column, i) => ({
    column,
    position: i + standardFixedColumns.length + specColumns.length,
    isHidden: false,
    isLimited: false,
    channels: undefined,
  }));
