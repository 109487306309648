import {
  AllBookingColumns,
  allSpecSheetTabs,
  bookingGroupStandardColumns,
  bookingStandardColumns,
  oohCategories,
  placementCategory,
  placementStandardColumns,
  SpecSheetTab,
  specSheetTabSpecial,
} from "@specsheet-common/shared-types";

export const columnsToShowInCategories: Record<
  AllBookingColumns,
  {
    placementTabs: SpecSheetTab[];
  }
> = {
  [placementStandardColumns.mediaOwnerColumn]: {
    placementTabs: allSpecSheetTabs,
  },
  [bookingStandardColumns.deliveryInstructionsColumn]: {
    placementTabs: allSpecSheetTabs,
  },
  [bookingStandardColumns.creativeInstructionsColumn]: {
    placementTabs: allSpecSheetTabs,
  },
  [bookingStandardColumns.notesColumn]: {
    placementTabs: allSpecSheetTabs,
  },
  [bookingStandardColumns.dispatchDateColumn]: {
    placementTabs: allSpecSheetTabs,
  },
  [bookingStandardColumns.liveDateColumn]: {
    placementTabs: oohCategories,
  },
  [bookingStandardColumns.endDateColumn]: {
    placementTabs: oohCategories,
  },
  [placementStandardColumns.channelColumn]: {
    placementTabs: [specSheetTabSpecial.Overview],
  },
  [placementStandardColumns.placementColumn]: {
    placementTabs: allSpecSheetTabs,
  },
  [placementStandardColumns.statusColumn]: { placementTabs: [] },
  [placementStandardColumns.documentsColumn]: {
    placementTabs: [placementCategory.RICHMEDIA],
  },
  [bookingGroupStandardColumns.subGroup]: {
    placementTabs: allSpecSheetTabs,
  },
  [bookingGroupStandardColumns.topGroup]: {
    placementTabs: allSpecSheetTabs,
  },
  [placementStandardColumns.artworkScaleColumn]: {
    placementTabs: [placementCategory.PRINT_OOH, placementCategory.OOH_TRANSIT],
  },
  [placementStandardColumns.aspectRatiosColumn]: {
    placementTabs: [
      placementCategory.CINEMA,
      placementCategory.DISPLAY,
      placementCategory.NATIVE,
      placementCategory.ONLINE,
      placementCategory.SOCIAL,
      placementCategory.VIDEO,
      placementCategory.OTHER,
    ],
  },
  [placementStandardColumns.bitRateColumn]: {
    placementTabs: [
      placementCategory.CINEMA,
      placementCategory.VIDEO,
      placementCategory.OTHER,
    ],
  },
  [placementStandardColumns.bleedAreaColumn]: {
    placementTabs: [placementCategory.OOH_TRANSIT],
  },
  [placementStandardColumns.copyColumn]: {
    placementTabs: [placementCategory.NATIVE, placementCategory.SOCIAL],
  },
  [placementStandardColumns.trimColumn]: {
    placementTabs: [
      placementCategory.PRINT_MAGAZINE,
      placementCategory.PRINT_NEWSPAPER,
    ],
  },
  [placementStandardColumns.dimensionsColumn]: {
    placementTabs: [
      placementCategory.CINEMA,
      placementCategory.DISPLAY,
      placementCategory.NATIVE,
      placementCategory.ONLINE,
      placementCategory.SOCIAL,
      placementCategory.VIDEO,
      placementCategory.DOOH,
      placementCategory.OTHER,
      placementCategory.PRINT_OOH,
      placementCategory.OOH_TRANSIT,
    ],
  },
  [placementStandardColumns.availableDurationColumn]: {
    placementTabs: [
      placementCategory.CINEMA,
      placementCategory.NATIVE,
      placementCategory.ONLINE,
      placementCategory.RICHMEDIA,
      placementCategory.SOCIAL,
      placementCategory.VIDEO,
      placementCategory.DOOH,
      placementCategory.OTHER,
    ],
  },
  [placementStandardColumns.addressColumn]: {
    placementTabs: oohCategories,
  },
  [placementStandardColumns.siteIdColumn]: {
    placementTabs: oohCategories,
  },
  [placementStandardColumns.stateColumn]: {
    placementTabs: oohCategories,
  },
  [placementStandardColumns.suburbColumn]: {
    placementTabs: oohCategories,
  },
  [placementStandardColumns.physicalDimensionColumn]: {
    placementTabs: [placementCategory.PRINT_OOH, placementCategory.OOH_TRANSIT],
  },
  [placementStandardColumns.artWorkDimensionColumn]: {
    placementTabs: [placementCategory.PRINT_OOH, placementCategory.OOH_TRANSIT],
  },
  [placementStandardColumns.fileFormatsColumn]: {
    placementTabs: [
      specSheetTabSpecial.Overview,
      placementCategory.CINEMA,
      placementCategory.DISPLAY,
      placementCategory.NATIVE,
      placementCategory.ONLINE,
      placementCategory.SEM,
      placementCategory.SOCIAL,
      placementCategory.VIDEO,
      placementCategory.DOOH,
      placementCategory.PRINT_OOH,
      placementCategory.OOH_TRANSIT,
      placementCategory.PRINT_MAGAZINE,
      placementCategory.PRINT_NEWSPAPER,
      placementCategory.OTHER,
    ],
  },
  [placementStandardColumns.fileSizesColumn]: {
    placementTabs: [
      specSheetTabSpecial.Overview,
      placementCategory.CINEMA,
      placementCategory.DISPLAY,
      placementCategory.NATIVE,
      placementCategory.ONLINE,
      placementCategory.SOCIAL,
      placementCategory.VIDEO,
      placementCategory.DOOH,
      placementCategory.OTHER,
      placementCategory.PRINT_OOH,
      placementCategory.OOH_TRANSIT,
      placementCategory.PRINT_MAGAZINE,
      placementCategory.PRINT_NEWSPAPER,
    ],
  },
  [placementStandardColumns.frameRateColumn]: {
    placementTabs: [placementCategory.VIDEO],
  },
  [placementStandardColumns.resolutionColumn]: {
    placementTabs: [
      placementCategory.PRINT_OOH,
      placementCategory.OOH_TRANSIT,
      placementCategory.PRINT_MAGAZINE,
      placementCategory.PRINT_NEWSPAPER,
    ],
  },
  [placementStandardColumns.safeAreaColumn]: {
    placementTabs: [
      placementCategory.PRINT_OOH,
      placementCategory.OOH_TRANSIT,
      placementCategory.PRINT_NEWSPAPER,
      placementCategory.PRINT_MAGAZINE,
    ],
  },
  [placementStandardColumns.sampleRateColumn]: {
    placementTabs: [],
  },
  [bookingStandardColumns.requiredDurationColumn]: {
    placementTabs: [
      placementCategory.AUDIO,
      placementCategory.RADIO,
      placementCategory.BROADCAST,
      placementCategory.NATIVE,
      placementCategory.BVOD,
      placementCategory.SOCIAL,
      placementCategory.VIDEO,
    ],
  },
  [bookingStandardColumns.publicationsColumn]: {
    placementTabs: [
      specSheetTabSpecial.Overview,
      placementCategory.AUDIO,
      placementCategory.RADIO,
      placementCategory.BROADCAST,
      placementCategory.BVOD,
      placementCategory.CINEMA,
      placementCategory.DISPLAY,
      placementCategory.NATIVE,
      placementCategory.ONLINE,
      placementCategory.RICHMEDIA,
      placementCategory.SEM,
      placementCategory.SOCIAL,
      placementCategory.VIDEO,
      placementCategory.PRINT_MAGAZINE,
      placementCategory.PRINT_NEWSPAPER,
      placementCategory.OTHER,
    ],
  },
  [bookingStandardColumns.campaignCodeColumn]: {
    placementTabs: [placementCategory.PRINT_OOH, placementCategory.DOOH],
  },
};
