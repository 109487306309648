import {
  N_ArtworkSpec,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { notNull, notNullish } from "../../typescript";

export const getFrameRates = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>(
    (rates, spec) =>
      spec.value.kind === "video" || spec.value.kind === "html5"
        ? [
            ...rates,
            ...[
              spec.value.value.frameRate,
              spec.value.kind === "html5" &&
              notNull(spec.value.value.formats.value.video) &&
              notNull(
                spec.value.value.formats.value.video.value.video.frameRate
              )
                ? spec.value.value.formats.value.video.value.video.frameRate
                : null,
            ]
              .filter(notNull)
              .map((fr) => fr.value.value)
              .filter(notNullish)
              .map((fr) => ({
                ...spec,
                value: `${fr} FPS`,
              })),
          ]
        : rates,
    []
  );
