import { DateFormat } from "@adltools/dateformats";
import {
  bigDecimalFieldFns,
  FieldFns,
  instantFieldFns,
  labelledValuesFieldFns,
  localDateTimeFieldFns,
  localTimeFieldFns,
  mkLocalDateFieldFns,
  regexStringFieldFns,
  stringFieldFns,
} from "@adltools/fields";

import {
  getValidRegexAnnotation,
  getValidValuesAnnotation,
} from "./adl-annotations";
import {
  localDateVEditor,
  maybeLocalDateVEditor,
  nullableLocalDateVEditor,
} from "./adl-customize-common";
import { typeExprFieldFns } from "./adl-field";
import * as common from "./adl-gen/common";
import * as commonstrings from "./adl-gen/common/strings";
import * as adl from "./adl-gen/runtime/adl";
import { typeExprsEqual } from "./adl-gen/runtime/utils";
import * as adlast from "./adl-gen/sys/adlast";
import * as systypes from "./adl-gen/sys/types";
import { CustomContext, Customize, VEditor } from "./adl-veditor";

export { noCustomize } from "./adl-veditor";

const texprInstant = common.texprInstant().value;

const texprLocalDate = common.texprLocalDate().value;
const texprMaybeLocalDate = systypes.texprMaybe(common.texprLocalDate()).value;
const texprNullableLocalDate = adl.texprNullable(common.texprLocalDate()).value;

const texprLocalDateTime = common.texprLocalDateTime().value;

const texprLocalTime = common.texprLocalTime().value;

const texprTypeExpr = adlast.texprTypeExpr().value;

const texprString: adlast.TypeExpr = {
  typeRef: { kind: "primitive", value: "String" },
  parameters: [],
};
const texprStringML = commonstrings.texprStringML().value;
const texprStringMD = commonstrings.texprStringMD().value;

const texprBigDecimal = common.texprBigDecimal().value;

// const texprNullableStringML = adl.texprNullable(commonstrings.texprStringML()).value;

// Custom field editors for:
//     common.Instant
//     common.LocalDate
//     common.LocalDateTime
//     common.Time
//     sys.adlast.TypeExpr
//     fields of type string with a ValidRegex attribute
//     type aliases over a string, with a ValidRegex attribute

export interface Locale {
  dateFormat: DateFormat;
}

export function getCustomField(
  locale: Locale,
  ctx: CustomContext
): FieldFns<unknown> | null {
  if (typeExprsEqual(ctx.typeExpr, texprStringML)) {
    const fieldfns = { ...stringFieldFns, rows: 4 };
    return fieldfns;
  }

  if (typeExprsEqual(ctx.typeExpr, texprStringMD)) {
    const fieldfns = { ...stringFieldFns, rows: 10 };
    return fieldfns;
  }

  if (typeExprsEqual(ctx.typeExpr, texprInstant)) {
    return instantFieldFns;
  }

  if (typeExprsEqual(ctx.typeExpr, texprLocalDate)) {
    return mkLocalDateFieldFns(locale.dateFormat);
  }

  if (typeExprsEqual(ctx.typeExpr, texprLocalDateTime)) {
    return localDateTimeFieldFns;
  }

  if (typeExprsEqual(ctx.typeExpr, texprLocalTime)) {
    return localTimeFieldFns;
  }

  if (typeExprsEqual(ctx.typeExpr, texprBigDecimal)) {
    return bigDecimalFieldFns();
  }

  if (typeExprsEqual(ctx.typeExpr, texprTypeExpr)) {
    return typeExprFieldFns;
  }

  if (typeExprsEqual(ctx.typeExpr, texprString)) {
    if (ctx.field) {
      const fieldfns = getFieldFnsFromAnnotations(ctx, ctx.field.annotations);
      if (fieldfns) {
        return fieldfns;
      }
    }
  }

  const stringTypeDef = getStringTypeDef(ctx);
  if (stringTypeDef) {
    const fieldfns = getFieldFnsFromAnnotations(ctx, stringTypeDef.annotations);
    if (fieldfns) {
      return fieldfns;
    }
  }

  return null;
}

function getStringTypeDef(ctx: CustomContext): adlast.Decl | null {
  if (ctx.typeExpr.typeRef.kind === "reference") {
    const decl: adlast.ScopedDecl = ctx.declResolver(
      ctx.typeExpr.typeRef.value
    );
    if (decl.decl.type_.kind === "type_") {
      if (typeExprsEqual(decl.decl.type_.value.typeExpr, texprString)) {
        return decl.decl;
      }
    }
  }
  return null;
}

function getFieldFnsFromAnnotations(
  ctx: CustomContext,
  annotations: adlast.Annotations
): FieldFns<unknown> | null {
  const validRegex = getValidRegexAnnotation(ctx.declResolver, annotations);
  if (validRegex) {
    return regexStringFieldFns(validRegex.regex, validRegex.description);
  }
  const validValues = getValidValuesAnnotation(ctx.declResolver, annotations);
  if (validValues) {
    const mappings = validValues.values.map((v) => ({ value: v, label: v }));
    return labelledValuesFieldFns(
      validValues.description,
      (s1, s2) => s1 === s2,
      mappings
    );
  }
  return null;
}

export function getCustomVEditor(
  locale: Locale,
  ctx: CustomContext
): VEditor<unknown, unknown, unknown> | null {
  if (typeExprsEqual(ctx.typeExpr, texprLocalDate)) {
    return localDateVEditor(locale.dateFormat);
  } else if (typeExprsEqual(ctx.typeExpr, texprMaybeLocalDate)) {
    return maybeLocalDateVEditor(locale.dateFormat);
  } else if (typeExprsEqual(ctx.typeExpr, texprNullableLocalDate)) {
    return nullableLocalDateVEditor(locale.dateFormat);
  }
  return null;
}

// Customized UIs for the ADL types in sys.*
export function sysCustomize(locale: Locale): Customize {
  return {
    getCustomField: (ctx) => getCustomField(locale, ctx),
    getCustomVEditor: (ctx) => getCustomVEditor(locale, ctx),
  };
}
