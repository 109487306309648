import { N_Attributes, N_Specifications } from "src/adl";
import { idFlavor, N_ContactDetails, N_UrlResource } from "../../common";
import { N_PlacementCategory, N_PlacementLocation } from "./general";
import { z } from "zod";

export interface CreatePlacementData {
  name: string;
  publisherName: string;
  category: N_PlacementCategory;
  originalSpecUrl: N_UrlResource | null;
  documents: N_UrlResource[];
  specifications: N_Specifications;
  mediaOwnerContactDetails: N_ContactDetails | null;
  location: N_PlacementLocation | null;
  attributes: N_Attributes | null;
}

export type CreatePlacementReq = {
  campaignId: idFlavor<"Campaign">;
} & CreatePlacementData;

export interface UpdatePlacementData {
  name: string;
  publisherName: string;
  category: N_PlacementCategory;
  originalSpecUrl: N_UrlResource | null;
  documents: N_UrlResource[];
  specifications?: N_Specifications;
  mediaOwnerContactDetails: N_ContactDetails | null;
}

export interface UpdatePlacementReq {
  /***
   * @deprecated this was being used for permission checks instead
   * of inferring the campaign from the placementId. this can lead to a security
   * vulnerability where someone can inject a dummy campaign they have access
   * to instead of the actual campaign.
   */
  campaignId: idFlavor<"Campaign">;
  placementId: idFlavor<"Placement">;
  name: string;
  category: N_PlacementCategory;
  originalSpecUrl: N_UrlResource;
  oldDocuments: N_UrlResource[];
  documents: N_UrlResource[];
  specifications?: N_Specifications;
  mediaOwnerContactDetails: N_ContactDetails;
  location: N_PlacementLocation | null;
  attributes: N_Attributes;
}

type SortOrder = "asc" | "desc";

export interface PlacementListReq {
  searchTerms?: string[];
  publisherIds?: idFlavor<"Publisher">[];
  publicationIds?: idFlavor<"Publication">[];
  countries?: string[];
  categories?: N_PlacementCategory[];
  sortByMediaOwner?: SortOrder;
  sortByCategory?: SortOrder;
  sortByName?: SortOrder;
  size: number;
  skip: number;
  organisationId: idFlavor<"Organisation">;
}

export interface UpdateMissingPlacementReq {
  campaignId: idFlavor<"Campaign">;
  placementId: idFlavor<"Placement">;
  name: string;
  category: N_PlacementCategory;
  originalSpecUrl: N_UrlResource | null;
  originalSpecRelativeUrl: N_UrlResource | null;
  documents: N_UrlResource[];
  mediaOwnerContactDetails: N_ContactDetails | null;
}

export type CheckToSpecDto = z.infer<typeof checkToSpecDto>;

export const checkToSpecDto = z.object({
  url: z.string().url(),
});
