import z from "zod";

export const goMediaDigitalBookingSchema = z.object({
  campaignId: z.string(),
  campaignName: z.string(),
  advertiser: z.string(),
  billingClient: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  siteId: z.string(),
  panelName: z.string(),
  image: z.string(),
  originalAdSpecs: z.string(),
  address: z.string(),
  suburb: z.string(),
  postcode: z.string(),
  region: z.string(),
  country: z.string(),
  physicalSize: z.string(),
  standardPixelWidth: z.string(),
  standardPixelHeight: z.string(),
  dynamicPixelWidth: z.string(),
  dynamicPixelHeight: z.string(),
  aspectRatio: z.string(),
  dpi: z.string(),
  formats: z.string(),
  maxFileSize: z.string(),
  colour: z.string(),
  deliveryDeadlines: z.string(),
  asaAndWakaKohatiRequirements: z.string(),
  artworkSpecNotes: z.string(),
  dispatch: z.string(),
});

export type GoMediaDigitalBooking = z.infer<typeof goMediaDigitalBookingSchema>;
