import { z } from "zod";
import {
  campaignMetaLogIntent,
  campaignMetaLogRecordType,
} from "../campaignMetaLog";

export const createCampaignGroupDto = z.object({
  campaignId: z.string(),
  groupName: z.string(),
  groupLevel: z.literal(0).or(z.literal(1)),
  groupParent: z.string().nullable(),
  bookingIds: z.array(z.string()).min(1, {
    message: "Groups can only be created from a selection of booking ids",
  }),
  groupIds: z.array(z.string()).optional(),
});

export type CreateCampaignGroupDto = z.infer<typeof createCampaignGroupDto>;

export const updateCampaignGroupDto = z.object({
  groupId: z.string(),
  groupName: z.string().optional(),
  parentId: z.string().nullish(),
  campaignMetaLogIntent: z
    .literal(campaignMetaLogIntent.MOVE)
    .or(z.literal(campaignMetaLogIntent.AMEND)),
});

export type UpdateCampaignGroupDto = z.infer<typeof updateCampaignGroupDto>;

export const moveBookingsToCampaignGroupDto = z.object({
  /***
   * @deprecated this was being used for permission checks instead
   * of inferring the campaign from the group. this can lead to a security
   * vulnerability where someone can inject a dummy campaign they have access
   * to instead of the actual campaign the group belongs to.
   */
  campaignId: z.string(),
  groupId: z.string(),
  bookingIds: z.array(z.string()).min(1, {
    message: "Must select bookings to be moved into a group",
  }),
});

export type MoveBookingsToCampaignGroupDto = z.infer<
  typeof moveBookingsToCampaignGroupDto
>;

export const moveGroupsToCampaignGroupDto = z.object({
  /***
   * @deprecated this was being used for permission checks instead
   * of inferring the campaign from the group. this can lead to a security
   * vulnerability where someone can inject a dummy campaign they have access
   * to instead of the actual campaign the group belongs to.
   */
  campaignId: z.string(),
  groupId: z.string(),
  groupIds: z.array(z.string()).min(1, {
    message: "Must select at least one group to move",
  }),
});

export type MoveGroupsToCampaignGroupDto = z.infer<
  typeof moveGroupsToCampaignGroupDto
>;

export const ungroupFromCampaignGroupDto = z.object({
  campaignId: z.string(),
  bookingsToRegroup: z
    .array(
      z.object({
        bookingId: z.string(),
        groupId: z.string(),
      })
    )
    .optional(),
  bookingsToUngroup: z.array(z.string()).optional(),
  groupsToUngroup: z.array(z.string()).optional(),
  cmlRecordType: z
    .literal(campaignMetaLogRecordType.BOOKING)
    .or(z.literal(campaignMetaLogRecordType.BOOKINGGROUP)),
});

export type UngroupFromCampaignGroupDto = z.infer<
  typeof ungroupFromCampaignGroupDto
>;
