/* @generated from adl module kachemedia.specsheet.clients */

import * as ADL from './../../runtime/adl';
import * as common_db from './../../common/db';
import * as common_strings from './../../common/strings';
import * as kachemedia_specsheet_common from './common';
import * as kachemedia_specsheet_db from './db';

export interface ClientData {
  name: common_strings.StringNE;
  newLogo: (kachemedia_specsheet_common.ImageHash|null);
}

export function makeClientData(
  input: {
    name: common_strings.StringNE,
    newLogo?: (kachemedia_specsheet_common.ImageHash|null),
  }
): ClientData {
  return {
    name: input.name,
    newLogo: input.newLogo === undefined ? null : input.newLogo,
  };
}

const ClientData_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.clients","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[],"serializedName":"newLogo","default":{"kind":"just","value":null},"name":"newLogo","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.common","name":"ImageHash"}},"parameters":[]}]}}]}},"name":"ClientData","version":{"kind":"nothing"}}};

export const snClientData: ADL.ScopedName = {moduleName:"kachemedia.specsheet.clients", name:"ClientData"};

export function texprClientData(): ADL.ATypeExpr<ClientData> {
  return {value : {typeRef : {kind: "reference", value : snClientData}, parameters : []}};
}

export interface UpdateClientReq {
  clientId: common_db.DbKey<kachemedia_specsheet_db.Client>;
  data: ClientData;
  clearLogo: boolean;
}

export function makeUpdateClientReq(
  input: {
    clientId: common_db.DbKey<kachemedia_specsheet_db.Client>,
    data: ClientData,
    clearLogo?: boolean,
  }
): UpdateClientReq {
  return {
    clientId: input.clientId,
    data: input.data,
    clearLogo: input.clearLogo === undefined ? false : input.clearLogo,
  };
}

const UpdateClientReq_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.clients","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"clientId","default":{"kind":"nothing"},"name":"clientId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Client"}},"parameters":[]}]}},{"annotations":[],"serializedName":"data","default":{"kind":"nothing"},"name":"data","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.clients","name":"ClientData"}},"parameters":[]}},{"annotations":[],"serializedName":"clearLogo","default":{"kind":"just","value":false},"name":"clearLogo","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}}]}},"name":"UpdateClientReq","version":{"kind":"nothing"}}};

export const snUpdateClientReq: ADL.ScopedName = {moduleName:"kachemedia.specsheet.clients", name:"UpdateClientReq"};

export function texprUpdateClientReq(): ADL.ATypeExpr<UpdateClientReq> {
  return {value : {typeRef : {kind: "reference", value : snUpdateClientReq}, parameters : []}};
}

export interface ChangeClientIsActiveStatus {
  clientId: common_db.DbKey<kachemedia_specsheet_db.Client>;
  isActive: boolean;
}

export function makeChangeClientIsActiveStatus(
  input: {
    clientId: common_db.DbKey<kachemedia_specsheet_db.Client>,
    isActive: boolean,
  }
): ChangeClientIsActiveStatus {
  return {
    clientId: input.clientId,
    isActive: input.isActive,
  };
}

const ChangeClientIsActiveStatus_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.clients","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"clientId","default":{"kind":"nothing"},"name":"clientId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"kachemedia.specsheet.db","name":"Client"}},"parameters":[]}]}},{"annotations":[],"serializedName":"isActive","default":{"kind":"nothing"},"name":"isActive","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}}]}},"name":"ChangeClientIsActiveStatus","version":{"kind":"nothing"}}};

export const snChangeClientIsActiveStatus: ADL.ScopedName = {moduleName:"kachemedia.specsheet.clients", name:"ChangeClientIsActiveStatus"};

export function texprChangeClientIsActiveStatus(): ADL.ATypeExpr<ChangeClientIsActiveStatus> {
  return {value : {typeRef : {kind: "reference", value : snChangeClientIsActiveStatus}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "kachemedia.specsheet.clients.ClientData" : ClientData_AST,
  "kachemedia.specsheet.clients.UpdateClientReq" : UpdateClientReq_AST,
  "kachemedia.specsheet.clients.ChangeClientIsActiveStatus" : ChangeClientIsActiveStatus_AST
};
