import { idFlavor } from "../../common";

export const notificationSubRoute = {
  list: "list",
  changeStatus: "changestatus",
  markAllAsRead: "markallasread",
} as const;

export type NotificationRoutes =
  | {
      sub: typeof notificationSubRoute.list;
      method?: "Get";
    }
  | {
      sub: typeof notificationSubRoute.changeStatus;
      tailParam: idFlavor<"Notification">;
      method?: "Post";
    }
  | {
      sub: typeof notificationSubRoute.markAllAsRead;
      method?: "Get";
    };
