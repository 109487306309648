import { SimplifiedTimelineBooking } from "../booking";

export const bookingInfoSchemaConfigChangeKind = {
  mergeCells: "mergeCells",
  unmergeCells: "unmergeCells",
} as const;

export type BookingInfoSchemaConfigChangeKind =
  (typeof bookingInfoSchemaConfigChangeKind)[keyof typeof bookingInfoSchemaConfigChangeKind];

export interface BookingInfoSchemaConfigEdit_MergeCells {
  kind: typeof bookingInfoSchemaConfigChangeKind.mergeCells;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  booking: SimplifiedTimelineBooking;
  columnName: string;
}
export interface BookingInfoSchemaConfigEdit_UnmergeCells {
  kind: typeof bookingInfoSchemaConfigChangeKind.unmergeCells;
  placementName: string | undefined;
  placementSummary: string | undefined;
  publisherName: string | undefined;
  booking: SimplifiedTimelineBooking;
  columnName: string;
}

export type BookingInfoSchemaConfigEdit =
  | BookingInfoSchemaConfigEdit_MergeCells
  | BookingInfoSchemaConfigEdit_UnmergeCells;
