import { idFlavor, N_ChangedValue } from "../../common";
import { BookingWithHistoryTyped, N_BookingEdit } from "../booking";
import { N_BookingGroupEdit } from "../bookingGroup";
import { N_CampaignDetailsEdit, N_CampaignUpdatedValues } from "../campaign";
import { N_CampaignVersion } from "../campaignVersion";
import {
  N_BookingInfoSchemaEdit,
  N_BookingInfoSchemaEditWithData,
} from "../infoSchema";
import { CampaignContractVariationEdit } from "src/entities/external";
import { BookingInfoSchemaConfigEdit } from "../bookingInfoSchemaFieldConfig/edits";

export interface N_BookingPublicationEdit {
  bookingId: idFlavor<"Booking">;
  publicationsChanged: N_ChangedValue<idFlavor<"Publication">[]>;
  title: string;
  booking?: BookingWithHistoryTyped;
  publisherName: string;
}

export interface N_ColumnEdit {
  kind: "columnAdded" | "columnUpdated" | "columnDeleted";
  columnId: idFlavor<"InfoSchemaField">;
  columnTypeChanged?: N_ChangedValue<string> | null;
  columnNameChanged?: N_ChangedValue<string> | null;
  columnColourChanged?: N_ChangedValue<string> | null;
  columnPositionChanged?: N_ChangedValue<string> | null;
  columnHidden?: N_ChangedValue<string> | null;
  columnAdded: boolean;
  columnDeleted: boolean;
  columnName: string;
}

export const campaignChangeKind = {
  campaignEdit: "campaignEdit",
  bookingEdit: "bookingEdit",
  multiRowAdded: "multiRowAdded",
  multiRowDeleted: "multiRowDeleted",
  groupEdit: "groupEdit",
  bookingPublicationEdit: "bookingPublicationEdit",
  bookingInfoSchemaEdit: "bookingInfoSchemaEdit",
  // "campaignDetailsEdit" is the legacy type when only detail edits were tracked
  campaignDetailsEdit: "campaignDetailsEdit",
  columnEdit: "columnEdit",
  contractVariation: "contractVariation",
  revertEvent: "revertEvent",
  versionShared: "versionShared",
  bookingInfoSchemaConfigEdit: "bookingInfoSchemaConfigEdit",
} as const;

export type CampaignChangeKind =
  (typeof campaignChangeKind)[keyof typeof campaignChangeKind];

export interface N_CampaignEdit_CampaignEdit {
  kind: typeof campaignChangeKind.campaignEdit;
  value: N_CampaignDetailsEdit;
}

export interface N_CampaignEdit_BookingEdit {
  kind: typeof campaignChangeKind.bookingEdit;
  value: N_BookingEdit;
}

export interface N_CampaignEdit_MultiRowAdded {
  kind: typeof campaignChangeKind.multiRowAdded;
  value: N_BookingEdit;
}

export interface N_CampaignEdit_MultiRowDeleted {
  kind: typeof campaignChangeKind.multiRowDeleted;
  value: N_BookingEdit;
}

export interface N_CampaignEdit_GroupEdit {
  kind: typeof campaignChangeKind.groupEdit;
  value: N_BookingGroupEdit;
}

export interface CampaignEdit_ContractEdit {
  kind: typeof campaignChangeKind.contractVariation;
  value: CampaignContractVariationEdit;
}

export interface N_CampaignEdit_BookingPublicationEdit {
  kind: typeof campaignChangeKind.bookingPublicationEdit;
  value: N_BookingPublicationEdit;
}
export interface N_CampaignEdit_BookingInfoSchemaEdit {
  kind: typeof campaignChangeKind.bookingInfoSchemaEdit;
  value: N_BookingInfoSchemaEdit;
}
export interface N_CampaignEdit_BookingInfoSchemaEditWithData {
  kind: typeof campaignChangeKind.bookingInfoSchemaEdit;
  value: N_BookingInfoSchemaEditWithData | undefined;
}
export interface N_CampaignEdit_CampaignDetailsEdit {
  kind: typeof campaignChangeKind.campaignDetailsEdit;
  value: N_CampaignUpdatedValues;
}

export interface N_CampaignEdit_ColumnEdit {
  kind: typeof campaignChangeKind.columnEdit;
  value: N_ColumnEdit;
}

export interface N_CampaignEdit_RevertEvent {
  kind: typeof campaignChangeKind.revertEvent;
  value: {
    revertTimestamp: Date;
  };
}

export interface N_CampaignEdit_VersionShared {
  kind: typeof campaignChangeKind.versionShared;
  value: N_CampaignVersion;
}

export interface N_BookingInfoSchemaConfigAmend {
  kind: typeof campaignChangeKind.bookingInfoSchemaConfigEdit;
  value: BookingInfoSchemaConfigEdit;
}

export type N_CampaignEdit =
  | N_CampaignEdit_BookingEdit
  | N_CampaignEdit_GroupEdit
  | N_CampaignEdit_BookingPublicationEdit
  | N_CampaignEdit_BookingInfoSchemaEdit
  | N_CampaignEdit_CampaignDetailsEdit
  | CampaignEdit_ContractEdit;

export type N_CampaignEditWithInfoSchemaLog =
  | N_CampaignEdit_CampaignEdit
  | N_CampaignEdit_BookingEdit
  | N_CampaignEdit_GroupEdit
  | N_CampaignEdit_BookingPublicationEdit
  | N_CampaignEdit_BookingInfoSchemaEditWithData
  | N_CampaignEdit_CampaignDetailsEdit
  | N_CampaignEdit_ColumnEdit
  | CampaignEdit_ContractEdit
  | N_CampaignEdit_RevertEvent
  | N_CampaignEdit_VersionShared
  | N_CampaignEdit_MultiRowAdded
  | N_CampaignEdit_MultiRowDeleted
  | N_BookingInfoSchemaConfigAmend;

export interface SimplifiedTimelineUser {
  userId: idFlavor<"AppUser">;
  name: string;
  lastName: string;
}

export interface N_CampaignChange {
  id: idFlavor<"CampaignChange">;
  user?: SimplifiedTimelineUser;
  changedByExtPublisher?: string;
  date: number;
  changes: N_CampaignEditWithInfoSchemaLog[];
  campaignVersion: number;
  isConsolidated: boolean;
  campaignMetaLogId: idFlavor<"CampaignMetaLog"> | null;
}
