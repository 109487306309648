import {
  bitRateUnit,
  N_ArtworkSpec,
  N_BitRate,
  N_BitRateUnit,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { notNull } from "../../typescript";

export const getBitRateUnit = (unit: N_BitRateUnit): string => {
  switch (unit) {
    case bitRateUnit.Kbps:
      return "Kbps";
    case bitRateUnit.Mbps:
      return "Mbps";
  }
};

export const stringifyBitRate = (bitRate: N_BitRate): string =>
  `${bitRate.value} ${getBitRateUnit(bitRate.unit)}`;

const getBitRateNumber = (bitRate: N_BitRate): number => {
  switch (bitRate.unit) {
    case bitRateUnit.Kbps:
      return bitRate.value;
    case bitRateUnit.Mbps:
      return bitRate.value * 1024;
  }
};

export const getBitRates = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): (N_NamedAnnotated<string> & { numberValue: number })[] =>
  specifications.reduce<(N_NamedAnnotated<string> & { numberValue: number })[]>(
    (rates, spec) =>
      spec.value.kind === "video" || spec.value.kind === "html5"
        ? [
            ...rates,
            ...[
              spec.value.kind === "video" ? spec.value.value.bitrate : null,
              spec.value.kind === "html5" &&
              notNull(spec.value.value.formats.value.video)
                ? spec.value.value.formats.value.video.value.video.bitrate
                : null,
            ]
              .filter(notNull)
              .map((br) => ({
                ...spec,
                value: stringifyBitRate(br.value),
                numberValue: getBitRateNumber(br.value),
              })),
          ]
        : rates,
    []
  );
