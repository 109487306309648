// DO NOT USE, DEPRECATED
import { notNull } from "@specsheet-common/shared-tools";
import classnames from "classnames";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./table.css";

export type TableAlign = "TOP" | "MIDDLE" | "BOTTOM";

export interface TableProps {
  /** Additional classes to apply */
  className?: string;
  /** Table rows */
  children: React.ReactNode;
  /** Animations */
  animate?: boolean;
  /** Table cells align */
  align?: TableAlign;
  /** Table id */
  id?: string;
}

/** Props for a table row */
export interface TableRowProps {
  /** Additional classes to apply */
  className?: string;
  /** Whether this is a header row */
  header?: boolean;
  /** Table columns */
  children: React.ReactNode;
  /** Tool tip message */
  title?: string;
  /** Click callback */
  onClick?(): void;
}

/** Props for a table cell */
export interface TableCellProps {
  /** Additional classes to apply */
  className?: string;
  /** Optional title to be displayed on hover */
  title?: string;
  /** Cell contents */
  children?: React.ReactNode;
  /** Click callback */
  onClick?(e: React.BaseSyntheticEvent): void;
  /** Col span */
  colSpan?: number;
  /** Inline Css Properties */
  style?: CSSProperties;
}

/**
 * Table component, to be used as the outermost component of a table
 * definition, similar to `table` HTML element.
 */
@observer
export class Table extends React.Component<TableProps> {
  /** Partitions the rows into header and body rows */
  partitionRows(): { headerRows: JSX.Element[]; bodyRows: JSX.Element[] } {
    const headerRows = [];
    const bodyRows = [];

    const childrenAsArray =
      React.Children.map(this.props.children, (child) => {
        if (
          typeof child !== "string" &&
          typeof child !== "number" &&
          typeof child !== "boolean" &&
          child !== undefined &&
          child !== null &&
          "props" in child
        ) {
          return child;
        }
        return null;
      })?.filter(notNull) || [];

    for (const child of childrenAsArray) {
      const props = child.props;
      if (props.header) {
        headerRows.push(child);
      } else {
        bodyRows.push(child);
      }
    }

    return { headerRows, bodyRows };
  }

  /** Renders the component */
  render() {
    const { headerRows, bodyRows } = this.partitionRows();
    const { align, className, id } = this.props;

    const bodyRowsToRender = bodyRows.map((bodyRow, i) => (
      <CSSTransition
        key={i}
        classNames={{
          enter: styles.highlightRow,
          exit: styles.fadeRow,
          exitActive: styles.fadeRowActive,
        }}
        timeout={{ enter: 1000, exit: 200 }}
      >
        {bodyRow}
      </CSSTransition>
    ));

    return (
      <table
        id={id}
        className={classnames(styles.table, className, styles.middleAlign, {
          [styles.topAlign]: align === "TOP",
          [styles.bottomAlign]: align === "BOTTOM",
        })}
      >
        {headerRows.length > 0 ? (
          <thead className={styles.thead}>{headerRows}</thead>
        ) : undefined}
        {bodyRowsToRender.length > 0 ? (
          <tbody className={styles.tbody}>
            {this.props.animate ? (
              <TransitionGroup component={React.Fragment}>
                {bodyRowsToRender}
              </TransitionGroup>
            ) : (
              bodyRows
            )}
          </tbody>
        ) : undefined}
      </table>
    );
  }
}

/**
 * A table row, similar to `tr` HTML element.
 */
@observer
export class TableRow extends React.Component<TableRowProps> {
  /** Renders the component */
  render() {
    return (
      <tr
        className={classnames(
          styles.tableRow,
          this.props.className,
          this.props.onClick && styles.clickable
        )}
        title={this.props.title}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </tr>
    );
  }
}

/**
 * A table cell, similar to a `td` or `th` HTML element.
 */
@observer
export class TableCell extends React.Component<TableCellProps> {
  /** Renders the component */
  render() {
    return (
      <td
        title={this.props.title}
        className={classnames(styles.tableCell, this.props.className, {
          [styles.clickable]: this.props.onClick,
        })}
        onClick={this.props.onClick}
        colSpan={this.props.colSpan}
        style={this.props.style}
      >
        {this.props.children}
      </td>
    );
  }
}
