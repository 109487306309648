import React, { createContext, useEffect, useReducer } from "react";
import { ProgressFallback } from "src/ui/components/shared/system/ProgressFallback";
import { useNavigate } from "react-router-dom";
import {
  coreRoutes,
  AuthWhoAmIRes,
  BasicOrgAccessInfo,
  BasicWorkspaceAccessInfo,
  N_UserProfile,
  ORGANISATION_PERMISSIONS,
  orgMemberRole,
  orgMemberStatus,
  routePaths,
  urlBuilder,
  WhoAmIReq,
  WORKSPACE_PERMISSIONS,
  workspaceMemberRole,
  UserOnboardingRes,
  authSubRoute,
} from "@specsheet-common/shared-types";
import { useAuth0 } from "@auth0/auth0-react";
import { useApi } from "../configuration/api";

export type AuthOrganisation = Omit<BasicOrgAccessInfo, "permissions"> & {
  permissions: Set<ORGANISATION_PERMISSIONS>;
};

export type AuthWorkspace = Omit<BasicWorkspaceAccessInfo, "permissions"> & {
  permissions: Set<WORKSPACE_PERMISSIONS>;
};

interface State {
  isAuthenticated: boolean;
  isInitialised: boolean;
  hasWorkspaceAccess: boolean;
  profile: N_UserProfile;
  organisation: AuthOrganisation;
  workspace: AuthWorkspace;
  isOpsManager: boolean;
  isInternalUser: boolean;
  allOrgs: BasicOrgAccessInfo[];
}

const EMPTY_WORKSPACE = {
  id: "",
  name: "",
  logo: "",
  isPaid: true,
  role: workspaceMemberRole.GUEST,
  permissions: new Set<WORKSPACE_PERMISSIONS>(),
};

const EMPTY_ORGANISATION = {
  id: "noActiveOrg",
  name: "No active organisations",
  logo: "",
  config: null,
  role: orgMemberRole.BASIC,
  status: orgMemberStatus.Removed,
  permissions: new Set<ORGANISATION_PERMISSIONS>(),
  orgMemberId: "",
  hidden: true,
};

const initialAuthState: State = {
  isAuthenticated: false,
  isInitialised: false,
  profile: {
    userId: "",
    profilePic: "",
    lastName: "",
    name: "",
    email: "",
    requiresOnboarding: false,
  },
  organisation: EMPTY_ORGANISATION,
  workspace: EMPTY_WORKSPACE,
  isOpsManager: false,
  isInternalUser: false,
  hasWorkspaceAccess: false,
  allOrgs: [],
};

type ReducerAction =
  | {
      payload:
        | {
            isAuthenticated: true;
            whoAmIData: AuthWhoAmIRes;
          }
        | { isAuthenticated: false };
      type: "INITIALISE";
    }
  | {
      payload: {
        profile: N_UserProfile;
      };
      type: "UPDATE_SELF_DETAILS";
    }
  | {
      payload?: never;
      type: "LOGOUT";
    }
  | {
      type: "FINALISE_ONBOARDING";
      payload: UserOnboardingRes;
    };

const populateWorkspaceWithPermissions = (
  workspace: BasicWorkspaceAccessInfo
): State["workspace"] => {
  return { ...workspace, permissions: new Set(workspace.permissions) };
};

const populateOrganisationWithPermissions = (
  org?: BasicOrgAccessInfo
): State["organisation"] => {
  return org
    ? { ...org, permissions: new Set(org.permissions) }
    : EMPTY_ORGANISATION;
};

const reducer = (state: State, action: ReducerAction): State => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated } = action.payload;

      if (!action.payload.isAuthenticated) {
        return {
          ...initialAuthState,
          isInitialised: true,
        };
      }

      const {
        workspace,
        organisation,
        userProfile,
        isOpsManager,
        isInternalUser,
        allOrgs,
      } = action.payload.whoAmIData;

      if (!workspace) {
        return {
          ...state,
          isInitialised: true,
          isAuthenticated,
          organisation: populateOrganisationWithPermissions(organisation),
          profile: userProfile,
          isOpsManager: Boolean(isOpsManager),
          isInternalUser: Boolean(isInternalUser),
          allOrgs,
        };
      }

      return {
        ...state,
        isInitialised: true,
        isAuthenticated,
        workspace: populateWorkspaceWithPermissions(workspace),
        organisation: populateOrganisationWithPermissions(organisation),
        profile: userProfile,
        isOpsManager: Boolean(isOpsManager),
        isInternalUser: Boolean(isInternalUser),
        allOrgs,
      };
    }
    case "LOGOUT": {
      return {
        ...initialAuthState,
        isInitialised: true,
        isAuthenticated: false,
      };
    }
    case "UPDATE_SELF_DETAILS": {
      const { profile } = action.payload;

      return {
        ...state,
        profile,
      };
    }
    case "FINALISE_ONBOARDING": {
      const data = action.payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          name: data.name,
          lastName: data.lastName,
          requiresOnboarding: false,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

type AuthContextProps = State & {
  logout(): void;
  reinitialise(): void;
  finaliseOnboarding(data: UserOnboardingRes): void;
};

export const AuthContext = createContext<AuthContextProps | null>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const navigate = useNavigate();
  const {
    isAuthenticated: isAuthenticated0,
    user,
    getAccessTokenSilently,
    logout: auth0Logout,
  } = useAuth0();
  const api = useApi();

  const doLogin = async () => {
    const whoAmIRes = await api.post<never, AuthWhoAmIRes, WhoAmIReq>(
      urlBuilder({
        root: coreRoutes.auth,
        sub: authSubRoute.whoAmI,
      })
    );

    dispatch({
      type: "INITIALISE",
      payload: { isAuthenticated: true, whoAmIData: whoAmIRes },
    });
  };

  const finaliseOnboarding = (data: UserOnboardingRes) => {
    try {
      dispatch({
        type: "FINALISE_ONBOARDING",
        payload: data,
      });
    } catch {}
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    auth0Logout();
    navigate(routePaths.login);
  };

  const initialise = async (data: WhoAmIReq) => {
    try {
      const accessToken = await getAccessTokenSilently();
      if (accessToken) {
        try {
          const reqData: WhoAmIReq = data;

          const whoAmIRes = await api.post<never, AuthWhoAmIRes, WhoAmIReq>(
            urlBuilder({
              root: coreRoutes.auth,
              sub: authSubRoute.whoAmI,
            }),
            reqData
          );

          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              whoAmIData: whoAmIRes,
            },
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } else {
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      dispatch({
        type: "INITIALISE",
        payload: {
          isAuthenticated: false,
        },
      });
    }
  };

  const reinitialise = async () => {
    await initialise(undefined);
  };

  useEffect(() => {
    void reinitialise();
  }, []);

  useEffect(() => {
    if (isAuthenticated0 && user?.email && !state.isAuthenticated) {
      void getAccessTokenSilently().then(doLogin);
    }
  }, [isAuthenticated0]);

  if (!state.isInitialised) {
    return <ProgressFallback />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        reinitialise,
        logout,
        finaliseOnboarding,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
