import {
  N_ArtworkSpec,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { notNull } from "../../typescript";
import { stringifyPrintDimension } from "./stringify";

export const getArtWorkDimensions = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>(
    (dimensions, spec) =>
      spec.value.kind === "printOOH" &&
      notNull(spec.value.value.artworkDimension)
        ? notNull(spec.value.value.artworkDimension.value.visualSize)
          ? [
              ...dimensions,
              {
                ...spec,
                value: stringifyPrintDimension(
                  spec.value.value.artworkDimension.value.visualSize.value
                ),
              },
            ]
          : notNull(spec.value.value.artworkDimension.value.finishSize)
          ? [
              ...dimensions,
              {
                ...spec,
                value: stringifyPrintDimension(
                  spec.value.value.artworkDimension.value.finishSize.value
                ),
              },
            ]
          : dimensions
        : spec.value.kind === "magazine" && notNull(spec.value.value.trim)
        ? [
            ...dimensions,
            {
              ...spec,
              value: stringifyPrintDimension(spec.value.value.trim.value),
            },
          ]
        : dimensions,
    []
  );
