import z from "zod";

export const mediaWorksStaticPanelSchema = z.object({
  optionDetails: z.string(),
  clientName: z.string(),
  quantity: z.string(),
  size: z.string(),
  panel: z.string(),
  panelAddress: z.string(),
  region: z.string(),
  panelGradeName: z.string(),
  startDate: z.string(),
  fixtureType: z.string(),
  illuminationType: z.string(),
  visualWidth: z.string(),
  visualHeight: z.string(),
  bleedWidth: z.string(),
  bleedHeight: z.string(),
  bleedTop: z.string(),
  bleedBottom: z.string(),
  bleedLeft: z.string(),
  bleedRight: z.string(),
  physicalWidth: z.string(),
  physicalHeight: z.string(),
  productionComments: z.string(),
  productionSpecs1: z.string(),
  panelCensorships: z.string(),
  panelProhibitions: z.string(),
  installCompanyName: z.string(),
  panelDirection: z.string(),
  dimensions: z.string(),
  illuminated: z.string(),
});

export type MediaWorksStaticPanel = z.infer<typeof mediaWorksStaticPanelSchema>;
