/* @generated from adl module kachemedia.specsheet.uiconfig */

import * as ADL from './../../runtime/adl';
import * as common_config_log from './../../common/config/log';

/**
 * Configuration for the web frontend.
 */
export interface UiConfig {
  /**
   * Application name
   */
  name: string;
  /**
   * Environment name, e.g. dev, uat, prod
   */
  environment: string;
  /**
   * Name of the release / code version
   */
  releaseName: string;
  /**
   * Client side Rollbar config
   */
  rollbar: (common_config_log.ClientRollbarConfig|null);
  /**
   * Google Client Id
   */
  googleClientId: string;
}

export function makeUiConfig(
  input: {
    name: string,
    environment: string,
    releaseName: string,
    rollbar?: (common_config_log.ClientRollbarConfig|null),
    googleClientId: string,
  }
): UiConfig {
  return {
    name: input.name,
    environment: input.environment,
    releaseName: input.releaseName,
    rollbar: input.rollbar === undefined ? null : input.rollbar,
    googleClientId: input.googleClientId,
  };
}

const UiConfig_AST : ADL.ScopedDecl =
  {"moduleName":"kachemedia.specsheet.uiconfig","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Configuration for the web frontend.\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Application name\n"}],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Environment name, e.g. dev, uat, prod\n"}],"serializedName":"environment","default":{"kind":"nothing"},"name":"environment","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Name of the release / code version\n"}],"serializedName":"releaseName","default":{"kind":"nothing"},"name":"releaseName","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Client side Rollbar config\n"}],"serializedName":"rollbar","default":{"kind":"just","value":null},"name":"rollbar","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"common.config.log","name":"ClientRollbarConfig"}},"parameters":[]}]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Google Client Id\n"}],"serializedName":"googleClientId","default":{"kind":"nothing"},"name":"googleClientId","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}]}},"name":"UiConfig","version":{"kind":"nothing"}}};

export const snUiConfig: ADL.ScopedName = {moduleName:"kachemedia.specsheet.uiconfig", name:"UiConfig"};

export function texprUiConfig(): ADL.ATypeExpr<UiConfig> {
  return {value : {typeRef : {kind: "reference", value : snUiConfig}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "kachemedia.specsheet.uiconfig.UiConfig" : UiConfig_AST
};
