import { idFlavor } from "../../common";
import {
  infoSchemaFieldValueZod,
  infoSchemaValueChangeSourceType,
} from "../infoSchema";
import { updateBookingInfo } from "./general";
import { CreatePlacementData } from "../placement";
import { CampaignMetaLogRecordType } from "../campaignMetaLog";
import { z } from "zod";

export interface CreateBookingData {
  duration?: string;
  notes?: string;
}

export interface CreateCampaignBookingsReq {
  campaignId: idFlavor<"Campaign">;
  groupId?: idFlavor<"BookingGroup">;
  placementIds: idFlavor<"Placement">[];
  publicationIds?: idFlavor<"Publication">[];
}

export interface UpdateCampaignBookingPublicationsReq {
  bookingId: idFlavor<"Booking">;
  publications: idFlavor<"Publication">[];
}

export interface CreateCampaignBookingWithPlacementReq {
  campaignId: idFlavor<"Campaign">;
  groupId?: idFlavor<"BookingGroup">;
  booking: CreateBookingData;
  placement: CreatePlacementData;
}

export const bookingDetailsChange = z.discriminatedUnion("kind", [
  z.strictObject({
    kind: z.literal("infoSchemaUpdates"),
    values: z.array(infoSchemaFieldValueZod),
  }),
  z.strictObject({
    kind: z.literal("standardFieldUpdates"),
    value: updateBookingInfo,
  }),
]);

export type BookingDetailsChange = z.infer<typeof bookingDetailsChange>;

export const bookingDetailsChangeReq = z.strictObject({
  bookingId: z.string(),
  infoSchemaValueId: z.string().optional(),
  changes: z.array(bookingDetailsChange),
});

export type BookingDetailsChangeReq = z.infer<typeof bookingDetailsChangeReq>;

export const updateCampaignBookingsDto = z.strictObject({
  campaignId: z.string(),
  updates: z.array(bookingDetailsChangeReq),
  source: z.discriminatedUnion("kind", [
    z.strictObject({
      kind: z.literal(infoSchemaValueChangeSourceType.TABLE),
      id: z.string(),
    }),
    z.strictObject({
      kind: z.literal(infoSchemaValueChangeSourceType.BLOCK),
      id: z.string(),
    }),
  ]),
});

export type UpdateCampaignBookingsDto = z.infer<
  typeof updateCampaignBookingsDto
>;

export const updateInfoSchemaConfigDto = z.strictObject({
  bookingId: z.string(),
  infoSchemaFieldId: z.string(),
  mergeCells: z.boolean().optional(),
});

export type UpdateInfoSchemaConfigDto = z.infer<
  typeof updateInfoSchemaConfigDto
>;

export interface DuplicateCampaignBookingsReq {
  campaignId: idFlavor<"Campaign">;
  bookingIds: idFlavor<"Booking">[];
  bookingGroupIds: idFlavor<"BookingGroup">[];
  cmlRecordType: CampaignMetaLogRecordType;
}

export interface RemoveBookingsRequest {
  campaignId: idFlavor<"Campaign">;
  bookingIds: idFlavor<"Booking">[];
  bookingGroupIds: idFlavor<"BookingGroup">[];
  cmlRecordType: CampaignMetaLogRecordType;
}

export interface GetBookingDetailsReq {
  bookingIds: idFlavor<"Booking">[];
}
