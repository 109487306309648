import { randomBytes } from "crypto";
import { N_Instant } from "@specsheet-common/shared-types";
import { DateTime } from "luxon";

/** Number of bytes in a megabyte */
const BYTES_IN_MB = 1_000_000;

/** Default debouce time for searches */
export const DEFAULT_DEBOUNCE_TIME = 200;

/** Given a numerical value in bytes, converts to megabytes */
export function bytesToMegabytes(bytesVal: number): number {
  return bytesVal / BYTES_IN_MB;
}

/*
 * Given a file,
 * removes the extension if the file name is straightforward
 * (doesn't contain dots within the file name)
 * and returns the file name without the extension.
 * Used to automatically annotate files uploaded that
 * haven't been given a name by using the name of the file
 * and removing the extension where possible.
 */
export function cleanFileName(file: File) {
  const fileParts = file.name.split(".");
  return fileParts.length === 2 ? fileParts[0] : file.name;
}

/** Uploads a file to the specified URL via a simple PUT request */
export function uploadFile(url: string, file: File) {
  return fetch(url, {
    method: "PUT",
    body: file,
  });
}

export function range(begin: number, end: number, step: number = 1): number[] {
  const res: number[] = [];
  for (let i = begin; i < end; i += step) {
    res.push(i);
  }
  return res;
}

export function createRandomString(): string {
  return randomBytes(20).toString("hex");
}

const NEW_BOOKING_THRESHOLD = {
  minutes: 10,
  hours: 0,
};

export const isRecent = (instant: N_Instant | undefined): boolean => {
  if (instant === undefined) {
    return false;
  }
  const timestamp = DateTime.fromMillis(instant);
  const cutoff = DateTime.now().minus(NEW_BOOKING_THRESHOLD);
  return timestamp > cutoff;
};

export function eqSet(as: Set<unknown>, bs: Set<unknown>) {
  if (as.size !== bs.size) {
    return false;
  }
  for (const a of as) {
    if (!bs.has(a)) {
      return false;
    }
  }
  return true;
}

/**
 * Allows multiline semantics in typescript
 * usage: run(() => {
 *  const blah = doThing()
 *  return blah();
 * })
 *
 * See https://maxgreenwald.me/blog/do-more-with-run for additional usage hints
 */
export const run = <T>(fn: () => T): T => {
  return fn();
};

/**
 * Allows concurrent batching
 * usage: asyncPool(array, batchSize, astnc () => {})
 */
export async function asyncPool<T, V>(
  array: T[],
  poolLimit: number,
  fn: (item: T) => Promise<V>
): Promise<V[]> {
  const results: Promise<V>[] = [];
  const executing = new Set<Promise<V>>();

  for (const item of array) {
    const prom = Promise.resolve().then(() => fn(item));
    results.push(prom);

    if (poolLimit <= array.length) {
      executing.add(prom);
      // eslint-disable-next-line no-void
      void prom.then(() => executing.delete(prom));

      if (executing.size >= poolLimit) {
        await Promise.race(executing);
      }
    }
  }

  return Promise.all(results);
}
