import { useContext } from "react";
import { DialogContext } from "src/contexts/DialogContext";

export const useDialogContext = () => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error(
      "No Dialog context available. Please check that you call useDialogContext inside DrawerProvider"
    );
  }

  return context;
};
