import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React, { useState } from "react";

interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean;
  loadingIndicator?: string;
  spinnerPosition?: "end" | "start";
  async?: boolean;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  disabled,
  async,
  loadingIndicator,
  spinnerPosition,
  children,
  startIcon,
  endIcon,
  onClick,
  ...rest
}): JSX.Element => {
  const [processingAsync, setProcessingAsync] = useState(false);

  const finalIsLoading = processingAsync || isLoading;
  const finalDisabled = disabled || finalIsLoading;
  const showLoadingIndicator = finalIsLoading && loadingIndicator;

  const processOnClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (async) {
      setProcessingAsync(true);
    }
    if (onClick) {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await onClick(e);
    }

    setProcessingAsync(false);
  };

  const renderSpinner = () => {
    return <CircularProgress color="inherit" size={18} />;
  };

  const renderChildren = () => {
    if (showLoadingIndicator) {
      return loadingIndicator;
    }
    if (finalIsLoading && !spinnerPosition) {
      return renderSpinner();
    }
    return children;
  };

  return (
    <Button
      {...rest}
      startIcon={
        finalIsLoading && spinnerPosition === "start"
          ? renderSpinner()
          : startIcon
      }
      endIcon={
        finalIsLoading && spinnerPosition === "end" ? renderSpinner() : endIcon
      }
      disabled={finalDisabled}
      onClick={processOnClick}
    >
      {renderChildren()}
    </Button>
  );
};
