export const WORKSPACE_PERMISSIONS = {
  WORKSPACE_UPDATE: "W1.1",
  WORKSPACE_CHANGE_IS_ACTIVE_STATUS: "W1.2",
  WORKSPACE_CHANGE_MEMBERS: "W1.3",
  WORKSPACE_VIEW: "W1.4",
  CLIENT_CREATE: "W2.1",
  CLIENT_UPDATE: "W2.2",
  CLIENT_CHANGE_IS_ACTIVE_STATUS: "W2.3",
  CLIENT_LIST_VIEW: "W2.4",
  CLIENT_VIEW: "W2.5",
  TEMPLATE_CREATE: "W3.1",
  TEMPLATE_UPDATE: "W3.2",
  TEMPLATE_CHANGE_IS_ACTIVE_STATUS: "W3.3",
  TEMPLATE_VIEW: "W3.4",
  WORKSPACE_MEMBER_CREATE: "W4.1",
  WORKSPACE_MEMBER_CHANGE_IS_ACTIVE_STATUS: "W4.2",
  WORKSPACE_MEMBER_LIST_VIEW: "W4.3",
  WORKSPACE_MEMBER_VIEW: "W4.4",
  USER_GROUP_CREATE: "W5.1",
  USER_GROUP_UPDATE: "W5.2",
  USER_GROUP_CHANGE_MEMBERS: "W5.3",
  USER_GROUP_CHANGE_IS_ACTIVE_STATUS: "W5.4",
  USER_GROUP_VIEW: "W5.5",
  USER_GROUP_LIST_VIEW: "W5.6",
  CAMPAIGN_CREATE: "W6.1",
  CAMPAIGN_VIEW: "W6.2",
  CAMPAIGN_LIST_VIEW: "W6.3",
  CAMPAIGN_UPDATE_DETAILS: "W6.4",
  CAMPAIGN_CHANGE_IS_ACTIVE_STATUS: "W6.5",
  CAMPAIGN_DUPLICATE: "W6.6",
  CAMPAIGN_SHARE: "W6.7",
  CAMPAIGN_CHANGE_WORKSPACE: "W6.8",
} as const;

export type WORKSPACE_PERMISSIONS =
  (typeof WORKSPACE_PERMISSIONS)[keyof typeof WORKSPACE_PERMISSIONS];

export const WORKSPACE_PERMISSIONS_LABELS: Record<
  WORKSPACE_PERMISSIONS,
  string
> = {
  [WORKSPACE_PERMISSIONS.WORKSPACE_UPDATE]: "Update Workspace",
  [WORKSPACE_PERMISSIONS.WORKSPACE_CHANGE_IS_ACTIVE_STATUS]:
    "Change workspace is active status",
  [WORKSPACE_PERMISSIONS.WORKSPACE_CHANGE_MEMBERS]: "Change Workspace Members",
  [WORKSPACE_PERMISSIONS.WORKSPACE_VIEW]: "View Workspace",
  [WORKSPACE_PERMISSIONS.CLIENT_CREATE]: "Create new client",
  [WORKSPACE_PERMISSIONS.CLIENT_UPDATE]: "Update existing client",
  [WORKSPACE_PERMISSIONS.CLIENT_CHANGE_IS_ACTIVE_STATUS]:
    "Change client's is active status",
  [WORKSPACE_PERMISSIONS.CLIENT_LIST_VIEW]: "Clients list view",
  [WORKSPACE_PERMISSIONS.CLIENT_VIEW]: "View client details",
  [WORKSPACE_PERMISSIONS.TEMPLATE_CREATE]: "Create new campaign template",
  [WORKSPACE_PERMISSIONS.TEMPLATE_UPDATE]: "Update existing campaign template",
  [WORKSPACE_PERMISSIONS.TEMPLATE_CHANGE_IS_ACTIVE_STATUS]:
    "Change template is active status",
  [WORKSPACE_PERMISSIONS.TEMPLATE_VIEW]: "View campaign template details",
  [WORKSPACE_PERMISSIONS.WORKSPACE_MEMBER_CREATE]:
    "Create new workspace member",
  [WORKSPACE_PERMISSIONS.WORKSPACE_MEMBER_CHANGE_IS_ACTIVE_STATUS]:
    "Change workspace member is active status",
  [WORKSPACE_PERMISSIONS.WORKSPACE_MEMBER_LIST_VIEW]:
    "Workspace members list view",
  [WORKSPACE_PERMISSIONS.WORKSPACE_MEMBER_VIEW]:
    "View workspace member derails",
  [WORKSPACE_PERMISSIONS.USER_GROUP_CREATE]: "Create new user group",
  [WORKSPACE_PERMISSIONS.USER_GROUP_UPDATE]: "Update existing user group",
  [WORKSPACE_PERMISSIONS.USER_GROUP_CHANGE_MEMBERS]:
    "Change user group members",
  [WORKSPACE_PERMISSIONS.USER_GROUP_CHANGE_IS_ACTIVE_STATUS]:
    "Change user group is active status",
  [WORKSPACE_PERMISSIONS.USER_GROUP_VIEW]: "View user group details",
  [WORKSPACE_PERMISSIONS.USER_GROUP_LIST_VIEW]: "User groups list view",
  [WORKSPACE_PERMISSIONS.CAMPAIGN_CREATE]: "Create new campaign",
  [WORKSPACE_PERMISSIONS.CAMPAIGN_VIEW]: "View campaign details",
  [WORKSPACE_PERMISSIONS.CAMPAIGN_LIST_VIEW]: "Campaigns list view",
  [WORKSPACE_PERMISSIONS.CAMPAIGN_UPDATE_DETAILS]: "Update existing campaign",
  [WORKSPACE_PERMISSIONS.CAMPAIGN_CHANGE_IS_ACTIVE_STATUS]:
    "Change campaign's is active status",
  [WORKSPACE_PERMISSIONS.CAMPAIGN_DUPLICATE]: "Duplicate campaign",
  [WORKSPACE_PERMISSIONS.CAMPAIGN_SHARE]: "Share campaign",
  [WORKSPACE_PERMISSIONS.CAMPAIGN_CHANGE_WORKSPACE]:
    "Change campaign's workspace",
};

export const ORGANISATION_PERMISSIONS = {
  ORGANISATION_UPDATE: "O0.1",
  ORG_MEMBER_INVITE: "O1.1",
  ORG_MEMBER_UPDATE: "O1.2",
  ORG_MEMBER_ACTIVATE_DEACTIVATE: "O1.3",
  ORG_MEMBER_LIST_VIEW: "O1.4",
  ORG_MEMBER_VIEW: "O1.5",
  WORKSPACE_CREATE: "O2.1",
  WORKSPACE_ARCHIVE: "O2.2",
  WORKSPACE_LIST_VIEW: "O2.3",
} as const;

export type ORGANISATION_PERMISSIONS =
  (typeof ORGANISATION_PERMISSIONS)[keyof typeof ORGANISATION_PERMISSIONS];

export const ORGANISATION_PERMISSIONS_LABELS: Record<
  ORGANISATION_PERMISSIONS,
  string
> = {
  [ORGANISATION_PERMISSIONS.ORGANISATION_UPDATE]:
    "Update existing organisation",
  [ORGANISATION_PERMISSIONS.ORG_MEMBER_INVITE]: "Invite organisation member",
  [ORGANISATION_PERMISSIONS.ORG_MEMBER_UPDATE]: "Update organisation member",
  [ORGANISATION_PERMISSIONS.ORG_MEMBER_ACTIVATE_DEACTIVATE]:
    "Activate or deactivate organisation member",
  [ORGANISATION_PERMISSIONS.ORG_MEMBER_LIST_VIEW]:
    "View organisation members list",
  [ORGANISATION_PERMISSIONS.ORG_MEMBER_VIEW]:
    "View organisation member details",
  [ORGANISATION_PERMISSIONS.WORKSPACE_CREATE]: "Create new workspace",
  [ORGANISATION_PERMISSIONS.WORKSPACE_ARCHIVE]: "Archive workspace",
  [ORGANISATION_PERMISSIONS.WORKSPACE_LIST_VIEW]: "View workspaces list",
};

export const CAMPAIGN_PERMISSIONS = {
  BOOKING_CREATE: "С1.1",
  BOOKING_DELETE: "С1.2",
  BOOKING_UPDATE: "С1.3",
  SELECT_BOOKING: "С1.5",
  REVERT_CHANGES: "С1.6",
  GROUP_CREATE: "С2.1",
  GROUP_UPDATE: "С2.2",
  GROUP_DELETE: "С2.3",
  PUBLICATION_UPDATE: "С3.1",
  INVITE_TO_CAMPAIGN: "С4.1",
  CAMPAIGN_USER_MANAGEMENT: "С4.2",
  REMOVE_OTHERS_FROM_CAMPAIGN: "С4.3",
  CAMPAIGN_USER_LIST_VIEW: "С4.4",
  TRANSFER_OWNERSHIP: "С4.5",
  REQUEST_HIGHER_ACCESS: "С4.6",
  REMOVE_MYSELF_FROM_CAMPAIGN: "С4.7",
  CAMPAIGN_DETAILS_UPDATE: "С5.1",
  VERSION_CREATE: "С6.1",
  CAMPAIGN_DOWNLOAD: "С6.2",
  SEARCH_FILTER: "С7.1",
  UPDATE_COLUMN: "С8.1",
  CREATE_COLUMN: "С8.2",
  RESIZE_COLUMN: "С8.3",
} as const;

export type CAMPAIGN_PERMISSIONS =
  (typeof CAMPAIGN_PERMISSIONS)[keyof typeof CAMPAIGN_PERMISSIONS];

export const CAMPAIGN_PERMISSIONS_LABELS: Record<CAMPAIGN_PERMISSIONS, string> =
  {
    [CAMPAIGN_PERMISSIONS.BOOKING_CREATE]: "Add booking to campaign",
    [CAMPAIGN_PERMISSIONS.BOOKING_DELETE]: "Remove booking from campaign",
    [CAMPAIGN_PERMISSIONS.BOOKING_UPDATE]: "Update booking in campaign",
    [CAMPAIGN_PERMISSIONS.SELECT_BOOKING]: "Select booking",
    [CAMPAIGN_PERMISSIONS.REVERT_CHANGES]: "Revert Changes",
    [CAMPAIGN_PERMISSIONS.GROUP_CREATE]: "Create group",
    [CAMPAIGN_PERMISSIONS.GROUP_UPDATE]: "Update group",
    [CAMPAIGN_PERMISSIONS.GROUP_DELETE]: "Remove group",
    [CAMPAIGN_PERMISSIONS.PUBLICATION_UPDATE]: "Publication update",
    [CAMPAIGN_PERMISSIONS.INVITE_TO_CAMPAIGN]: "Invite to campaign",
    [CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_MANAGEMENT]: "Campaign User Management",
    [CAMPAIGN_PERMISSIONS.REMOVE_OTHERS_FROM_CAMPAIGN]:
      "Remove others from campaign",
    [CAMPAIGN_PERMISSIONS.CAMPAIGN_USER_LIST_VIEW]: "Campaign user list view",
    [CAMPAIGN_PERMISSIONS.TRANSFER_OWNERSHIP]: "Transfer ownership",
    [CAMPAIGN_PERMISSIONS.REQUEST_HIGHER_ACCESS]: "Request higher access",
    [CAMPAIGN_PERMISSIONS.REMOVE_MYSELF_FROM_CAMPAIGN]:
      "Remove myself from campaign",
    [CAMPAIGN_PERMISSIONS.CAMPAIGN_DETAILS_UPDATE]: "Campaign details update",
    [CAMPAIGN_PERMISSIONS.VERSION_CREATE]: "Create version",
    [CAMPAIGN_PERMISSIONS.CAMPAIGN_DOWNLOAD]: "Download Campaign",
    [CAMPAIGN_PERMISSIONS.SEARCH_FILTER]: "Search filter",
    [CAMPAIGN_PERMISSIONS.UPDATE_COLUMN]: "Update Column",
    [CAMPAIGN_PERMISSIONS.CREATE_COLUMN]: "Create Column",
    [CAMPAIGN_PERMISSIONS.RESIZE_COLUMN]: "Resize Column",
  };
