import { idFlavor } from "./flavors";

export const EventTypes = {
  INDEX_CML: "index-cml",
  APPLY_VARIATIONS_FOR_CAMPAIGN: "apply-variations-for-campaign",
} as const;

export type EventTypeTypes = (typeof EventTypes)[keyof typeof EventTypes];

export interface IndexCMLEvent {
  type: "index-cml";
  cmlId: idFlavor<"CampaignMetaLog">;
}

export interface ApplyVariationsForCampaignEvent {
  type: "apply-variations-for-campaign";
  cmlId: idFlavor<"CampaignMetaLog">;
  campaignId: idFlavor<"Campaign">;
}

export type EventJob =
  | {
      type: EventTypeTypes;
    } & (IndexCMLEvent | ApplyVariationsForCampaignEvent);
