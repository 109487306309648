import React, { createContext, PropsWithChildren } from "react";
import { RootStore } from "../legacy/store/RootStore";
import { useSharedResources } from "../useSharedResources";

export const RootMobxStoreContext = createContext<RootStore | undefined>(
  undefined
);

export const RootMobxStoreProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element | null => {
  const { service } = useSharedResources();

  return (
    <RootMobxStoreContext.Provider value={new RootStore(service)}>
      {children}
    </RootMobxStoreContext.Provider>
  );
};
