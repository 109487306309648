import { idFlavor } from "../../common";

export const workspaceMemberSubRoute = {
  list: "list",
  update: "",
  updateRoleUsers: "updateroleusers",
} as const;

export type WorkspaceMemberRoutes =
  | {
      sub: typeof workspaceMemberSubRoute.list;
      tailParam: idFlavor<"Workspace">;
      method?: "Get";
    }
  | {
      sub: typeof workspaceMemberSubRoute.update;
      method?: "Patch";
    }
  | {
      sub: typeof workspaceMemberSubRoute.updateRoleUsers;
      method?: "Patch";
    };
