import { Components, Theme } from "@mui/material";

export const MuiSwitch: Components<Theme>["MuiSwitch"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: 28,
      height: 18,
      padding: 2,
      "& .MuiSwitch-switchBase": {
        margin: 1,
        padding: 0,
        transform: "translate(2px, 2px)",
        "&.Mui-checked": {
          transform: "translate(12px, 2px)",
          "& .MuiSwitch-thumb:before": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path fill="${encodeURIComponent(
              theme.newColors.theme.primary
            )}" d="M6.36799 1.98497C6.40211 1.95015 6.44284 1.92249 6.48778 1.90361C6.53273 1.88473 6.58099 1.875 6.62974 1.875C6.67849 1.875 6.72675 1.88473 6.7717 1.90361C6.81664 1.92249 6.85737 1.95015 6.89149 1.98497C7.03449 2.12947 7.03649 2.36297 6.89649 2.50997L3.93999 6.00497C3.90642 6.04183 3.86569 6.07145 3.82028 6.09203C3.77487 6.11261 3.72574 6.1237 3.67589 6.12464C3.62605 6.12557 3.57654 6.11633 3.53039 6.09747C3.48424 6.07862 3.44242 6.05054 3.40749 6.01497L1.60849 4.19197C1.53911 4.12121 1.50024 4.02606 1.50024 3.92697C1.50024 3.82787 1.53911 3.73272 1.60849 3.66197C1.64261 3.62715 1.68334 3.59949 1.72828 3.58061C1.77323 3.56173 1.82149 3.552 1.87024 3.552C1.91899 3.552 1.96725 3.56173 2.0122 3.58061C2.05714 3.59949 2.09787 3.62715 2.13199 3.66197L3.65799 5.20847L6.35799 1.99597C6.3611 1.9921 6.36444 1.98843 6.36799 1.98497Z"/></svg>')`,
          },
          "& + .MuiSwitch-track": {
            opacity: 1,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.12,
          },
        },
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.newColors.neutral.white,
        width: 12,
        height: 12,
        "&:before": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",

          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 8 8"><path fill="${encodeURIComponent(
            theme.newColors.neutral.primary
          )}" d="M1.0186 1.15396C1.03121 1.12359 1.04968 1.096 1.07296 1.07278C1.09618 1.04949 1.12377 1.03102 1.15414 1.01842C1.18451 1.00582 1.21707 0.999329 1.24996 0.999329C1.28284 0.999329 1.3154 1.00582 1.34578 1.01842C1.37615 1.03102 1.40374 1.04949 1.42696 1.07278L3.99995 3.64577L6.57295 1.07278C6.59617 1.04949 6.62376 1.03102 6.65413 1.01842C6.68451 1.00582 6.71707 0.999329 6.74995 0.999329C6.78283 0.999329 6.81539 1.00582 6.84577 1.01842C6.87614 1.03102 6.90373 1.04949 6.92695 1.07278C6.95023 1.096 6.9687 1.12359 6.98131 1.15396C6.99391 1.18433 7.0004 1.21689 7.0004 1.24978C7.0004 1.28266 6.99391 1.31522 6.98131 1.34559C6.9687 1.37596 6.95023 1.40355 6.92695 1.42678L4.35395 3.99977L6.92696 6.57277C6.9739 6.61972 7.00027 6.68339 7.00027 6.74977C7.00027 6.81616 6.9739 6.87983 6.92696 6.92677C6.88002 6.97372 6.81635 7.00009 6.74996 7.00009C6.68357 7.00009 6.6199 6.97372 6.57296 6.92677L3.99995 4.35377L1.42695 6.92677C1.38001 6.97372 1.31634 7.00009 1.24995 7.00009C1.18356 7.00009 1.11989 6.97372 1.07295 6.92677C1.02601 6.87983 0.999634 6.81616 0.999634 6.74977C0.999634 6.68339 1.02601 6.61972 1.07295 6.57277L3.64595 3.99977L1.07296 1.42678C1.04968 1.40355 1.03121 1.37596 1.0186 1.34559C1.006 1.31522 0.999512 1.28266 0.999512 1.24978C0.999512 1.21689 1.006 1.18433 1.0186 1.15396Z"/></svg>')`,
        },
      },
    }),
  },
};
