import { N_Instant, N_LocalDate, refString } from "../../common";
import { idFlavor } from "../../common";
import { N_Publisher, N_PublisherView } from "../publisher";
import {
  InfoSchemaFieldValueValues,
  N_InfoSchemaValueView,
} from "../infoSchema";
import { N_PlacementView } from "../placement";
import * as z from "zod";
import { N_BookingGroup } from "../bookingGroup";
import { N_PublicationView } from "../publication";
import { BasicInfoForTssCampaign } from "src/entities/external";
import { N_Country } from "../country";
import { ConsolidationType } from "../bookingConsolidation";
import { CampaignChangeCreatedBy } from "../campaignChange";
import { BookingInfoSchemaFieldConfig } from "../bookingInfoSchemaFieldConfig/general";
import { bookingDetailsChange } from "./req";

export const materialInstructionsZod = z.object({
  objectives: z.string(),
  creativeRotation: z.string(),
  messaging: z.string(),
  targeting: z.string().optional(),
  notes: z.string().optional(),
});

export type N_MaterialInstructions = z.infer<typeof materialInstructionsZod>;

export type BookingWithHistoryTyped = {
  id: string;
  campaignId: string;
  campaignVersion: number;
  groupId: string | null;
  dispatchDate: Date | null;
  duration: string | null;
  dispatchDetails: string | null;
  dispatchTo: string;
  liveDate: Date;
  endDate: Date | null;
  notes: string;
  extCampaignRef: string | null;
  created: Date;
  updated: Date;
  infoSchemaValueId: string | null;
  materialInstructions: N_MaterialInstructions | null;
  lastEditedType: CampaignChangeCreatedBy;
  lastEditedUserId: string | null;
  lastEditedExtImportEventId: string | null;
  isActive: boolean;
  sysPeriodStart: Date;
  sysPeriodEnd: Date | null;
  campaignMetaLogId: string;
};

export interface N_Booking {
  id: idFlavor<"Booking">;
  campaignId: idFlavor<"Campaign">;
  campaignVersion: number;
  groupId: idFlavor<"BookingGroup"> | null;
  dispatchDate: N_LocalDate | null;
  duration: string | null;
  dispatchDetails: string;
  dispatchTo: string;
  liveDate: N_LocalDate | null;
  endDate: N_LocalDate | null;
  materialInstructions: N_MaterialInstructions | null;
  notes: string;
  extCampaignRef: refString<"ExtCampaign"> | null;
  created: N_Instant;
  updated: N_Instant;
  bookingConsolidation: {
    consolidationType: ConsolidationType;
    consolidationValue: string | null;
  } | null;
}

export interface BookingWithIds extends N_Booking {
  placementIds?: idFlavor<"Placement">[];
  placementId?: idFlavor<"Placement">;
  extBookingIds?: idFlavor<"ExtBooking">[];
  extBookingId?: idFlavor<"ExtBooking">;
}

export interface N_BookingViewBasic {
  bookingId: idFlavor<"Booking">;
  bookingDetails: N_Booking;
  publishers: N_PublisherView[];
  placements: N_PlacementView[];
  placementAuthorUserName: string | null;
  isPlacementEditable: boolean;
  /** @deprecated use infoSchemaValues*/
  infoSchemaFieldValues: InfoSchemaFieldValueValues;
  infoSchemaValues: Array<{
    id?: idFlavor<"InfoSchemaValue">;
    fieldValues: InfoSchemaFieldValueValues;
  }>;
  includesFoldedIds: idFlavor<"Booking">[];
  countries: N_Country[];
  bookingConsolidation: {
    consolidationType: ConsolidationType;
    consolidationValue: string | null;
  } | null;
  infoSchemaFieldConfig: BookingInfoSchemaFieldConfig[];
}

export const updateBookingInfo = z.strictObject({
  materialInstructions: materialInstructionsZod.nullish(),
  notes: z.string().optional(),
  groupId: z.string().nullish(),
  dispatchDate: z.string().datetime().nullish(),
  dispatchDetails: z.string().optional(),
  dispatchTo: z.string().optional(),
  duration: z.string().nullish(),
});

export type UpdateBookingInfo = z.infer<typeof updateBookingInfo>;

export interface BookingView {
  id: idFlavor<"Booking">;
  bookingDetails: BookingWithIds;
  publishers: N_Publisher[];
  placements: N_PlacementView[];
  groupParents: N_BookingGroup[];
  validPublications: N_PublicationView[];
  publications: N_PublicationView[];
  placementAuthorUserName: string | null;
  isPlacementEditable: boolean;
  /** @deprecated use infoSchemaValues*/
  infoSchemaFieldValues: InfoSchemaFieldValueValues;
  externalCampaignInfo: BasicInfoForTssCampaign | null;
  includesFoldedIds: idFlavor<"Booking">[];
  infoSchemaValues: Array<{
    id?: idFlavor<"InfoSchemaValue">;
    fieldValues: InfoSchemaFieldValueValues;
  }>;
  countries: N_Country[];
  infoSchemaFieldConfig: BookingInfoSchemaFieldConfig[];
}

export interface BookingDetailsView {
  bookingDetails: N_Booking;
  /** @deprecated use infoSchemaValues*/
  infoSchemaValue: N_InfoSchemaValueView | null;
  /** @deprecated use infoSchemaValues*/
  infoSchemaFieldValues: InfoSchemaFieldValueValues;
  infoSchemaValues: Array<{
    id?: idFlavor<"InfoSchemaValue">;
    fieldValues: InfoSchemaFieldValueValues;
  }>;
}

export interface BookingDetailsViewExcludingFoldedKeys {
  bookingDetails: Omit<N_Booking, "liveDate" | "endDate">;
  /** @deprecated use infoSchemaValues*/
  infoSchemaValue: N_InfoSchemaValueView | null;
  /** @deprecated use infoSchemaValues*/
  infoSchemaFieldValues: InfoSchemaFieldValueValues;
  infoSchemaValues: Array<{
    id?: idFlavor<"InfoSchemaValue">;
    fieldValues: InfoSchemaFieldValueValues;
  }>;
}
