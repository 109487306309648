import { idFlavor, refString } from "src/common";

export const extCampaignKind = {
  OOH: "OOH",
  JCD: "JCD",
  JCDNZ: "JCDNZ",
  QMS: "QMS",
  GO_MEDIA: "GO_MEDIA",
  API: "API",
  MEDIA_WORKS: "MEDIA_WORKS",
} as const;

export type ExtCampaignKind =
  (typeof extCampaignKind)[keyof typeof extCampaignKind];

export const extCampaignKindLabels: Record<ExtCampaignKind, string> = {
  [extCampaignKind.OOH]: "oOh!media AU",
  [extCampaignKind.JCD]: "JCDecaux AU",
  [extCampaignKind.JCDNZ]: "JCDecaux NZ",
  [extCampaignKind.QMS]: "QMS",
  [extCampaignKind.GO_MEDIA]: "Go Media",
  [extCampaignKind.MEDIA_WORKS]: "MediaWorks",
  [extCampaignKind.API]: "Other",
};

export interface BasicInfoForTssCampaign {
  extCampaignRef: idFlavor<"ExtCampaign"> | refString<"ExtCampaign">;
  extCampaignId: idFlavor<"ExtCampaign">;
  providerName: string;
}
