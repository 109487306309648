export const reduceNotNull = <R, T>(
  array: T[],
  extractor: (el: T) => R | null | undefined
): R[] => {
  return array.reduce<R[]>((acc, cur) => {
    const res = extractor(cur);

    if (res === null || res === undefined) {
      return acc;
    }

    return [...acc, res];
  }, []);
};
