import * as z from "zod";
import { mediaWorksExtBookingData, mediaWorksPanelKinds } from "./general";

const stringChange = z.object({ before: z.string(), after: z.string() });

export const mediaWorksExtBookingChangeData = z.union([
  z.object({
    panelKindChanged: z.literal(true),
    change: z.object({
      before: mediaWorksExtBookingData,
      after: mediaWorksExtBookingData,
    }),
  }),
  z.object({
    panelKindChanged: z.literal(false),
    data: z.union([
      z.object({
        kind: z.literal(mediaWorksPanelKinds.DIGITAL),
        change: z.record(z.nativeEnum({}), stringChange),
      }),
      z.object({
        kind: z.literal(mediaWorksPanelKinds.STATIC),
        change: z.record(z.nativeEnum({}), stringChange),
      }),
      z.object({}),
    ]),
  }),
]);

export type MediaWorksExtBookingChangeData = z.infer<
  typeof mediaWorksExtBookingChangeData
>;
