import { N_PlacementView } from "../placement";
import { N_Publisher } from "../publisher";
import { N_PublicationView } from "../publication";
import { N_CampaignView, N_CampaignWithBookings } from "./general";
import { idFlavor, refString, SuccessOrError } from "../../common";
import { ExternalBookingImportType } from "../external";
import { OrgMemberRole } from "../orgMember";
import { WorkspaceMemberRole } from "../workspaceMember";
import { JsonApiData, JsonApiResponse } from "../../common/jsonApi";
import { CampaignMetaLog } from "../campaignMetaLog";

export interface DeprecatedPlacement {
  placement: N_PlacementView;
  publisher: N_Publisher;
  validPublications: N_PublicationView[];
  bookingsCount: number;
}

export interface BookingsAllowedForDuplication {
  customCount: number;
  allCount: number;
}

export const LEGACY = "LEGACY" as const;

export interface PartOfExtCampaignCounts {
  kind: ExternalBookingImportType | typeof LEGACY;
  count: number;
}

export interface BookingsForbiddenForDuplication {
  deprecatedCount: number;
  partOfExtCampaign: PartOfExtCampaignCounts[];
  deprecatedPlacements: DeprecatedPlacement[] | null;
}

export interface CampaignToDuplicateRes {
  campaign: N_CampaignView;
  bookingsToDuplicate: BookingsAllowedForDuplication;
  bookingsToSkip: BookingsForbiddenForDuplication;
}

export interface AlreadyClaimedCampaignData {
  extCampaignRef: refString<"ExtCampaign"> | idFlavor<"ExtCampaign">;
  code: string;
  client: string;
  source: "qms" | "ooh" | "ext";
}

export type CampaignRevertChangesRes = AlreadyClaimedCampaignData[];

export interface CampaignAllDocumentsRes {
  status: "requested";
}

type Archived = {
  kind: "archived";
};

type NoAccess = {
  kind: "noacess";
  campaignOwner: string;
};

type NoAccessData = Archived | NoAccess;

export const isNoAccessData = (data: NoAccessData): data is NoAccess => {
  return data.kind === "noacess";
};

export type CampaignWithBookingsOkRes =
  | {
      campaignData: N_CampaignWithBookings;
      organisationRole: OrgMemberRole;
      workspaceRole: WorkspaceMemberRole;
    }
  | {
      campaignData?: never;
      organisationRole?: never;
      workspaceRole?: never;
      noAccessData: NoAccessData;
    };

export type CampaignWithBookingsRes = SuccessOrError<CampaignWithBookingsOkRes>;

export interface ClaimedContractsInCampaign {
  mediaOwner: string;
  clientName: string;
  campaignCode: { raw: string; code: string };
  bookingsCount: number;
}

export type HidCampaignMetaLogRes = JsonApiResponse<
  JsonApiData<"campaignMetaLog", CampaignMetaLog>,
  undefined
>;
