import {
  N_ArtworkSpec,
  N_DigitalMediaDimension,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { notNull } from "../../typescript";
import { joinNames } from "./joinNames";
import { stringifyPrintDimension } from "./stringify";

const getDimension = (dimension: N_DigitalMediaDimension): string => {
  if (
    dimension.pixelDimension.width === 0 &&
    dimension.pixelDimension.height === 0
  ) {
    return "Refer to PDF";
  }

  return `${dimension.pixelDimension.width} x ${dimension.pixelDimension.height} px`;
};

export const getDimensions = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): (N_NamedAnnotated<string> & { width: number; height: number })[] =>
  specifications.reduce<
    (N_NamedAnnotated<string> & { width: number; height: number })[]
  >(
    (dimensions, spec) => [
      ...dimensions,
      ...(spec.value.kind === "newspaper"
        ? [
            ...(spec.value.value.additionalPrintDimensions !== null
              ? spec.value.value.additionalPrintDimensions.value.map((v) => ({
                  ...v,
                  value: stringifyPrintDimension(v.value),
                  width: v.value.width.value,
                  height: v.value.width.value,
                }))
              : []),
          ].map((ds) => ({
            ...spec,
            name: joinNames(spec.name, ds.name),
            value: ds.value,
            width: ds.width,
            height: ds.height,
          }))
        : []),
      ...(spec.value.kind === "digitalStatic" ||
      spec.value.kind === "animated" ||
      spec.value.kind === "html5" ||
      spec.value.kind === "video"
        ? [
            ...spec.value.value.dimensions.value,
            ...(spec.value.kind === "html5" &&
            notNull(spec.value.value.formats.value.video) &&
            notNull(spec.value.value.formats.value.video.value.video.dimensions)
              ? spec.value.value.formats.value.video.value.video.dimensions
                  .value
              : []),
          ].map((ds) => ({
            ...spec,
            name: joinNames(spec.name, ds.name),
            value: getDimension(ds.value),
            width: ds.value.pixelDimension.width,
            height: ds.value.pixelDimension.height,
          }))
        : []),
    ],
    []
  );
