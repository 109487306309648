import * as z from "zod";

export const instantZod = z.number().lte(9999999999999).int();
export type N_Instant = z.infer<typeof instantZod>;

export type N_LocalDate = string;
export type N_Url = string;
export type RawEmailAddress = string & { _type?: "rawEmail" };
export type NormalizedEmailAddress = string & { _type?: "normalizedEmail" };

const documentUrlSchema = z.object({
  data: z.object({ kind: z.literal("url"), value: z.string() }),
  name: z.string().nullable(),
  notes: z.string().nullable(),
  fileSize: z.number().nullish(),
});

export const documentUrlsSchema = z.array(documentUrlSchema);

export const urlResourceValidator = z.object({
  data: z.object({ url: z.string() }),
  name: z.string().nullable(),
  notes: z.string().nullable(),
  fileSize: z.number().nullish(),
});

export const urlResourcesZod = z.array(urlResourceValidator);

export type DB_UrlResource = z.infer<typeof urlResourceValidator>;

export interface N_UrlResource {
  name: string | null;
  data: {
    kind: "url";
    value: N_Url;
  };
  notes: string | null;
  fileSize: number | null;
}

export const contactDetailsZod = z.object({
  name: z.string(),
  emailAddress: z.string(),
  notes: z.string().nullable(),
});

export type N_ContactDetails = z.infer<typeof contactDetailsZod>;

export interface N_Unit {}

export type N_StringKeyMap<_K extends string, V> = {
  [key in _K]: V;
};

export const sourceType = {
  admin: "admin",
  mediaOwner: "mediaOwner",
  custom: "custom",
} as const;

export type N_SourceType = (typeof sourceType)[keyof typeof sourceType];

export interface N_DataOrigin_Url {
  kind: "url";
  value: N_Url;
}

export const dataOriginUrlZod = z.object({ url: z.string() });
export type DB_DataOrigin_Url = z.infer<typeof dataOriginUrlZod>;
