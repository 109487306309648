import * as z from "zod";
import { idFlavor } from "src/common";
import { CampaignVersionNumber } from "src/entities";

export const socketMessageKinds = {
  campaignVersionReportFileReady: "campaignVersionReportFileReady",
  contractVariationReportFileReady: "contractVariationReportFileReady",
  campaignAllDocumentsZipFileReady: "campaignAllDocumentsZipFileReady",
  placementAllDocumentsZipFileReady: "placementAllDocumentsZipFileReady",
  placementOneDocumentsZipFileReady: "placementOneDocumentsZipFileReady",
  userUpdated: "userUpdated",
  clientLogin: "clientLogin",
  enteredCampaign: "enteredCampaign",
  leftCampaign: "leftCampaign",
  campaignVisitorsUpdated: "campaignVisitorsUpdated",
  applicationVersion: "applicationVersion",
  campaignDataRefetch: "campaignDataRefetch",
} as const;

export type SocketMessageKinds =
  (typeof socketMessageKinds)[keyof typeof socketMessageKinds];

const campaignVersionReportFileReadyMessageData = z.object({
  clientFileId: z.string().min(1),
  campaignId: z.string().min(1),
  campaignVersion: z.number().min(1),
});

const contractVariationReportFileReadyMessageData = z.object({
  clientFileId: z.string().min(1),
  campaignId: z.string().min(1),
  campaignVersion: z.number().min(1),
  extCampaignId: z.string().min(1),
  extImportEventId: z.string().min(1),
});

const campaignVersionFileReadyMessage = z.object({
  kind: z.literal(socketMessageKinds.campaignVersionReportFileReady),
  toSocketId: z.string().min(1),
  data: campaignVersionReportFileReadyMessageData,
});

const contractVariationFileReadyMessage = z.object({
  kind: z.literal(socketMessageKinds.contractVariationReportFileReady),
  toSocketId: z.string().min(1),
  data: contractVariationReportFileReadyMessageData,
});

const campaignAllDocumentsZipFileReadyMessageData = z.object({
  clientFileId: z.string().min(1),
});

export type CampaignVersionFileReadyMessage = Omit<
  z.infer<typeof campaignVersionFileReadyMessage>,
  "data" | "toSocketId"
> & {
  toSocketId: idFlavor<"WebsocketId">;
  data: {
    clientFileId: idFlavor<"ClientFile">;
    campaignId: idFlavor<"Campaign">;
    campaignVersion: CampaignVersionNumber;
  };
};

export type ContractVariationFileReadyMessage = Omit<
  z.infer<typeof contractVariationFileReadyMessage>,
  "data" | "toSocketId"
> & {
  toSocketId: idFlavor<"WebsocketId">;
  data: {
    clientFileId: idFlavor<"ClientFile">;
    campaignId: idFlavor<"Campaign">;
    campaignVersion: CampaignVersionNumber;
    extCampaignId: idFlavor<"ExtCampaign">;
    extImportEventId: idFlavor<"ExtImportEvent">;
  };
};

export type CampaignAllDocumentsZipFileReadyMessage = Omit<
  z.infer<typeof campaignAllDocumentsZipFileReadyMessage>,
  "data" | "toSocketId"
> & {
  toSocketId: idFlavor<"WebsocketId">;
  data: {
    clientFileId: idFlavor<"ClientFile">;
  };
};

export const validateCampaignVersionReportFileReadyMessageData = (
  data: unknown
): CampaignVersionFileReadyMessage["data"] => {
  return campaignVersionReportFileReadyMessageData.parse(data);
};

export const validateContractVariationReportFileReadyMessageData = (
  data: unknown
): ContractVariationFileReadyMessage["data"] => {
  return contractVariationReportFileReadyMessageData.parse(data);
};

const campaignAllDocumentsZipFileReadyMessage = z.object({
  kind: z.literal(socketMessageKinds.campaignAllDocumentsZipFileReady),
  toSocketId: z.string().min(1),
  data: campaignAllDocumentsZipFileReadyMessageData,
});

export const validateCampaignAllDocumentsZipFileReadyMessageData = (
  data: unknown
): CampaignAllDocumentsZipFileReadyMessage["data"] => {
  return campaignAllDocumentsZipFileReadyMessageData.parse(data);
};

const placementAllDocumentsZipFileReadyMessageData = z.object({
  clientFileId: z.string().min(1),
});

const placementAllDocumentsZipFileReadyMessage = z.object({
  kind: z.literal(socketMessageKinds.placementAllDocumentsZipFileReady),
  toSocketId: z.string().min(1),
  data: placementAllDocumentsZipFileReadyMessageData,
});

export type PlacementAllDocumentsZipFileReadyMessage = Omit<
  z.infer<typeof placementAllDocumentsZipFileReadyMessage>,
  "data" | "toSocketId"
> & {
  toSocketId: idFlavor<"WebsocketId">;
  data: {
    clientFileId: idFlavor<"ClientFile">;
  };
};

export const validatPlacementAllDocumentsZipFileReadyMessageData = (
  data: unknown
): PlacementAllDocumentsZipFileReadyMessage["data"] => {
  return placementAllDocumentsZipFileReadyMessageData.parse(data);
};

const placementOneDocumentsZipFileReadyMessageData = z.object({
  clientFileId: z.string().min(1),
});

const placementOneDocumentsZipFileReadyMessage = z.object({
  kind: z.literal(socketMessageKinds.placementOneDocumentsZipFileReady),
  toSocketId: z.string().min(1),
  data: placementOneDocumentsZipFileReadyMessageData,
});

export type PlacementOneDocumentsZipFileReadyMessage = Omit<
  z.infer<typeof placementOneDocumentsZipFileReadyMessage>,
  "data" | "toSocketId"
> & {
  toSocketId: idFlavor<"WebsocketId">;
  data: {
    clientFileId: idFlavor<"ClientFile">;
  };
};

export const validatPlacementOneDocumentsZipFileReadyMessageData = (
  data: unknown
): PlacementOneDocumentsZipFileReadyMessage["data"] => {
  return placementOneDocumentsZipFileReadyMessageData.parse(data);
};

const campaignVisitorsListChangesMessageData = z.array(
  z.object({
    id: z.string().min(1),
    name: z.string().min(1),
    lastVisitLabel: z.string().min(1).or(z.literal("online")),
    email: z.string().min(1),
  })
);

const campaignVisitorsListChangesMessage = z.object({
  kind: z.literal(socketMessageKinds.campaignVisitorsUpdated),
  toSocketId: z.string().min(1),
  data: campaignVisitorsListChangesMessageData,
});

export type CampaignVisitorsListChangesMessage = Omit<
  z.infer<typeof campaignVisitorsListChangesMessage>,
  "data" | "toSocketId"
> & {
  toSocketId: idFlavor<"WebsocketId">;
  data: {
    id: idFlavor<"AppUser">;
    name: string;
    lastVisitLabel: string | "online";
    email: string;
  }[];
};
export const validateCampaignVisitorsListChangesMessageData = (
  data: unknown
): CampaignVisitorsListChangesMessage["data"] => {
  return campaignVisitorsListChangesMessageData.parse(data);
};

export const campaignDataRefetchReasons = {
  contractVariation: "CONTRACT_VARIATION",
} as const;

export type CampaignDataRefetchReasons =
  (typeof campaignDataRefetchReasons)[keyof typeof campaignDataRefetchReasons];

const campaignDataRefetchData = z.object({
  reason: z.nativeEnum(campaignDataRefetchReasons),
  campaignId: z.string(),
});

const campaignDataRefetchMessage = z.object({
  kind: z.literal(socketMessageKinds.campaignDataRefetch),
  campaignId: z.string().min(1),
  data: campaignDataRefetchData,
});

export type CampaignDataRefetchMessage = Omit<
  z.infer<typeof campaignDataRefetchMessage>,
  "data" | "campaignId"
> & {
  campaignId: idFlavor<"Campaign">;
  data: {
    reason: CampaignDataRefetchReasons;
    campaignId: idFlavor<"Campaign">;
  };
};

export const validateCampaignDataRefetchMessageData = (
  data: unknown
): CampaignDataRefetchMessage["data"] => {
  return campaignDataRefetchData.parse(data);
};

const applicationVersionMessageData = z.object({
  version: z.string().min(1),
});

const applicationVersionMessage = z.object({
  kind: z.literal(socketMessageKinds.applicationVersion),
  toSocketId: z.string().min(1),
  data: applicationVersionMessageData,
});

export type ApplicationVersionMessage = Omit<
  z.infer<typeof applicationVersionMessage>,
  "data" | "toSocketId"
> & {
  toSocketId: idFlavor<"WebsocketId">;
  data: { version: string };
};

export const validateApplicationVersionMessageData = (
  data: unknown
): ApplicationVersionMessage["data"] => {
  return applicationVersionMessageData.parse(data);
};

export type WebsocketMessages =
  | CampaignVersionFileReadyMessage
  | ContractVariationFileReadyMessage
  | CampaignAllDocumentsZipFileReadyMessage
  | PlacementAllDocumentsZipFileReadyMessage
  | PlacementOneDocumentsZipFileReadyMessage
  | CampaignVisitorsListChangesMessage
  | ApplicationVersionMessage
  | CampaignDataRefetchMessage;
