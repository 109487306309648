import { idFlavor } from "../../common";

export const bucketKind = {
  CLIENT_FILES: "CLIENT_FILES",
  CORE_CLIENT: "CORE_CLIENT",
  PUBLIC_LEGACY: "PUBLIC_LEGACY",
  PUBLIC_FILES: "PUBLIC_FILES",
  PRIVATE_FILES: "PRIVATE_FILES",
  EXT_QUARANTINE_FILES: "EXT_QUARANTINE_FILES",
} as const;

export type BucketKind = (typeof bucketKind)[keyof typeof bucketKind];

export const fileTypes = {
  SHARED_REPORT: "SHARED_REPORT",
} as const;

export type FileTypes = (typeof fileTypes)[keyof typeof fileTypes];

export const knownFileExtensions = {
  xlsx: "xlsx",
  csv: "csv",
  zip: "zip",
  txt: "txt",
} as const;

export type KnownFileExtensions =
  (typeof knownFileExtensions)[keyof typeof knownFileExtensions];

export interface ClientFile {
  id: idFlavor<"ClientFile">;
  fileName: string;
  fileType: FileTypes;
  extension: KnownFileExtensions;
}
