export interface NamedAnnotated<T> {
  name: string | null;
  value: T;
}
export const dedupeNamedAnnotatedStringList = <
  Type extends NamedAnnotated<string>
>(
  value: Type[]
): Type[] => {
  return value.reduce<Type[]>((acc, value) => {
    if (
      acc.findIndex((existingValue) => {
        return (
          value.value === existingValue.value &&
          value.name === existingValue.name
        );
      }) !== -1
    ) {
      return acc;
    }
    return [...acc, value];
  }, []);
};
