import { N_NamedAnnotated } from "@specsheet-common/shared-types";

interface CollectedNames {
  value: string;
  names: string[];
}

export const collectNames = (
  values: N_NamedAnnotated<string>[]
): CollectedNames[] =>
  [
    ...values.reduce<Map<string, string[]>>(
      (map, item) =>
        new Map([
          ...map.entries(),
          [
            item.value,
            [
              ...(map.get(item.value) ?? []),
              ...(item.name !== null ? [item.name] : []),
            ],
          ],
        ]),
      new Map()
    ),
  ].map((entry) => ({ value: entry[0], names: entry[1] }));
