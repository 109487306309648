import z from "zod";
import { oohDigitalBookingSchema } from "./digitalBooking";
import { oohClassicBookingSchema } from "./classicBooking";

export const oohPanelKinds = {
  DIGITAL: "DIGITAL",
  CLASSIC: "CLASSIC",
} as const;

export const oohDigitalPanelData = oohDigitalBookingSchema;
export const oohClassicPanelData = oohClassicBookingSchema;

export const oohDigitalData = z.object({
  kind: z.literal(oohPanelKinds.DIGITAL),
  data: oohDigitalPanelData,
});

export const oohClassicData = z.object({
  kind: z.literal(oohPanelKinds.CLASSIC),
  data: oohClassicPanelData,
});

export const oohExtBookingData = z.union([oohDigitalData, oohClassicData]);

export type OohPanelKinds = (typeof oohPanelKinds)[keyof typeof oohPanelKinds];
export type OohClassicData = z.infer<typeof oohClassicPanelData>;
export type OohDigitalData = z.infer<typeof oohDigitalPanelData>;
export type OohExtBookingData = z.infer<typeof oohExtBookingData>;
