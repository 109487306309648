import React, { useState } from "react";
import { FieldFns } from "@adltools/fields";
import styles from "./dbkey.css";
import { AdlField } from "@adltools/components/AdlField";
import { Icon } from "semantic-ui-react";
import { useRootStore } from "../../../hooks/contexts/useRootStore";
import { Observer } from "mobx-react";
import { WithDbId } from "../../../adl-gen/common/db";
import { fetchTableAdmin } from "../../../service/admin/fetch-table-admin";
import { DbRow } from "../../../adl-gen/common/adminui/api";
import {
  ConcatArgument,
  FieldPredicate,
} from "../../../adl-gen/common/tabular";
import { SsAutocompleteSingle } from "../../temporaryComponentsToShare/SsAutocompleteSingle";

interface ExternalIdSearchFieldProps<T> {
  fieldfns?: FieldFns<T>;
  validate(text: string): null | string;
  value: string;
  onUpdate(data: string): void;
  tableHref?: string;
  tableName: string;
  additionalFilter?: FieldPredicate;
  customUpdate?(data: WithDbId<DbRow>): void;
  allowClearable?: boolean;
  onClear?(): void;
  onTouch?(): void;
}

const sortString = (a: string, b: string): 0 | 1 | -1 => {
  // tslint:disable-next-line:strict-comparisons
  if (a < b) {
    return -1;
  }

  // tslint:disable-next-line:strict-comparisons
  if (a > b) {
    return 1;
  }
  return 0;
};

export const ExternalIdSearchField = <T,>({
  fieldfns,
  validate,
  value,
  onUpdate,
  tableHref,
  tableName,
  additionalFilter,
  customUpdate,
  allowClearable,
  onClear,
  onTouch,
}: ExternalIdSearchFieldProps<T>) => {
  const { service } = useRootStore();
  const [searchResults, setSearchResults] = useState<WithDbId<DbRow>[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getTableNameDependantConfig = (): {
    searchFields: ConcatArgument[];
    getLabel(option: WithDbId<DbRow>): string;
    sortingFunc(optionA: WithDbId<DbRow>, optionB: WithDbId<DbRow>): 0 | 1 | -1;
  } => {
    switch (tableName) {
      case "publisher":
      case "organisation":
      case "publication":
      case "client":
      case "placement": {
        return {
          searchFields: [
            { kind: "name", value: "name" },
            { kind: "name", value: "id" },
          ],
          getLabel: (option: WithDbId<DbRow>) => String(option.value.name),
          sortingFunc: (optionA: WithDbId<DbRow>, optionB: WithDbId<DbRow>) =>
            sortString(
              String(optionA.value.name).toLowerCase(),
              String(optionB.value.name).toLowerCase()
            ),
        };
      }
      case "app_user": {
        return {
          searchFields: [
            { kind: "name", value: "name" },
            { kind: "name", value: "last_name" },
            { kind: "name", value: "email" },
            { kind: "name", value: "id" },
          ],
          getLabel: (option: WithDbId<DbRow>) =>
            `${option.value.name} ${option.value.last_name} ${option.value.email}`,
          sortingFunc: (optionA: WithDbId<DbRow>, optionB: WithDbId<DbRow>) =>
            sortString(
              String(optionA.value.last_name).toLowerCase(),
              String(optionB.value.last_name).toLowerCase()
            ),
        };
      }
      case "campaign": {
        return {
          searchFields: [
            { kind: "name", value: "title" },
            { kind: "name", value: "job_number" },
            { kind: "name", value: "id" },
          ],
          getLabel: (option: WithDbId<DbRow>) => String(option.value.title),
          sortingFunc: (optionA: WithDbId<DbRow>, optionB: WithDbId<DbRow>) =>
            sortString(
              String(optionA.value.title).toLowerCase(),
              String(optionB.value.title).toLowerCase()
            ),
        };
      }

      case "qms_campaign": {
        return {
          searchFields: [
            { kind: "name", value: "tss_campaign_id" },
            { kind: "name", value: "qms_campaign_code" },
            { kind: "name", value: "id" },
          ],
          getLabel: (option: WithDbId<DbRow>) =>
            String(option.value.qms_campaign_code),
          sortingFunc: (optionA: WithDbId<DbRow>, optionB: WithDbId<DbRow>) =>
            sortString(
              String(optionA.value.qms_campaign_code).toLowerCase(),
              String(optionB.value.qms_campaign_code).toLowerCase()
            ),
        };
      }
      default: {
        return {
          searchFields: [{ kind: "name", value: "id" }],
          getLabel: (option: WithDbId<DbRow>) => String(option.id),
          sortingFunc: (optionA: WithDbId<DbRow>, optionB: WithDbId<DbRow>) =>
            sortString(
              String(optionA.value.id).toLowerCase(),
              String(optionB.value.id).toLowerCase()
            ),
        };
      }
    }
  };

  const config = getTableNameDependantConfig();

  const performIdSearch = async (term?: string) => {
    setIsLoading(true);
    const res = await fetchTableAdmin({
      service,
      term,
      tableName,
      searchFields: config.searchFields,
      filter: additionalFilter,
    });
    setSearchResults(res.sort(config.sortingFunc));
    setIsLoading(false);
    if (onTouch) {
      onTouch();
    }
  };

  const validationError = validate(value);

  if (tableName) {
    return (
      <Observer>
        {() => (
          <SsAutocompleteSingle
            placeholder={value || `${tableName} ID`}
            setSelectedValue={(option) => {
              if (customUpdate) {
                customUpdate(option);
              } else {
                onUpdate(option.id);
              }
            }}
            getOptionLabel={config.getLabel}
            isOptionEqualToValue={(option, val) => option.id === val.id}
            fetchOptions={performIdSearch}
            isLoading={isLoading}
            options={searchResults}
            error={Boolean(validationError)}
            helperText={validationError}
            disableClearable={!allowClearable}
            onClear={onClear}
          />
        )}
      </Observer>
    );
  }

  if (!fieldfns) {
    return null;
  }

  return (
    <span className={styles.fieldContainer}>
      <AdlField
        fieldfns={fieldfns}
        text={value}
        disabled={false}
        onChange={onUpdate}
      />

      <span className={styles.linkIcon}>
        <a href={tableHref ?? ""} target="_blank">
          <Icon name="external alternate" />
        </a>
      </span>
    </span>
  );
};
