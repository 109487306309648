import {
  DB_CampaignTemplateStandardColumn,
  campaignTemplateStandardColumn,
  fieldType,
  InfoSchemaFieldWithDefaultElements,
  infoSchemaTableViewColumnType,
  fieldSystemType,
  CampaignTemplateStandardColumn,
} from "@specsheet-common/shared-types";
import { specColumns } from "./getSpecColumns";

const standardColumnsForNewCampaignTemplate: Array<{
  key: CampaignTemplateStandardColumn;
  position: number;
}> = [
  {
    key: campaignTemplateStandardColumn.MEDIA_OWNER,
    position: 0,
  },
  {
    key: campaignTemplateStandardColumn.PLACEMENT_INFO,
    position: 1,
  },
  {
    key: campaignTemplateStandardColumn.STATUS,
    position: specColumns.length + 3,
  },
  {
    key: campaignTemplateStandardColumn.CAMPAIGN_CODE,
    position: specColumns.length + 4,
  },
  {
    key: campaignTemplateStandardColumn.CHANNEL,
    position: specColumns.length + 5,
  },
  {
    key: campaignTemplateStandardColumn.PUBLICATIONS,
    position: specColumns.length + 6,
  },
  {
    key: campaignTemplateStandardColumn.LIVE_DATE,
    position: specColumns.length + 8,
  },
  {
    key: campaignTemplateStandardColumn.END_DATE,
    position: specColumns.length + 9,
  },
];

const standardInfoSchemaColumnsForNewCampaignTemplate: Array<
  Omit<InfoSchemaFieldWithDefaultElements, "isLimited" | "isHidden">
> = [
  {
    systemType: fieldSystemType.FILE_NAME,
    fieldLabel: "File Name",
    fieldType: fieldType.TEXT,
    tableViewColumnType: infoSchemaTableViewColumnType.text,
    position: 2,
  },
  {
    systemType: fieldSystemType.DISPATCH_DATE,
    fieldLabel: "Delivery",
    fieldType: fieldType.DATE,
    tableViewColumnType: infoSchemaTableViewColumnType.date,
    position: specColumns.length + 10,
  },
  {
    systemType: fieldSystemType.LIVE_DATE,
    fieldLabel: "Live",
    fieldType: fieldType.DATE,
    tableViewColumnType: infoSchemaTableViewColumnType.date,
    position: specColumns.length + 10,
  },
  {
    systemType: fieldSystemType.END_DATE,
    fieldLabel: "End",
    fieldType: fieldType.DATE,
    tableViewColumnType: infoSchemaTableViewColumnType.date,
    position: specColumns.length + 11,
  },
];

export const getStandardColumnsForNewCampaignTemplate =
  (): DB_CampaignTemplateStandardColumn[] =>
    standardColumnsForNewCampaignTemplate.map((column, i) => ({
      column: column.key,
      position: column.position,
      isHidden: false,
      isLimited: false,
      channels: undefined,
    }));

export const getStandardInfoSchemaColumnsForNewCampaignTemplate =
  (): InfoSchemaFieldWithDefaultElements[] =>
    standardInfoSchemaColumnsForNewCampaignTemplate.map((column, i) => ({
      ...column,
      position: column.position,
      isHidden: false,
      isLimited: false,
      channels: undefined,
    }));
