export const assertNever = (x: never, msg?: string): never => {
  console.log(msg || "assert never unexpected object:", x);
  throw new Error(`${msg || "assert never unexpected object:"} ${x}`);
};

export const notNull = <T>(value: T | null): value is T => {
  return value !== null;
};

export function truthy<T>(value: T | null): value is T {
  return !!value;
}

export const isString = (value: unknown): value is string =>
  typeof value === "string";

export function notNullish<T>(value: T | null | undefined): value is T {
  return !(value === null || value === undefined);
}

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

export type XOR<T, U> = T extends object
  ? U extends object
    ? (Without<T, U> & U) | (Without<U, T> & T)
    : U
  : T;

export type JsonObject = { [Key in string]?: JsonValue };
export interface JsonArray extends Array<JsonValue> {}
export type JsonValue =
  | string
  | number
  | boolean
  | JsonObject
  | JsonArray
  | null;

export type unknownNotNull = Exclude<JsonValue, null>;

export type ExactObj<T, U> = T & Record<Exclude<keyof U, keyof T>, never>;
