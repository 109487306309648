import { idFlavor, N_LocalDate, N_UrlResource } from "src/common";
import { N_WysiwygDescendant } from "src/elements";
import { N_CampaignStatus } from "./general";
import { z } from "zod";

export interface CheckWorkspaceJobNumberReq {
  workspaceId: idFlavor<"Workspace">;
  jobNumber: string;
}

export interface CreateCampaignData {
  workspaceId: idFlavor<"Workspace">;
  title: string;
  summary: string;
  liveDate: N_LocalDate;
  endDate: N_LocalDate | null;
  clientId: idFlavor<"Client">;
  campaignTemplateId: idFlavor<"CampaignTemplate"> | null;
  newCampaignTemplateId?: idFlavor<"CampaignTemplate"> | null;
  jobNumber: string;
  notes: N_WysiwygDescendant[];
  documents: N_UrlResource[];
  status: N_CampaignStatus;
}

export type DuplicateCampaignData = Omit<
  CreateCampaignData,
  "campaignTemplateId"
> & {
  updateCampaignTemplate:
    | { newCampaignTemplateId: idFlavor<"CampaignTemplate"> | null }
    | false;
  oldDocuments: N_UrlResource[];
};

export interface CreateNewCampaignReq {
  workspaceId: idFlavor<"Workspace">;
  title: string;
  summary: string;
  liveDate: N_LocalDate;
  endDate: N_LocalDate | null;
  client: { id: idFlavor<"Client"> } | { name: string };
  campaignTemplateId: idFlavor<"CampaignTemplate"> | null;
  newCampaignTemplateId?: idFlavor<"CampaignTemplate"> | null;
  jobNumber: string;
  notes: N_WysiwygDescendant[];
  documents: N_UrlResource[];
  status: N_CampaignStatus;
}

export type DuplicateCampaignReq = Omit<
  CreateNewCampaignReq,
  "campaignTemplateId" | "workspaceId"
> & {
  /***
   * @deprecated this was being used for permission checks instead
   * of inferring the workspace from the campaignId. this can lead to a security
   * vulnerability where someone can inject a dummy workspace they have access
   * to instead of the actual workspace.
   */
  workspaceId: idFlavor<"Workspace">;
  updateCampaignTemplate:
    | { newCampaignTemplateId: idFlavor<"CampaignTemplate"> | null }
    | false;
  oldDocuments: N_UrlResource[];
};

export const shareCampaignDto = z.object({
  versionName: z.string().optional(),
  emails: z.array(z.string().email()),
  message: z.string().or(z.string().length(0)),
});

export type ShareCampaignDto = z.infer<typeof shareCampaignDto>;

export const shareCampaignVersionDto = z.object({
  version: z.number(),
  emails: z
    .array(z.string().email())
    .min(1, { message: "Must provide atleast one user" }),
  message: z.string().or(z.string().length(0)),
});

export type ShareCampaignVersionDto = z.infer<typeof shareCampaignVersionDto>;

export interface UpdateCampaignVersionReq {
  version: number;
  name?: string;
  message?: string;
}

export interface UpdateTeamMembersReq {
  campaignId: idFlavor<"Campaign">;
  userIds: idFlavor<"AppUser">[];
  userGroupIds: idFlavor<"UserGroup">[];
}
export interface MoveCampaignReq {
  workspaceId: idFlavor<"Workspace">;
}

export interface UpdateCampaignReq {
  title: string;
  liveDate: N_LocalDate;
  endDate: N_LocalDate | null;
  notes: N_WysiwygDescendant[];
  documents: N_UrlResource[];
  oldDocuments: N_UrlResource[];
  jobNumber: string;
}

export const campaignAllDocumentsReqKind = {
  campaign: "campaign",
  placement: "placement",
  placementOne: "placementOne",
} as const;

export type CampaignAllDocumentsReqKind =
  (typeof campaignAllDocumentsReqKind)[keyof typeof campaignAllDocumentsReqKind];

export interface CampaignAllDocumentsReq {
  campaignId: idFlavor<"Campaign">;
  socketId: idFlavor<"WebsocketId">;
  placementIds?: idFlavor<"Placement">[];
  kind: CampaignAllDocumentsReqKind;
  campaignVersion: number;
  email?: string;
}

export interface SaveFilterReq {
  search?: string;
  tab?: string;
  dateRanges?: Record<string, { start?: number; end?: number }>;
  options?: Record<string, string[]>;
}
