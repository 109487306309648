import { idFlavor } from "src/common";
import { OrgMemberStatus } from "../orgMember";
import { CampaignUserActions } from "src/entities/campaignUserDetails/actions";

export const calculatedCampaignUserRole = {
  OWNER: "OWNER",
  FULL_ACCESS_INTERNAL: "FULL_ACCESS_INTERNAL",
  FULL_ACCESS_EXTERNAL: "FULL_ACCESS_EXTERNAL",
  EDITOR_INTERNAL: "EDITOR_INTERNAL",
  EDITOR_EXTERNAL: "EDITOR_EXTERNAL",
  VIEWER_INTERNAL: "VIEWER_INTERNAL",
  VIEWER_EXTERNAL: "VIEWER_EXTERNAL",
} as const;

export type CalculatedCampaignUserRole =
  (typeof calculatedCampaignUserRole)[keyof typeof calculatedCampaignUserRole];

export const campaignAccessLevel = {
  FULL_ACCESS: "LVL1",
  EDITOR: "LVL2",
  VIEWER: "LVL3",
} as const;

export type CampaignAccessLevel =
  (typeof campaignAccessLevel)[keyof typeof campaignAccessLevel];

export const campaignAccessLevelWeight = {
  [campaignAccessLevel.FULL_ACCESS]: 3,
  [campaignAccessLevel.EDITOR]: 2,
  [campaignAccessLevel.VIEWER]: 1,
} as const satisfies Record<CampaignAccessLevel, number>;

export const campaignCalculatedAccessLevelWeight = {
  [calculatedCampaignUserRole.OWNER]: 3,
  [calculatedCampaignUserRole.FULL_ACCESS_EXTERNAL]: 3,
  [calculatedCampaignUserRole.FULL_ACCESS_INTERNAL]: 3,
  [calculatedCampaignUserRole.EDITOR_EXTERNAL]: 2,
  [calculatedCampaignUserRole.EDITOR_INTERNAL]: 2,
  [calculatedCampaignUserRole.VIEWER_EXTERNAL]: 1,
  [calculatedCampaignUserRole.VIEWER_INTERNAL]: 1,
} as const satisfies Record<CalculatedCampaignUserRole, number>;

export const campaignAccessLevelLabels = {
  [campaignAccessLevel.FULL_ACCESS]: "Full Access",
  [campaignAccessLevel.EDITOR]: "Editor",
  [campaignAccessLevel.VIEWER]: "Viewer",
} as const satisfies Record<CampaignAccessLevel, string>;

export type CampaignAccessLevelLabels =
  (typeof campaignAccessLevelLabels)[keyof typeof campaignAccessLevelLabels];

export interface CampaignUserDetails {
  id: idFlavor<"CampaignUserDetails">;
  appUserId: idFlavor<"AppUser">;
  name: string;
  lastName: string;
  accessLevel: CampaignAccessLevel;
  email: string;
  profilePic: string;
  status: OrgMemberStatus;
  invitedBy?: idFlavor<"AppUser">;
  allowedActions: CampaignUserActions[];
  isOwner: boolean;
  isGuest: boolean;
}

export interface CampaignUserDetailsByStatus {
  active: CampaignUserDetails[];
  pending: CampaignUserDetails[];
}
