interface ValueCommon<T> {
  value: T;
}

export interface Value {
  kind: "valid";
}

export interface Invalid {
  kind: "invalid";
}

export interface Initial {
  kind: "initial";
}

export type InputValue<T> = (Value | Invalid | Initial) & ValueCommon<T>;

/** Helper function to create a valid value */
export function validInputValue<T>(value: T): InputValue<T> {
  return {
    kind: "valid",
    value,
  };
}
