import { Components, Theme } from "@mui/material";

export const MuiListItemText: Components<Theme>["MuiListItemText"] = {
  styleOverrides: {
    primary: ({ theme }) => ({
      fontSize: theme.fonts.sizes.smallFontSize,
      lineHeight: 1.33,
    }),
  },
};
