import { idFlavor } from "../../common";

export const bookingSubRoute = {
  createV2: "createV2",
  createWithPlacement: "createWithPlacement",
  updateWithPlacement: "updateWithPlacement",
  updatePublications: "updatePublications",
  update: "",
  duplicate: "duplicate",
  delete: "delete",
  originalCampaignData: "originalextcampaigndata",
  updateInfoSchemaConfig: "updateInfoSchemaConfig",
} as const;

export type BookingRoutes =
  | {
      sub: typeof bookingSubRoute.createV2;
      method?: "Post";
    }
  | {
      sub: typeof bookingSubRoute.createWithPlacement;
      method?: "Post";
    }
  | {
      sub: typeof bookingSubRoute.updateWithPlacement;
      method?: "Post";
    }
  | {
      sub: typeof bookingSubRoute.update;
      method?: "Patch";
    }
  | {
      sub: typeof bookingSubRoute.updatePublications;
      method?: "Patch";
    }
  | {
      sub: typeof bookingSubRoute.duplicate;
      method?: "Post";
    }
  | {
      sub: typeof bookingSubRoute.delete;
      method?: "Post";
    }
  | {
      sub: typeof bookingSubRoute.updateInfoSchemaConfig;
      method?: "Post";
    }
  | {
      sub: typeof bookingSubRoute.originalCampaignData;
      method?: "Get";
      tailParam?: idFlavor<"Booking">;
    };
