import { Components, Theme } from "@mui/material";

interface Property {
  padding: string | number | undefined;
  fontSize: string | number | undefined;
}

interface Sizes {
  small: Property;
  "extra-small": Property;
  medium: Property;
  large: Property;
  "extra-large": Property;
}

export const MuiSvgIcon: Components<Theme>["MuiSvgIcon"] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const sizes: Sizes = {
        "extra-small": {
          fontSize: theme.typography.subtitle4.fontSize,
          padding: 0,
        },
        small: {
          fontSize: theme.typography.h4.fontSize,
          padding: theme.spacing(0.25),
        },
        medium: {
          fontSize: theme.typography.h4.fontSize,
          padding: theme.spacing(0.5),
        },
        large: {
          fontSize: theme.typography.h3.fontSize,
          padding: theme.spacing(0.75),
        },
        "extra-large": {
          fontSize: theme.typography.extraLargeIcon.fontSize,
          padding: theme.spacing(1),
        },
      };
      return {
        ...sizes[ownerState.fontSize ?? "inherit"],
      };
    },
  },
};
