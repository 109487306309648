import {
  N_Placement,
  placementCategoryLabels,
  PlacementStandardColumns,
  placementStandardColumns,
} from "@specsheet-common/shared-types";
import { getPlacementStatus } from "./getPlacementStatus";
import {
  getPrintDimensions,
  getDurations,
  getTrims,
  getDimensions,
  getPhysicalDimensions,
  getSafeAreas,
  getDesignAreas,
  getArtWorkDimensions,
  getBleedAreas,
  getArtworkScales,
  getAspectRatios,
  getBitRates,
  hasCopyPlacement,
  getFileFormats,
  getFileSizes,
  getFrameRates,
  getResolutions,
  searchValuesFromNamedAnnotated,
} from "./specifications";
import { getSampleRates } from "./specifications/sampleRate";
import { notNull } from "../typescript";

export const getStandardValuesForPlacement = (
  placement: Omit<N_Placement, "attributes">
): { [key in PlacementStandardColumns]: () => string[] } => {
  return {
    [placementStandardColumns.placementColumn]: () => [
      `${placement.name}${placement.summary}`,
    ],
    [placementStandardColumns.channelColumn]: () => [
      placementCategoryLabels[placement.category],
    ],
    [placementStandardColumns.statusColumn]: () => [
      getPlacementStatus(placement),
    ],
    [placementStandardColumns.addressColumn]: () =>
      placement.location?.address ? [placement.location?.address] : [],
    [placementStandardColumns.siteIdColumn]: () =>
      placement.location?.siteNumber ? [placement.location?.siteNumber] : [],
    [placementStandardColumns.stateColumn]: () =>
      placement.location?.state ? [placement.location?.state] : [],
    [placementStandardColumns.suburbColumn]: () =>
      placement.location?.suburb ? [placement.location?.suburb] : [],
    [placementStandardColumns.documentsColumn]: () => {
      const documents = placement.documents || [];
      return documents.map((d) => d.name).filter(notNull);
    },
    [placementStandardColumns.availableDurationColumn]: () =>
      searchValuesFromNamedAnnotated(
        getDurations(placement.specifications.value)
      ),
    [placementStandardColumns.trimColumn]: () => {
      return searchValuesFromNamedAnnotated([
        ...getTrims(placement.specifications.value),
        ...getPrintDimensions(placement.specifications.value),
      ]);
    },
    [placementStandardColumns.dimensionsColumn]: () =>
      searchValuesFromNamedAnnotated(
        getDimensions(placement.specifications.value)
      ),
    [placementStandardColumns.physicalDimensionColumn]: () =>
      searchValuesFromNamedAnnotated(
        getPhysicalDimensions(placement.specifications.value)
      ),
    [placementStandardColumns.artWorkDimensionColumn]: () =>
      searchValuesFromNamedAnnotated(
        getArtWorkDimensions(placement.specifications.value)
      ),
    [placementStandardColumns.safeAreaColumn]: () => {
      return searchValuesFromNamedAnnotated([
        ...getSafeAreas(placement.specifications.value),
        ...getDesignAreas(placement.specifications.value, placement.category),
      ]);
    },
    [placementStandardColumns.bleedAreaColumn]: () =>
      searchValuesFromNamedAnnotated(
        getBleedAreas(placement.specifications.value)
      ),
    [placementStandardColumns.artworkScaleColumn]: () =>
      searchValuesFromNamedAnnotated(
        getArtworkScales(placement.specifications.value)
      ),
    [placementStandardColumns.aspectRatiosColumn]: () =>
      searchValuesFromNamedAnnotated(
        getAspectRatios(placement.specifications.value)
      ),
    [placementStandardColumns.bitRateColumn]: () =>
      searchValuesFromNamedAnnotated(
        getBitRates(placement.specifications.value)
      ),
    [placementStandardColumns.copyColumn]: () => [
      hasCopyPlacement(placement.specifications.value) ? "Yes" : "No",
    ],
    [placementStandardColumns.fileFormatsColumn]: () =>
      searchValuesFromNamedAnnotated(
        getFileFormats(placement.specifications.value)
      ),
    [placementStandardColumns.fileSizesColumn]: () =>
      searchValuesFromNamedAnnotated(
        getFileSizes(placement.specifications.value)
      ),
    [placementStandardColumns.frameRateColumn]: () =>
      searchValuesFromNamedAnnotated(
        getFrameRates(placement.specifications.value)
      ),
    [placementStandardColumns.resolutionColumn]: () =>
      searchValuesFromNamedAnnotated(
        getResolutions(placement.specifications.value)
      ),
    [placementStandardColumns.sampleRateColumn]: () =>
      searchValuesFromNamedAnnotated(
        getSampleRates(placement.specifications.value)
      ),
    [placementStandardColumns.mediaOwnerColumn]: () => [
      placement.publisher.name,
    ],
  };
};
