import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const Auth0Button = styled(Button)<ButtonProps>(() => ({
  color: "#fff",
  backgroundColor: "#0000FF",
  borderRadius: "3px",
  padding: "4px 16px",
  minHeight: "52px",
  fontSize: "16px",
  fontWeight: "400",
  fontFamily:
    "ulp-font,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,sans-serif",
  "&:hover, &:active": {
    backgroundColor: "#0000FF",
    boxShadow: "inset 0 0 0 150px rgba(0,0,0,.1)",
  },
}));
