import {
  campaignTemplateSpecColumn,
  CampaignTemplateSpecColumn,
  PlacementStandardColumns,
  placementStandardColumns,
} from "@specsheet-common/shared-types";
import { assertNever } from "../typescript";

export const convertCampaignTemplateSpecColumnToPlacementStandardColumn = (
  col: CampaignTemplateSpecColumn
): PlacementStandardColumns | null => {
  switch (col) {
    case campaignTemplateSpecColumn.ADDRESS: {
      return placementStandardColumns.addressColumn;
    }
    case "ARTWORK_DIMENSION": {
      return placementStandardColumns.artWorkDimensionColumn;
    }
    case "ARTWORK_SCALE": {
      return placementStandardColumns.artworkScaleColumn;
    }
    case "ASPECT_RATIOS": {
      return placementStandardColumns.aspectRatiosColumn;
    }
    case "AVAILABLE_DURATION": {
      return placementStandardColumns.availableDurationColumn;
    }
    case "BIT_RATE": {
      return placementStandardColumns.bitRateColumn;
    }
    case "BLEED_AREA": {
      return placementStandardColumns.bleedAreaColumn;
    }
    case "DOCUMENTS": {
      return placementStandardColumns.documentsColumn;
    }
    case "COPY": {
      return placementStandardColumns.copyColumn;
    }
    case "DIMENSIONS": {
      return placementStandardColumns.dimensionsColumn;
    }
    case "FILE_FORMATS": {
      return placementStandardColumns.fileFormatsColumn;
    }
    case "FILE_SIZES": {
      return placementStandardColumns.fileSizesColumn;
    }
    case "FRAME_RATE": {
      return placementStandardColumns.frameRateColumn;
    }
    case "PHYSICAL_DIMENSION": {
      return placementStandardColumns.physicalDimensionColumn;
    }
    case "RESOLUTION": {
      return placementStandardColumns.resolutionColumn;
    }
    case "SAFE_AREA": {
      return placementStandardColumns.safeAreaColumn;
    }
    case "SAMPLE_RATE": {
      return placementStandardColumns.sampleRateColumn;
    }
    case "SITE_ID": {
      return placementStandardColumns.siteIdColumn;
    }
    case "STATE": {
      return placementStandardColumns.stateColumn;
    }
    case "TRIM": {
      return placementStandardColumns.trimColumn;
    }
    case "SUBURB": {
      return placementStandardColumns.suburbColumn;
    }
    default: {
      return assertNever(col);
    }
  }
};
