import { idFlavor } from "src/common";

export const orgMemberSubRoute = {
  list: "list",
  updateOrgMember: "updateorgmember",
  toggleArchive: "togglearchive",
  toggleOrgMembersStatus: "toggleorgmembersstatus",
  updateOrgMemberRole: "updateorgmemberrole",
  addToWorkspaces: "addtoworkspaces",
  removeFromWorkspaces: "removefromworkspaces",
} as const;

export type OrgMemberRoutes =
  | {
      sub: typeof orgMemberSubRoute.list;
      tailParam: idFlavor<"Organisation">;
      method?: "Get";
    }
  | {
      sub: typeof orgMemberSubRoute.toggleArchive;
      tailParam: idFlavor<"OrganisationMember">;
      method?: "Get";
    }
  | {
      sub: typeof orgMemberSubRoute.toggleOrgMembersStatus;
      method?: "Post";
    }
  | {
      sub: typeof orgMemberSubRoute.updateOrgMember;
      method?: "Post";
    }
  | {
      sub: typeof orgMemberSubRoute.updateOrgMemberRole;
      method?: "Post";
    }
  | {
      sub: typeof orgMemberSubRoute.addToWorkspaces;
      method?: "Post";
    }
  | {
      sub: typeof orgMemberSubRoute.removeFromWorkspaces;
      method?: "Delete";
    };
