import {
  N_ArtworkSpec,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { stringifySampleRate } from "./stringify";

export const getSampleRates = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>(
    (rates, spec) =>
      spec.value.kind === "audio" && spec.value.value.sampleRate !== null
        ? [
            ...rates,
            {
              ...spec,
              value: stringifySampleRate(spec.value.value.sampleRate.value),
            },
          ]
        : rates,
    []
  );
