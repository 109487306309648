export const placementStandardColumns = {
  placementColumn: "placementColumn",
  channelColumn: "channelColumn",
  statusColumn: "statusColumn",
  addressColumn: "addressColumn",
  siteIdColumn: "siteIdColumn",
  stateColumn: "stateColumn",
  suburbColumn: "suburbColumn",
  documentsColumn: "documentsColumn",
  availableDurationColumn: "availableDurationColumn",
  trimColumn: "trimColumn",
  dimensionsColumn: "dimensionsColumn",
  physicalDimensionColumn: "physicalDimensionColumn",
  artWorkDimensionColumn: "artWorkDimensionColumn",
  safeAreaColumn: "safeAreaColumn",
  bleedAreaColumn: "bleedAreaColumn",
  artworkScaleColumn: "artworkScaleColumn",
  resolutionColumn: "resolutionColumn",
  bitRateColumn: "bitRateColumn",
  sampleRateColumn: "sampleRateColumn",
  frameRateColumn: "frameRateColumn",
  aspectRatiosColumn: "aspectRatiosColumn",
  fileSizesColumn: "fileSizesColumn",
  fileFormatsColumn: "fileFormatsColumn",
  copyColumn: "copyColumn",
  mediaOwnerColumn: "mediaOwnerColumn",
} as const;

export type PlacementStandardColumns =
  (typeof placementStandardColumns)[keyof typeof placementStandardColumns];
