import {
  fileSizeUnit,
  N_ArtworkSpec,
  N_DataAmount,
  N_FileSizeUnit,
  N_Html5Formats,
  N_NamedAnnotated,
} from "@specsheet-common/shared-types";
import { notNull, notNullish } from "../../typescript";
import { joinNames } from "./joinNames";

const FILE_SIZE_UNIT_MAP: Record<N_FileSizeUnit, string> = {
  [fileSizeUnit.kb]: "KB",
  [fileSizeUnit.mb]: "MB",
  [fileSizeUnit.gb]: "GB",
};

const getFileSize = (fileSize: N_DataAmount): string =>
  `${fileSize.amount} ${FILE_SIZE_UNIT_MAP[fileSize.unit]}`;

const getHtmlFormatsFileSizes = (
  formats: N_Html5Formats
): N_NamedAnnotated<string>[] => [
  ...(notNullish(formats.video) &&
  notNullish(formats.video.value.video.dataAmounts)
    ? formats.video.value.video.dataAmounts.value.flatMap((amount) =>
        amount.value.amount.amount > 0
          ? [{ ...amount, value: getFileSize(amount.value.amount) }]
          : []
      )
    : []),
];

type FileSize = N_NamedAnnotated<string> & { dataAmountValue?: N_DataAmount };

export const getFileSizes = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): FileSize[] =>
  specifications.reduce<FileSize[]>(
    (fileSizes, spec) => [
      ...((spec.value.kind === "digitalStatic" ||
        spec.value.kind === "animated" ||
        spec.value.kind === "audio" ||
        spec.value.kind === "magazine" ||
        spec.value.kind === "newspaper") &&
      spec.value.value.maxFileSize !== null
        ? [
            ...fileSizes,
            ...(spec.value.value.maxFileSize.value.amount > 0
              ? [
                  {
                    ...spec,
                    name: joinNames(spec.name, "Max File Size"),
                    value: getFileSize(spec.value.value.maxFileSize.value),
                    dataAmountValue: spec.value.value.maxFileSize.value,
                  },
                ]
              : []),
          ]
        : fileSizes),
      ...(spec.value.kind === "copy"
        ? spec.value.value.text.value.flatMap((text) =>
            text.value.maxFileSize !== null && text.value.maxFileSize.amount > 0
              ? [
                  {
                    ...spec,
                    name: joinNames(spec.name, text.name),
                    value: getFileSize(text.value.maxFileSize),
                    dataAmountValue: text.value.maxFileSize,
                  },
                ]
              : []
          )
        : []),
      ...(spec.value.kind === "video" && notNull(spec.value.value.dataAmounts)
        ? spec.value.value.dataAmounts.value.flatMap((amount) =>
            amount.value.amount.amount > 0
              ? [
                  {
                    ...spec,
                    name: joinNames(spec.name, amount.name),
                    value: getFileSize(amount.value.amount),
                    dataAmountValue: amount.value.amount,
                  },
                ]
              : []
          )
        : []),
      ...(spec.value.kind === "html5" && notNull(spec.value.value.dataAmounts)
        ? spec.value.value.dataAmounts.value.flatMap((amount) =>
            amount.value.amount.amount > 0
              ? [
                  {
                    ...spec,
                    name: joinNames(spec.name, amount.name),
                    value: getFileSize(amount.value.amount),
                    dataAmountValue: amount.value.amount,
                  },
                ]
              : []
          )
        : []),
      ...(spec.value.kind === "html5" && notNull(spec.value.value.formats)
        ? getHtmlFormatsFileSizes(spec.value.value.formats.value).map((fs) => {
            const fileSize: FileSize = {
              ...spec,
              name: joinNames(spec.name, fs.name),
              value: fs.value,
            };
            return fileSize;
          })
        : []),
    ],
    []
  );
