import {
  CampaignChangeCreatedBy,
  campaignChangeCreatedBy,
  extCampaignKindLabels,
} from "@specsheet-common/shared-types";

export const changeCreatedByToExtPublisher = (
  type: CampaignChangeCreatedBy
): string | undefined => {
  switch (type) {
    case campaignChangeCreatedBy.EXT_IMPORT_API: {
      return "External API";
    }
    case campaignChangeCreatedBy.EXT_IMPORT_OOH: {
      return extCampaignKindLabels.OOH;
    }
    case campaignChangeCreatedBy.EXT_IMPORT_QMS: {
      return extCampaignKindLabels.QMS;
    }
    case campaignChangeCreatedBy.EXT_IMPORT_JCD: {
      return extCampaignKindLabels.JCD;
    }
    case campaignChangeCreatedBy.EXT_IMPORT_JCDNZ: {
      return extCampaignKindLabels.JCDNZ;
    }
    case campaignChangeCreatedBy.EXT_IMPORT_GO_MEDIA: {
      return extCampaignKindLabels.GO_MEDIA;
    }
    case campaignChangeCreatedBy.EXT_IMPORT_MEDIA_WORKS: {
      return extCampaignKindLabels.MEDIA_WORKS;
    }
    default: {
      return undefined;
    }
  }
};
