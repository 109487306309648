import {
  stringifyPrintBorderDimensions,
  stringifyPrintDimension,
} from "./stringify";
import {
  N_ArtworkSpec,
  N_NamedAnnotated,
  N_PlacementCategory,
  placementCategory,
} from "@specsheet-common/shared-types";
import { stringifyPrintNewspaperMagazineDimension } from "./printDimension";

export const getSafeAreas = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>(
    (areas, spec) =>
      spec.value.kind === "newspaper" && spec.value.value.textSafeArea !== null
        ? [
            ...areas,
            {
              ...spec,
              value: stringifyPrintBorderDimensions(
                spec.value.value.textSafeArea.value
              ),
            },
          ]
        : areas,
    []
  );

export const getDesignAreas = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[],
  category: N_PlacementCategory
): N_NamedAnnotated<string>[] =>
  specifications.reduce<N_NamedAnnotated<string>[]>(
    (areas, spec) =>
      spec.value.kind === "magazine" && spec.value.value.design !== null
        ? [
            ...areas,
            {
              ...spec,
              value:
                category === placementCategory.PRINT_OOH
                  ? stringifyPrintDimension(spec.value.value.design.value)
                  : stringifyPrintNewspaperMagazineDimension(
                      spec.value.value.design.value
                    ),
            },
          ]
        : areas,
    []
  );
