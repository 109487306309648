import { N_BookingGroup } from "./general";
import { N_ChangedValue, N_Instant, idFlavor } from "../../common";

export interface PartialBooking {
  id: idFlavor<"Booking">;
  placementName: string;
  publisherName: string;
}

export interface CML_Group {
  id: idFlavor<"BookingGroup">;
  bookings: PartialBooking[];
  level: number;
  name: string;
  campaignId: idFlavor<"Campaign">;
  campaignVersion: number;
  created: N_Instant;
  updated: N_Instant;
  subGroups: CML_Group[];
}

export interface N_BookingGroupDeleted_CML {
  groupsDeleted: CML_Group[];
  ungroupedDeletedBookings: PartialBooking[];
}

export interface N_BookingGroupDuplicated_CML {
  groupsDuplicated: CML_Group[];
  ungroupedDuplicatedBookings: PartialBooking[];
}

export interface N_BookingGroupRefName {
  id: idFlavor<"BookingGroup">;
  name: string;
}

export interface N_GroupUpdatedType {
  nameChanged?: N_ChangedValue<string>;
  parentChanged?: N_ChangedValue<N_BookingGroupRefName | null>;
}

export interface N_BookingGroupUpdated {
  value: N_BookingGroup;
  update: N_GroupUpdatedType;
}

export const bookingGroupEditKind = {
  groupDuplicated: "groupDuplicated",
  groupDuplicatedCml: "groupDuplicatedCml",
  groupAdded: "groupAdded",
  groupMoved: "groupMoved",
  groupUngrouped: "groupUngrouped",
  groupDeleted: "groupDeleted",
  groupDeletedCml: "groupDeletedCml",
  groupUpdated: "groupUpdated",
} as const;

export type BookingGroupEditKind =
  (typeof bookingGroupEditKind)[keyof typeof bookingGroupEditKind];

export interface N_BookingGroupEdit_GroupDuplicated_Cml {
  kind: typeof bookingGroupEditKind.groupDuplicatedCml;
  value: N_BookingGroupDuplicated_CML;
}

export interface N_BookingGroupEdit_GroupDuplicated_Legacy {
  kind: typeof bookingGroupEditKind.groupDuplicated;
  value: N_BookingGroup;
}

export interface N_BookingGroupEdit_GroupMoved {
  kind: typeof bookingGroupEditKind.groupMoved;
  value: N_BookingGroup & {
    updates: N_ChangedValue<string | undefined>;
  };
}

export interface N_BookingGroupEdit_GroupUngrouped {
  kind: typeof bookingGroupEditKind.groupUngrouped;
  value: N_BookingGroup;
}

// new variant of duplicated is needed for cml facelift

export interface N_BookingGroupEdit_GroupAdded {
  kind: typeof bookingGroupEditKind.groupAdded;
  value: N_BookingGroup;
}

// New variant for CML deletes
export interface N_BookingGroupEdit_GroupDeleted_Cml {
  kind: typeof bookingGroupEditKind.groupDeletedCml;
  value: N_BookingGroupDeleted_CML;
}

// legacy variant
export interface N_BookingGroupEdit_GroupDeleted_Legacy {
  kind: typeof bookingGroupEditKind.groupDeleted;
  value: N_BookingGroup;
}
export interface N_BookingGroupEdit_GroupUpdated {
  kind: typeof bookingGroupEditKind.groupUpdated;
  value: N_BookingGroupUpdated;
}

export type N_BookingGroupEdit =
  | N_BookingGroupEdit_GroupDuplicated_Cml
  | N_BookingGroupEdit_GroupDuplicated_Legacy
  | N_BookingGroupEdit_GroupAdded
  | N_BookingGroupEdit_GroupMoved
  | N_BookingGroupEdit_GroupUngrouped
  | N_BookingGroupEdit_GroupDeleted_Legacy
  | N_BookingGroupEdit_GroupDeleted_Cml
  | N_BookingGroupEdit_GroupUpdated;
