import {
  N_BookingEdit,
  N_BookingGroupEdit,
  N_CampaignUpdatedValues,
} from "@specsheet-common/shared-types";
import {
  CampaignChangeBasicWithInfoSchemaLog,
  DB_BookingEditWithPlacement,
  DB_BookingGroupEdit,
  DB_CampaignEditWithInfoSchemaLog,
  DB_CampaignUpdatedValues,
  N_CampaignChange,
  N_CampaignEditWithInfoSchemaLog,
} from "@specsheet-common/shared-types";
import { assertNever } from "../typescript";
import { convertDbUrlRecourceToNUrlRecource } from "./placementSpecsConverter";
import { convertFromDBWysiwygDescendant } from "./wysiwyg";

const convertFromDbBookingEdit = (
  edit: DB_BookingEditWithPlacement
): N_BookingEdit => {
  const placements = {
    placementName: edit.bookingPlacement?.name,
    publisherName: edit.bookingPlacement?.publisher.name,
    placementSummary: edit.bookingPlacement?.location?.address
      ? `${edit.bookingPlacement.summary}-${edit.bookingPlacement.location.address}`
      : undefined,
  };

  if ("bookingUpdated" in edit) {
    return {
      kind: "bookingUpdated",
      booking: {
        id: edit.bookingUpdated.value.id,
        bookingConsolidation: edit.bookingUpdated.value.bookingConsolidation,
        updated: edit.bookingUpdated.value.updated,
        extCampaignRef: edit.bookingUpdated.value.extCampaignRef,
      },
      updated: {
        updates: Object.fromEntries(
          Object.entries(edit.bookingUpdated.updates).filter(
            ([_, v]) => v !== null
          )
        ),
      },
      ...placements,
    };
  }

  if ("bookingAdded" in edit) {
    return {
      kind: "bookingAdded",
      booking: {
        id: edit.bookingAdded.id,
        bookingConsolidation: edit.bookingAdded.bookingConsolidation,
        updated: edit.bookingAdded.updated,
        extCampaignRef: edit.bookingAdded.extCampaignRef,
      },
      ...placements,
    };
  }

  if ("bookingDeleted" in edit) {
    return {
      kind: "bookingDeleted",
      booking: {
        id: edit.bookingDeleted.id,
        bookingConsolidation: edit.bookingDeleted.bookingConsolidation,
        updated: edit.bookingDeleted.updated,
        extCampaignRef: edit.bookingDeleted.extCampaignRef,
      },
      ...placements,
    };
  }

  if ("bookingDuplicated" in edit) {
    return {
      kind: "bookingDuplicated",
      booking: {
        id: edit.bookingDuplicated.value.id,
        bookingConsolidation: edit.bookingDuplicated.value.bookingConsolidation,
        updated: edit.bookingDuplicated.value.updated,
        extCampaignRef: edit.bookingDuplicated.value.extCampaignRef,
      },
      duplicated: { bookingId: edit.bookingDuplicated.bookingId },
      ...placements,
    };
  }

  assertNever(edit);
  throw new Error();
};

const convertFromCampaignDetailsEdit = (
  values: DB_CampaignUpdatedValues
): N_CampaignUpdatedValues => ({
  ...values,
  documentsChanged: values.documentsChanged
    ? {
        after: values.documentsChanged.after.map((url) =>
          convertDbUrlRecourceToNUrlRecource(url)
        ),
        before: values.documentsChanged.before.map((url) =>
          convertDbUrlRecourceToNUrlRecource(url)
        ),
      }
    : null,
  notesChanged: values.notesChanged
    ? {
        before: values.notesChanged.before.map((des) =>
          convertFromDBWysiwygDescendant(des)
        ),
        after: values.notesChanged.after.map((des) =>
          convertFromDBWysiwygDescendant(des)
        ),
      }
    : null,
});

const convertFromDbGroupEdit = (
  edit: DB_BookingGroupEdit
): N_BookingGroupEdit => {
  if ("groupUpdated" in edit) {
    return {
      kind: "groupUpdated",
      value: {
        value: edit.groupUpdated.value,
        update: {
          nameChanged: edit.groupUpdated.update.nameChanged || undefined,
          parentChanged: edit.groupUpdated.update.parentChanged || undefined,
        },
      },
    };
  }

  if ("groupAdded" in edit) {
    return {
      kind: "groupAdded",
      value: edit.groupAdded,
    };
  }

  if ("groupDeleted" in edit) {
    return {
      kind: "groupDeleted",
      value: edit.groupDeleted,
    };
  }

  if ("groupDuplicated" in edit) {
    return {
      kind: "groupDuplicated",
      value: edit.groupDuplicated,
    };
  }
  assertNever(edit);
  throw new Error();
};

const convertFromDbCampaignEdit = (
  edit: DB_CampaignEditWithInfoSchemaLog
): N_CampaignEditWithInfoSchemaLog => {
  if ("bookingEdit" in edit) {
    return {
      kind: "bookingEdit",
      value: convertFromDbBookingEdit(edit.bookingEdit),
    };
  }

  if ("campaignDetailsEdit" in edit) {
    return {
      kind: "campaignDetailsEdit",
      value: convertFromCampaignDetailsEdit(edit.campaignDetailsEdit),
    };
  }

  if ("groupEdit" in edit) {
    return {
      kind: "groupEdit",
      value: convertFromDbGroupEdit(edit.groupEdit),
    };
  }

  if ("bookingInfoSchemaEdit" in edit) {
    return {
      kind: "bookingInfoSchemaEdit",
      value: edit.bookingInfoSchemaEdit,
    };
  }

  if ("bookingPublicationEdit" in edit) {
    return {
      kind: "bookingPublicationEdit",
      value: edit.bookingPublicationEdit,
    };
  }

  assertNever(edit);
  throw new Error();
};

export const convertFromDbCampaignChange = (
  change: CampaignChangeBasicWithInfoSchemaLog
): N_CampaignChange => ({
  ...change,
  user: change.user && {
    userId: change.user.userId,
    name: change.user.name,
    lastName: change.user.lastName,
  },
  isConsolidated: change.isConsolidated,
  date: change.date.getTime(),
  changes: change.changes.map((c) => convertFromDbCampaignEdit(c)),
});
