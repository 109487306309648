import * as z from "zod";
import { sitecardsData } from "../sitecards";

export const jcdPanelKinds = {
  BILLBOARDS: "BILLBOARDS",
  FURNITURE: "FURNITURE",
  DIGITAL: "DIGITAL",
  TRANSIT_GENERAL: "TRANSIT_GENERAL",
  TRANSIT_PANEL: "TRANSIT_PANEL",
} as const;

export type JcdPanelKinds = (typeof jcdPanelKinds)[keyof typeof jcdPanelKinds];

export const commonJCDProperties = {
  bookingId: "bookingId",
  campaignId: "campaignId",
  campaignName: "campaignName",
  campaignClientName: "campaignClientName",
  startDate: "startDate",
} as const;

export type CommonJCDProperties =
  (typeof commonJCDProperties)[keyof typeof commonJCDProperties];

export const partiallySharedJCDProperties = {
  quantity: "quantity",
  sizeName: "sizeName",
  panelId: "panelId",
  panelName: "panelName",
  state: "state",
  suburb: "suburb",
  panelDirectionName: "panelDirectionName",
  fixtureType: "fixtureType",
  illuminationType: "illuminationType",
  visualWidth: "visualWidth",
  pixelWidth: "pixelWidth",
  pixelHeight: "pixelHeight",
  visualHeight: "visualHeight",
  physicalWidth: "physicalWidth",
  physicalHeight: "physicalHeight",
  digitalAspectRatio: "digitalAspectRatio",
  productionComments: "productionComments",
  productionSpec: "productionSpec",
  objectExtension: "objectExtension",
  panelCensorship: "panelCensorship",
  panelProhibitions: "panelProhibitions",
  endDate: "endDate",
} as const;

export type PartiallySharedJCDProperties =
  (typeof partiallySharedJCDProperties)[keyof typeof partiallySharedJCDProperties];

export const billboardJCDProperties = {
  ...commonJCDProperties,
  [partiallySharedJCDProperties.quantity]:
    partiallySharedJCDProperties.quantity,
  [partiallySharedJCDProperties.sizeName]:
    partiallySharedJCDProperties.sizeName,
  [partiallySharedJCDProperties.panelId]: partiallySharedJCDProperties.panelId,
  [partiallySharedJCDProperties.panelName]:
    partiallySharedJCDProperties.panelName,
  [partiallySharedJCDProperties.state]: partiallySharedJCDProperties.state,
  [partiallySharedJCDProperties.suburb]: partiallySharedJCDProperties.suburb,
  [partiallySharedJCDProperties.panelDirectionName]:
    partiallySharedJCDProperties.panelDirectionName,
  [partiallySharedJCDProperties.fixtureType]:
    partiallySharedJCDProperties.fixtureType,
  [partiallySharedJCDProperties.illuminationType]:
    partiallySharedJCDProperties.illuminationType,
  [partiallySharedJCDProperties.visualWidth]:
    partiallySharedJCDProperties.visualWidth,
  [partiallySharedJCDProperties.visualHeight]:
    partiallySharedJCDProperties.visualHeight,
  [partiallySharedJCDProperties.physicalWidth]:
    partiallySharedJCDProperties.physicalWidth,
  [partiallySharedJCDProperties.physicalHeight]:
    partiallySharedJCDProperties.physicalHeight,
  [partiallySharedJCDProperties.productionComments]:
    partiallySharedJCDProperties.productionComments,
  [partiallySharedJCDProperties.productionSpec]:
    partiallySharedJCDProperties.productionSpec,
  [partiallySharedJCDProperties.objectExtension]:
    partiallySharedJCDProperties.objectExtension,
  [partiallySharedJCDProperties.panelCensorship]:
    partiallySharedJCDProperties.panelCensorship,
  [partiallySharedJCDProperties.panelProhibitions]:
    partiallySharedJCDProperties.panelProhibitions,
} as const;

export type BillboardJCDProperties =
  (typeof billboardJCDProperties)[keyof typeof billboardJCDProperties];

export const furnitureJCDProperties = {
  ...commonJCDProperties,
  [partiallySharedJCDProperties.quantity]:
    partiallySharedJCDProperties.quantity,
  [partiallySharedJCDProperties.sizeName]:
    partiallySharedJCDProperties.sizeName,
  [partiallySharedJCDProperties.state]: partiallySharedJCDProperties.state,
  [partiallySharedJCDProperties.visualWidth]:
    partiallySharedJCDProperties.visualWidth,
  [partiallySharedJCDProperties.visualHeight]:
    partiallySharedJCDProperties.visualHeight,
  [partiallySharedJCDProperties.physicalWidth]:
    partiallySharedJCDProperties.physicalWidth,
  [partiallySharedJCDProperties.physicalHeight]:
    partiallySharedJCDProperties.physicalHeight,
  [partiallySharedJCDProperties.productionComments]:
    partiallySharedJCDProperties.productionComments,
  [partiallySharedJCDProperties.productionSpec]:
    partiallySharedJCDProperties.productionSpec,
  [partiallySharedJCDProperties.objectExtension]:
    partiallySharedJCDProperties.objectExtension,
} as const;

export type FurnitureJCDProperties =
  (typeof furnitureJCDProperties)[keyof typeof furnitureJCDProperties];

export const digitalJCDProperties = {
  ...commonJCDProperties,
  [partiallySharedJCDProperties.sizeName]:
    partiallySharedJCDProperties.sizeName,
  [partiallySharedJCDProperties.state]: partiallySharedJCDProperties.state,
  [partiallySharedJCDProperties.suburb]: partiallySharedJCDProperties.suburb,
  [partiallySharedJCDProperties.panelId]: partiallySharedJCDProperties.panelId,
  [partiallySharedJCDProperties.panelName]:
    partiallySharedJCDProperties.panelName,
  [partiallySharedJCDProperties.panelDirectionName]:
    partiallySharedJCDProperties.panelDirectionName,
  [partiallySharedJCDProperties.pixelWidth]:
    partiallySharedJCDProperties.pixelWidth,
  [partiallySharedJCDProperties.pixelHeight]:
    partiallySharedJCDProperties.pixelHeight,
  [partiallySharedJCDProperties.digitalAspectRatio]:
    partiallySharedJCDProperties.digitalAspectRatio,
  [partiallySharedJCDProperties.productionComments]:
    partiallySharedJCDProperties.productionComments,
  [partiallySharedJCDProperties.productionSpec]:
    partiallySharedJCDProperties.productionSpec,
  [partiallySharedJCDProperties.objectExtension]:
    partiallySharedJCDProperties.objectExtension,
  [partiallySharedJCDProperties.panelCensorship]:
    partiallySharedJCDProperties.panelCensorship,
  [partiallySharedJCDProperties.panelProhibitions]:
    partiallySharedJCDProperties.panelProhibitions,
  [partiallySharedJCDProperties.endDate]: partiallySharedJCDProperties.endDate,
} as const;

export type DigitalJCDProperties =
  (typeof digitalJCDProperties)[keyof typeof digitalJCDProperties];

export const transitGeneralJCDProperties = {
  ...commonJCDProperties,
  [partiallySharedJCDProperties.quantity]:
    partiallySharedJCDProperties.quantity,
  [partiallySharedJCDProperties.sizeName]:
    partiallySharedJCDProperties.sizeName,
  [partiallySharedJCDProperties.state]: partiallySharedJCDProperties.state,
  [partiallySharedJCDProperties.fixtureType]:
    partiallySharedJCDProperties.fixtureType,
  [partiallySharedJCDProperties.visualWidth]:
    partiallySharedJCDProperties.visualWidth,
  [partiallySharedJCDProperties.visualHeight]:
    partiallySharedJCDProperties.visualHeight,
  [partiallySharedJCDProperties.physicalWidth]:
    partiallySharedJCDProperties.physicalWidth,
  [partiallySharedJCDProperties.physicalHeight]:
    partiallySharedJCDProperties.physicalHeight,
  [partiallySharedJCDProperties.productionComments]:
    partiallySharedJCDProperties.productionComments,
  [partiallySharedJCDProperties.productionSpec]:
    partiallySharedJCDProperties.productionSpec,
  [partiallySharedJCDProperties.objectExtension]:
    partiallySharedJCDProperties.objectExtension,
} as const;

export type TransitGeneralJCDProperties =
  (typeof transitGeneralJCDProperties)[keyof typeof transitGeneralJCDProperties];

export const transitPanelJCDProperties = {
  ...commonJCDProperties,
  [partiallySharedJCDProperties.quantity]:
    partiallySharedJCDProperties.quantity,
  [partiallySharedJCDProperties.sizeName]:
    partiallySharedJCDProperties.sizeName,
  [partiallySharedJCDProperties.panelId]: partiallySharedJCDProperties.panelId,
  [partiallySharedJCDProperties.panelName]:
    partiallySharedJCDProperties.panelName,
  [partiallySharedJCDProperties.fixtureType]:
    partiallySharedJCDProperties.fixtureType,
  [partiallySharedJCDProperties.visualWidth]:
    partiallySharedJCDProperties.visualWidth,
  [partiallySharedJCDProperties.visualHeight]:
    partiallySharedJCDProperties.visualHeight,
  [partiallySharedJCDProperties.physicalWidth]:
    partiallySharedJCDProperties.physicalWidth,
  [partiallySharedJCDProperties.physicalHeight]:
    partiallySharedJCDProperties.physicalHeight,
  [partiallySharedJCDProperties.productionComments]:
    partiallySharedJCDProperties.productionComments,
  [partiallySharedJCDProperties.productionSpec]:
    partiallySharedJCDProperties.productionSpec,
  [partiallySharedJCDProperties.objectExtension]:
    partiallySharedJCDProperties.objectExtension,
} as const;

export type TransitPanelJCDProperties =
  (typeof transitPanelJCDProperties)[keyof typeof transitPanelJCDProperties];

export const jcdBillboardData = z
  .object({
    [billboardJCDProperties.bookingId]: z.string().min(1),
    [billboardJCDProperties.campaignId]: z.string().min(1),
    [billboardJCDProperties.campaignName]: z.string().min(1),
    [billboardJCDProperties.campaignClientName]: z.string().min(1),
    [billboardJCDProperties.quantity]: z.string(),
    [billboardJCDProperties.sizeName]: z.string(),
    [billboardJCDProperties.panelId]: z.string().min(1),
    [billboardJCDProperties.panelName]: z.string(),
    [billboardJCDProperties.state]: z.string(),
    [billboardJCDProperties.suburb]: z.string(),
    [billboardJCDProperties.startDate]: z.string(),
    [billboardJCDProperties.panelDirectionName]: z.string(),
    [billboardJCDProperties.fixtureType]: z.string(),
    [billboardJCDProperties.illuminationType]: z.string(),
    [billboardJCDProperties.visualWidth]: z.string(),
    [billboardJCDProperties.visualHeight]: z.string(),
    [billboardJCDProperties.physicalWidth]: z.string(),
    [billboardJCDProperties.physicalHeight]: z.string(),
    [billboardJCDProperties.productionComments]: z.string(),
    [billboardJCDProperties.productionSpec]: z.string(),
    [billboardJCDProperties.objectExtension]: z.string(),
    [billboardJCDProperties.panelCensorship]: z.string(),
    [billboardJCDProperties.panelProhibitions]: z.string(),
  })
  .merge(sitecardsData);

export type JcdBillboardData = z.infer<typeof jcdBillboardData>;

export type JcdBillboardDataProps = keyof JcdBillboardData;

export const jcdFurnitureData = z
  .object({
    [furnitureJCDProperties.bookingId]: z.string().min(1),
    [furnitureJCDProperties.campaignId]: z.string().min(1),
    [furnitureJCDProperties.campaignName]: z.string().min(1),
    [furnitureJCDProperties.campaignClientName]: z.string().min(1),
    [furnitureJCDProperties.quantity]: z.string(),
    [furnitureJCDProperties.sizeName]: z.string(),
    [furnitureJCDProperties.state]: z.string(),
    [furnitureJCDProperties.startDate]: z.string(),
    [furnitureJCDProperties.visualWidth]: z.string(),
    [furnitureJCDProperties.visualHeight]: z.string(),
    [furnitureJCDProperties.physicalWidth]: z.string(),
    [furnitureJCDProperties.physicalHeight]: z.string(),
    [furnitureJCDProperties.productionComments]: z.string(),
    [furnitureJCDProperties.productionSpec]: z.string(),
    [billboardJCDProperties.objectExtension]: z.string(),
  })
  .merge(sitecardsData);

export type JcdFurnitureData = z.infer<typeof jcdFurnitureData>;

export const jcdDigitalData = z
  .object({
    [digitalJCDProperties.bookingId]: z.string().min(1),
    [digitalJCDProperties.campaignId]: z.string().min(1),
    [digitalJCDProperties.campaignName]: z.string().min(1),
    [digitalJCDProperties.campaignClientName]: z.string().min(1),
    [digitalJCDProperties.sizeName]: z.string(),
    [digitalJCDProperties.state]: z.string(),
    [digitalJCDProperties.suburb]: z.string(),
    [digitalJCDProperties.panelId]: z.string().min(1),
    [digitalJCDProperties.panelName]: z.string(),
    [digitalJCDProperties.startDate]: z.string(),
    [digitalJCDProperties.panelDirectionName]: z.string(),
    [digitalJCDProperties.pixelWidth]: z.string(),
    [digitalJCDProperties.pixelHeight]: z.string(),
    [digitalJCDProperties.digitalAspectRatio]: z.string(),
    [digitalJCDProperties.productionComments]: z.string(),
    [digitalJCDProperties.productionSpec]: z.string(),
    [digitalJCDProperties.objectExtension]: z.string(),
    [digitalJCDProperties.panelCensorship]: z.string(),
    [digitalJCDProperties.panelProhibitions]: z.string(),
    [digitalJCDProperties.endDate]: z.string().or(z.undefined()),
  })
  .merge(sitecardsData);

export type JcdDigitalData = z.infer<typeof jcdDigitalData>;

export const jcdTransitGeneralData = z
  .object({
    [transitGeneralJCDProperties.bookingId]: z.string().min(1),
    [transitGeneralJCDProperties.campaignId]: z.string().min(1),
    [transitGeneralJCDProperties.campaignName]: z.string().min(1),
    [transitGeneralJCDProperties.campaignClientName]: z.string().min(1),
    [transitGeneralJCDProperties.quantity]: z.string(),
    [transitGeneralJCDProperties.sizeName]: z.string(),
    [transitGeneralJCDProperties.state]: z.string(),
    [transitGeneralJCDProperties.startDate]: z.string(),
    [transitGeneralJCDProperties.fixtureType]: z.string(),
    [transitGeneralJCDProperties.visualWidth]: z.string(),
    [transitGeneralJCDProperties.visualHeight]: z.string(),
    [transitGeneralJCDProperties.physicalWidth]: z.string(),
    [transitGeneralJCDProperties.physicalHeight]: z.string(),
    [transitGeneralJCDProperties.productionComments]: z.string(),
    [transitGeneralJCDProperties.productionSpec]: z.string(),
    [transitGeneralJCDProperties.objectExtension]: z.string(),
  })
  .merge(sitecardsData);

export type JcdTransitGeneralData = z.infer<typeof jcdTransitGeneralData>;

export const jcdTransitPanelData = z
  .object({
    [transitPanelJCDProperties.bookingId]: z.string().min(1),
    [transitPanelJCDProperties.campaignId]: z.string().min(1),
    [transitPanelJCDProperties.campaignName]: z.string().min(1),
    [transitPanelJCDProperties.campaignClientName]: z.string().min(1),
    [transitPanelJCDProperties.quantity]: z.string(),
    [transitPanelJCDProperties.sizeName]: z.string(),
    [transitPanelJCDProperties.panelId]: z.string().min(1),
    [transitPanelJCDProperties.panelName]: z.string(),
    [transitPanelJCDProperties.startDate]: z.string(),
    [transitPanelJCDProperties.fixtureType]: z.string(),
    [transitPanelJCDProperties.visualWidth]: z.string(),
    [transitPanelJCDProperties.visualHeight]: z.string(),
    [transitPanelJCDProperties.physicalWidth]: z.string(),
    [transitPanelJCDProperties.physicalHeight]: z.string(),
    [transitPanelJCDProperties.productionComments]: z.string(),
    [transitPanelJCDProperties.productionSpec]: z.string(),
    [transitPanelJCDProperties.objectExtension]: z.string(),
  })
  .merge(sitecardsData);

export type JcdTransitPanelData = z.infer<typeof jcdTransitPanelData>;

export const jcdExtBookingData = z.union([
  z.object({
    kind: z.literal(jcdPanelKinds.BILLBOARDS),
    data: jcdBillboardData,
  }),
  z.object({
    kind: z.literal(jcdPanelKinds.FURNITURE),
    data: jcdFurnitureData,
  }),
  z.object({
    kind: z.literal(jcdPanelKinds.DIGITAL),
    data: jcdDigitalData,
  }),
  z.object({
    kind: z.literal(jcdPanelKinds.TRANSIT_GENERAL),
    data: jcdTransitGeneralData,
  }),
  z.object({
    kind: z.literal(jcdPanelKinds.TRANSIT_PANEL),
    data: jcdTransitPanelData,
  }),
]);

export type JcdExtBookingData = z.infer<typeof jcdExtBookingData>;

export type JcdProperties =
  | BillboardJCDProperties
  | FurnitureJCDProperties
  | DigitalJCDProperties
  | TransitGeneralJCDProperties
  | TransitPanelJCDProperties;

export const jcdPanelKindToProperties: {
  [jcdPanelKinds.BILLBOARDS]: BillboardJCDProperties[];
  [jcdPanelKinds.FURNITURE]: FurnitureJCDProperties[];
  [jcdPanelKinds.DIGITAL]: DigitalJCDProperties[];
  [jcdPanelKinds.TRANSIT_GENERAL]: TransitGeneralJCDProperties[];
  [jcdPanelKinds.TRANSIT_PANEL]: TransitPanelJCDProperties[];
} = {
  [jcdPanelKinds.BILLBOARDS]: Object.values(billboardJCDProperties),
  [jcdPanelKinds.FURNITURE]: Object.values(furnitureJCDProperties),
  [jcdPanelKinds.DIGITAL]: Object.values(digitalJCDProperties),
  [jcdPanelKinds.TRANSIT_GENERAL]: Object.values(transitGeneralJCDProperties),
  [jcdPanelKinds.TRANSIT_PANEL]: Object.values(transitPanelJCDProperties),
} as const;

export const jcdPanelKindToZod = {
  [jcdPanelKinds.BILLBOARDS]: jcdBillboardData,
  [jcdPanelKinds.FURNITURE]: jcdFurnitureData,
  [jcdPanelKinds.DIGITAL]: jcdDigitalData,
  [jcdPanelKinds.TRANSIT_GENERAL]: jcdTransitGeneralData,
  [jcdPanelKinds.TRANSIT_PANEL]: jcdTransitPanelData,
} as const;
