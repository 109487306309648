export const campaignUserActions = {
  inviteWithFullAccess: "inviteWithFullAccess",
  inviteWithEditorAccess: "inviteWithEditorAccess",
  inviteWithViewAccess: "inviteWithViewAccess",
  setFullAccess: "setFullAccess",
  setEditorAccess: "setEditorAccess",
  setViewerAccess: "setViewerAccess",
  removeFromCampaign: "removeFromCampaign",
  assignOwnership: "assignOwnership",
} as const;

export type CampaignUserActions =
  (typeof campaignUserActions)[keyof typeof campaignUserActions];
