import { Components, Theme } from "@mui/material";

export const MuiTab: Components<Theme>["MuiTab"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.subtitle1,
      textTransform: "none",
      padding: theme.spacing(3, 6, 2, 6),
      border: `${theme.spacing(1)} solid ${theme.newColors.neutral.white}`,
      borderRadius: theme.spacing(2),
      minHeight: 0,
      color: theme.newColors.neutral.primary,
      "& .MuiChip-root": {
        margin: theme.spacing(0, 0, 0, 2),
      },
      ["&:hover"]: {
        background: theme.newColors.neutral.gray100,
      },
    }),
  },
};
