import {
  N_AnimatedMediaFormat,
  N_ArtworkSpec,
  N_AudioFormat,
  N_Html5Formats,
  N_NamedAnnotated,
  N_PrintMediaFormat,
  N_StaticMediaFormat,
  N_VideoFormat,
  N_WebMediaFormat,
} from "@specsheet-common/shared-types";
import { notNull } from "../../typescript";

const getStaticMediaFormats = (format: N_StaticMediaFormat | null): string[] =>
  [
    format && format.ai !== null ? "AI" : null,
    format && format.bmp !== null ? "BMP" : null,
    format && format.eps !== null ? "EPS" : null,
    format && format.gif !== null ? "GIF" : null,
    format && format.jpg !== null ? "JPG" : null,
    format && format.png !== null ? "PNG" : null,
    format && (format.psdFlattened !== null || format.psdLayered !== null)
      ? "PSD"
      : null,
    format && format.svg !== null ? "SVG" : null,
    format && format.tiff !== null ? "TIFF" : null,
    ...(format && format.other !== null
      ? format.other.map((o) => o.value)
      : []),
  ].filter(notNull);

const getPrintMediaFormats = (format: N_PrintMediaFormat): string[] =>
  [
    format.ai !== null ? "AI" : null,
    format.eps !== null ? "EPS" : null,
    format.indd !== null ? "INDD" : null,
    format.pdf !== null ? "PDF" : null,
    format.psd !== null ? "PSD" : null,
    format.quarkXPress !== null ? "QuarkXPress" : null,
    ...(format.other !== null ? format.other.map((o) => o.value) : []),
  ].filter(notNull);

const getAnimatedMediaFormats = (format: N_AnimatedMediaFormat): string[] =>
  [
    format.gif !== null ? "GIF" : null,
    ...(format.other !== null ? format.other.map((o) => o.value) : []),
  ].filter(notNull);

const getVideoFormats = (format: N_VideoFormat): string[] =>
  [
    format.AVI !== null ? "AVI" : null,
    format.FLV !== null ? "FLV" : null,
    format.MOV !== null ? "MOV" : null,
    format.MP4 !== null ? "MP4" : null,
    format.OGG !== null ? "OGG" : null,
    format.ThreeGP !== null ? "3GP" : null,
    format.WMV !== null ? "WMV" : null,
    format.WebM !== null ? "WebM" : null,
    ...(format.other !== null ? format.other.map((o) => o.value) : []),
  ].filter(notNull);

const getAudioFormats = (format: N_AudioFormat): string[] =>
  [
    format.AAC !== null ? "AAC" : null,
    format.AIFF !== null ? "AIFF" : null,
    format.MP3 !== null ? "MP3" : null,
    format.WAV !== null ? "WAV" : null,
    ...(format.other !== null ? format.other.map((o) => o.value) : []),
  ].filter(notNull);

const getWebMediaFormats = (format: N_WebMediaFormat): string[] =>
  [
    format.CSS !== null ? "CSS" : null,
    format.HTML5 !== null ? "HTML5" : null,
    format.JS !== null ? "JS" : null,
  ].filter(notNull);

const getHtml5Formats = (formats: N_Html5Formats): string[] =>
  [
    ...(formats.animated !== null
      ? getAnimatedMediaFormats(formats.animated.value)
      : []),
    ...(formats.static !== null
      ? getStaticMediaFormats(formats.static.value)
      : []),
    ...(formats.video !== null
      ? getVideoFormats(formats.video.value.video.formats.value)
      : []),
    ...(formats.web !== null ? getWebMediaFormats(formats.web.value) : []),
  ].filter(notNull);

export const getFileFormats = (
  specs: N_NamedAnnotated<N_ArtworkSpec>[]
): N_NamedAnnotated<string>[] =>
  specs.reduce<N_NamedAnnotated<string>[]>(
    (formats, spec) => [
      ...formats,
      ...[
        ...(spec.value.kind === "digitalStatic"
          ? getStaticMediaFormats(
              spec.value.value.formats && spec.value.value.formats.value
            )
          : []),
        ...(spec.value.kind === "video"
          ? getVideoFormats(spec.value.value.formats.value)
          : []),
        ...(spec.value.kind === "audio"
          ? getAudioFormats(spec.value.value.formats.value)
          : []),
        ...(spec.value.kind === "html5"
          ? getHtml5Formats(spec.value.value.formats.value)
          : []),
        ...(spec.value.kind === "newspaper"
          ? getPrintMediaFormats(spec.value.value.formats.value)
          : []),
        ...(spec.value.kind === "magazine"
          ? getPrintMediaFormats(spec.value.value.formats.value)
          : []),
        ...(spec.value.kind === "animated"
          ? getAnimatedMediaFormats(spec.value.value.formats.value)
          : []),
        ...(spec.value.kind === "printOOH" && spec.value.value.formats !== null
          ? getPrintMediaFormats(spec.value.value.formats.value)
          : []),
      ].map((format) => ({ ...spec, value: format })),
    ],
    []
  );
