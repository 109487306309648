export const responseStatus = {
  OK: "OK",
  ERROR: "ERROR",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
} as const;

export type ResponseStatus =
  (typeof responseStatus)[keyof typeof responseStatus];

export type SuccessOrError_Success<DATA = void> = {
  result: typeof responseStatus.OK;
} & (DATA extends void
  ? { data?: never }
  : {
      data: DATA;
    });

export interface SuccessOrError_Error {
  result: typeof responseStatus.ERROR;
  message: string;
}

export interface SuccessOrError_UnknownError {
  result: typeof responseStatus.UNKNOWN_ERROR;
  message?: string;
}

export type SuccessOrError<DATA = void> =
  | SuccessOrError_Success<DATA>
  | SuccessOrError_Error
  | SuccessOrError_UnknownError;
