import {
  dimensionUnit,
  N_ArtworkSpec,
  N_DimensionUnit,
  N_NamedAnnotated,
  N_PrintDimensions,
} from "@specsheet-common/shared-types";

export const getPrintDimensionUnit = (unit: N_DimensionUnit): string => {
  switch (unit) {
    case dimensionUnit.m:
      return "m";
    case dimensionUnit.cm:
      return "cm";
    case dimensionUnit.mm:
      return "mm";
  }
};

export const stringifyPrintNewspaperMagazineDimension = (
  dimensions: N_PrintDimensions
): string =>
  `${dimensions.height.value} ${getPrintDimensionUnit(
    dimensions.height.unit
  )} x ${dimensions.width.value} ${getPrintDimensionUnit(
    dimensions.width.unit
  )}`;

export const getPrintDimensions = (
  specifications: N_NamedAnnotated<N_ArtworkSpec>[]
): (N_NamedAnnotated<string> & { width: number; height: number })[] =>
  specifications.reduce<
    (N_NamedAnnotated<string> & { width: number; height: number })[]
  >(
    (dimensions, spec) =>
      spec.value.kind === "newspaper"
        ? [
            ...dimensions,
            ...(spec.value.value.dimension !== null
              ? [
                  {
                    ...spec,
                    value: stringifyPrintNewspaperMagazineDimension(
                      spec.value.value.dimension.value
                    ),
                    width: spec.value.value.dimension.value.width.value,
                    height: spec.value.value.dimension.value.height.value,
                  },
                ]
              : []),
            ...(spec.value.value.additionalPrintDimensions !== null
              ? spec.value.value.additionalPrintDimensions.value.map((v) => ({
                  ...v,
                  value: stringifyPrintNewspaperMagazineDimension(v.value),
                  width: v.value.width.value,
                  height: v.value.height.value,
                }))
              : []),
          ]
        : dimensions,
    []
  );
