import z from "zod";
import { sitecardsData } from "../sitecards";

export const oohClassicBookingSchema = z
  .object({
    bookingType: z.string(),
    suburb: z.string(),
    state: z.string(),
    locationName: z.string(),
    frameName: z.string(),
    sizeType: z.string(),
    campaignNumber: z.number(),
    campaignName: z.string(),
    advertiser: z.string(),
    startDate: z.string(),
    endDate: z.string(),

    physicalHeight: z.number().optional(),
    physicalWidth: z.number().optional(),
    finishHeight: z.number().optional(),
    finishWidth: z.number().optional(),
    stock: z.string().optional(),
    printColour: z.string().optional(),
    printFormat: z.string().optional(),
    printResolution: z.number().optional(),
    bleedLeft: z.number().optional(),
    bleedTop: z.number().optional(),
    bleedRight: z.number().optional(),
    bleedBottom: z.number().optional(),
    trimSize: z.string().optional(),
    additionalInformation: z.string().optional(),
    overridePlacementId: z.string().optional(),
    originalSpecUrl: z.string().optional(),

    siteCardImage: z.string().optional(),
  })
  .merge(sitecardsData);

export type OohClassicBooking = z.infer<typeof oohClassicBookingSchema>;
