import { N_Instant, N_LocalDate } from "@specsheet-common/shared-types";
import { DateTime } from "luxon";

export const DURATION_PLACEHOLDER: string = "--:--:--";
export const DURATION_RANGE_PLACEHOLDER: string = "--:--:-- <--> --:--:--";
export const DATE_PLACEHOLDER: string = "--/--/----";
export const DATE_RANGE_PLACEHOLDER: string = "--/--/---- <--> --/--/----";

export const DEFAULT_DATE_FORMAT: string = "dd/LL/yyyy";
export const WEEKDAY_DATE_FORMAT: string = "EEE dd/LL/yyyy";
export const YYYMMDD_DATE_FORMAT: string = "yyyy/LL/dd";
export const DOT_DATE_FORMAT: string = "dd.LL.yyyy";
export const HUMAN_READABLE_DATE_FORMAT: string = "dd-LL-yyyy";
export const HUMAN_READABLE_DATE_FORMAT_EXPANDED: string = "dd LLLL yyyy";
export const PUBLISHED_DATE_FORMAT: string = "dd/LL/yyyy @ h.mma";
export const PUBLISHED_DATE_FORMAT_WITHOUT_TIME: string = "d LLL yyyy";
export const PUBLISHED_DATE_FORMAT_WITH_24_HOUR_TIME: string =
  "dd LLLL yyyy @ HH:mm";
export const PUBLISHED_DATE_FORMAT_WITH_24_HOUR_TIME_SHORT_MONTH: string =
  "dd LLL yyyy @ HH:mm";

export { DateTime } from "luxon";

export const DATABASE_TIMEZONE: string = "Australia/Sydney";

/**
 * Formats a local date into a given string format.
 */
export function formatDate(date: N_LocalDate, format: string): string {
  return DateTime.fromISO(date).toFormat(format);
}

/**
 * Formats an Instant in milliseconds since epoch into the given string format
 */
export function formatInstant(
  milliseconds: N_Instant,
  format: string,
  timeZone?: string
): string {
  const tz = timeZone || DATABASE_TIMEZONE;
  return DateTime.fromMillis(milliseconds).setZone(tz).toFormat(format);
}

export function toLocalUnixTime(milliseconds: N_Instant): N_Instant {
  const tz = DATABASE_TIMEZONE;
  const sydneyDate = DateTime.fromMillis(milliseconds).setZone(tz);
  const day = sydneyDate.get("day");
  const month = sydneyDate.get("month");
  const year = sydneyDate.get("year");
  const hours = sydneyDate.get("hour");
  const minutes = sydneyDate.get("minute");
  const seconds = sydneyDate.get("second");

  return (
    DateTime.now()
      .set({
        day: day,
        month: month,
        year: year,
        hour: hours,
        minute: minutes,
        second: seconds,
      })
      .toUnixInteger() * 1000
  );
}

export function toServerUnixTime(milliseconds: N_Instant): N_Instant {
  const tz = DATABASE_TIMEZONE;
  const sydneyDate = DateTime.fromMillis(milliseconds);
  const day = sydneyDate.get("day");
  const month = sydneyDate.get("month");
  const year = sydneyDate.get("year");
  const hours = sydneyDate.get("hour");
  const minutes = sydneyDate.get("minute");
  const seconds = sydneyDate.get("second");

  return (
    DateTime.now()
      .setZone(tz)
      .set({
        day: day,
        month: month,
        year: year,
        hour: hours,
        minute: minutes,
        second: seconds,
      })
      .toUnixInteger() * 1000
  );
}

export function formatInstantForServer(
  milliseconds?: N_Instant | null
): N_Instant | null {
  return dateToInstantNumber(
    milliseconds
      ? startOfDay(new Date(toServerUnixTime(milliseconds)), DATABASE_TIMEZONE)
      : null
  );
}

/**
 * Transforms LocalDate to a Javascript Date
 */
export function toJSDate(localDate: N_LocalDate): Date {
  return DateTime.fromISO(localDate).toJSDate();
}

export function toDate(localDate: string, fmt: string): Date {
  return DateTime.fromFormat(localDate, fmt).toJSDate();
}

export function toDateFromISO(localDate: string): Date {
  return DateTime.fromISO(localDate).toJSDate();
}

export function toUTCDate(localDate: string, fmt: string): Date {
  return DateTime.fromFormat(localDate, fmt, { zone: "UTC" }).toJSDate();
}

/**
 * Transforms Javascript Date to LocalDate in ISO format
 */
export function fromJSDate(date: Date): N_LocalDate {
  return DateTime.fromJSDate(date).toISODate();
}

export function formatDateAsDDMMYYY(
  date: N_Instant | N_LocalDate | Date
): string {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date).toFormat(HUMAN_READABLE_DATE_FORMAT);
  }

  if (typeof date === "string") {
    return DateTime.fromISO(date).toFormat(HUMAN_READABLE_DATE_FORMAT);
  }

  return DateTime.fromMillis(date).toFormat(HUMAN_READABLE_DATE_FORMAT);
}

export function formatDateAsMMDDYYYY(date: Date, toTimeZone?: string): string {
  if (toTimeZone) {
    return DateTime.fromJSDate(date)
      .setZone(toTimeZone)
      .toFormat(DEFAULT_DATE_FORMAT);
  }

  return DateTime.fromJSDate(date).toFormat(DEFAULT_DATE_FORMAT);
}

export function formatDateAsWeekdayMMDDYYYY(
  date: Date,
  toTimeZone?: string
): string {
  if (toTimeZone) {
    return DateTime.fromJSDate(date)
      .setZone(toTimeZone)
      .toFormat(WEEKDAY_DATE_FORMAT);
  }

  return DateTime.fromJSDate(date).toFormat(WEEKDAY_DATE_FORMAT);
}

export function formatDateAsPUBLISHED_DATE_FORMAT_WITHOUT_TIME(
  date: Date
): string {
  return DateTime.fromJSDate(date).toFormat(PUBLISHED_DATE_FORMAT_WITHOUT_TIME);
}

export function formatDateAsPUBLISHED_DATE_FORMAT(
  date: Date,
  toTimeZone?: string
): string {
  if (toTimeZone) {
    return DateTime.fromJSDate(date)
      .setZone(toTimeZone)
      .toFormat(PUBLISHED_DATE_FORMAT);
  }

  return DateTime.fromJSDate(date).toFormat(PUBLISHED_DATE_FORMAT);
}

export function durationStringToInstant(duration: string): N_Instant {
  if (duration === "") {
    return 0;
  }

  const updates = [
    (date: DateTime, v: number) => date.plus({ hours: v }),
    (date: DateTime, v: number) => date.plus({ minutes: v }),
    (date: DateTime, v: number) => date.plus({ seconds: v }),
  ];

  const date = DateTime.now().setZone(DATABASE_TIMEZONE).startOf("day");
  const instant =
    duration
      .split(":")
      .reduce((date, time, idx) => {
        return updates[idx](date, Number.parseInt(time));
      }, date)
      .toUnixInteger() * 1000;
  return instant ?? 0;
}

export function templatedStringForDurationCell(duration: N_Instant): string {
  const formattedString = formatInstant(Number(duration), "HH:mm:ss");

  const rawTime = formattedString
    .split(":")
    .map((item) => item.replace(/^0+/, ""));

  const units = ["h", "m", "s"];

  const timeDurationString = rawTime
    .map((value, index) => value + units[index])
    .filter((str) => str.length > 1)
    .join(" ");

  return timeDurationString ? timeDurationString : "--:--:--";
}

export function stringLabelSinceDate(
  date: Date,
  isExtended?: boolean,
  useDefaultFormat?: boolean
): string {
  const dateNow = new Date();
  const msSince = dateNow.getTime() - date.getTime();
  const minutesSince = Math.floor(msSince / (1000 * 60));
  const hoursSince = Math.floor(msSince / (1000 * 60 * 60));
  const daysSince = Math.floor(msSince / (1000 * 60 * 60 * 24));
  const monthsSince = Math.floor(msSince / (1000 * 60 * 60 * 24 * 30));
  const yearsSince = Math.floor(msSince / (1000 * 60 * 60 * 24 * 365));

  if (minutesSince < 1) {
    return `Just now`;
  }
  if (minutesSince < 60) {
    return `${minutesSince} minute${minutesSince < 2 ? "" : "s"} ago`;
  }
  if (minutesSince < 60 * 24) {
    return `${hoursSince} hour${hoursSince < 2 ? "" : "s"} ago`;
  }

  if (!isExtended) {
    return DateTime.fromJSDate(date).toFormat(
      useDefaultFormat
        ? DEFAULT_DATE_FORMAT
        : PUBLISHED_DATE_FORMAT_WITH_24_HOUR_TIME
    );
  }

  if (daysSince < 30) {
    return `${daysSince} day${daysSince < 2 ? "" : "s"} ago`;
  }

  if (monthsSince < 12) {
    return `${monthsSince} month${monthsSince < 2 ? "" : "s"} ago`;
  }

  return `${yearsSince} year${yearsSince < 2 ? "" : "s"} ago`;
}

export function dateToLocalDateString<T extends Date | string | null>(
  date: T
): T extends Date ? N_LocalDate : null;

export function dateToLocalDateString(
  date: Date | string | null
): N_LocalDate | null {
  if (typeof date === "string") {
    return new Date(date).toISOString().split("T")[0];
  }

  if (date instanceof Date) {
    return date.toISOString().split("T")[0];
  }
  return null;
}

export function dateToInstantNumber<T extends Date | null>(
  date: T
): T extends Date ? N_Instant : null;

export function dateToInstantNumber(date: Date | null): N_Instant | null {
  if (date instanceof Date) {
    return date.getTime();
  }
  return null;
}

export function startOfDay(date: Date, zone?: string): Date {
  return DateTime.fromJSDate(date).setZone(zone).startOf("day").toJSDate();
}

export function endOfDay(date: Date): Date {
  return DateTime.fromJSDate(date).endOf("day").toJSDate();
}

export function addDays(date: Date, days: number): Date {
  return DateTime.fromJSDate(date).plus({ days }).toJSDate();
}

export function timestampToDateString(timestamp: number): string {
  return fromJSDate(new Date(timestamp));
}

const weekdays = [1, 2, 3, 4, 5];
export function addBusinessDays(
  date: Date,
  days: number,
  timeZone?: string
): Date {
  let d = DateTime.fromJSDate(date);
  if (timeZone) {
    d = d.setZone(timeZone);
  }
  return addBusinessDaysDateTime(d, days).toJSDate();
}

function addBusinessDaysDateTime(date: DateTime, days: number): DateTime {
  const isNegative = days < 0;
  const businessDaysLeftToAdd = isNegative ? days + 1 : days - 1;

  if (days === 0) {
    return date;
  }

  const addedDate = date.plus({ days: isNegative ? -1 : 1 });
  return addBusinessDaysDateTime(
    addedDate,
    weekdays.includes(addedDate.weekday) ? businessDaysLeftToAdd : days
  );
}

/**
 * For a JS Date in a local timezone, remove the timezone info but preserve
 * the time information. So 2022-01-01T00:00:00+11:00 becomes
 * 2022-01-01T00:00:00Z. This is mainly for use in react-datepicker which
 * refuses to capture anything other than the local timezone grr
 */
export const forceUtc = (d: Date): Date => {
  const local = DateTime.fromJSDate(d);
  const utc = DateTime.utc(
    local.year,
    local.month,
    local.day,
    local.hour,
    local.minute,
    local.second,
    local.millisecond
  );
  return utc.toJSDate();
};

export const getCurrentTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const addMilliseconds = (date: Date, milliseconds: number): Date => {
  const result = new Date(date);
  result.setMilliseconds(result.getMilliseconds() + milliseconds);
  return result;
};

export const subtractSeconds = (date: Date, seconds: number): Date => {
  const result = new Date(date);
  result.setSeconds(date.getSeconds() - seconds);

  return result;
};

export function sameDay(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

// returns a date as dd/mm/yyyy as a string
export const getCurrentDateToString = (): string => {
  const today = DateTime.now();
  return today.toFormat("dd/LL/yyyy");
};

export function timestampGapLessThanDay(
  oldInstant: N_Instant,
  newInstant: N_Instant
): boolean {
  const twentyFourHours = 86_400_000;
  return oldInstant - twentyFourHours < newInstant;
}
