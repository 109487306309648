import {
  N_WysiwygDescendant,
  wysiwygItemKind,
} from "@specsheet-common/shared-types";

export const convertWysiwygDependentToString = (
  data: N_WysiwygDescendant
): string => {
  switch (data.kind) {
    case wysiwygItemKind.paragraph: {
      return data.value.map((v) => v.text).join(" ");
    }
    case wysiwygItemKind.bulletedList:
    case wysiwygItemKind.numberedList: {
      return data.value
        .map((v) => v.value.map((vs) => vs.text).join(" "))
        .join("\r\n");
    }
  }
};
