export const infoSchemaSubRoute = {
  list: "list",
  create: "create",
  updateFieldValue: "fieldValue",
  createFieldValue: "fieldValue",
  deleteFieldValue: "fieldValue",
  withValues: "fieldValues",
  addInfoSchemaValue: "infoSchemaValue",
  duplicateInfoSchemaValue: "infoSchemaValue",
  deleteAllInfoSchemaValues: "infoSchemaValue/deleteAll",
  deleteMultipleInfoSchemaValues: "infoSchemaValue/deleteMultiple",
} as const;

export const infoSchemaUrls = {
  info_schema: "infoschemas",
  "info_schema.link.preview": "infoschemas/linkPreview",
} as const;

export type InfoSchemaRoutes =
  | {
      sub: typeof infoSchemaSubRoute.create;
      method?: "Get";
    }
  | {
      sub: typeof infoSchemaSubRoute.list;
      method?: "Post";
    }
  | {
      sub: typeof infoSchemaSubRoute.createFieldValue;
      method?: "Post";
    }
  | {
      sub: typeof infoSchemaSubRoute.updateFieldValue;
      method?: "Patch";
    }
  | {
      sub: typeof infoSchemaSubRoute.deleteFieldValue;
      method?: "Delete";
    }
  | {
      sub: typeof infoSchemaSubRoute.withValues;
      method?: "Get";
    }
  | {
      sub: typeof infoSchemaSubRoute.addInfoSchemaValue;
      method?: "Post";
    }
  | {
      sub: typeof infoSchemaSubRoute.duplicateInfoSchemaValue;
      method?: "Patch";
    }
  | {
      sub: typeof infoSchemaSubRoute.deleteAllInfoSchemaValues;
      method?: "Delete";
    }
  | {
      sub: typeof infoSchemaSubRoute.deleteMultipleInfoSchemaValues;
      method?: "Delete";
    };
